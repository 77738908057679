import {
  GET_API_KEY_FAIL,
  GET_API_KEY_RESET,
  GET_API_KEY_START,
  GET_API_KEY_SUCCESS,
} from './constants';
import {GetApiKeyActions, GetApiKeyState} from './types';

const initialState: GetApiKeyState = {
  loading: false,
  data: undefined,
  error: undefined,
};

const reducer = (
  state = initialState,
  action: GetApiKeyActions,
): GetApiKeyState => {
  switch (action.type) {
    case GET_API_KEY_START:
      return {
        ...initialState,
        loading: true,
      };
    case GET_API_KEY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case GET_API_KEY_FAIL:
     return {
        ...state,
        loading: false,
        error: action.error,
      };
    case GET_API_KEY_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
