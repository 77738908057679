import Adjust from "@adjustcom/adjust-web-sdk";
import 'firebase/analytics';
import firebase from 'firebase/app';
import 'firebase/remote-config';
import {Faq} from "../models/custom/faq";
import {Helpers} from '../utilities/helpers';

export default class FirebaseService {
  private static readonly config = {
    apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
    authDomain: `${process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}`,
    databaseURL: `${process.env.REACT_APP_FIREBASE_DATABASE_URL}`,
    projectId: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}`,
    storageBucket: `${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}`,
    messagingSenderId: `${process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID}`,
    appId: `${process.env.REACT_APP_FIREBASE_APP_ID}`,
    measurementId: `${process.env.REACT_APP_FIREBASE_MEASUREMENT_ID}`,
  };

  private static readonly firebaseApp: firebase.app.App = firebase.initializeApp(FirebaseService.config);
  private static readonly analytics: firebase.analytics.Analytics = FirebaseService.firebaseApp.analytics();
  private static readonly remoteConfig: firebase.remoteConfig.RemoteConfig = FirebaseService.firebaseApp.remoteConfig();

  /*  
    
    Kim GB İster ile yaptığım kampanyalar 3 operatör için de geçerli midir?|  faq_question
    Kim GB İster'den yapacağınız puan alışverişiniz 3 operatörü de kapsamaktadır.| faq_answer
  */

  constructor() {
    FirebaseService.remoteConfig.defaultConfig = {
      campaign_desc_turkcell: "Turkcell'lilere özel %33 indirimle GB'ları düşünmeden alıp kolayca hediye edebilirsin.<br/>Bu sayede hem işin büyüyecek hem müşteri memnuniyetin artacak! Bereketin bol olsun!",
      campaign_limit_error_button: "Tamam",
      campaign_limit_error_desc: "Kampanya limiti 1000 adet ile sınırlıdır, girmiş olduğunuz paket miktarını kontrol edip tekrar deneyiniz.",
      campaign_limit_error_title: "Kampanya limitini aştınız!",
      campaign_title_turkcell: "Hey Turkcell'li, kazançlısın!",
      contact_subject: "Paket satın alma|Paket yükleme|Bilgi alma",
      faq_answer: "Yüklemeleriniz 1 saat içerisinde gerçekleşir ve müşterileriniz seçtiğiniz  marka adınızın kullanılarak kazandığı hediye ile ilgili SMS yoluyla bilgilendirilir.|Hayır, müşterileriniz dilerse Kim GB İster puanlarını biriktirmek için uygulamayı indirebilir ya da indirmeden SMS yolu ile direkt kullanabilirler. |Kim GB İster cüzdanda biriken puanlar yüklendiği tarihten itibaren 1 sene boyunca müşteriniz tarafından kullanılabilir.|<img src=\"https://static.4play.com.tr/kurumsal-kimgbister/campaign-type-usage-code.svg\" alt=\"how to\"/>|<img src=\"https://static.4play.com.tr/kurumsal-kimgbister/campaign-type-usage-link.svg\" alt=\"how to\"/>|<img src=\"https://static.4play.com.tr/kurumsal-kimgbister/campaign-type-usage-bulk.svg\" alt=\"how to\"/>|<img src=\"https://static.4play.com.tr/kurumsal-kimgbister/campaign-type-usage-api.svg\" alt=\"how to\"/>|Aldığınız puanların iadesi mümkün değildir. ",
      faq_question: "Müşterilerim hediyelerini yüklemelerimi gerçekleştirdikten ne kadar zaman sonra alır?|Müşterilerimin hediyesini kullanmak için Kim GB İster uygulamasına sahip olmaları gerekir mi?|Müşterilerim hediye ettiğim Kim GB İster puanlarını biriktirme kararı alırsa cüzdanlarındaki puanları ne zamana kadar kullanabilirler?|Kod ile yükle nasıl kullanılır?|Link ile yükle nasıl kullanılır?|Toplu yükle nasıl kullanılır?|API ile yükle nasıl kullanılır? |Aldığım puanları iade edebilir miyim?",
      generate_code_error_desc: "Sorunun çözülmezse bize <a href=\"https://campaign-wizard.solidict.com/help/contactus\"_blank\" rel=\"noreferrer\" style=\"text-decoration: underline;color: #3290C3;\">buradan</a>  ulaşabilirsin.",
      generate_code_error_title: "Teknik bir hata oldu!",
      landing_header_campaign_tab: "Kampanya hedefleri",
      landing_header_campaigns_tab: 'Kampanyalarım',
      landing_header_customer_tab: "Müşteri deneyimi",
      landing_header_how_to_use_tab: "Nasıl kullanılır?",
      landing_header_integration_tab: "Entegrasyon",
      landing_header_login: "Giriş yap",
      landing_header_signup: "Kayıt ol",
      landing_mobile_try_link_button_close: "Vazgeç",
      landing_mobile_try_link_button_try: "Dene",
      landing_mobile_try_link_desc: "Hızlı test etmek için;",
      landing_mobile_try_link_note: "Yukarıdaki linke tıkların ve adımları takip edin.",
      landing_mobile_try_link_title: "Link ile yükle",
      landing_section1_button: "Hızlı kayıt",
      landing_section1_desc: "Kim GB İster ile işte bu kadar kolay.",
      landing_section1_main_title: "Tebrikler!",
      landing_section1_note: "Kayıt olan bazı markalar;",
      landing_section1_title: "Markanızın hedefine ulaştınız!",
      landing_section2_desc: "Türkiye, internette tüm dünyadan 1 saat daha fazla zaman harcıyor. Dolayısıyla 7'den 77'ye herkesin internet promosyonuna motivasyonu yükseliyor.",
      landing_section2_header: "Mobil promosyon etkisi",
      landing_section2_info1_desc: "Kim GB İster ile yapılan kampanyalar, diğer kampanyalara oranla %70 daha fazla tercih ediliyor.",
      landing_section2_info1_title: "Etkinizi %70 artırın",
      landing_section2_info2_desc: "Kim GB İster ile yapılan kampanyalar, hedeflenen işlemleri %5 artırıyor.",
      landing_section2_info2_title: "Hedefinizi %5 artırın",
      landing_section2_note: "3 operatör desteklidir. ",
      landing_section2_title: "İnternet; en temel ihtiyaç!",
      landing_section3_desc: "\"Hedefinizi besleyecek çevrimiçi ve/veya çevrimdışı her noktaya kolayca entegre olabilir.  Daha önceki kampanyalarda oluşan hedef ve kurgu örnekleri sağdaki gibidir. \"",
      landing_section3_header: "Kampanya hedefleri",
      landing_section3_model1: "Satış",
      landing_section3_model1_desc: "\"Belirli sepet tutarına  Belirli kategori satışlarına  Belirli bölge satışlarına  GB puan hediye ederek satışlarınızı %5 artırın. \"",
      landing_section3_model1_title: "Satışlarınızı anında artırın!",
      landing_section3_model2: "Yeni kazanım",
      landing_section3_model2_desc: "\"Belirli müşteri aksiyonlarına  Sadık müşteri ödüllendirmelerinde  GB puan hediye ederek diğer kampanyalarınızda %70 daha fazla verim alın. \"",
      landing_section3_model2_title: "Müşterilerinizin aktifliğini artırın!",
      landing_section3_model3: "Tutundurma",
      landing_section3_model3_desc: "\" Uygulama indirmeye  İlk alışverişe  Arkadaşını getirene  Abonelik başlatmaya  Yüksek trafik sağlanmasında  Üye olana ve/veya form doldurana   GB puan hediye ederek geniş kitlelere hitap edin. \"",
      landing_section3_model3_title: "Yeni müşteriler kazanın!",
      landing_section3_model4: "Müşteri ilişkisi ",
      landing_section3_model4_desc: "\" Olumsuz deneyim yaşayan müşterinize  Müşteri memnuniyeti anketinize katılanlara  Müşteri sadakatini artırmaya yönelik çalışmalara   GB puan hediye ederek mutlu müşteriler yaratın. \"",
      landing_section3_model4_title: "Müşteri memnuniyetini artırın!",
      landing_section3_model5: "Çalışan mutluluğu",
      landing_section3_model5_desc: "\" Motivasyonu artırmada  Çalışan bağlılığını güçlendirmede  Çalışanlarınızı ödüllendirmede  GB puan hediye ederek mutlu çalışanlar yaratın. \"",
      landing_section3_model5_title: "Çalışan mutluluğunu artırın!",
      landing_section3_title: "Nerede kullanılır?",
      landing_section4_desc: "Kayıt aşamasından sonra kolayca kampanyanızı hazırlayın, 1 saat içinde entegre olun ve başlayın!",
      landing_section4_header: "3 adımda mobil promosyon",
      landing_section4_step1: "Kayıt ol",
      landing_section4_step2: "Puan al",
      landing_section4_step3: "Puan hediye et",
      landing_section4_title: "Nasıl kullanılır?",
      landing_section5_desc: "\"Altyapınıza uyumlu entegrasyon yöntemini seçin, teknik eforunuz olmadan kampanya başlatın.  Yöntemleri kayıt olmadan deneyebilirsiniz.\"",
      landing_section5_header: "Entegrasyon",
      landing_section5_model1: "Kod",
      landing_section5_model1_desc: "\" Kampanyanıza özel dilediğiniz hacimde benzersiz kod üretilir.   Kodları mobil uygulamanızda, websitenizde ve/veya mağazanızda müşterinize hediye edersiniz.   Müşteriniz kodunu ücretsiz olarak 6610'a SMS atar ve hediyesini alır.   Bu sayede müşterinizin telefon numarasını paylaşmadan kampanya yapabilirsiniz.  \"",
      landing_section5_model1_title: "Kod ile yükle",
      landing_section5_model2: "Link",
      landing_section5_model2_desc: "\"  Kampanyanıza özel dilediğiniz hacimde benzersiz link üretilir.   Linkleri mobil uygulamanızda, ve/veya mobil websitenizde müşteri temas noktasına yerleştirirsiniz.  Müşteriniz linke tıklar, hazır mesajlı SMS sayfası açılır, 6610'a ücretsiz SMS atmak için gönder butonuna tıklar ve hediyesini alır.  Bu sayede müşterinizin telefon numarasını paylaşmadan kampanya yapabilirsiniz.  \"",
      landing_section5_model2_title: "Link ile yükle",
      landing_section5_model3: "Toplu yükle",
      landing_section5_model3_desc: "\"  Kampanyanıza katılan müşterilerinizin telefon numaralarını listelersiniz.  Listenizi Kim GB İster panel arayüzüne yüklersiniz.  Yüklemeyi gerçekleştirdiğiniz anda müşterileriniz SMS ile bilgilendirilerek hediyelerini alır.  Yükeleme gerçekleştikten sonra telefon numarası verisi sistemde tutulmaz. \"",
      landing_section5_model3_title: "Toplu yükle",
      landing_section5_model4: "API",
      landing_section5_model4_desc: "Size özel üretilen API key'i kampanya noktalarına entegre edersiniz.  Kampanya şartını yerine getiren müşterileriniz otomatik bir şekilde SMS ile bilgilendirilerek hediyelerini alır.  Bu sayede tüm kampanya sürecini otomatize edebilir ve otomatik yenile seçeneği ile kampanyanızın kesintisiz devam etmesini sağlayabilirsiniz.",
      landing_section5_model4_title: "API ile yükle",
      landing_section5_title: "Size en uygun yöntemi seçin",
      landing_section5_try_button: "Dene",
      landing_section6_desc: "Müşterileriniz kazandıkları hediyeleri, uygulama indirmeksizin, SMS içerisinde yer alan website linkinden hemen GB faydasına dönüştürebilir veya GB Puan Cüzdan uygulamasını indirerek daha sonraki ihtiyaç anında kullanabilir.",
      landing_section6_feature1: "Puan kazanma",
      landing_section6_feature2: "Puanı GB paketine dönüştürme",
      landing_section6_feature3: "Puan biriktirme",
      landing_section6_feature4: "Puan gönderme",
      landing_section6_header: "Müşterilerinizin deneyimi",
      landing_section6_title: "Uygulama indirilmesine gerek olmaz",
      landing_section7_contactus: "Bize Ulaş",
      landing_section7_desc: "Müşterilerinizin temel ihtiyaçlarına dokunun.",
      landing_section7_info1_desc: "Tüm operatörlerin ortak ürününden faydalanın.",
      landing_section7_info1_title: "3 Operatör",
      landing_section7_info2_desc: "Zengin kullanıcı deneyimi ile müşteri memnuniyetini artırın.",
      landing_section7_info2_title: "İnternete dönüşen puan",
      landing_section7_signup: "Kayıt ol",
      landing_section7_title: "Hedeflerinize hızla ulaşmak için hemen kayıt olun!",
      landing_try_code_button: "Anladım",
      landing_try_code_desc: "Hızlı test etmek için;",
      landing_try_code_note: "Yukarıdaki kodu 6610’a ücretsiz SMS atın ve adımları takip edin.",
      landing_try_code_title: "Kod ile yükle",
      landing_try_link_button: "Anladım",
      landing_try_link_desc: "Hızlı test etmek için;",
      landing_try_link_note: "Bu linke mobil cihaz üzerinden tıklamanız gerekmektedir.",
      landing_try_link_title: "Link ile yükle",
      point_page_campaign_note: "*Kodlarınızı 6610'a SMS atarak internete çevirebilirsiniz.",
      point_redeem_nonturkcell_error: "Sorunun çözülmezse bize<a href=\"https://campaign-wizard.solidict.com/help/contactus\"_blank\" rel=\"noreferrer\" style=\"text-decoration: underline;color: #3290C3;\">buradan</a>  ulaşabilirsin.",
    };
  }

  public async fetchAndActivateRemoteConfig(): Promise<void> {
    await FirebaseService.firebaseApp
      .remoteConfig()
      .fetchAndActivate()
      .then(() => {
      })
      .catch(() => {
      });
  }

  public static getValue(key: string): string {
    return this.remoteConfig.getValue(key).asString();
  }

  public static logEvent(key: string): void {
    if (!Helpers.isEnvProd()) {
      console.log(`>> EVENT: ${key}`);
    }
    this.analytics.logEvent(key);
    this.sendAdjustEventIfAvailable(key);
  }

  private static sendAdjustEventIfAvailable(fbEventKey: string): void {
    let adjustId = '';
    switch (fbEventKey) {
      case FirebaseService.landing_view_without_login:
        adjustId = 'a3tl5i';
        break;
      case FirebaseService.landing_view_with_login:
        adjustId = 'vtputg';
        break;
      case FirebaseService.count_sign_up_clicked:
        adjustId = 'g435ir';
        break;
      case FirebaseService.count_login_clicked:
        adjustId = 'vfb4zh';
        break;
      case FirebaseService.user_count_campaign_goals_clicked:
        adjustId = 'twv8tr';
        break;
      case FirebaseService.user_count_landing_how_to_use_clicked:
        adjustId = 'h7703q';
        break;
      case FirebaseService.user_count_landing_integration_clicked:
        adjustId = '9j4p6r';
        break;
      case FirebaseService.user_count_customer_experience_clicked:
        adjustId = 'e8qtmt';
        break;
      case FirebaseService.user_count_control_panel_clicked:
        adjustId = 'kef2up';
        break;
      case FirebaseService.user_count_landing_integration_code_try_clicked:
        adjustId = 'wu5a95';
        break;
      case FirebaseService.user_count_landing_integration_link_try_clicked:
        adjustId = '77jdah';
        break;
      case FirebaseService.user_count_landing_integration_bulk_try_clicked:
        adjustId = 'ov887s';
        break;
      case FirebaseService.user_count_landing_integration_api_try_clicked:
        adjustId = 'ur6yp4';
        break;
      case FirebaseService.user_count_landing_link_popup_copy_clicked:
        adjustId = 's9we3d';
        break;
      case FirebaseService.user_count_landing_bulk_popup_upload_clicked:
        adjustId = 'xfwsg4';
        break;
      case FirebaseService.nav_bar_quick_buy_points_clicked:
        adjustId = 'hpqh8p';
        break;
      case FirebaseService.nav_bar_quick_create_campaign_clicked:
        adjustId = '64iwjx';
        break;
      case FirebaseService.nav_bar_campaign_panel_clicked:
        adjustId = 'm5b5gj';
        break;
      case FirebaseService.nav_bar_quick_load_clicked:
        adjustId = '6pl8sq';
        break;
      case FirebaseService.landing_view_total:
        adjustId = 'ble8hv';
        break;
      case FirebaseService.payment_error_view:
        adjustId = 'vvykv2';
        break;
      case FirebaseService.create_new_campaign_view:
        adjustId = 'jedree';
        break;
      case FirebaseService.create_new_campaign_no_points_clicked:
        adjustId = 'fwvljj';
        break;
      case FirebaseService.approve_clicked_code:
        adjustId = 'h3e0u1';
        break;
      case FirebaseService.approve_clicked_link:
        adjustId = 'qzrs3m';
        break;
      case FirebaseService.approve_clicked_bulk:
        adjustId = 'o229f1';
        break;
      case FirebaseService.approve_clicked_api:
        adjustId = 'v03610';
        break;
    }
    if (adjustId) {
      if (!Helpers.isEnvProd()) {
        console.log(`>> ADJUST: ${adjustId}`);
      }
      this.adjustEvent(adjustId);
    }
  }

  private static adjustEvent(eventId: string): void {
    Adjust.trackEvent({
      eventToken: eventId,
    })
  }

  public static getContactSubjects(): string[] {
    return (this.getValue(this.contact_subject) as string).split('|');
  }

  public static getFaqs(): Faq[] {
    const faqs: Faq[] = [];
    const questions = (this.getValue(this.faq_question) as string).split('|');
    const answers = (this.getValue(this.faq_answer) as string).split('|');
    questions.forEach((question, i) => faqs.push({question, answer: answers[i]}));
    return faqs;
  }

  // KEYS
  public static readonly campaign_desc_turkcell = 'campaign_desc_turkcell';
  public static readonly campaign_limit_error_button = 'campaign_limit_error_button';
  public static readonly campaign_limit_error_desc = 'campaign_limit_error_desc';
  public static readonly campaign_limit_error_title = 'campaign_limit_error_title';
  public static readonly campaign_title_turkcell = 'campaign_title_turkcell';
  public static readonly contact_subject = 'contact_subject';
  public static readonly faq_answer = 'faq_answer';
  public static readonly faq_question = 'faq_question';
  public static readonly generate_code_error_desc = 'generate_code_error_desc';
  public static readonly generate_code_error_title = 'generate_code_error_title';
  public static readonly landing_header_campaign_tab = 'landing_header_campaign_tab';
  public static readonly landing_header_campaigns_tab = 'landing_header_campaigns_tab';
  public static readonly landing_header_customer_tab = 'landing_header_customer_tab';
  public static readonly landing_header_how_to_use_tab = 'landing_header_how_to_use_tab';
  public static readonly landing_header_integration_tab = 'landing_header_integration_tab';
  public static readonly landing_header_login = 'landing_header_login';
  public static readonly landing_header_signup = 'landing_header_signup';
  public static readonly landing_section1_button = 'landing_section1_button';
  public static readonly landing_section1_desc = 'landing_section1_desc';
  public static readonly landing_section1_main_title = 'landing_section1_main_title';
  public static readonly landing_section1_note = 'landing_section1_note';
  public static readonly landing_section1_title = 'landing_section1_title';
  public static readonly landing_section2_desc = 'landing_section2_desc';
  public static readonly landing_section2_header = 'landing_section2_header';
  public static readonly landing_section2_info1_desc = 'landing_section2_info1_desc';
  public static readonly landing_section2_info1_title = 'landing_section2_info1_title';
  public static readonly landing_section2_info2_desc = 'landing_section2_info2_desc';
  public static readonly landing_section2_info2_title = 'landing_section2_info2_title';
  public static readonly landing_section2_note = 'landing_section2_note';
  public static readonly landing_section2_title = 'landing_section2_title';
  public static readonly landing_section3_desc = 'landing_section3_desc';
  public static readonly landing_section3_header = 'landing_section3_header';
  public static readonly landing_section3_model1 = 'landing_section3_model1';
  public static readonly landing_section3_model1_desc = 'landing_section3_model1_desc';
  public static readonly landing_section3_model1_title = 'landing_section3_model1_title';
  public static readonly landing_section3_model2 = 'landing_section3_model2';
  public static readonly landing_section3_model2_desc = 'landing_section3_model2_desc';
  public static readonly landing_section3_model2_title = 'landing_section3_model2_title';
  public static readonly landing_section3_model3 = 'landing_section3_model3';
  public static readonly landing_section3_model3_desc = 'landing_section3_model3_desc';
  public static readonly landing_section3_model3_title = 'landing_section3_model3_title';
  public static readonly landing_section3_model4 = 'landing_section3_model4';
  public static readonly landing_section3_model4_desc = 'landing_section3_model4_desc';
  public static readonly landing_section3_model4_title = 'landing_section3_model4_title';
  public static readonly landing_section3_model5 = 'landing_section3_model5';
  public static readonly landing_section3_model5_desc = 'landing_section3_model5_desc';
  public static readonly landing_section3_model5_title = 'landing_section3_model5_title';
  public static readonly landing_section3_title = 'landing_section3_title';
  public static readonly landing_section4_desc = 'landing_section4_desc';
  public static readonly landing_section4_header = 'landing_section4_header';
  public static readonly landing_section4_step1 = 'landing_section4_step1';
  public static readonly landing_section4_step2 = 'landing_section4_step2';
  public static readonly landing_section4_step3 = 'landing_section4_step3';
  public static readonly landing_section4_title = 'landing_section4_title';
  public static readonly landing_section5_desc = 'landing_section5_desc';
  public static readonly landing_section5_header = 'landing_section5_header';
  public static readonly landing_section5_model1 = 'landing_section5_model1';
  public static readonly landing_section5_model1_desc = 'landing_section5_model1_desc';
  public static readonly landing_section5_model1_title = 'landing_section5_model1_title';
  public static readonly landing_section5_model2 = 'landing_section5_model2';
  public static readonly landing_section5_model2_desc = 'landing_section5_model2_desc';
  public static readonly landing_section5_model2_title = 'landing_section5_model2_title';
  public static readonly landing_section5_model3 = 'landing_section5_model3';
  public static readonly landing_section5_model3_desc = 'landing_section5_model3_desc';
  public static readonly landing_section5_model3_title = 'landing_section5_model3_title';
  public static readonly landing_section5_model4 = 'landing_section5_model4';
  public static readonly landing_section5_model4_desc = 'landing_section5_model4_desc';
  public static readonly landing_section5_model4_title = 'landing_section5_model4_title';
  public static readonly landing_section5_title = 'landing_section5_title';
  public static readonly landing_section5_try_button = 'landing_section5_try_button';
  public static readonly landing_section6_desc = 'landing_section6_desc';
  public static readonly landing_section6_feature1 = 'landing_section6_feature1';
  public static readonly landing_section6_feature2 = 'landing_section6_feature2';
  public static readonly landing_section6_feature3 = 'landing_section6_feature3';
  public static readonly landing_section6_feature4 = 'landing_section6_feature4';
  public static readonly landing_section6_header = 'landing_section6_header';
  public static readonly landing_section6_title = 'landing_section6_title';
  public static readonly landing_section7_contactus = 'landing_section7_contactus';
  public static readonly landing_section7_desc = 'landing_section7_desc';
  public static readonly landing_section7_info1_desc = 'landing_section7_info1_desc';
  public static readonly landing_section7_info1_title = 'landing_section7_info1_title';
  public static readonly landing_section7_info2_desc = 'landing_section7_info2_desc';
  public static readonly landing_section7_info2_title = 'landing_section7_info2_title';
  public static readonly landing_section7_signup = 'landing_section7_signup';
  public static readonly landing_section7_title = 'landing_section7_title';
  public static readonly point_page_campaign_note = 'point_page_campaign_note';
  public static readonly point_redeem_nonturkcell_error = 'point_redeem_nonturkcell_error';
  public static readonly landing_try_code_title = 'landing_try_code_title';
  public static readonly landing_try_code_desc = 'landing_try_code_desc';
  public static readonly landing_try_code_note = 'landing_try_code_note';
  public static readonly landing_try_code_button = 'landing_try_code_button';
  public static readonly landing_mobile_try_link_title = 'landing_mobile_try_link_title';
  public static readonly landing_mobile_try_link_desc = 'landing_mobile_try_link_desc';
  public static readonly landing_mobile_try_link_note = 'landing_mobile_try_link_note';
  public static readonly landing_mobile_try_link_button_try = 'landing_mobile_try_link_button_try';
  public static readonly landing_mobile_try_link_button_close = 'landing_mobile_try_link_button_close';
  public static readonly landing_try_link_title = 'landing_try_link_title';
  public static readonly landing_try_link_desc = 'landing_try_link_desc';
  public static readonly landing_try_link_note = 'landing_try_link_note';
  public static readonly landing_try_link_button = 'landing_try_link_button';

  // EVENTS
  public static landing_view_without_login = 'landing_view_without_login';
  public static user_count_landing_view_without_login = 'user_count_landing_view_without_login';
  public static landing_view_with_login = 'landing_view_with_login';
  public static user_count_landing_view_with_login = 'user_count_landing_view_with_login';
  public static count_sign_up_clicked = 'count_sign_up_clicked';
  public static count_login_clicked = 'count_login_clicked';
  public static user_count_campaign_goals_clicked = 'user_count_campaign_goals_clicked';
  public static user_count_landing_how_to_use_clicked = 'user_count_landing_how_to_use_clicked';
  public static user_count_landing_integration_clicked = 'user_count_landing_integration_clicked';
  public static user_count_customer_experience_clicked = 'user_count_customer_experience_clicked';
  public static user_count_control_panel_clicked = 'user_count_control_panel_clicked';
  public static user_count_landing_integration_code_try_clicked = 'user_count_landing_integration_code_try_clicked';
  public static user_count_landing_integration_link_try_clicked = 'user_count_landing_integration_link_try_clicked';
  public static user_count_landing_integration_bulk_try_clicked = 'user_count_landing_integration_bulk_try_clicked';
  public static user_count_landing_integration_api_try_clicked = 'user_count_landing_integration_api_try_clicked';
  public static user_count_landing_link_popup_copy_clicked = 'user_count_landing_link_popup_copy_clicked';
  public static user_count_landing_bulk_popup_upload_clicked = 'user_count_landing_bulk_popup_upload_clicked';
  public static nav_bar_quick_buy_points_clicked = 'nav_bar_quick_buy_points_clicked';
  public static nav_bar_quick_create_campaign_clicked = 'nav_bar_quick_create_campaign_clicked';
  public static nav_bar_campaign_panel_clicked = 'nav_bar_campaign_panel_clicked';
  public static nav_bar_quick_load_clicked = 'nav_bar_quick_load_clicked';
  public static landing_view_total = 'landing_view_total';
  public static payment_error_view = 'payment_error_view';
  public static create_new_campaign_view = 'create_new_campaign_view';
  public static create_new_campaign_no_points_clicked = 'create_new_campaign_no_points_clicked';
  public static approve_clicked_code = 'approve_clicked_code';
  public static approve_clicked_link = 'approve_clicked_link';
  public static approve_clicked_bulk = 'approve_clicked_bulk';
  public static approve_clicked_api = 'approve_clicked_api';
}
