import axios from '../../../utilities/axios';
import {RegisterRequest} from '../../../models/requests/register.request';

export const registerRequest = async (request: RegisterRequest): Promise<void> => {
  try {
    const response = await axios.post<void>(
      `${process.env.REACT_APP_BASE_URL}/users/add-details`,
      {...request},
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
