import { Tabs } from "antd";
import { ReactNode, useState } from "react";
import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
// import arrow from "../../../../assets/images/landing/chevron-right-black.svg";
import api from "../../../../assets/images/landing/method-api.svg";
import bulk from "../../../../assets/images/landing/method-bulk.svg";
import code from "../../../../assets/images/landing/method-code.svg";
import link from "../../../../assets/images/landing/method-link.svg";
import FirebaseKey from "../../../../components/FirebaseKey/FirebaseKey";
import TryBulkModal from "../../../../components/Modals/TryModals/TryBulkModal/TryBulkModal";
import TryCodeModal from "../../../../components/Modals/TryModals/TryCodeModal/TryCodeModal";
import TryLinkModal from "../../../../components/Modals/TryModals/TryLinkModal/TryLinkModal";
import { setVideoUrl } from "../../../../core/services/appService/setVideoUrl/actions";
import FirebaseService from "../../../../core/services/firebase.service";
import { IStore } from "../../../../core/utilities/reducers";
import { UI } from "../../../../core/utilities/ui";
import FeatureCard from "../FeatureCard/FeatureCard";
import "./SectionIntegration.scss";

const { TabPane } = Tabs;

interface IProps {
  sectionRef: any;
  videoUrl: string;
  setVideoUrl: (url: string) => void;
}

const SectionIntegration = (props: IProps) => {
  const [method, setMethod] = useState(1);
  // const [isVideoVisited, setVideoVisited] = useState(false);
  // const [isVideoPlaying, setVideoPlaying] = useState(true);
  const [integrationModal, setIntegrationModal] = useState(0);

  // useEffect(() => {
  //   if (props.videoUrl.length > 0) {
  //     setVideoPlaying(false);
  //   }
  // }, [props.videoUrl]);

  // function handleVideoClick(event: React.MouseEvent, url: string): void {
  //   if (!isMobile) {
  //     if (!isSafari) {
  //       event.preventDefault();
  //       props.setVideoUrl(url);
  //     }
  //   }
  // }

  // function handleTryButtonClick(method: number): void {
  //   // setVideoPlaying(false);
  //   setIntegrationModal(method);
  //   switch (method) {
  //     case 1:
  //       FirebaseService.logEvent(
  //         FirebaseService.user_count_landing_integration_code_try_clicked
  //       );
  //       break;
  //     case 2:
  //       FirebaseService.logEvent(
  //         FirebaseService.user_count_landing_integration_link_try_clicked
  //       );
  //       break;
  //     case 3:
  //       FirebaseService.logEvent(
  //         FirebaseService.user_count_landing_integration_bulk_try_clicked
  //       );
  //       break;
  //     case 4:
  //       FirebaseService.logEvent(
  //         FirebaseService.user_count_landing_integration_api_try_clicked
  //       );
  //       break;
  //   }
  // }

  function handleCloseIntegrationModal(): void {
    setIntegrationModal(0);
  }

  function getMethodKey(method: number): string {
    return `landing_section5_model${method}`;
  }

  function renderMethodTabs(): ReactNode {
    return (
      <Tabs
        className="feature-tabs"
        defaultActiveKey={method.toString()}
        onChange={(key) => {
          // setVideoPlaying(true);
          setMethod(parseInt(key));
        }}
      >
        <TabPane tab={<FirebaseKey firebaseKey={getMethodKey(1)} />} key="1" />
        <TabPane tab={<FirebaseKey firebaseKey={getMethodKey(2)} />} key="2" />
        <TabPane tab={<FirebaseKey firebaseKey={getMethodKey(3)} />} key="3" />
        <TabPane tab={<FirebaseKey firebaseKey={getMethodKey(4)} />} key="4" />
      </Tabs>
    );
  }

  function renderMethod(): ReactNode {
    let icon = <img src={code} alt="method" />;
    // let videoUrl = "";
    const titleKey = `landing_section5_model${method}_title`;
    const descriptionKey = `landing_section5_model${method}_desc`;
    switch (method) {
      case 1:
        icon = <img src={code} alt="method" />;
        // videoUrl = Constants.videoUrlCode;
        break;
      case 2:
        icon = <img src={link} alt="method" />;
        // videoUrl = Constants.videoUrlLink;
        break;
      case 3:
        icon = <img src={bulk} alt="method" />;
        // videoUrl = Constants.videoUrlBulk;
        break;
      case 4:
        icon = <img src={api} alt="method" />;
        // videoUrl = Constants.videoUrlApi;
        break;
    }
    return (
      <div className="method row">
        <div className="col-12">
          <FeatureCard
            icon={icon}
            titleKey={titleKey}
            descriptionKey={descriptionKey}
          />
          {/* {method === 4 ? (
            <a
              href={Constants.linkApiDocumentation}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => handleTryButtonClick(method)}
            >
              <Button className="landing-button">
                <FirebaseKey
                  firebaseKey={FirebaseService.landing_section5_try_button}
                />
                <img className="arrow" src={arrow} alt="arrow" />
              </Button>
            </a>
          ) : (
            <Button
              className="landing-button"
              onClick={() => handleTryButtonClick(method)}
            >
              <FirebaseKey
                firebaseKey={FirebaseService.landing_section5_try_button}
              />
              <img className="arrow" src={arrow} alt="arrow" />
            </Button>
          )} */}
        </div>
        {/* <div className="video-wrapper col-12 col-md-5">
          <VisibilitySensor
            partialVisibility
            offset={{bottom: 200}}
            onChange={isVisible => {
              if (isVisible && !isVideoVisited) {
                setVideoVisited(true);
                setVideoPlaying(true);
              } else if (!isVisible && isVideoPlaying) {
                setVideoPlaying(false);
              }
            }}
          >
            <div
              className="player-wrapper"
              onClick={event => handleVideoClick(event, videoUrl)}
            >
              <Placeholder/>
              <ReactPlayer
                key={`integration-video-${method}`}
                controls
                muted
                loop
                className="react-player"
                width="100%"
                height="100%"
                url={videoUrl}
                playing={isVideoPlaying}
                onPlay={() => setVideoPlaying(true)}
                onPause={() => setVideoPlaying(false)}
              />
            </div>
          </VisibilitySensor>
        </div> */}
      </div>
    );
  }

  return (
    <div ref={props.sectionRef} id="section-integration" className="section">
      <div className="section-content">
        <div className="row w-xl-100">
          <div
            className={`section-left ${UI.getLandingSectionResponsiveClass()}`}
          >
            <div className="content">
              <FirebaseKey
                className="header"
                firebaseKey={FirebaseService.landing_section5_header}
              />
              <FirebaseKey
                className="title"
                firebaseKey={FirebaseService.landing_section5_title}
              />
              <FirebaseKey
                className="description"
                firebaseKey={FirebaseService.landing_section5_desc}
              />
            </div>
          </div>
          <div
            className={`section-right ${UI.getLandingSectionResponsiveClass()}`}
          >
            <div className="d-flex flex-column w-xl-100">
              {renderMethodTabs()}
              {renderMethod()}
            </div>
          </div>
        </div>
      </div>
      {integrationModal === 1 && (
        <TryCodeModal callbackClose={handleCloseIntegrationModal} />
      )}
      {integrationModal === 2 && (
        <TryLinkModal callbackClose={handleCloseIntegrationModal} />
      )}
      {integrationModal === 3 && (
        <TryBulkModal callbackClose={handleCloseIntegrationModal} />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      setVideoUrl,
    },
    dispatch
  );
};
const mapStateToProps = (store: IStore) => {
  return {
    videoUrl: store.setVideoUrl.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionIntegration);
