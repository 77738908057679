import {Dispatch} from 'redux';
import {ErrorDto, getErrorDtoFromApiError} from '../../../models/dtos/error.dto';
import {FakeBulkSmsRequest} from '../../../models/requests/fakeBulkSms.request';
import {TRY_BULK_FAIL, TRY_BULK_RESET, TRY_BULK_START, TRY_BULK_SUCCESS} from './constants';
import {tryBulkRequest} from './repository';
import {TryBulkFailAction, TryBulkResetAction, TryBulkStartAction, TryBulkSuccessAction} from './types';

const tryBulkStartAction = (): TryBulkStartAction => {
  return {
    type: TRY_BULK_START,
  };
};
const tryBulkSuccessAction = (payload: boolean): TryBulkSuccessAction => {
  return {
    type: TRY_BULK_SUCCESS,
    payload: payload,
  };
};
const tryBulkFailAction = (error: ErrorDto): TryBulkFailAction => {
  return {
    type: TRY_BULK_FAIL,
    error: error,
  };
};
const tryBulkResetAction = (): TryBulkResetAction => {
  return {
    type: TRY_BULK_RESET,
  };
};

export const tryBulk = (request: FakeBulkSmsRequest) => async (dispatch: Dispatch) => {
  try {
    dispatch(tryBulkStartAction());
    await tryBulkRequest(request);
    dispatch(tryBulkSuccessAction(true));
  } catch (error) {
    dispatch(tryBulkFailAction(getErrorDtoFromApiError(error)));
  }
};

export const tryBulkReset = () => (dispatch: Dispatch) => {
  dispatch(tryBulkResetAction());
};
