import {Collapse, Input} from "antd";
import parse from "html-react-parser";
import React, {Component, ReactNode} from 'react';
import inputPrefix from "../../../assets/images/magnifying-glass.svg";
import mail from "../../../assets/images/mail.svg";
import AppButton from "../../../components/Buttons/AppButton/AppButton";
import PageTitle from "../../../components/PageTitle/PageTitle";
import {Faq} from "../../../core/models/custom/faq";
import FirebaseService from "../../../core/services/firebase.service";
import {history} from "../../../core/utilities/history";
import {router} from "../../../core/utilities/router";
import "./FaqPage.scss";

const {Panel} = Collapse;

interface IProps {
}

interface ISTate {
  faqs: Faq[];
  query: string;
}

class FaqPage extends Component<IProps> {
  state: ISTate = {
    faqs: [],
    query: '',
  }

  componentDidMount() {
    const faqs = FirebaseService.getFaqs();
    this.setState({faqs});
  }

  private isQueryMatch(item: string): boolean {
    return item.toLowerCase().includes(this.state.query.toLowerCase());
  }

  private renderPageTitle(): ReactNode {
    return (
      <div className="page-title-wrapper">
        <PageTitle text="Yardım"/>
        <AppButton
          content={
            <div className="d-flex align-items-center">
              <img src={mail} alt="mail"/>
              &nbsp;&nbsp;
              <span>Bize ulaşın</span>
            </div>
          }
          onClick={() => history.push(router.CONTACT_US)}
        />
      </div>
    );
  }

  private renderSearchbar(): ReactNode {
    return (
      <Input
        className="form-input search"
        prefix={<img src={inputPrefix} alt="search"/>}
        placeholder="Size nasıl yardımcı olabiliriz?"
        allowClear
        onChange={e => this.setState({query: e.target.value})}
      />
    );
  }

  private renderFaqs(): ReactNode {
    return (
      <Collapse
        accordion
        expandIconPosition="right"
        className="faqs"
      >
        {
          this.state.faqs
            .filter(faq => this.isQueryMatch(faq.question) || this.isQueryMatch(faq.answer))
            .map(faq => {
              return (
                <Panel
                  key={faq.question}
                  header={parse(faq.question ?? '')}
                >
                  {parse(faq.answer ?? '')}
                </Panel>
              );
            })
        }
      </Collapse>
    );
  }

  render() {
    return (
      <div id="faq-page" className="page">
        <div className="page-content">
          {this.renderPageTitle()}
          {this.renderSearchbar()}
          {this.renderFaqs()}
        </div>
      </div>
    );
  }
}

export default FaqPage;
