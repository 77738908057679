import {Dispatch} from 'redux';
import {ErrorDto, getErrorDtoFromApiError} from '../../../models/dtos/error.dto';
import {
  GET_USED_AGENCY_CODES_FAIL,
  GET_USED_AGENCY_CODES_RESET,
  GET_USED_AGENCY_CODES_START,
  GET_USED_AGENCY_CODES_SUCCESS,
} from './constants';
import {getUsedAgencyCodesRequest} from './repository';
import {
  GetUsedAgencyCodesFailAction,
  GetUsedAgencyCodesResetAction,
  GetUsedAgencyCodesStartAction,
  GetUsedAgencyCodesSuccessAction,
} from './types';

const getUsedAgencyCodesStartAction = (): GetUsedAgencyCodesStartAction => {
  return {
    type: GET_USED_AGENCY_CODES_START,
  };
};
export const getUsedAgencyCodesSuccessAction = (payload: string[]): GetUsedAgencyCodesSuccessAction => {
  return {
    type: GET_USED_AGENCY_CODES_SUCCESS,
    payload: payload,
  };
};
const getUsedAgencyCodesFailAction = (error: ErrorDto): GetUsedAgencyCodesFailAction => {
  return {
    type: GET_USED_AGENCY_CODES_FAIL,
    error: error,
  };
};
const getUsedAgencyCodesResetAction = (): GetUsedAgencyCodesResetAction => {
  return {
    type: GET_USED_AGENCY_CODES_RESET,
  };
};

export const getUsedAgencyCodes = () => async (dispatch: Dispatch) => {
  try {
    dispatch(getUsedAgencyCodesStartAction());
    const response =  await getUsedAgencyCodesRequest();
    dispatch(getUsedAgencyCodesSuccessAction(response));
  } catch (error) {
    dispatch(getUsedAgencyCodesFailAction(getErrorDtoFromApiError(error)));
  }
};

export const getUsedAgencyCodesReset = () => (dispatch: Dispatch) => {
  dispatch(getUsedAgencyCodesResetAction());
};
