import {
  CONTACT_US_FAIL,
  CONTACT_US_RESET,
  CONTACT_US_START,
  CONTACT_US_SUCCESS,
} from './constants';
import {ContactUsActions, ContactUsState} from './types';

const initialState: ContactUsState = {
  loading: false,
  data: false,
  error: undefined,
};

const reducer = (
  state = initialState,
  action: ContactUsActions,
): ContactUsState => {
  switch (action.type) {
    case CONTACT_US_START:
      return {
        ...initialState,
        loading: true,
      };
    case CONTACT_US_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case CONTACT_US_FAIL:
     return {
        ...state,
        loading: false,
        error: action.error,
      };
    case CONTACT_US_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
