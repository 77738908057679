import { Button, Modal } from "antd";
import React, { ReactNode, useRef } from 'react';
import { isAndroid, isMobile } from 'react-device-detect';
import FirebaseService from "../../../../core/services/firebase.service";
import { UI } from "../../../../core/utilities/ui";
import FirebaseKey from "../../../FirebaseKey/FirebaseKey";
import CopyBox from "../CopyBox/CopyBox";
import "./TryLinkModal.scss";


interface IProps {
  callbackClose: () => void;
}

const TryLinkModal = (props: IProps) => {
  const smsNo: string = '6610';
  const smsMessage: string = 'KURUMSALDENEME';
  const refSmsLinkAndroid = useRef<HTMLAnchorElement>(null);
  const refSmsLinkIOS = useRef<HTMLAnchorElement>(null);

  function handleClickLink(): void {
    FirebaseService.logEvent(FirebaseService.user_count_landing_link_popup_copy_clicked);
    isAndroid ? refSmsLinkAndroid.current?.click() : refSmsLinkIOS.current?.click()
  }

  function getSmsLinkAndroid(): ReactNode {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-has-content
      <a
        ref={refSmsLinkAndroid}
        href={`sms:${smsNo}?body=${smsMessage}`}
        hidden
      />
    );
  }

  function getSmsLinkIOS(): ReactNode {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-has-content
      <a
        ref={refSmsLinkIOS}
        href={`sms:${smsNo}&body=${smsMessage}`}
        hidden
      />
    );
  }

  function renderMobile(): ReactNode {
    return (
      <div className="content">
        <FirebaseKey className="title" firebaseKey={FirebaseService.landing_mobile_try_link_title} />
        <div className="line-wrapper d-none d-md-flex">
          <span className="line" />
        </div>
        <FirebaseKey className="text" firebaseKey={FirebaseService.landing_mobile_try_link_desc} />
        <CopyBox text="https://www.kisa.link/Qmf9" callbackClick={() => handleClickLink()} />
        <FirebaseKey className="note" firebaseKey={FirebaseService.landing_mobile_try_link_note} />
        <div className="button-wrapper">
          <Button
            className="landing-button outline"
            onClick={props.callbackClose}
          >
            <FirebaseKey firebaseKey={FirebaseService.landing_mobile_try_link_button_try} />
          </Button>
        </div>
        {getSmsLinkAndroid()}
        {getSmsLinkIOS()}
      </div>
    );
  }

  function renderDesktop(): ReactNode {
    return (
      <div className="content">
        <FirebaseKey className="title" firebaseKey={FirebaseService.landing_try_link_title} />
        <div className="line-wrapper d-none d-md-flex">
          <span className="line" />
        </div>
        <FirebaseKey className="text" firebaseKey={FirebaseService.landing_try_link_desc} />
        <CopyBox text="https://www.kisa.link/Qmf9" />
        <FirebaseKey className="note" firebaseKey={FirebaseService.landing_try_link_note} />
        <div className="button-wrapper">
          <Button
            className="landing-button outline"
            onClick={props.callbackClose}
          >
            <FirebaseKey firebaseKey={FirebaseService.landing_try_link_button} />
          </Button>
        </div>
      </div>
    );
  }

  return (
    <Modal
      wrapClassName="try-link-modal-wrapper"
      className="try-link-modal"
      maskStyle={UI.modalMaskStyle()}
      closable={false}
      footer={false}
      visible
      centered
      destroyOnClose
      onCancel={props.callbackClose}
    >
      {
        isMobile
          ? renderMobile()
          : renderDesktop()
      }
    </Modal>
  );
}

export default TryLinkModal;
