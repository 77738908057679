import {
  DELETE_CARD_FAIL,
  DELETE_CARD_RESET,
  DELETE_CARD_START,
  DELETE_CARD_SUCCESS,
} from './constants';
import {DeleteCardActions, DeleteCardState} from './types';

const initialState: DeleteCardState = {
  loading: false,
  data: false,
  error: undefined,
};

const reducer = (
  state = initialState,
  action: DeleteCardActions,
): DeleteCardState => {
  switch (action.type) {
    case DELETE_CARD_START:
      return {
        ...initialState,
        loading: true,
      };
    case DELETE_CARD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case DELETE_CARD_FAIL:
     return {
        ...state,
        loading: false,
        error: action.error,
      };
    case DELETE_CARD_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
