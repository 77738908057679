import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import ContactUsSuccessModal from "../../../components/Modals/ContactUsSuccessModal/ContactUsSuccessModal";
import PageTitle from "../../../components/PageTitle/PageTitle";
import {FormValuesContactUs} from "../../../core/models/custom/formValuesContactUs";
import {ContactUsRequest} from "../../../core/models/requests/contactUs.request";
import FirebaseService from "../../../core/services/firebase.service";
import {contactUs, contactUsReset} from "../../../core/services/miscService/contactUs/actions";
import {ContactUsState} from "../../../core/services/miscService/contactUs/types";
import {history} from "../../../core/utilities/history";
import {IStore} from "../../../core/utilities/reducers";
import {router} from "../../../core/utilities/router";
import ContactUsForm from "./ContactUsForm/ContactUsForm";
import "./ContactUsPage.scss";

interface IProps {
  contactUsState: ContactUsState;
  contactUs: (request: ContactUsRequest) => void;
  contactUsReset: () => void;
}

class ContactUsPage extends Component<IProps> {

  private handleSubmit(values: FormValuesContactUs): void {
    const request: ContactUsRequest = {
      ...values,
    }
    this.props.contactUs(request);
  }

  private handleModalContactUsSuccess(): void {
    this.props.contactUsReset();
    history.push(router.HOME);
  }

  render() {
    return (
      <div id="contact-us-page" className="page">
        <div className="page-content">
          <PageTitle text="Bize Ulaşın"/>
          <ContactUsForm
            subjects={FirebaseService.getContactSubjects()}
            isSubmitting={this.props.contactUsState.loading}
            callbackSubmit={values => this.handleSubmit(values)}
          />
        </div>

        {
          this.props.contactUsState.data &&
          <ContactUsSuccessModal
            callback={() => this.handleModalContactUsSuccess()}
          />
        }
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      contactUs,
      contactUsReset,
    },
    dispatch
  );
};
const mapStateToProps = (store: IStore) => {
  return {
    contactUsState: store.contactUs,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsPage);
