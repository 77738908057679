import {
  RESEND_CONFIRM_EMAIL_FAIL,
  RESEND_CONFIRM_EMAIL_RESET,
  RESEND_CONFIRM_EMAIL_START,
  RESEND_CONFIRM_EMAIL_SUCCESS,
} from './constants';
import {ResendConfirmEmailActions, ResendConfirmEmailState} from './types';

const initialState: ResendConfirmEmailState = {
  loading: false,
  data: false,
  error: undefined,
};

const reducer = (
  state = initialState,
  action: ResendConfirmEmailActions,
): ResendConfirmEmailState => {
  switch (action.type) {
    case RESEND_CONFIRM_EMAIL_START:
      return {
        ...initialState,
        loading: true,
      };
    case RESEND_CONFIRM_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case RESEND_CONFIRM_EMAIL_FAIL:
     return {
        ...state,
        loading: false,
        error: action.error,
      };
    case RESEND_CONFIRM_EMAIL_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
