export const router = {
  LANDING: '/',
  HOME: '/home',
  AUTH: '/auth',
  RESET_PASSWORD: '/create-new-password',
  ACTIVATION: '/activation',
  REGISTRATION: '/registration',
  REGISTRATION_WAITING: '/registration/waiting',
  REWARD: '/reward',
  REWARD_MSISDN: '/reward-msisdn',
  ORDER: '/order',
  ORDER_HISTORY: '/order/history',
  CAMPAIGNS: '/campaigns',
  CAMPAIGN_CREATE: '/create-campaign',
  PROFILE: '/profile',
  FAQ: '/faq',
  CONTACT_US: '/contact-us',
  PAY_BY_ME_SUCCESS: '/payment-redirect', // Backend related
  PAY_BY_ME_FAILED: '/payment-failed', // Backend related
};
