import axios from '../../../utilities/axios';
import { AgencyRevenueRequest } from '../../../models/requests/agencyRevenue.request';

export const claimRevenueRequest = async (request: AgencyRevenueRequest): Promise<void> => {
  try {
    const response = await axios.post<void>(
      `${process.env.REACT_APP_BASE_URL}/agency/claim`,
      {...request},
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
