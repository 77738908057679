import {Dispatch} from 'redux';
import {ErrorDto, getErrorDtoFromApiError} from '../../../models/dtos/error.dto';
import {JwtDto} from '../../../models/dtos/jwt.dto';
import {ResetPasswordRequest} from '../../../models/requests/resetPassword.request';
import {Helpers} from '../../../utilities/helpers';
import {history} from '../../../utilities/history';
import {LocalStorage} from '../../../utilities/localStorage';
import {getMeRequest} from '../../userService/getMe/repository';
import {NEW_PASSWORD_FAIL, NEW_PASSWORD_RESET, NEW_PASSWORD_START, NEW_PASSWORD_SUCCESS} from './constants';
import {newPasswordRequest} from './repository';
import {
  NewPasswordFailAction,
  NewPasswordResetAction,
  NewPasswordStartAction,
  NewPasswordSuccessAction,
} from './types';

const newPasswordStartAction = (): NewPasswordStartAction => {
  return {
    type: NEW_PASSWORD_START,
  };
};
const newPasswordSuccessAction = (payload: JwtDto): NewPasswordSuccessAction => {
  return {
    type: NEW_PASSWORD_SUCCESS,
    payload: payload,
  };
};
const newPasswordFailAction = (error: ErrorDto): NewPasswordFailAction => {
  return {
    type: NEW_PASSWORD_FAIL,
    error: error,
  };
};
const newPasswordResetAction = (): NewPasswordResetAction => {
  return {
    type: NEW_PASSWORD_RESET,
  };
};

export const newPassword = (request: ResetPasswordRequest) => async (dispatch: Dispatch) => {
  try {
    dispatch(newPasswordStartAction());
    const response = await newPasswordRequest(request);
    LocalStorage.set(LocalStorage.token, response.token);
    const me = await getMeRequest(dispatch);
    dispatch(newPasswordSuccessAction(response));
    history.push(Helpers.getRouteFromMe(me));
    Helpers.showNotification('success', 'Başarılı', 'Şifreniz başarıyla güncellenmiştir.');
  } catch (error) {
    dispatch(newPasswordFailAction(getErrorDtoFromApiError(error)));
  }
};

export const newPasswordReset = () => (dispatch: Dispatch) => {
  dispatch(newPasswordResetAction());
};
