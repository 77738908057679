import {Dispatch} from 'redux';
import {ErrorDto, getErrorDtoFromApiError} from '../../../models/dtos/error.dto';
import {RegisterRequest} from '../../../models/requests/register.request';
import {REGISTER_FAIL, REGISTER_RESET, REGISTER_START, REGISTER_SUCCESS} from './constants';
import {registerRequest} from './repository';
import {RegisterFailAction, RegisterResetAction, RegisterStartAction, RegisterSuccessAction} from './types';
import {getMeRequest} from '../getMe/repository';
import {history} from '../../../utilities/history';
import {Helpers} from '../../../utilities/helpers';

const registerStartAction = (): RegisterStartAction => {
  return {
    type: REGISTER_START,
  };
};
const registerSuccessAction = (payload: boolean): RegisterSuccessAction => {
  return {
    type: REGISTER_SUCCESS,
    payload: payload,
  };
};
const registerFailAction = (error: ErrorDto): RegisterFailAction => {
  return {
    type: REGISTER_FAIL,
    error: error,
  };
};
const registerResetAction = (): RegisterResetAction => {
  return {
    type: REGISTER_RESET,
  };
};

export const register = (request: RegisterRequest) => async (dispatch: Dispatch) => {
  try {
    dispatch(registerStartAction());
    await registerRequest(request);
    const me = await getMeRequest(dispatch);
    dispatch(registerSuccessAction(true));
    history.push(Helpers.getRouteFromMe(me));
  } catch (error) {
    dispatch(registerFailAction(getErrorDtoFromApiError(error)));
  }
};

export const registerReset = () => (dispatch: Dispatch) => {
  dispatch(registerResetAction());
};
