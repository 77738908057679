import axios from '../../../utilities/axios';

export const getUsedAgencyCodesRequest = async (): Promise<string[]> => {
  try {
    const response = await axios.get<string[]>(
      `${process.env.REACT_APP_BASE_URL}/agency/used`,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
