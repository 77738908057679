import {Modal, Progress, Upload} from "antd";
import {RcFile} from "antd/es/upload";
import parse from "html-react-parser";
import React, {ReactNode, useState} from 'react';
import bulkFileResultError from "../../../assets/images/bulk-file-result-error.svg";
import bulkFileResultSuccess from "../../../assets/images/bulk-file-result-success.svg";
import bulkFileResultWarn from "../../../assets/images/bulk-file-result-warn.svg";
import cancel from "../../../assets/images/cancel.svg";
import checkmarkFilledPrimary from "../../../assets/images/checkmark-filled-primary.png";
import download from "../../../assets/images/download.svg";
import fileUpload from "../../../assets/images/file-upload.png";
import fileUploaded from "../../../assets/images/file-uploaded.svg";
import icon from "../../../assets/images/modal-icon-campaign-approval-bulk.svg";
import {BulkFile} from "../../../core/models/custom/bulkFile";
import {CampaignData} from "../../../core/models/custom/campaignData";
import {Helpers} from "../../../core/utilities/helpers";
import {UI} from "../../../core/utilities/ui";
import AppButton, {AppButtonType} from "../../Buttons/AppButton/AppButton";
import "./CampaignBulkFileModal.scss";

const {Dragger} = Upload;

interface IProps {
  data: CampaignData;
  callbackOk: (data: CampaignData) => void;
  callbackCancel: () => void;
}

enum BulkFileResult {
  success = 'success',
  warn = 'warn',
  error = 'error',
  fileError = 'fileError',
}

const CampaignBulkFileModal = (props: IProps) => {
  const [bulkFile, setBulkFile] = useState<BulkFile>();

  function getBulkFileResult(bulkFile: BulkFile): BulkFileResult {
    if (bulkFile.validLine === 0) {
      return BulkFileResult.fileError;
    } else if (bulkFile.validLine > props.data.amount) {
      return BulkFileResult.error;
    } else if (bulkFile.validLine < props.data.amount) {
      return BulkFileResult.warn;
    }
    return BulkFileResult.success;
  }

  function isSubmitDisabled(): boolean {
    return (
      !bulkFile ||
      getBulkFileResult(bulkFile) === BulkFileResult.error ||
      getBulkFileResult(bulkFile) === BulkFileResult.fileError
    );
  }

  async function handleFileUpload(file: RcFile): Promise<void> {
    const content = await file.text();
    const lines = content.split("\n");
    let validLine = 0;
    lines.forEach(line => {
      if (Helpers.isStringValidMsisdn(line)) {
        validLine++
      }
    });
    setBulkFile({file, validLine});
  }

  function getHeaderTitle(): string {
    if (bulkFile) {
      const result = getBulkFileResult(bulkFile);
      switch (result) {
        case BulkFileResult.success:
        case BulkFileResult.warn:
          return 'İşlem başarılı!';
        case BulkFileResult.error:
        case BulkFileResult.fileError:
          return 'Bir hata oluştu!';
      }
    }
    return `${Helpers.getPointEquivalent(props.data.amount)} MetaByte toplu yükleme için hazır!`;
  }

  function getHeaderText(): string {
    if (bulkFile) {
      const result = getBulkFileResult(bulkFile);
      switch (result) {
        case BulkFileResult.success:
        case BulkFileResult.warn:
          return 'Lütfen tekrar deneyin.';
        case BulkFileResult.error:
          return 'Lütfen tekrar deneyin.';
        case BulkFileResult.fileError:
          return 'Listeniz sistemimize kaydedildi fakat okunamadı. Lütfen tekrar deneyin.';
      }
    }
    return 'Toplu yükleme yapabilmeniz için telefon numarası listenizi örnekte belirtilen formatta hazırlayıp sisteme yüklemeniz gerekmektedir. ';
  }

  function getDownloadSampleButtonContent(): ReactNode {
    return (
      <div className="d-flex align-items-center">
        <span>Örnek listeyi indir</span>
        &nbsp;&nbsp;
        <img src={download} alt="download"/>
      </div>
    );
  }

  function getBulkFileResultImage(bulkFile: BulkFile): string {
    switch (getBulkFileResult(bulkFile)) {
      case BulkFileResult.success:
        return bulkFileResultSuccess;
      case BulkFileResult.warn:
        return bulkFileResultWarn;
      case BulkFileResult.error:
      case BulkFileResult.fileError:
        return bulkFileResultError;
      default:
        return '';
    }
  }

  function getBulkFileResultTitle(bulkFile: BulkFile): string {
    switch (getBulkFileResult(bulkFile)) {
      case BulkFileResult.success:
        return 'kişi sayısı seçtiğiniz MetaByte adedine eşit';
      case BulkFileResult.warn:
        return 'kişi sayısı seçtiğiniz MetaByte adedinden az';
      case BulkFileResult.error:
        return 'Kişi sayısı seçtiğiniz MetaByte adedinden fazla';
      case BulkFileResult.fileError:
        return 'Listenizde bir sorun var!';
      default:
        return '';
    }
  }

  function getBulkFileResultText(bulkFile: BulkFile): string {
    switch (getBulkFileResult(bulkFile)) {
      case BulkFileResult.success:
        return 'Yüklemenizi tamamlayabilirsiniz.';
      case BulkFileResult.warn:
        return "Fazla MetaByte'larınız bakiyenize iade edilecek.";
      case BulkFileResult.error:
        return 'Lütfen listenizi kontrol ederek tekrar yükleyin.';
      case BulkFileResult.fileError:
        return 'Sorun listenizin ya da telefon numaralarının formatından kaynaklanıyor olabilir, lütfen kontrol edin.';
      default:
        return '';
    }
  }

  function renderSampleLink(): ReactNode {
    return (
      <a
        className="download-wrapper"
        href="https://s3-eu-west-1.amazonaws.com/static.4p/ornek-format.txt"
        target="_blank"
        rel="noreferrer"
      >
        <AppButton
          type={AppButtonType.outline}
          className="download"
          content={getDownloadSampleButtonContent()}
          onClick={() => {
          }}
        />
      </a>
    );
  }

  function renderUpload(): ReactNode {
    return (
      <Dragger
        className="file-upload"
        name="file"
        multiple={false}
        showUploadList={false}
        beforeUpload={file => {
          handleFileUpload(file);
          return false;
        }}
      >
        <img src={fileUpload} alt="file upload"/>
        <p className="upload-text">
          <span className="bold">Yüklemek için tıkla</span>
          &nbsp;ya da sürükle bırak
          <br/>
          Dosyanız .txt formatında olmalıdır
        </p>
      </Dragger>
    );
  }

  function renderFile(bulkFile: BulkFile): ReactNode {
    return (
      <div className="file-uploaded">
        <div className="line-info">
          <img src={fileUploaded} alt="uploaded"/>
          <div className="details">
            <label className="file-name">{bulkFile.file.name}</label>
            <label className="file-size">{bulkFile.file.size / 1000} KB</label>
          </div>
          <img
            className="file-delete"
            src={cancel}
            alt="cancel"
            onClick={() => setBulkFile(undefined)}
          />
        </div>
        <div className="line-progress">
          <Progress
            className="progress"
            percent={100}
            showInfo={false}
            strokeColor="#138656"
          />
          <label className="percent">100%</label>
          <img className="checkmark" src={checkmarkFilledPrimary} alt="checkmark"/>
        </div>
      </div>
    );
  }

  function renderFileContent(bulkFile: BulkFile): ReactNode {
    return (
      <div className={`file-uploaded-result ${getBulkFileResult(bulkFile)}`}>
        <div className="result-data">
          <div className="result-data-package">
            <div className="result-data-package-amount">{Helpers.getPointEquivalent(props.data.amount)}</div>
            <div className="result-data-package-text">MetaByte</div>
          </div>
          <div className="result-data-valid">
            <div className="result-data-valid-line">{bulkFile.validLine}</div>
            <div className="result-data-valid-text">Kişi</div>
          </div>
        </div>
        <div className="result-info">
          <img className="mb-auto" src={getBulkFileResultImage(bulkFile)} alt="result"/>
          <div className="result-description">
            <div className="result-description-title">
              {getBulkFileResultTitle(bulkFile)}
            </div>
            <div className="result-description-text">
              {parse(getBulkFileResultText(bulkFile))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Modal
      className="campaign-bulk-file-modal"
      maskStyle={UI.modalMaskStyle()}
      closable={false}
      footer={false}
      visible
      centered
      destroyOnClose
      onCancel={props.callbackCancel}
    >
      <div className="content">
        <img className="icon" src={icon} alt="icon"/>
        <label className="title">
          {getHeaderTitle()}
        </label>
        <label className="text">
          {getHeaderText()}
        </label>

        {
          bulkFile
            ?
            <React.Fragment>
              {renderFile(bulkFile)}
              {renderFileContent(bulkFile)}
            </React.Fragment>
            :
            <React.Fragment>
              {renderSampleLink()}
              {renderUpload()}
            </React.Fragment>
        }

        <div className="button-wrapper">
          <AppButton
            type={AppButtonType.outline}
            content="Vazgeç"
            onClick={props.callbackCancel}
          />
          <AppButton
            content="Toplu yükle"
            disabled={isSubmitDisabled()}
            onClick={() => props.callbackOk({...props.data, bulkFile: bulkFile})}
          />
        </div>
      </div>
    </Modal>
  );
}

export default CampaignBulkFileModal;
