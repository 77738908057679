import {Form, Input} from 'antd';
import React, {useState} from 'react';
import AppButton from '../../../../components/Buttons/AppButton/AppButton';
import ForgotPasswordModal from '../../../../components/Modals/ForgotPasswordModal/ForgotPasswordModal';
import {FormValuesLogin} from '../../../../core/models/custom/formValuesLogin';
import {Constants} from "../../../../core/utilities/constants";
import './LoginForm.scss';

interface IProps {
  isSubmitting: boolean;
  callbackLogin: (values: FormValuesLogin) => void;
}

const LoginForm = (props: IProps) => {
  const [isModalForgotPasswordVisible, setModalForgotPasswordVisible] = useState(false);

  function handleFinish(values: FormValuesLogin) {
    props.callbackLogin(values);
  }

  return (
    <Form id="login-form" className="form" onFinish={handleFinish}>
      <p className="title h1">ÜYE İŞ YERİ GİRİŞİ</p>

      <Form.Item
        name="email"
        rules={[
          {required: true, message: Constants.inputErrorRequiredField},
          {type: 'email', message: 'Hatalı mail'},
        ]}
      >
        <Input
          id="email-login"
          className="form-input"
          type="email"
          placeholder="Mail"
        />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[{required: true, message: Constants.inputErrorRequiredField}]}
      >
        <Input.Password
          id="password-login"
          className="form-input"
          placeholder="Şifre"
        />
      </Form.Item>

      <AppButton
        content="GİRİŞ YAP"
        htmlType="submit"
        disabled={props.isSubmitting}
        isLoading={props.isSubmitting}
        onClick={() => {
        }}
      />

      <AppButton
        className="forgot-password"
        content="Şifremi Unuttum"
        onClick={() => setModalForgotPasswordVisible(true)}
      />

      {
        isModalForgotPasswordVisible &&
        <ForgotPasswordModal
          callbackClose={() => setModalForgotPasswordVisible(false)}
        />
      }
    </Form>
  );
};

export default LoginForm;
