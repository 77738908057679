import React from "react";
import "./Placeholder.scss";

interface IProps {
  className?: string;
  dim?: string;
}

const Placeholder = (props: IProps) => {

  return (
    <img
      id="placeholder"
      className={`placeholder ${props.className ?? ''}`}
      src={`https://via.placeholder.com/${props.dim ?? '1920x1080'}`}
      alt="placeholder"
    />
  );
}

export default Placeholder;
