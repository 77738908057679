import { CheckAgencyCodeDto } from '../../../models/dtos/checkAgencyCode.dto';
import axios from '../../../utilities/axios';

export const checkAndGetAgencyCodeRequest = async (code?: string): Promise<CheckAgencyCodeDto> => {
  try {
    const response = await axios.get<CheckAgencyCodeDto>(
      `${process.env.REACT_APP_BASE_URL}/agency/${code}`,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
