import {Modal} from "antd";
import React, {ReactNode, useState} from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import apiKeyGrey from "../../../assets/images/api-key-grey.svg";
import apiKeyWhite from "../../../assets/images/api-key-white.svg";
import right from "../../../assets/images/chevron-right-primary.svg";
import copy from "../../../assets/images/copy.svg";
import download from "../../../assets/images/download-white.svg";
import icon from "../../../assets/images/modal-icon-success.svg";
import outerLink from "../../../assets/images/outer-link.svg";
import {CampaignSuccessData} from "../../../core/models/custom/campaignSuccessData";
import {CampaignType} from "../../../core/models/enums/campaignType";
import {Constants} from "../../../core/utilities/constants";
import {Helpers} from "../../../core/utilities/helpers";
import {UI} from "../../../core/utilities/ui";
import AppButton, {AppButtonType} from "../../Buttons/AppButton/AppButton";
import CreateCampaignCard from "../../CreateCampaignCard/CreateCampaignCard";
import "./CampaignCreateSuccessModal.scss";

interface IProps {
  data: CampaignSuccessData;
  callbackUsageLink: (type: CampaignType) => void;
  callbackNewCampaign: () => void;
  callbackHomepage: () => void;
}

interface DynamicContent {
  title: string;
  text: string;
  downloadButton: string;
  linkText: string;
}

const CampaignCreateSuccessModal = (props: IProps) => {
  const [dynamicContent] = useState<DynamicContent>(getDynamicContent);
  const [isCopied, setCopied] = useState(false);

  function isUsageLink(): boolean {
    return (
      props.data.type === CampaignType.CODE ||
      props.data.type === CampaignType.LINK
    );
  }

  function handleCopy(): void {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }

  function getDynamicContent(): DynamicContent {
    let title = '';
    let text = '';
    let downloadButton = '';
    let linkText = '';
    switch (props.data.type) {
      case CampaignType.CODE:
        title = `${Helpers.getPointEquivalent(props.data.amount)} MetaByte kod ile yüklemeye hazır!`
        text = "MetaByte'larınız koda dönüştürülerek sizin için dosyalandı. Kodlarınızı hemen indirip müşterilerinizle paylaşabilirsiniz.";
        downloadButton = 'Kod dosyasını';
        linkText = 'Kod ile';
        break;
      case CampaignType.LINK:
        title = `${Helpers.getPointEquivalent(props.data.amount)} MetaByte link ile yüklemeye hazır!`
        text = "MetaByte'larınız linke dönüştürülerek sizin için dosyalandı. Link dosyasını hemen indirip müşterilerinizle paylaşabilirsiniz.";
        downloadButton = 'Link dosyasını';
        linkText = 'Link ile';
        break;
      case CampaignType.BULK:
        title = 'Toplu yükleme işleminiz başarıyla tamamlandı.'
        text = "MetaByte'larınız listedeki müşterilerinize bilgilendirilerek yüklendi.";
        break;
      case CampaignType.API:
        title = `${Helpers.getPointEquivalent(props.data.amount)} MetaByte API ile yüklemeye hazır!`
        text = 'API entegrasyonunuz için gerekli API keye ve entegrasyon dökümanına buradan ulaşabilirsiniz. ';
        break;
      default:
        alert(`Invalid campaign type: ${props.data.type}`);
    }
    return {title, text, downloadButton, linkText};
  }

  function getDownloadButtonContent(): ReactNode {
    return (
      <div className="d-flex align-items-center">
        <span>{dynamicContent.downloadButton}&nbsp;indir</span>
        &nbsp;&nbsp;
        <img src={download} alt="download"/>
      </div>
    );
  }

  return (
    <Modal
      className="campaign-create-success-modal"
      maskStyle={UI.modalMaskStyle()}
      closable={false}
      footer={false}
      visible
      centered
      destroyOnClose
    >
      <div className="content">
        <img className="icon" src={icon} alt="icon"/>
        <label className="title">
          {dynamicContent.title}
        </label>
        <label className="text">
          {dynamicContent.text}
        </label>

        {
          props.data.downloadData &&
          <AppButton
            className="download"
            content={getDownloadButtonContent()}
            onClick={() => Helpers.downloadStringAsTxt(props.data.downloadData!, props.data.name)}
          />
        }

        {
          isUsageLink() &&
          <label
            className="link"
            onClick={() => props.callbackUsageLink(props.data.type)}
          >
            {`${dynamicContent.linkText} yükleme yönteminin detaylar için tıklayınız.`}
            <img className="ms-3" src={right} alt="link"/>
          </label>
        }

        {
          props.data.type !== CampaignType.API &&
          <CreateCampaignCard
            balance={props.data.balance}
            callback={props.callbackNewCampaign}
          />
        }

        {
          props.data.type === CampaignType.API &&
          <React.Fragment>
            <div className="api-key">
              <span className="api-key-title">API KEY</span>
              <div className="api-key-value">
                <img className="prefix" src={apiKeyGrey} alt="api key"/>
                <span>{props.data.apiKey}</span>
                <CopyToClipboard
                  text={props.data.apiKey!}
                  onCopy={handleCopy}
                >
                  <AppButton
                    className="api-key-copy"
                    content={<img src={copy} alt="copy"/>}
                    onClick={() => {
                    }}
                  />
                </CopyToClipboard>
              </div>
              <label className="api-key-note">
                API keyinizi kopyalamayı unutmayın.
                <span className={`copied ${isCopied && 'visible'}`}>
                  API KEY<br/>Kopyalandı
                </span>
              </label>
            </div>
            <AppButton
              className="api-documentation-link"
              content={
                <a href={Constants.linkApiDocumentation} target="_blank" rel="noreferrer">
                  <img src={apiKeyWhite} alt="api key"/>
                  <span className="text">Entegrasyon Dokümanı</span>
                  <img src={outerLink} alt="link"/>
                </a>
              }
              onClick={() => {
              }}
            />
          </React.Fragment>
        }

        <AppButton
          type={AppButtonType.outline}
          className="homepage"
          content="Ana Sayfa"
          onClick={props.callbackHomepage}
        />
      </div>
    </Modal>
  );
}

export default CampaignCreateSuccessModal;
