import {LoadingOutlined} from "@ant-design/icons";
import {Form, Input} from "antd";
import React from 'react';
import {FormValuesSideUser} from "../../../../core/models/custom/formValuesSideUser";
import AppButton, {AppButtonType} from "../../../Buttons/AppButton/AppButton";
import "./SideUserForm.scss";

interface IProps {
  isSubmitting: boolean;
  callbackSubmit: (values?: FormValuesSideUser) => void;
}

const SideUserForm = (props: IProps) => {
  const [form] = Form.useForm();

  function handleFinish(values: FormValuesSideUser): void {
    props.callbackSubmit(values);
  }

  return (
    <Form
      form={form}
      id="side-user-form"
      onFinish={handleFinish}
    >
      <Form.Item
        name="fullName"
        rules={[{required: true, message: 'Bu alanın doldurulması gerekmektedir.'}]}
      >
        <Input className="form-input" placeholder="Ad Soyad"/>
      </Form.Item>
      <Form.Item
        name="email"
        rules={[
          {required: true, message: 'Bu alanın doldurulması gerekmektedir.'},
          {type: 'email', message: 'Geçersiz email'},
        ]}
      >
        <Input className="form-input" type="email" placeholder="Email"/>
      </Form.Item>
      <p className="note">
        Eklediğim yöneticinin tüm yetkilere sahip olmasına izin veriyorum.
      </p>
      {
        props.isSubmitting
          ?
          <div className="loading-wrapper">
            <LoadingOutlined className="loading"/>
          </div>
          :
          <div className="button-wrapper">
            <AppButton
              type={AppButtonType.outline}
              content="Vazgeç"
              onClick={() => props.callbackSubmit()}
            />
            <AppButton
              htmlType="submit"
              content="Onayla"
              onClick={() => {
              }}
            />
          </div>
      }
    </Form>
  );
}

export default SideUserForm;
