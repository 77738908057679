import {
  REWARD_MSISDN_FAIL,
  REWARD_MSISDN_RESET,
  REWARD_MSISDN_START,
  REWARD_MSISDN_SUCCESS,
} from './constants';
import {RewardMsisdnActions, RewardMsisdnState} from './types';

const initialState: RewardMsisdnState = {
  loading: false,
  data: false,
  error: undefined,
};

const reducer = (
  state = initialState,
  action: RewardMsisdnActions,
): RewardMsisdnState => {
  switch (action.type) {
    case REWARD_MSISDN_START:
      return {
        ...initialState,
        loading: true,
      };
    case REWARD_MSISDN_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case REWARD_MSISDN_FAIL:
     return {
        ...state,
        loading: false,
        error: action.error,
      };
    case REWARD_MSISDN_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
