import {Select, Tabs} from "antd";
import React, {ReactNode, useState} from "react";
import goal1 from "../../../../assets/images/landing/feature-area.svg";
import goal4 from "../../../../assets/images/landing/feature-headphones.svg";
import goal2 from "../../../../assets/images/landing/feature-line.svg";
import goal3 from "../../../../assets/images/landing/feature-loyalty.svg";
import goal5 from "../../../../assets/images/landing/feature-smile.svg";
import FirebaseKey from "../../../../components/FirebaseKey/FirebaseKey";
import FirebaseService from "../../../../core/services/firebase.service";
import {UI} from "../../../../core/utilities/ui";
import FeatureCard from "../FeatureCard/FeatureCard";
import "./SectionGoals.scss";

const {Option} = Select;
const {TabPane} = Tabs;

interface IProps {
  sectionRef: any;
}

const SectionGoals = (props: IProps) => {
  const [goal, setGoal] = useState(1);

  function getGoalKey(goal: number): string {
    return `landing_section3_model${goal}`;
  }

  function renderGoalSelect(): ReactNode {
    return (
      <div className="select-wrapper d-flex d-lg-none">
        <label className="label">Kampanya hedefi</label>
        <Select
          className="select"
          dropdownClassName="goal-dropdown"
          value={goal}
          onChange={goal => setGoal(goal as number)}
        >
          {renderOptionGoal(1)}
          {renderOptionGoal(2)}
          {renderOptionGoal(3)}
          {renderOptionGoal(4)}
          {renderOptionGoal(5)}
        </Select>
      </div>
    );
  }

  function renderOptionGoal(goal: number): ReactNode {
    const key = getGoalKey(goal);
    return (
      <Option key={key} value={goal}>
        <FirebaseKey firebaseKey={key}/>
      </Option>
    );
  }

  function renderGoalTabs(): ReactNode {
    return (
      <Tabs
        className="feature-tabs d-none d-lg-flex"
        defaultActiveKey={goal.toString()}
        onChange={key => setGoal(parseInt(key))}
      >
        <TabPane tab={<FirebaseKey firebaseKey={getGoalKey(1)}/>} key="1"/>
        <TabPane tab={<FirebaseKey firebaseKey={getGoalKey(2)}/>} key="2"/>
        <TabPane tab={<FirebaseKey firebaseKey={getGoalKey(3)}/>} key="3"/>
        <TabPane tab={<FirebaseKey firebaseKey={getGoalKey(4)}/>} key="4"/>
        <TabPane tab={<FirebaseKey firebaseKey={getGoalKey(5)}/>} key="5"/>
      </Tabs>
    );
  }

  function renderGoal(): ReactNode {
    let icon = <img src={goal1} alt="goal"/>;
    const titleKey = `landing_section3_model${goal}_title`;
    const descriptionKey = `landing_section3_model${goal}_desc`;
    switch (goal) {
      case 1:
        icon = <img src={goal1} alt="goal"/>
        break;
      case 2:
        icon = <img src={goal2} alt="goal"/>
        break;
      case 3:
        icon = <img src={goal3} alt="goal"/>
        break;
      case 4:
        icon = <img src={goal4} alt="goal"/>
        break;
      case 5:
        icon = <img src={goal5} alt="goal"/>
        break;
    }
    return (
      <FeatureCard
        icon={icon}
        titleKey={titleKey}
        descriptionKey={descriptionKey}
      />
    );
  }

  return (
    <div
      ref={props.sectionRef}
      id="section-goals" className="section"
    >
      <div className="section-content">
        <div className="row w-xl-100">
          <div className={`section-left ${UI.getLandingSectionResponsiveClass()}`}>
            <div className="content">
              <FirebaseKey className="header" firebaseKey={FirebaseService.landing_section3_header}/>
              <FirebaseKey className="title" firebaseKey={FirebaseService.landing_section3_title}/>
              <FirebaseKey className="description" firebaseKey={FirebaseService.landing_section3_desc}/>
            </div>
          </div>
          <div className={`section-right ${UI.getLandingSectionResponsiveClass()}`}>
            {renderGoalSelect()}
            {renderGoalTabs()}
            {renderGoal()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionGoals;
