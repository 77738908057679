import {
  CREATE_API_CAMPAIGN_FAIL,
  CREATE_API_CAMPAIGN_RESET,
  CREATE_API_CAMPAIGN_START,
  CREATE_API_CAMPAIGN_SUCCESS,
} from './constants';
import {CreateApiCampaignActions, CreateApiCampaignState} from './types';

const initialState: CreateApiCampaignState = {
  loading: false,
  data: undefined,
  error: undefined,
};

const reducer = (
  state = initialState,
  action: CreateApiCampaignActions,
): CreateApiCampaignState => {
  switch (action.type) {
    case CREATE_API_CAMPAIGN_START:
      return {
        ...initialState,
        loading: true,
      };
    case CREATE_API_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case CREATE_API_CAMPAIGN_FAIL:
     return {
        ...state,
        loading: false,
        error: action.error,
      };
    case CREATE_API_CAMPAIGN_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
