import axios from '../../../utilities/axios';

export const confirmEmailRequest = async (code: string): Promise<void> => {
  try {
    const response = await axios.post<void>(
      `${process.env.REACT_APP_BASE_URL}/users/confirm-email?code=${code}`,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
