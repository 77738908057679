import {Dispatch} from 'redux';
import {ErrorDto, getErrorDtoFromApiError} from '../../../models/dtos/error.dto';
import {
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_RESET,
  RESET_PASSWORD_START,
  RESET_PASSWORD_SUCCESS,
} from './constants';
import {resetPasswordRequest} from './repository';
import {
  ResetPasswordFailAction,
  ResetPasswordResetAction,
  ResetPasswordStartAction,
  ResetPasswordSuccessAction,
} from './types';

const resetPasswordStartAction = (): ResetPasswordStartAction => {
  return {
    type: RESET_PASSWORD_START,
  };
};
const resetPasswordSuccessAction = (payload: boolean): ResetPasswordSuccessAction => {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload: payload,
  };
};
const resetPasswordFailAction = (error: ErrorDto): ResetPasswordFailAction => {
  return {
    type: RESET_PASSWORD_FAIL,
    error: error,
  };
};
const resetPasswordResetAction = (): ResetPasswordResetAction => {
  return {
    type: RESET_PASSWORD_RESET,
  };
};

export const resetPassword = (email: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(resetPasswordStartAction());
    await resetPasswordRequest(email);
    dispatch(resetPasswordSuccessAction(true));
  } catch (error) {
    dispatch(resetPasswordFailAction(getErrorDtoFromApiError(error)));
  }
};

export const resetPasswordReset = () => (dispatch: Dispatch) => {
  dispatch(resetPasswordResetAction());
};
