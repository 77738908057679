import {Dispatch} from 'redux';
import {ErrorDto, getErrorDtoFromApiError} from '../../../models/dtos/error.dto';
import {ContactUsRequest} from '../../../models/requests/contactUs.request';
import {CONTACT_US_FAIL, CONTACT_US_RESET, CONTACT_US_START, CONTACT_US_SUCCESS} from './constants';
import {contactUsRequest} from './repository';
import {ContactUsFailAction, ContactUsResetAction, ContactUsStartAction, ContactUsSuccessAction,} from './types';

const contactUsStartAction = (): ContactUsStartAction => {
  return {
    type: CONTACT_US_START,
  };
};
const contactUsSuccessAction = (payload: boolean): ContactUsSuccessAction => {
  return {
    type: CONTACT_US_SUCCESS,
    payload: payload,
  };
};
const contactUsFailAction = (error: ErrorDto): ContactUsFailAction => {
  return {
    type: CONTACT_US_FAIL,
    error: error,
  };
};
const contactUsResetAction = (): ContactUsResetAction => {
  return {
    type: CONTACT_US_RESET,
  };
};

export const contactUs = (request: ContactUsRequest) => async (dispatch: Dispatch) => {
  try {
    dispatch(contactUsStartAction());
    await contactUsRequest(request);
    dispatch(contactUsSuccessAction(true));
  } catch (error) {
    dispatch(contactUsFailAction(getErrorDtoFromApiError(error)));
  }
};

export const contactUsReset = () => (dispatch: Dispatch) => {
  dispatch(contactUsResetAction());
};
