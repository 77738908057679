import {
  BULK_REWARD_MSISDN_FAIL,
  BULK_REWARD_MSISDN_RESET,
  BULK_REWARD_MSISDN_START,
  BULK_REWARD_MSISDN_SUCCESS,
} from './constants';
import {BulkRewardMsisdnActions, BulkRewardMsisdnState} from './types';

const initialState: BulkRewardMsisdnState = {
  loading: false,
  data: undefined,
  error: undefined,
};

const reducer = (
  state = initialState,
  action: BulkRewardMsisdnActions,
): BulkRewardMsisdnState => {
  switch (action.type) {
    case BULK_REWARD_MSISDN_START:
      return {
        ...initialState,
        loading: true,
      };
    case BULK_REWARD_MSISDN_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case BULK_REWARD_MSISDN_FAIL:
     return {
        ...state,
        loading: false,
        error: action.error,
      };
    case BULK_REWARD_MSISDN_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
