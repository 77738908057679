import {Modal} from "antd";
import React from 'react';
import icon from "../../../assets/images/modal-icon-success.svg";
import {UI} from "../../../core/utilities/ui";
import AppButton from "../../Buttons/AppButton/AppButton";
import "./WithdrawSuccesModal.scss";

interface IProps {
  isVisible: boolean
  callback: () => void;
}

const WithdrawSuccessModal = (props: IProps) => {

  return (
    <Modal
      className="withdraw-success-modal"
      maskStyle={UI.modalMaskStyle()}
      closable={false}
      footer={false}
      visible={props.isVisible}
      centered
      destroyOnClose
      onCancel={props.callback}
    >
      <div className="content">
        <img className="icon" src={icon} alt="icon"/>
        <label className="title">
        Talebiniz oluşturuldu.
        </label>
        <label className="text">
        Kontroller sağlanıp kazancınız en kısa sürede banka hesabınıza aktarılacaktır. 
        </label>
        <AppButton
          content="Tamam"
          onClick={props.callback}
        />
      </div>
    </Modal>
  );
}

export default WithdrawSuccessModal;
