import {
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_RESET,
  RESET_PASSWORD_START,
  RESET_PASSWORD_SUCCESS,
} from './constants';
import {ResetPasswordActions, ResetPasswordState} from './types';

const initialState: ResetPasswordState = {
  loading: false,
  data: false,
  error: undefined,
};

const reducer = (
  state = initialState,
  action: ResetPasswordActions,
): ResetPasswordState => {
  switch (action.type) {
    case RESET_PASSWORD_START:
      return {
        ...initialState,
        loading: true,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case RESET_PASSWORD_FAIL:
     return {
        ...state,
        loading: false,
        error: action.error,
      };
    case RESET_PASSWORD_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
