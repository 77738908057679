import { ReactNode, useEffect, useState } from 'react';
// import rightPrimary600 from "../../../../assets/images/chevron-right-primary-600.svg";
// import right from "../../../../assets/images/chevron-right.svg";
// import master from "../../../../assets/images/credit-card-master.svg";
// import visa from "../../../../assets/images/credit-card-visa.svg";
import AppButton from "../../../../components/Buttons/AppButton/AppButton";
import FirebaseKey from "../../../../components/FirebaseKey/FirebaseKey";
import PayWithEftModal from "../../../../components/Modals/PayWithEftModal/PayWithEftModal";
import { MoneyTransferOrderRequest } from "../../../../core/models/requests/moneyTransferOrder.request";
// import { CardType, CreditCard } from "../../../../core/utilities/creditCard";
// import { Helpers } from "../../../../core/utilities/helpers";
import "./OrderPayment.scss";

interface IProps {
    amountValue?: number;
    isSubmitting: boolean;
    isDeleteCardLoading: boolean;
    creditCard?: string;
    callbackSavedCard: () => void;
    callbackNoCard: (isCardSaveEnabled: boolean) => void;
    callbackDeleteCard: () => void;
    callbackEftCompany: (request: MoneyTransferOrderRequest) => void;
}

const OrderPayment = (props: IProps) => {
    const [isCardSaveEnabled, setCardSaveEnabled] = useState(false);
    const [isPayWithEftModalVisible, setPayWithEftModalVisible] = useState(false);

    useEffect(() => {
        setCardSaveEnabled(false)
    }, [setCardSaveEnabled])

    // function getCardType(): ReactNode {
    //     if (!props.creditCard) return <React.Fragment/>
    //     const type = CreditCard.getCardType(props.creditCard);
    //     return type === CardType.visa
    //         ? <img src={visa} alt="visa"/>
    //         : <img src={master} alt="master"/>;
    // }

    function getButtonSubmitContent(): ReactNode {
        return (
            <div className="d-flex align-items-center">
                <span className='disabled'>Kart İle Devam Et</span>
            </div>
        );
    }

    function renderMoneyTransferButton(): ReactNode {
        return (
            <>
                <AppButton
                    onClick={() => setPayWithEftModalVisible(true)}
                    content="Havale ile devam et"
                />
                <PayWithEftModal
                    isSubmitting={props.isSubmitting}
                    amountValue={props.amountValue}
                    visible={isPayWithEftModalVisible}
                    callback={props.callbackEftCompany}
                    onCancel={() => setPayWithEftModalVisible(false)} />
            </>
        );
    }

    // function renderCard(): ReactNode {
    //     return (
    //         <div className="card">
    //             <div className="card-line">
    //                 <div className="d-flex flex-column">
    //                     <label className="title">
    //                         Kayıtlı kartınız
    //                     </label>
    //                     <div className="card-info">
    //                         {getCardType()}
    //                         <label className="card-no">
    //                             Son 4 hane:&nbsp;{Helpers.getCreditCardLastDigits(props.creditCard)}
    //                         </label>
    //                     </div>
    //                 </div>
    //                 <AppButton
    //                     type={AppButtonType.link}
    //                     content="Başka kart kullan"
    //                     disabled={props.isDeleteCardLoading}
    //                     onClick={props.callbackDeleteCard}
    //                 />
    //             </div>
    //             <div className="d-flex">
    //             {renderMoneyTransferButton()}
    //                 <AppButton
    //                     disabled={true}
    //                     content={getButtonSubmitContent()}
    //                     onClick={() => props.callbackSavedCard()}
    //                 />
    //             </div>
    //         </div>
    //     );
    // }

    function renderNoCard(): ReactNode {
        return (
            <div className="no-card">
                {/* <label className="note">
                    Sisteme kayıtlı bir kartınız bulunmamaktadır. Kartınızı kaydederek bir sonraki işlemlerinizde zaman
                    kazanabilirsiniz.
                </label>
                <div
                    className="switch"
                    onClick={() => setCardSaveEnabled(!isCardSaveEnabled)}
                >
                    <label className="text">
                        Sonraki ödemeler için kartımı kaydet
                    </label>
                    <Switch
                        defaultChecked
                        checked={isCardSaveEnabled}
                        onChange={setCardSaveEnabled}
                    />
                </div> */}
                <label className="note">
                    <FirebaseKey firebaseKey="payment_step_desc" />
                </label>
                <div className="d-flex mt-3">
                    {renderMoneyTransferButton()}
                    <div className='disabled-btn-wrapper'>
                        <AppButton
                            className="continue-btn"
                            disabled={true}
                            content={getButtonSubmitContent()}
                            onClick={() => props.callbackNoCard(isCardSaveEnabled)}
                        />
                        <div className='soon-wrapper'>
                            <span className='soon-text'>Çok yakında</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div id="order-payment">
            {
                // props.creditCard
                //     ? renderCard()
                //     : 
                renderNoCard()
            }
        </div>
    );
}

export default OrderPayment;
