import {Dispatch} from 'redux';
import {ErrorDto, getErrorDtoFromApiError} from '../../../models/dtos/error.dto';
import {OfferDto} from '../../../models/dtos/offer.dto';
import {GET_ALL_OFFERS_FAIL, GET_ALL_OFFERS_RESET, GET_ALL_OFFERS_START, GET_ALL_OFFERS_SUCCESS,} from './constants';
import {getAllOffersRequest} from './repository';
import {
  GetAllOffersFailAction,
  GetAllOffersResetAction,
  GetAllOffersStartAction,
  GetAllOffersSuccessAction,
} from './types';

const getAllOffersStartAction = (): GetAllOffersStartAction => {
  return {
    type: GET_ALL_OFFERS_START,
  };
};
const getAllOffersSuccessAction = (payload: OfferDto[]): GetAllOffersSuccessAction => {
  return {
    type: GET_ALL_OFFERS_SUCCESS,
    payload: payload,
  };
};
const getAllOffersFailAction = (error: ErrorDto): GetAllOffersFailAction => {
  return {
    type: GET_ALL_OFFERS_FAIL,
    error: error,
  };
};
const getAllOffersResetAction = (): GetAllOffersResetAction => {
  return {
    type: GET_ALL_OFFERS_RESET,
  };
};

export const getAllOffers = () => async (dispatch: Dispatch) => {
  try {
    dispatch(getAllOffersStartAction());
    const response = await getAllOffersRequest();
    dispatch(getAllOffersSuccessAction(response));
  } catch (error) {
    dispatch(getAllOffersFailAction(getErrorDtoFromApiError(error)));
  }
};

export const getAllOffersReset = () => (dispatch: Dispatch) => {
  dispatch(getAllOffersResetAction());
};
