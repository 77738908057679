import {Table} from "antd";
import moment from "moment";
import 'moment/locale/tr';
import React from 'react';
import {OrderHistoryDto} from "../../../../core/models/dtos/orderHistory.dto";
import {Constants} from "../../../../core/utilities/constants";
import {Helpers} from "../../../../core/utilities/helpers";
import "./OrderHistoryTable.scss";

interface IProps {
  data: OrderHistoryDto[];
}

const OrderHistoryTable = (props: IProps) => {
  const columns = [
    {
      title: 'Tarih',
      dataIndex: 'orderDate',
      key: 'orderDate',
      render: (orderDate: string) => {
        moment.locale('tr');
        return (
          <span>{moment(orderDate).format(Constants.dateFormatUI)}</span>
        );
      },
    },
    {
      title: 'Birim',
      dataIndex: 'offerType',
      key: 'offerType',
    },
    {
      title: 'Adet',
      dataIndex: 'offerCount',
      key: 'offerCount',
    },
    {
      title: 'Fiyat',
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      render: (totalPrice: number) => {
        return (
          <span>{Helpers.getPriceWithCurrency(totalPrice)}</span>
        );
      }
    },
  ];

  return (
    <Table
      id="order-history-table"
      className="table"
      rowKey="id"
      columns={columns}
      dataSource={props.data}
    />
  );
}

export default OrderHistoryTable;
