import {customBulkRewardMsisdnErrorPath, ErrorDto} from '../models/dtos/error.dto';

export class Errors {
  public static readonly errorGenericTitle: string = 'Bir hata oluştu.';
  public static readonly errorGenericText: string = 'Maalesef işleminize devam edemiyoruz. Lütfen tekrar deneyin.';

  public static readonly unauthorized: string = 'Unauthorized';
  public static readonly emailNotUnique: string = 'email_not_unique';
  public static readonly wrongConfirmationCode: string = 'wrong_confirmation_code';
  public static readonly wrongPasswordHash: string = 'wrong_password_hash';
  public static readonly invalidVkn: string = 'invalid_vkn';
  public static readonly invalidTckn: string = 'invalid_tckn';
  public static readonly sideUserInvalidEmail: string = 'invalid-side-user-email';
  public static readonly sideUserLimitReached: string = 'side-user-limit-reached';

  public static isAuthError(error: ErrorDto) {
    return error.status === 401 || error.status === 403;
  }

  public static isLoginError(error: ErrorDto) {
    return error.path.includes('auth/login');
  }

  public static isCustomBulkRewardMsisdnError(error: ErrorDto) {
    return error.path.includes(customBulkRewardMsisdnErrorPath);
  }
}
