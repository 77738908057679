import React, { ReactNode } from "react";
import { NavLink } from "react-router-dom";
// import logo4P from "../../../../assets/images/landing/logo-4P.svg";
import logo from "../../../../assets/images/landing/logo-footer.svg";
import { Constants } from "../../../../core/utilities/constants";
import { router } from "../../../../core/utilities/router";
import "./LandingFooter.scss";

interface IProps {}

const LandingFooter = (props: IProps) => {
  function renderMail(className: string): ReactNode {
    return (
      <div className={`mail-wrapper ${className ?? ""}`}>
        <span className="title">Tüm sorularınız için;</span>
        <span className="link">{Constants.link4PMail}</span>
      </div>
    );
  }

  return (
    <div id="landing-footer">
      {renderMail("d-flex d-md-none mb-4")}
      <div className="logo-wrapper">
        <NavLink to={router.LANDING}>
          <img src={logo} alt="logo" />
        </NavLink>
        {/* <a className="link-4P" href={Constants.link4P} rel="noreferrer" target="_blank">
          <img src={logo4P} alt="logo4P"/>
        </a> */}
        {renderMail("d-none d-md-flex")}
      </div>
    </div>
  );
};

export default LandingFooter;
