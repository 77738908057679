import axios from '../../../utilities/axios';

export const deleteCardRequest = async (): Promise<void> => {
  try {
    const response = await axios.delete<void>(
      `${process.env.REACT_APP_BASE_URL}/card`,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
