import {Dispatch} from 'redux';
import {ErrorDto, getErrorDtoFromApiError} from '../../../models/dtos/error.dto';
import {SaveCardDto} from '../../../models/dtos/saveCard.dto';
import {SaveCardRequest} from '../../../models/requests/saveCard.request';
import {SAVE_CARD_FAIL, SAVE_CARD_RESET, SAVE_CARD_START, SAVE_CARD_SUCCESS,} from './constants';
import {saveCardRequest} from './repository';
import {SaveCardFailAction, SaveCardResetAction, SaveCardStartAction, SaveCardSuccessAction,} from './types';

const saveCardStartAction = (): SaveCardStartAction => {
  return {
    type: SAVE_CARD_START,
  };
};
const saveCardSuccessAction = (payload: SaveCardDto): SaveCardSuccessAction => {
  return {
    type: SAVE_CARD_SUCCESS,
    payload: payload,
  };
};
const saveCardFailAction = (error: ErrorDto): SaveCardFailAction => {
  return {
    type: SAVE_CARD_FAIL,
    error: error,
  };
};
const saveCardResetAction = (): SaveCardResetAction => {
  return {
    type: SAVE_CARD_RESET,
  };
};

export const saveCard = (request: SaveCardRequest) => async (dispatch: Dispatch) => {
  try {
    dispatch(saveCardStartAction());
    const response = await saveCardRequest(request);
    dispatch(saveCardSuccessAction(response));
  } catch (error) {
    dispatch(saveCardFailAction(getErrorDtoFromApiError(error)));
  }
};

export const saveCardReset = () => (dispatch: Dispatch) => {
  dispatch(saveCardResetAction());
};
