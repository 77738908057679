import {Dispatch} from 'redux';
import {SET_VIDEO_URL} from './constants';
import {SetVideoUrlAction} from './types';

const setVideoUrlAction = (payload: string): SetVideoUrlAction => {
  return {
    type: SET_VIDEO_URL,
    payload: payload,
  };
};

export const setVideoUrl = (data: string) => async (
  dispatch: Dispatch,
) => {
  dispatch(setVideoUrlAction(data));
};
