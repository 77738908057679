import {Dispatch} from 'redux';
import {ErrorDto, getErrorDtoFromApiError} from '../../../models/dtos/error.dto';
import {JwtDto} from '../../../models/dtos/jwt.dto';
import { LocalStorage } from '../../../utilities/localStorage';
import {
  REFRESH_FAIL,
  REFRESH_RESET,
  REFRESH_START,
  REFRESH_SUCCESS,
} from './constants';
import {refreshRequest} from './repository';
import {
  RefreshFailAction,
  RefreshResetAction,
  RefreshStartAction,
  RefreshSuccessAction,
} from './types';

const refreshStartAction = (): RefreshStartAction => {
  return {
    type: REFRESH_START,
  };
};
const refreshSuccessAction = (payload: JwtDto): RefreshSuccessAction => {
  return {
    type: REFRESH_SUCCESS,
    payload: payload,
  };
};
const refreshFailAction = (error: ErrorDto): RefreshFailAction => {
  return {
    type: REFRESH_FAIL,
    error: error,
  };
};
const refreshResetAction = (): RefreshResetAction => {
  return {
    type: REFRESH_RESET,
  };
};

export const refresh = () => async (dispatch: Dispatch) => {
  try {
    dispatch(refreshStartAction());
    const response = await refreshRequest();
    LocalStorage.set(LocalStorage.token, response.token);
    dispatch(refreshSuccessAction(response));
  } catch (error) {
    dispatch(refreshFailAction(getErrorDtoFromApiError(error)));
  }
};

export const refreshReset = () => (dispatch: Dispatch) => {
  dispatch(refreshResetAction());
};
