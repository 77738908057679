import {Modal} from "antd";
import React from 'react';
import icon from '../../../assets/images/modal-icon-error.svg';
import {Errors} from "../../../core/utilities/errors";
import {UI} from "../../../core/utilities/ui";
import AppButton from "../../Buttons/AppButton/AppButton";
import "./OrderFailModal.scss";

interface IProps {
  callback: () => void;
}

const OrderFailModal = (props: IProps) => {

  return (
    <Modal
      className="order-fail-modal"
      maskStyle={UI.modalMaskStyle()}
      closable={false}
      footer={false}
      visible
      centered
      destroyOnClose
      onCancel={props.callback}
    >
      <div className="content">
        <img className="icon" src={icon} alt="icon"/>
        <label className="title">
          {Errors.errorGenericTitle}
        </label>
        <label className="text">
          {Errors.errorGenericText}
        </label>
        <AppButton
          content="Geri Dön"
          onClick={props.callback}
        />
      </div>
    </Modal>
  );
}

export default OrderFailModal;
