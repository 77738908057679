import React, {Component, ReactNode} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import AuthHeader from "../../../components/AuthHeader/AuthHeader";
import {FormValuesLogin} from '../../../core/models/custom/formValuesLogin';
import {FormValuesSignUp} from '../../../core/models/custom/formValuesSignUp';
import {LoginRequest} from '../../../core/models/requests/login.request';
import {SignUpRequest} from '../../../core/models/requests/signUp.request';
import {login} from '../../../core/services/authService/login/actions';
import {LoginState} from '../../../core/services/authService/login/types';
import {signUp} from '../../../core/services/authService/signUp/actions';
import {SignUpState} from '../../../core/services/authService/signUp/types';
import {IStore} from '../../../core/utilities/reducers';
import './AuthPage.scss';
import LoginForm from './LoginForm/LoginForm';
import SignUpForm from './SignUpForm/SignUpForm';

interface IProps {
  loginState: LoginState;
  signUpState: SignUpState;
  login: (request: LoginRequest) => void;
  signUp: (request: SignUpRequest) => void;
}

interface IState {
  authType: AuthType;
}

enum AuthType {
  login = 'login',
  signup = 'signup',
}

class AuthPage extends Component<IProps> {
  state: IState = {
    authType: AuthType.login,
  };

  private handleLogin(values: FormValuesLogin): void {
    const request: LoginRequest = {
      ...values,
    };
    this.props.login(request);
  }

  private handleSignUp(values: FormValuesSignUp): void {
    const request: SignUpRequest = {
      ...values,
    };
    this.props.signUp(request);
  }

  private renderLinkAuthType(authType: AuthType): ReactNode {
    return (
      <span
        className="link-auth-type h3"
        onClick={() => this.setState({authType})}
      >
        {authType === AuthType.login ? 'Giriş Yap' : 'Kayıt Oluştur'}
      </span>
    );
  }

  private renderLoginForm(linkAuthType?: AuthType): ReactNode {
    return (
      <React.Fragment>
        <LoginForm
          isSubmitting={this.props.loginState.loading}
          callbackLogin={(values) => this.handleLogin(values)}
        />
        {linkAuthType && this.renderLinkAuthType(linkAuthType)}
      </React.Fragment>
    );
  }

  private renderSignUpForm(linkAuthType?: AuthType): ReactNode {
    return (
      <React.Fragment>
        <SignUpForm
          isSubmitting={this.props.signUpState.loading}
          callbackSignUp={(values) => this.handleSignUp(values)}
        />
        {linkAuthType && this.renderLinkAuthType(linkAuthType)}
      </React.Fragment>
    );
  }

  render() {
    return (
      <div id="auth-page" className="page">
        <AuthHeader/>
        <div className="page-content">
          <div className="container">
            <div className="form-wrapper d-flex d-md-none">
              {this.state.authType === AuthType.login
                ? this.renderLoginForm(AuthType.signup)
                : this.renderSignUpForm(AuthType.login)}
            </div>
            <div className="form-wrapper d-none d-md-flex">
              {this.renderLoginForm()}
              {this.renderSignUpForm()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      login,
      signUp,
    },
    dispatch
  );
};
const mapStateToProps = (store: IStore) => {
  return {
    loginState: store.login,
    signUpState: store.signUp,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthPage);
