import {Modal} from "antd";
import React from 'react';
import ReactPlayer from "react-player/file";
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import {setVideoUrl} from "../../../core/services/appService/setVideoUrl/actions";
import {IStore} from "../../../core/utilities/reducers";
import {UI} from "../../../core/utilities/ui";
import Placeholder from "../../Placeholder/Placeholder";
import "./VideoModal.scss";

interface IProps {
  url: string;
  setVideoUrl: (url: string) => void;
}

const VideoModal = (props: IProps) => {

  return (
    <Modal
      className="video-modal"
      maskStyle={UI.modalMaskStyle()}
      closable={false}
      footer={false}
      visible={props.url.length > 0}
      centered
      destroyOnClose
      onCancel={() => props.setVideoUrl('')}
    >
      <div className="content">
        <div className="player-wrapper">
          <Placeholder/>
          <ReactPlayer
            key="modal-video"
            controls
            muted={false}
            loop
            className="react-player"
            width="100%"
            height="100%"
            url={props.url}
            playing={true}
          />
        </div>
      </div>
    </Modal>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      setVideoUrl,
    },
    dispatch
  );
};
const mapStateToProps = (store: IStore) => {
  return {
    url: store.setVideoUrl.data,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoModal);

