import axios from '../../../utilities/axios';
import {OrderHistoryDto} from '../../../models/dtos/orderHistory.dto';

export const getOrderHistoryRequest = async (): Promise<OrderHistoryDto[]> => {
  try {
    const response = await axios.get<OrderHistoryDto[]>(
      `${process.env.REACT_APP_BASE_URL}/orders/history`,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
