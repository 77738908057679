import {Dispatch} from 'redux';
import {ErrorDto, getErrorDtoFromApiError} from '../../../models/dtos/error.dto';
import {RewardMsisdnRequest} from '../../../models/requests/rewardMsisdn.request';
import {REWARD_MSISDN_FAIL, REWARD_MSISDN_RESET, REWARD_MSISDN_START, REWARD_MSISDN_SUCCESS,} from './constants';
import {rewardMsisdnRequest} from './repository';
import {
  RewardMsisdnFailAction,
  RewardMsisdnResetAction,
  RewardMsisdnStartAction,
  RewardMsisdnSuccessAction,
} from './types';

const rewardMsisdnStartAction = (): RewardMsisdnStartAction => {
  return {
    type: REWARD_MSISDN_START,
  };
};
const rewardMsisdnSuccessAction = (payload: boolean): RewardMsisdnSuccessAction => {
  return {
    type: REWARD_MSISDN_SUCCESS,
    payload: payload,
  };
};
const rewardMsisdnFailAction = (error: ErrorDto): RewardMsisdnFailAction => {
  return {
    type: REWARD_MSISDN_FAIL,
    error: error,
  };
};
const rewardMsisdnResetAction = (): RewardMsisdnResetAction => {
  return {
    type: REWARD_MSISDN_RESET,
  };
};

export const rewardMsisdn = (offerId: number, request: RewardMsisdnRequest) => async (dispatch: Dispatch) => {
  try {
    dispatch(rewardMsisdnStartAction());
    await rewardMsisdnRequest(offerId, request);
    dispatch(rewardMsisdnSuccessAction(true));
  } catch (error) {
    dispatch(rewardMsisdnFailAction(getErrorDtoFromApiError(error)));
  }
};

export const rewardMsisdnReset = () => (dispatch: Dispatch) => {
  dispatch(rewardMsisdnResetAction());
};
