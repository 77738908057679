import axios from '../../../utilities/axios';

export const resendConfirmEmailRequest = async (): Promise<void> => {
  try {
    const response = await axios.post<void>(
      `${process.env.REACT_APP_BASE_URL}/users/resend-confirmation-code`,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
