import {Dispatch} from 'redux';
import {CampaignDto} from '../../../models/dtos/campaign.dto';
import {ErrorDto, getErrorDtoFromApiError} from '../../../models/dtos/error.dto';
import {
  GET_CAMPAIGN_HISTORY_FAIL,
  GET_CAMPAIGN_HISTORY_RESET,
  GET_CAMPAIGN_HISTORY_START,
  GET_CAMPAIGN_HISTORY_SUCCESS,
} from './constants';
import {getCampaignHistoryRequest} from './repository';
import {
  GetCampaignHistoryFailAction,
  GetCampaignHistoryResetAction,
  GetCampaignHistoryStartAction,
  GetCampaignHistorySuccessAction,
} from './types';

const getCampaignHistoryStartAction = (): GetCampaignHistoryStartAction => {
  return {
    type: GET_CAMPAIGN_HISTORY_START,
  };
};
const getCampaignHistorySuccessAction = (payload: CampaignDto[]): GetCampaignHistorySuccessAction => {
  return {
    type: GET_CAMPAIGN_HISTORY_SUCCESS,
    payload: payload,
  };
};
const getCampaignHistoryFailAction = (error: ErrorDto): GetCampaignHistoryFailAction => {
  return {
    type: GET_CAMPAIGN_HISTORY_FAIL,
    error: error,
  };
};
const getCampaignHistoryResetAction = (): GetCampaignHistoryResetAction => {
  return {
    type: GET_CAMPAIGN_HISTORY_RESET,
  };
};

export const getCampaignHistory = () => async (dispatch: Dispatch) => {
  try {
    dispatch(getCampaignHistoryStartAction());
    const response = await getCampaignHistoryRequest();
    response.sort((a, b) => a.id > b.id ? -1 : 1);
    dispatch(getCampaignHistorySuccessAction(response));
  } catch (error) {
    dispatch(getCampaignHistoryFailAction(getErrorDtoFromApiError(error)));
  }
};

export const getCampaignHistoryReset = () => (dispatch: Dispatch) => {
  dispatch(getCampaignHistoryResetAction());
};
