import {Dispatch} from 'redux';
import {ErrorDto, getErrorDtoFromApiError} from '../../../models/dtos/error.dto';
import { OrderDto } from '../../../models/dtos/order.dto';
import { MoneyTransferOrderRequest } from '../../../models/requests/moneyTransferOrder.request';
import {
  CREATE_MONEY_TRANSFER_ORDER_FAIL,
  CREATE_MONEY_TRANSFER_ORDER_RESET,
  CREATE_MONEY_TRANSFER_ORDER_START,
  CREATE_MONEY_TRANSFER_ORDER_SUCCESS,
} from './constants';
import {createMoneyTransferOrderRequest} from './repository';
import {
  CreateMoneyTransferOrderFailAction,
  CreateMoneyTransferOrderResetAction,
  CreateMoneyTransferOrderStartAction,
  CreateMoneyTransferOrderSuccessAction,
} from './types';

const createMoneyTransferOrderStartAction = (): CreateMoneyTransferOrderStartAction => {
  return {
    type: CREATE_MONEY_TRANSFER_ORDER_START,
  };
};
const createMoneyTransferOrderSuccessAction = (payload: OrderDto): CreateMoneyTransferOrderSuccessAction => {
  return {
    type: CREATE_MONEY_TRANSFER_ORDER_SUCCESS,
    payload: payload,
  };
};
const createMoneyTransferOrderFailAction = (error: ErrorDto): CreateMoneyTransferOrderFailAction => {
  return {
    type: CREATE_MONEY_TRANSFER_ORDER_FAIL,
    error: error,
  };
};
const createMoneyTransferOrderResetAction = (): CreateMoneyTransferOrderResetAction => {
  return {
    type: CREATE_MONEY_TRANSFER_ORDER_RESET,
  };
};

export const createMoneyTransferOrder = (request: MoneyTransferOrderRequest) => async (dispatch: Dispatch) => {
  try {
    dispatch(createMoneyTransferOrderStartAction());
    const response = await createMoneyTransferOrderRequest(request);
    dispatch(createMoneyTransferOrderSuccessAction(response));
  } catch (error) {
    dispatch(createMoneyTransferOrderFailAction(getErrorDtoFromApiError(error)));
  }
};

export const createMoneyTransferOrderReset = () => (dispatch: Dispatch) => {
  dispatch(createMoneyTransferOrderResetAction());
};
