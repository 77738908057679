import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import VideoModal from "../../../components/Modals/VideoModal/VideoModal";
import { MeDto } from "../../../core/models/dtos/me.dto";
import FirebaseService from "../../../core/services/firebase.service";
import { IStore } from "../../../core/utilities/reducers";
import LandingFooter from "./LandingFooter/LandingFooter";
import "./LandingPage.scss";
import SectionBanner from "./SectionBanner/SectionBanner";
import SectionGoals from "./SectionGoals/SectionGoals";
import SectionIntegration from "./SectionIntegration/SectionIntegration";
import SectionKgbApp from "./SectionKgbApp/SectionKgbApp";
import SectionPromotion from "./SectionPromotion/SectionPromotion";
import SectionRegister from "./SectionRegister/SectionRegister";
import SectionUsage from "./SectionUsage/SectionUsage";

interface IProps {
  me?: MeDto;
}

class LandingPage extends Component<IProps> {
  private sectionRefGoals = React.createRef();
  private sectionRefUsage = React.createRef();
  private sectionRefIntegration = React.createRef();
  private sectionRefKgbApp = React.createRef();

  componentDidMount() {
    this.props.me
      ? FirebaseService.logEvent(FirebaseService.landing_view_with_login)
      : FirebaseService.logEvent(FirebaseService.landing_view_without_login);
  }

  render() {
    return (
      <div id="landing-page" className="page">
        <div className="page-content">
          <SectionBanner
            refGoal={this.sectionRefGoals}
            refUsage={this.sectionRefUsage}
            refIntegration={this.sectionRefIntegration}
            refKgbApp={this.sectionRefKgbApp}
          />
          <div className="divider" />
          <SectionPromotion />
          {/* <div className="divider"/>
          <SectionPromotionBottom/> */}
          <div className="divider" />
          <SectionGoals sectionRef={this.sectionRefGoals} />
          <div className="divider" />
          <SectionUsage sectionRef={this.sectionRefUsage} />
          <div className="divider" />
          <SectionIntegration sectionRef={this.sectionRefIntegration} />
          <div className="divider" />
          <SectionKgbApp sectionRef={this.sectionRefKgbApp} />
          {!this.props.me && (
            <React.Fragment>
              <div className="divider" />
              <SectionRegister />
            </React.Fragment>
          )}
          <LandingFooter />
        </div>
        <VideoModal />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({}, dispatch);
};
const mapStateToProps = (store: IStore) => {
  return {
    me: store.setMe.me,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
