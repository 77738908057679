import {Dispatch} from 'redux';
import {ErrorDto, getErrorDtoFromApiError} from '../../../models/dtos/error.dto';
import {RedirectUrlDto} from '../../../models/dtos/redirectUrl.dto';
import {CreateOrderAndPayRequest} from "../../../models/requests/createOrderAndPay.request";
import {createOrderRequest} from "../createOrder/repository";
import {payRequest} from "../pay/repository";
import {
  CREATE_ORDER_AND_PAY_FAIL,
  CREATE_ORDER_AND_PAY_RESET,
  CREATE_ORDER_AND_PAY_START,
  CREATE_ORDER_AND_PAY_SUCCESS,
} from './constants';
import {
  CreateOrderAndPayFailAction,
  CreateOrderAndPayResetAction,
  CreateOrderAndPayStartAction,
  CreateOrderAndPaySuccessAction,
} from './types';

const createOrderAndPayStartAction = (): CreateOrderAndPayStartAction => {
  return {
    type: CREATE_ORDER_AND_PAY_START,
  };
};
const createOrderAndPaySuccessAction = (payload: RedirectUrlDto): CreateOrderAndPaySuccessAction => {
  return {
    type: CREATE_ORDER_AND_PAY_SUCCESS,
    payload: payload,
  };
};
const createOrderAndPayFailAction = (error: ErrorDto): CreateOrderAndPayFailAction => {
  return {
    type: CREATE_ORDER_AND_PAY_FAIL,
    error: error,
  };
};
const createOrderAndPayResetAction = (): CreateOrderAndPayResetAction => {
  return {
    type: CREATE_ORDER_AND_PAY_RESET,
  };
};

export const createOrderAndPay = (request: CreateOrderAndPayRequest) => async (dispatch: Dispatch) => {
  try {
    dispatch(createOrderAndPayStartAction());
    const order = await createOrderRequest(request.orderRequest);
    const pay = await payRequest(order.id);
    dispatch(createOrderAndPaySuccessAction({redirectUrl: pay.redirectUrl}));
  } catch (error) {
    dispatch(createOrderAndPayFailAction(getErrorDtoFromApiError(error)));
  }
};

export const createOrderAndPayReset = () => (dispatch: Dispatch) => {
  dispatch(createOrderAndPayResetAction());
};
