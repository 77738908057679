import axios from '../../../utilities/axios';
import {CampaignDto} from '../../../models/dtos/campaign.dto';

export const getCampaignHistoryRequest = async (): Promise<CampaignDto[]> => {
  try {
    const response = await axios.get<CampaignDto[]>(
      `${process.env.REACT_APP_BASE_URL}/campaigns`,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
