import {Dispatch} from 'redux';
import FirebaseService from "../../firebase.service";
import {SET_REMOTE_CONFIG_START, SET_REMOTE_CONFIG_SUCCESS} from './constants';
import {SetRemoteConfigStartAction, SetRemoteConfigSuccessAction} from './types';

const setRemoteConfigStartAction = (): SetRemoteConfigStartAction => {
  return {
    type: SET_REMOTE_CONFIG_START,
  };
};
const setRemoteConfigSuccessAction = (payload: boolean): SetRemoteConfigSuccessAction => {
  return {
    type: SET_REMOTE_CONFIG_SUCCESS,
    payload: payload,
  };
};

export const setRemoteConfig = () => async (dispatch: Dispatch) => {
  try {
    dispatch(setRemoteConfigStartAction());
    const firebaseService: FirebaseService = new FirebaseService();
    await firebaseService.fetchAndActivateRemoteConfig();
    dispatch(setRemoteConfigSuccessAction(true));
  } catch (error) {
    dispatch(setRemoteConfigSuccessAction(false));
  }
};
