import React, { ReactNode, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import "./CopyBox.scss";

interface IProps {
  text: string;
  callbackClick?: () => void;
  copyButton?: string;
}

const CopyBox = (props: IProps) => {
  const [isCopied, setIsCopied] = useState(false);

  function handleCopy(): void {
    if (props.callbackClick) {
      props.callbackClick();
      return;
    }
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  }

  const renderIcon = (): ReactNode => {
    if (props.copyButton) {
      return (
        <img className="icon" src={props.copyButton} alt="icon" />
      )
    }
  }

  return (
    <React.Fragment>
      <CopyToClipboard
        text={props.text}
        onCopy={handleCopy}
      >
        <div id="copy-box">
          {props.text}
          {renderIcon()}
        </div>
      </CopyToClipboard>
      {isCopied && <span className="copy-box-copied-text">Kopyalandı!</span>}
    </React.Fragment>
  );
}

export default CopyBox;
