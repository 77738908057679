import {Dispatch} from 'redux';
import {ErrorDto, getErrorDtoFromApiError} from '../../../models/dtos/error.dto';
import { AgencyRevenueRequest } from '../../../models/requests/agencyRevenue.request';
import {
  CLAIM_REVENUE_FAIL,
  CLAIM_REVENUE_RESET,
  CLAIM_REVENUE_START,
  CLAIM_REVENUE_SUCCESS,
} from './constants';
import {claimRevenueRequest} from './repository';
import {
  ClaimRevenueFailAction,
  ClaimRevenueResetAction,
  ClaimRevenueStartAction,
  ClaimRevenueSuccessAction,
} from './types';

const claimRevenueStartAction = (): ClaimRevenueStartAction => {
  return {
    type: CLAIM_REVENUE_START,
  };
};
const claimRevenueSuccessAction = (payload: boolean): ClaimRevenueSuccessAction => {
  return {
    type: CLAIM_REVENUE_SUCCESS,
    payload: payload,
  };
};
const claimRevenueFailAction = (error: ErrorDto): ClaimRevenueFailAction => {
  return {
    type: CLAIM_REVENUE_FAIL,
    error: error,
  };
};
const claimRevenueResetAction = (): ClaimRevenueResetAction => {
  return {
    type: CLAIM_REVENUE_RESET,
  };
};

export const claimRevenue = (request:AgencyRevenueRequest) => async (dispatch: Dispatch) => {
  try {
    dispatch(claimRevenueStartAction());
    await claimRevenueRequest(request);
    dispatch(claimRevenueSuccessAction(true));
  } catch (error) {
    dispatch(claimRevenueFailAction(getErrorDtoFromApiError(error)));
  }
};

export const claimRevenueReset = () => (dispatch: Dispatch) => {
  dispatch(claimRevenueResetAction());
};
