import {InputNumber} from "antd";
import React, {ReactNode, useEffect, useState} from 'react';
import right from "../../../../assets/images/chevron-right.svg";
import AppButton from "../../../../components/Buttons/AppButton/AppButton";
import {OfferDto} from "../../../../core/models/dtos/offer.dto";
import {Helpers} from "../../../../core/utilities/helpers";
import {PackageOrder} from "../OrderPage";
import "./OrderPackage.scss";

interface IProps {
  offers: OfferDto[];
  callbackChange: () => void;
  callbackContinue: (offer: PackageOrder) => void;
}

const OrderPackage = (props: IProps) => {
  const [amount, setAmount] = useState<number>();
  const [isAmountError, setAmountError] = useState(false);

  useEffect(() => {
    setAmountError(false);
    props.callbackChange();
    // todo
    // eslint-disable-next-line
  }, [amount]);

  function handleDecrement(): void {
    if (!amount) {
      setAmount(1);
    } else if (amount > 1) {
      setAmount(amount - 1);
    }
  }

  function handleIncrement(): void {
    amount === undefined
      ? setAmount(1)
      : setAmount(amount + 1);
  }

  function handleContinue(offer: OfferDto): void {
    if (amount) {
      props.callbackContinue({amount, offer});
    } else {
      setAmountError(true);
    }
  }

  function getContinueButtonChild(): ReactNode {
    return (
      <div className="d-flex align-items-center">
        <span>Devam Et</span>
        &nbsp;&nbsp;
        <img src={right} alt="right"/>
      </div>
    );
  }

  return (
    <div id="order-package">
      {
        props.offers.map(offer => {
          return (
            <React.Fragment key={`offer-${offer.id}`}>
              <div className="offer-wrapper">
                <div className="info">
                  <div className="description">
                    <label className="point">{offer.name}</label>
                    {/* <label className="name">{offer.value}&nbsp;değerindedir.</label> */}
                  </div>
                  <div className="price-box">
                    <label className="price">{Helpers.getPriceWithCurrency(offer.price)}</label>
                  </div>
                </div>
                <div className="amount">
                  <label
                    className="clear"
                    onClick={() => setAmount(undefined)}
                  >
                    Temizle
                  </label>
                  <div className="input-box">
                    <div
                      className="adjust-button decrement"
                      onClick={handleDecrement}
                    >
                      -
                    </div>
                    <InputNumber
                      className="input"
                      placeholder="Adet"
                      value={amount}
                      min={0}
                      onChange={setAmount}
                    />
                    <div
                      className="adjust-button increment"
                      onClick={handleIncrement}
                    >
                      +
                    </div>
                  </div>
                </div>
                {
                  isAmountError &&
                  <div className="amount-error ant-form-item-explain ant-form-item-explain-error">
                    Adet girin
                  </div>
                }
              </div>
              <AppButton
                content={getContinueButtonChild()}
                onClick={() => handleContinue(offer)}
              />
            </React.Fragment>
          );
        })
      }
    </div>
  );
}

export default OrderPackage;
