import {CampaignDetailsDto} from '../../../models/dtos/campaignDetails.dto';
import axios from '../../../utilities/axios';

export const getDetailsRequest = async (id: number): Promise<CampaignDetailsDto> => {
  try {
    const response = await axios.get<CampaignDetailsDto>(
      `${process.env.REACT_APP_BASE_URL}/campaigns/${id}/details`,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
