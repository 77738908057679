import {
  CREATE_ORDER_AND_PAY_WITH_CARD_FAIL,
  CREATE_ORDER_AND_PAY_WITH_CARD_RESET,
  CREATE_ORDER_AND_PAY_WITH_CARD_START,
  CREATE_ORDER_AND_PAY_WITH_CARD_SUCCESS,
} from './constants';
import {CreateOrderAndPayWithCardActions, CreateOrderAndPayWithCardState} from './types';

const initialState: CreateOrderAndPayWithCardState = {
  loading: false,
  data: undefined,
  error: undefined,
};

const reducer = (
  state = initialState,
  action: CreateOrderAndPayWithCardActions,
): CreateOrderAndPayWithCardState => {
  switch (action.type) {
    case CREATE_ORDER_AND_PAY_WITH_CARD_START:
      return {
        ...initialState,
        loading: true,
      };
    case CREATE_ORDER_AND_PAY_WITH_CARD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case CREATE_ORDER_AND_PAY_WITH_CARD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case CREATE_ORDER_AND_PAY_WITH_CARD_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
