import React from "react";
import Loading from "../../../components/Loading/Loading";
import FirebaseService from "../../../core/services/firebase.service";
import {Constants} from "../../../core/utilities/constants";
import {LocalStorage} from "../../../core/utilities/localStorage";

class PayByMeFailedPage extends React.Component {

  componentDidMount() {
    LocalStorage.set(LocalStorage.vpos, Constants.vposResultFailed);
    FirebaseService.logEvent(FirebaseService.payment_error_view);
  }

  render() {
    return (
      <Loading className="app-loading" fontSize={80}/>
    );
  }
}

export default PayByMeFailedPage;
