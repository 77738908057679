import { CloseOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input, Select, Switch, Tooltip } from "antd";
import React, { ReactNode, useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import checkmark from "../../assets/images/checkmark-primary.svg";
import copy from "../../assets/images/copy-secondary.svg";
import download from "../../assets/images/download.svg";
import flag from "../../assets/images/flag.svg";
import info from "../../assets/images/info.svg";
import landmark from "../../assets/images/landmark.svg";
import pen from "../../assets/images/pen.svg";
import sideUserAdd from "../../assets/images/side-user-add.svg";
import sideUserDelete from "../../assets/images/side-user-delete.svg";
import { FormValuesProfile } from "../../core/models/custom/formValuesProfile";
import { FormValuesWithdraw } from "../../core/models/custom/formValuesWithdraw";
import { InitialValuesProfile } from "../../core/models/custom/initialValuesProfile";
import { InitialValuesRegister } from "../../core/models/custom/initialValuesRegister";
import { ProfileValues } from "../../core/models/custom/profileValues";
import { MeDto } from "../../core/models/dtos/me.dto";
import { SideUserDto } from "../../core/models/dtos/sideUser.dto";
import { GetWalletDetailsState } from "../../core/services/agencyService/getWalletDetails/types";
import { GetSideUsersState } from "../../core/services/userService/getSideUsers/types";
import { Constants } from "../../core/utilities/constants";
import { Helpers } from "../../core/utilities/helpers";
import AppButton from "../Buttons/AppButton/AppButton";
import FileUpload from "../FileUpload/FileUpload";
import Loading from "../Loading/Loading";
import WithdrawModal from "../Modals/WithdrawModal/WithdrawModal";
import WithdrawSuccessModal from "../Modals/WithdrawSuccesModel/WithdrawSuccesModal";
import "./ProfileForm.scss";
import SideUserCard from "./SideUserCard/SideUserCard";

const { Option } = Select;

interface IProps {
  me?: MeDto;
  isVisible: boolean;
  isSubmittedWithdraw: boolean;
  getSideUsersState?: GetSideUsersState;
  initialValues: InitialValuesRegister | InitialValuesProfile;
  isRegistration: boolean;
  isSubmitting: boolean;
  getWalletDetailsState: GetWalletDetailsState;
  callback: () => void;
  callbackOk: (values: FormValuesWithdraw) => void
  callbackSubmit: (values: ProfileValues) => void;
  callbackSideUserAdd?: () => void;
  callbackSideUserDelete?: (sideUser: SideUserDto) => void;
}

const ProfileForm = (props: IProps) => {
  const [countryISOCode, setCountryISOCode] = useState(
    props.initialValues.initialCountryCode
  );
  const [countryBillingISOCode, setCountryBillingISOCode] = useState(
    props.initialValues.initialBillingCountryCode
  );
  const [isSameBillingAddress, setSameBillingAddress] = useState(
    props.initialValues.isSameBillingAddress
  );

  const [isContractsApproved, setContractsApproved] = useState(
    props.initialValues.isContractsApproved
  );
  const [fileSignature, setFileSignature] = useState(
    props.initialValues.fileSignature
  );
  const [fileSignedContract, setFileSignedContract] = useState(
    props.initialValues.fileSignedContract
  );
  const [fileTaxPlate, setFileTaxPlate] = useState(
    props.initialValues.fileTaxPlate
  );
  const [isChecked, setChecked] = useState(true);
  const [currentlyEditableField, setCurrentlyEditableField] = useState("");
  const [isSubmitted, setSubmitted] = useState(false);
  const [isWithdrawModalVisible, setWithdrawModalVisible] = useState(false);
  const [isSideUserError, setSideUserError] = useState(false);
  const [form] = Form.useForm();

  // Profile editable fields
  const editableFieldFullName = "fullName";
  const editableFieldMsisdn = "msisdn";
  const editableFieldSmsName = "smsName";
  const editableFieldBillingCountry = "billingCountry";
  const editableFieldBillingCity = "billingCity";
  const editableFieldBillingAddress = "billingAddress";

  useEffect(() => {
    if (props.isVisible) {
      setWithdrawModalVisible(false)
    } else if (!props.isVisible && props.isSubmittedWithdraw) {
      setWithdrawModalVisible(true)
    }
  }, [props.isVisible, props.isSubmittedWithdraw]);

  function isSideUserProfile(): boolean {
    return !props.isRegistration && !props.getSideUsersState;
  }

  function getSideUsers(sideUsers: SideUserDto[]): SideUserDto[] {
    return sideUsers.filter(
      (sideUser) => sideUser.email !== props.initialValues.email
    );
  }

  function handleSideUserAdd(sideUserCount: number) {
    if (sideUserCount >= Constants.sideUserMaxLimit) {
      setSideUserError(true);
      return;
    } else {
      if (props.callbackSideUserAdd) {
        props.callbackSideUserAdd();
      }
    }
  }

  function handleSideUserDelete(sideUser: SideUserDto) {
    setSideUserError(false);
    if (props.callbackSideUserDelete) {
      props.callbackSideUserDelete(sideUser);
    }
  }

  function handleMsisdnChange(msisdn: string): void {
    if (msisdn) {
      if (msisdn.substring(0, 1) !== "5") {
        form.setFieldsValue({ msisdn: "" });
      }
      if (msisdn.length > 10) {
        form.setFieldsValue({ msisdn: msisdn.substring(0, 10) });
      }
    }
  }

  function handleMsisdnKeyPress(e: React.KeyboardEvent): void {
    if (isNaN(parseInt(e.key))) {
      e.preventDefault();
    }
  }

  function handleCountryChange(isBilling: boolean, value: string) {
    if (isBilling) {
      setCountryBillingISOCode(value);
      form.setFieldsValue({ billingCity: "" });
    } else {
      setCountryISOCode(value);
      form.setFieldsValue({ city: "" });
    }
  }

  function handleFileUploadError(error: any) {
    console.log(error);
    alert(Constants.inputErrorFileUpload);
  }

  function handleFinish(values: FormValuesProfile) {
    setCurrentlyEditableField("");
    if (
      !isContractsApproved ||
      !fileSignature ||
      !fileSignedContract ||
      !fileTaxPlate
    ) {
      return;
    }
    values.isAgency = isChecked;
    values.msisdn = `90${values.msisdn}`;
    props.callbackSubmit({
      profile: values,
      signature: fileSignature,
      signedContract: fileSignedContract,
      taxPlate: fileTaxPlate,
      isSameBillingAddress,
    });
  }

  function handleClickEdit(field: string): void {
    const current = currentlyEditableField;
    if (current) {
      const initialValues = props.initialValues as InitialValuesProfile;
      switch (current) {
        case editableFieldFullName:
          form.setFieldsValue({ fullName: initialValues.fullName });
          break;
        case editableFieldMsisdn:
          form.setFieldsValue({ msisdn: initialValues.msisdn });
          break;
        case editableFieldSmsName:
          form.setFieldsValue({ smsName: initialValues.smsName });
          break;
        case editableFieldBillingCountry:
          form.setFieldsValue({
            billingCountry: initialValues.billingCountry,
            billingCity: initialValues.billingCity,
          });
          break;
        case editableFieldBillingAddress:
          form.setFieldsValue({ billingAddress: initialValues.billingAddress });
          break;
      }
    }
    setCurrentlyEditableField(field);
  }

  function handleClickDone(): void {
    form.submit();
  }

  function handleChangeSameBillingAddress(isSame: boolean): void {
    setSameBillingAddress(isSame);
    if (!props.isRegistration) {
      if (isSame) {
        form.submit();
      } else {
        const initialValues = props.initialValues as InitialValuesProfile;
        form.setFieldsValue({
          billingCountry: initialValues.billingCountry,
          billingCity: initialValues.billingCity,
          billingAddress: initialValues.billingAddress,
        });
      }
    }
  }

  function isInputDisabled(field: string): boolean {
    let isDisabled = currentlyEditableField !== field;
    if (
      currentlyEditableField === editableFieldBillingCountry &&
      field === editableFieldBillingCity
    ) {
      isDisabled = false;
    }
    return !props.isRegistration && isDisabled;
  }

  function getInputClass(field: string): string {
    let isEditable = currentlyEditableField === field;
    if (
      currentlyEditableField === editableFieldBillingCountry &&
      field === editableFieldBillingCity
    ) {
      isEditable = true;
    }
    return `input ${isEditable ? "editable" : "non-editable"}`;
  }

  function getInputActions(field: string): ReactNode {
    if (props.isSubmitting) {
      return <Loading fontSize={16} className="me-1" />;
    }
    return currentlyEditableField === field ? (
      <img
        className="icon-checkmark"
        src={checkmark}
        alt="icon"
        onClick={() => handleClickDone()}
      />
    ) : (
      <img
        className="icon-pen"
        src={pen}
        alt="icon"
        onClick={() => handleClickEdit(field)}
      />
    );
  }

  function renderFileUpload(url: string, setFunction: Function): ReactNode {
    return (
      <div className="file-upload-reg">
        <FileUpload
          child={
            url ? (
              <a href={url} target="_blank" rel="noreferrer">
                {url.slice(0, 45).concat("...")}
              </a>
            ) : (
              "Ekle"
            )
          }
          callbackUpload={(url) => setFunction(url)}
          callbackFail={handleFileUploadError}
        />
      </div>
    );
  }

  function renderFileDelete(url: string, setFunction: Function): ReactNode {
    return (
      url && (
        <CloseOutlined
          className="icon-file-delete"
          onClick={() => setFunction("")}
        />
      )
    );
  }

  function handleDestroyWithdrawModal() {
    setWithdrawModalVisible(false);
  }

  function renderFileRequiredError(url: string): ReactNode {
    return (
      <div className="input-wrapper pt-0">
        {isSubmitted && !url && (
          <span className="ant-form-item-explain ant-form-item-explain-error">
            {Constants.inputErrorRequiredField}
          </span>
        )}
      </div>
    );
  }

  function renderSectionBalance(): ReactNode {
    if (!props.isRegistration && props.getWalletDetailsState.data) {
      return (
        <div className="section d-flex">
          <label className="title l">Ajans Hesabınız</label>
          <div className="d-flex flex-column">
            <div className="input-wrapper agency d-flex align-items-center border-top">
              <div className="d-flex">
                <img className="icon-landmark" src={landmark} alt="landmark" />
                <label className="label bolder">Bakiyeniz</label>
              </div>
            </div>
            <div className="input-wrapper content d-flex border-bottom">
              <div className="d-flex flex-column">
                <label className="label bold">₺{(props.getWalletDetailsState.data.balance).toLocaleString("tr-TR", { maximumFractionDigits: 2 })}</label>
                <label className="label">
                  Referans kodunuz kullanılarak yapılan satın alımlardan
                  kazancınız siz alana kadar bakiyenizde birikir.
                </label>
              </div>
              <Button
                className="ant-btn ant-btn-primary app-button primary withdraw"
                onClick={() => setWithdrawModalVisible(true)}
              >
                Kazancı Al
              </Button>
              <WithdrawModal
                isSubmittedWithdraw={props.isSubmittedWithdraw}
                callbackOk={props.callbackOk}
                withdraw={props.getWalletDetailsState.data.balance}
                visible={isWithdrawModalVisible}
                callbackCancel={() => handleDestroyWithdrawModal()}
              />
              <WithdrawSuccessModal
                isVisible={props.isVisible}
                callback={props.callback}
              />
            </div>
          </div>
        </div>
      );
    }
    else {
      return <React.Fragment />
    }
  }

  function renderRefCode(): ReactNode {
    if (props.initialValues.code) {
      return (
        <div className="d-flex align-items-baseline">
          <label className="label code"> {props.initialValues.code}</label>
          <CopyToClipboard text={props.initialValues.code}>
            <img className="icon-copy" src={copy} alt="copy" />
          </CopyToClipboard>
        </div>
      )
    }
  }

  function renderSectionProfit(): ReactNode {
    if (!props.isRegistration && props.getWalletDetailsState.data) {
      return (
        <div className="section">
          <div className="d-flex justify-content-end">
            <div className="d-flex flex-column">
              <div className="d-flex align-items-baseline">
                <img className="icon-flag" src={flag} alt="flag" />
                <label className="label ref">Referans Kodunuz</label>
              </div>
              {renderRefCode()}
            </div>
          </div>
          <div className="input-wrapper d-flex flex-column border-top border-bottom">
            <label className="label bolder">Toplam Kazancınız</label>
            <label className="label bold">₺{(props.getWalletDetailsState.data.totalRevenue).toLocaleString("tr-TR", { maximumFractionDigits: 2 })}</label>
            <label className="label ref">
              Referans kodunuz kullanılarak yapılan satın alımlardan toplam
              kazancınızı gösterir.
            </label>
          </div>
        </div>
      );
    } else {
      return <React.Fragment />
    }

  }

  function renderSectionAdmin(): ReactNode {
    return (
      <div className="section">
        <label className="title">Yönetici Bilgileri</label>

        <div
          className={`input-wrapper border-top ${isSideUserProfile() && "disabled"
            }`}
        >
          <div className="input-box">
            <label className="label">Ad - Soyad</label>
            <Form.Item
              name={editableFieldFullName}
              className="item"
              rules={[
                { required: true, message: Constants.inputErrorRequiredField },
              ]}
            >
              <Input
                className={getInputClass(editableFieldFullName)}
                disabled={isInputDisabled(editableFieldFullName)}
              />
            </Form.Item>
          </div>
          {!isSideUserProfile() && getInputActions(editableFieldFullName)}
        </div>

        <div className="input-wrapper disabled">
          <div className="input-box">
            <label className="label">Mail</label>
            <Form.Item
              name="email"
              className="item"
              rules={[
                { required: true, message: Constants.inputErrorRequiredField },
                { type: "email", message: "Hatalı e-mail" },
              ]}
            >
              <Input className="input" type="email" disabled />
            </Form.Item>
          </div>
        </div>

        <div
          className={`input-wrapper border-bottom ${isSideUserProfile() && "disabled"
            }`}
        >
          <div className="input-box">
            <label className="label">Telefon numarası</label>
            <Form.Item
              name={editableFieldMsisdn}
              className="item"
              rules={[
                { required: true, message: Constants.inputErrorRequiredField },
                {
                  min: 10,
                  message:
                    "Lütfen telefon numaranızı başında sıfır olmadan 10 hane olarak giriniz. ÖRN: 5XX XXX XX XX",
                },
              ]}
            >
              <Input
                className={getInputClass(editableFieldMsisdn)}
                disabled={isInputDisabled(editableFieldMsisdn)}
                type="tel"
                prefix="+90"
                onChange={(e) => handleMsisdnChange(e.target.value)}
                onKeyPress={handleMsisdnKeyPress}
              />
            </Form.Item>
          </div>
          {!isSideUserProfile() && getInputActions(editableFieldMsisdn)}
        </div>
        <br></br>

        <div
          className={`input-wrapper switch py-3 ${!props.isRegistration && "none"
            }`}
          onClick={() => setChecked(!isChecked)}
        >
          <div className="input-box d-flex flex-row">
            <span className="no-select d-flex px-2" style={{ cursor: "pointer" }}>
              Ajans hesabıdır.
            </span>
            <Switch
              className="checkbox"
              disabled={!props.isRegistration}
              checked={isChecked}
              onChange={(e) => setChecked(e)}
            />
          </div>
        </div>
      </div>
    );
  }
  function renderSectionCompany(): ReactNode {
    return (
      <div className="section">
        <label className="title">Şirket Bilgileri</label>

        <div className="input-wrapper border-top disabled">
          <div className="input-box">
            <label className="label">Ticaret ünvanı</label>
            <Form.Item
              name="companyName"
              className="item"
              rules={[
                { required: true, message: Constants.inputErrorRequiredField },
              ]}
            >
              <Input className="input" disabled />
            </Form.Item>
          </div>
        </div>

        <div className={`input-wrapper ${isSideUserProfile() && "disabled"}`}>
          <div className="input-box">
            <label className="label d-flex align-items-center">
              Marka Adı&nbsp;&nbsp;&nbsp;
              <Tooltip
                overlayClassName="tooltip-sms-name"
                placement="topLeft"
                title={
                  <React.Fragment>
                    <span className="title">Marka Adı</span>
                    <br />
                    <span className="text">
                      Bu isim aynı zamanda SMS'lerde müşterilerinizin göreceği
                      isimdir.
                    </span>
                  </React.Fragment>
                }
              >
                <img className="ml-3" src={info} alt="info" />
              </Tooltip>
            </label>
            <Form.Item
              name={editableFieldSmsName}
              className="item"
              rules={[
                { required: true, message: Constants.inputErrorRequiredField },
              ]}
            >
              <Input
                className={getInputClass(editableFieldSmsName)}
                disabled={isInputDisabled(editableFieldSmsName)}
              />
            </Form.Item>
          </div>
          {!isSideUserProfile() && getInputActions(editableFieldSmsName)}
        </div>

        <div className={`input-wrapper ${!props.isRegistration && "disabled"}`}>
          <div className="input-box">
            <label className="label">TCKN</label>
            <Form.Item
              name="tckn"
              className="item"
              rules={[
                { required: true, message: Constants.inputErrorRequiredField },
              ]}
            >
              <Input className="input" disabled={!props.isRegistration} />
            </Form.Item>
          </div>
          {props.isRegistration && (
            <img className="icon-pen" src={pen} alt="icon" />
          )}
        </div>

        <div className={`input-wrapper ${!props.isRegistration && "disabled"}`}>
          <div className="input-box">
            <label className="label">Ülke</label>
            <Form.Item
              name="country"
              className="item"
              rules={[
                { required: true, message: Constants.inputErrorRequiredField },
              ]}
            >
              <Select
                className="input"
                disabled={!props.isRegistration}
                onChange={(value) =>
                  handleCountryChange(false, value as string)
                }
              >
                {Helpers.getAllCountries().map((country) => {
                  return (
                    <Option key={country.name} value={country.isoCode}>
                      {country.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>
          {props.isRegistration && (
            <img className="icon-pen" src={pen} alt="icon" />
          )}
        </div>

        <div className={`input-wrapper ${!props.isRegistration && "disabled"}`}>
          <div className="input-box">
            <label className="label">Şehir</label>
            <Form.Item
              name="city"
              className="item"
              rules={[
                { required: true, message: Constants.inputErrorRequiredField },
              ]}
            >
              {countryISOCode === "TR" ? (
                <Select
                  key={`city-for-${countryISOCode}`}
                  className="input"
                  disabled={!props.isRegistration}
                >
                  {Helpers.getCitiesOfTurkey().map((city) => {
                    return (
                      <Option key={city} value={city}>
                        {city}
                      </Option>
                    );
                  })}
                </Select>
              ) : (
                <Input className="input" disabled={!props.isRegistration} />
              )}
            </Form.Item>
          </div>
          {props.isRegistration && (
            <img className="icon-pen" src={pen} alt="icon" />
          )}
        </div>

        <div className={`input-wrapper ${!props.isRegistration && "disabled"}`}>
          <div className="input-box">
            <label className="label">Adres</label>
            <Form.Item
              name="address"
              className="item"
              rules={[
                { required: true, message: Constants.inputErrorRequiredField },
              ]}
            >
              <Input className="input" disabled={!props.isRegistration} />
            </Form.Item>
          </div>
          {props.isRegistration && (
            <img className="icon-pen" src={pen} alt="icon" />
          )}
        </div>

        <div
          className={`input-wrapper py-3 ${isSideUserProfile() && "disabled"}`}
        >
          <div className="input-box">
            <Form.Item className="item">
              <span
                className="no-select"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  handleChangeSameBillingAddress(!isSameBillingAddress)
                }
              >
                Fatura adresi olarak da bu adresi kullanın.
              </span>
            </Form.Item>
          </div>
          <Switch
            className="checkbox my-auto"
            defaultChecked={isSameBillingAddress}
            disabled={isSideUserProfile()}
            checked={isSameBillingAddress}
            onChange={(e) => handleChangeSameBillingAddress(e)}
          />
        </div>

        {!isSameBillingAddress && (
          <React.Fragment>
            <div className="input-wrapper">
              <div className="input-box">
                <label className="label">Ülke</label>
                <Form.Item
                  name={editableFieldBillingCountry}
                  className="item"
                  rules={[
                    {
                      required: !isSameBillingAddress,
                      message: Constants.inputErrorRequiredField,
                    },
                  ]}
                >
                  <Select
                    className={getInputClass(editableFieldBillingCountry)}
                    disabled={isInputDisabled(editableFieldBillingCountry)}
                    onChange={(value) =>
                      handleCountryChange(true, value as string)
                    }
                  >
                    {Helpers.getAllCountries().map((country) => {
                      return (
                        <Option key={country.name} value={country.isoCode}>
                          {country.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
              {getInputActions(editableFieldBillingCountry)}
            </div>

            <div className="input-wrapper">
              <div className="input-box">
                <label className="label">Şehir</label>
                <Form.Item
                  name={editableFieldBillingCity}
                  className="item"
                  rules={[
                    {
                      required: !isSameBillingAddress,
                      message: Constants.inputErrorRequiredField,
                    },
                  ]}
                >
                  {countryBillingISOCode === "TR" ? (
                    <Select
                      key={`city-for-${countryBillingISOCode}`}
                      className={getInputClass(editableFieldBillingCity)}
                      disabled={isInputDisabled(editableFieldBillingCity)}
                    >
                      {Helpers.getCitiesOfTurkey().map((city) => {
                        return (
                          <Option key={city} value={city}>
                            {city}
                          </Option>
                        );
                      })}
                    </Select>
                  ) : (
                    <Input
                      className={getInputClass(editableFieldBillingCity)}
                      disabled={isInputDisabled(editableFieldBillingCity)}
                    />
                  )}
                </Form.Item>
              </div>
              {/*{getInputActions(editableFieldBillingCity)}*/}
            </div>

            <div className="input-wrapper">
              <div className="input-box">
                <label className="label">Adres</label>
                <Form.Item
                  name={editableFieldBillingAddress}
                  className="item"
                  rules={[
                    {
                      required: !isSameBillingAddress,
                      message: Constants.inputErrorRequiredField,
                    },
                  ]}
                >
                  <Input
                    className={getInputClass(editableFieldBillingAddress)}
                    disabled={isInputDisabled(editableFieldBillingAddress)}
                  />
                </Form.Item>
              </div>
              {getInputActions(editableFieldBillingAddress)}
            </div>
          </React.Fragment>
        )}

        <div className={`input-wrapper ${!props.isRegistration && "disabled"}`}>
          <div className="input-box">
            <label className="label">Vergi Numarası</label>
            <Form.Item
              name="taxNumber"
              className="item"
              rules={[
                { required: true, message: Constants.inputErrorRequiredField },
              ]}
            >
              <Input className="input" disabled={!props.isRegistration} />
            </Form.Item>
          </div>
          {props.isRegistration && (
            <img className="icon-pen" src={pen} alt="icon" />
          )}
        </div>

        <div className={`input-wrapper ${!props.isRegistration && "disabled"}`}>
          <div className="input-box">
            <label className="label">Şirket Telefon Numarası</label>
            <Form.Item
              name="companyPhoneNumber"
              className="item"
              rules={[
                { required: true, message: Constants.inputErrorRequiredField },
              ]}
            >
              <Input className="input" disabled={!props.isRegistration} />
            </Form.Item>
          </div>
          {props.isRegistration && (
            <img className="icon-pen" src={pen} alt="icon" />
          )}
        </div>

        <div className={`input-wrapper ${!props.isRegistration && "disabled"}`}>
          <div className="input-box">
            <label className="label">İmza Sirküleri</label>
            <Form.Item name="" className="item">
              {renderFileUpload(fileSignature, setFileSignature)}
            </Form.Item>
          </div>
          {props.isRegistration &&
            renderFileDelete(fileSignature, setFileSignature)}
        </div>
        {props.isRegistration && renderFileRequiredError(fileSignature)}

        <div
          className={`input-wrapper border-bottom ${!props.isRegistration && "disabled"
            }`}
        >
          <div className="input-box">
            <label className="label">Vergi Levhası</label>
            <Form.Item name="" className="item">
              {renderFileUpload(fileTaxPlate, setFileTaxPlate)}
            </Form.Item>
          </div>
          {props.isRegistration &&
            renderFileDelete(fileTaxPlate, setFileTaxPlate)}
        </div>
        {props.isRegistration && renderFileRequiredError(fileTaxPlate)}
      </div>
    );
  }

  function renderSideUser(
    sideUser: SideUserDto,
    isFirst: boolean,
    isLast: boolean
  ): ReactNode {
    return (
      <div
        key={sideUser.id}
        className={`side-user ${isFirst && "border-top"} ${isLast && "border-bottom"
          }`}
      >
        <span className="full-name">{sideUser.fullName}</span>
        <img
          className="delete"
          src={sideUserDelete}
          alt="side-user"
          onClick={() => handleSideUserDelete(sideUser)}
        />
      </div>
    );
  }

  function renderSectionSideUser(): ReactNode {
    if (props.getSideUsersState) {
      if (props.getSideUsersState.loading) {
        return (
          <div className="section">
            <label className="title">Ortak Yönetici</label>
            <Loading />
          </div>
        );
      } else if (props.getSideUsersState.data) {
        const sideUsers = getSideUsers(props.getSideUsersState.data);
        if (sideUsers.length === 0) {
          return (
            <div className="section">
              <label className="title">Ortak Yönetici</label>
              <SideUserCard
                callback={() => handleSideUserAdd(sideUsers.length)}
              />
            </div>
          );
        } else {
          return (
            <div className="section">
              <label className="title">
                Ortak yöneticiler
                <div
                  className="title-side-user-suffix"
                  onClick={() => handleSideUserAdd(sideUsers.length)}
                >
                  <img src={sideUserAdd} alt="side-user" />
                  <span className="text">Yeni yönetici ekle</span>
                </div>
              </label>
              {isSideUserError && (
                <div className="side-user-error">
                  En fazla {Constants.sideUserMaxLimit} yönetici eklenebilir.
                </div>
              )}
              <div className="side-users">
                {sideUsers.map((sideUser, index) => {
                  const isFirst = index === 0;
                  const isLast = index === sideUsers.length - 1;
                  return renderSideUser(sideUser, isFirst, isLast);
                })}
              </div>
            </div>
          );
        }
      }
      return (
        <div className="section">
          <label className="title">Ortak Yönetici</label>
        </div>
      );
    }
    return <React.Fragment />;
  }

  function renderSectionContract(): ReactNode {
    return (
      <div className="section">
        <label className="title">Sözleşme</label>

        <div
          className={`input-wrapper border-top ${isSideUserProfile() && "disabled"
            }`}
        >
          <div className="input-box">
            <a
              href={Constants.linkKullaniciTaahhutnamesi}
              target="_blank"
              rel="noreferrer"
              className="label d-flex"
              style={{ cursor: "pointer", color: "#138656" }}
            >
              <span className="button-text-m">
                Kullanıcı taahhütnamesini indirin
              </span>
              <img className="icon-download" src={download} alt="icon" />
            </a>
            <Form.Item className="item">
              <label style={{ color: "#858585" }}>
                Sözleşmeyi indirdikten sonra her sayfanın altı{" "}
                <strong>şirket imza yetkilisi</strong> tarafından
                imzalanmalıdır. Son sayfanın altında{" "}
                <strong>tarih, isim - soyisim ve kaşe</strong> olacak şekilde
                imzalanması ve taratılarak sisteme geri yüklenmesi
                gerekmektedir.
              </label>
            </Form.Item>
          </div>
        </div>

        <div className={`input-wrapper ${!props.isRegistration && "disabled"}`}>
          <div className="input-box">
            <label className="label">İmzalı Sözleşme</label>
            <Form.Item name="" className="item">
              {renderFileUpload(fileSignedContract, setFileSignedContract)}
            </Form.Item>
          </div>
          {props.isRegistration &&
            renderFileDelete(fileSignedContract, setFileSignedContract)}
        </div>
        {props.isRegistration && renderFileRequiredError(fileSignedContract)}

        <div className={`input-wrapper ${isSideUserProfile() && "disabled"}`}>
          <span className="button-text-m pt-1">
            <a
              href={Constants.linkAydinlatmaMetni}
              target="_blank"
              rel="noreferrer"
              className="contract-link"
            >
              Aydınlatma Metni'ni
            </a>
            &nbsp;okudum ve anladım. &nbsp;
          </span>
          <Form.Item
            name="contractsApproved"
            valuePropName="checked"
            className="me-1 mb-0"
          >
            <Checkbox
              checked={isContractsApproved}
              disabled={!props.isRegistration}
              onChange={(e) => setContractsApproved(e.target.checked)}
            />
          </Form.Item>
        </div>
        <div
          className={`input-wrapper border-bottom pt-0 ${isSideUserProfile() && "disabled"
            }`}
        >
          {isSubmitted && !isContractsApproved && (
            <span className="ant-form-item-explain ant-form-item-explain-error">
              Sözleşmeleri kabul etmelisiniz
            </span>
          )}
        </div>
      </div>
    );
  }

  return (
    <Form
      form={form}
      id="profile-form"
      className={`form ${props.isRegistration ? "form-register" : ""}`}
      initialValues={{
        ...props.initialValues,
      }}
      onFinish={handleFinish}
    >
      <div className="row d-flex d-md-none">
        <div className="col-12 col-md-6">{renderSectionBalance()}</div>
        <div className="col-12 col-md-6">{renderSectionProfit()}</div>
        <div className="col-12 col-md-6">{renderSectionAdmin()}</div>
        <div className="col-12 col-md-6">{renderSectionCompany()}</div>
        <div className="col-12 col-md-6">{renderSectionSideUser()}</div>
        <div className="col-12 col-md-6">{renderSectionContract()}</div>
      </div>

      <div className="row d-none d-md-flex">
        <div className="col-7">{renderSectionBalance()}</div>
        <div className="col-5">{renderSectionProfit()}</div>
      </div>

      <div className="row d-none d-md-flex">
        <div className="col-6">
          {renderSectionAdmin()}
          {renderSectionSideUser()}
          {renderSectionContract()}
        </div>
        <div className="col-6">{renderSectionCompany()}</div>
      </div>
      {props.isRegistration && (
        <AppButton
          content="ONAYA GÖNDER"
          htmlType="submit"
          disabled={props.isSubmitting}
          isLoading={props.isSubmitting}
          onClick={() => setSubmitted(true)}
        />
      )}
    </Form>
  );
};

export default ProfileForm;
