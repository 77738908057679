import {Dispatch} from 'redux';
import {ErrorDto, getErrorDtoFromApiError} from '../../../models/dtos/error.dto';
import {AddSideUserRequest} from '../../../models/requests/addSideUser.request';
import {ADD_SIDE_USER_FAIL, ADD_SIDE_USER_RESET, ADD_SIDE_USER_START, ADD_SIDE_USER_SUCCESS} from './constants';
import {addSideUserRequest} from "./repository";
import {AddSideUserFailAction, AddSideUserResetAction, AddSideUserStartAction, AddSideUserSuccessAction} from './types';

const addSideUserStartAction = (): AddSideUserStartAction => {
  return {
    type: ADD_SIDE_USER_START,
  };
};
const addSideUserSuccessAction = (payload: boolean): AddSideUserSuccessAction => {
  return {
    type: ADD_SIDE_USER_SUCCESS,
    payload: payload,
  };
};
const addSideUserFailAction = (error: ErrorDto): AddSideUserFailAction => {
  return {
    type: ADD_SIDE_USER_FAIL,
    error: error,
  };
};
const addSideUserResetAction = (): AddSideUserResetAction => {
  return {
    type: ADD_SIDE_USER_RESET,
  };
};

export const addSideUser = (request: AddSideUserRequest) => async (dispatch: Dispatch) => {
  try {
    dispatch(addSideUserStartAction());
    await addSideUserRequest(request);
    dispatch(addSideUserSuccessAction(true));
  } catch (error) {
    dispatch(addSideUserFailAction(getErrorDtoFromApiError(error)));
  }
};

export const addSideUserReset = () => (dispatch: Dispatch) => {
  dispatch(addSideUserResetAction());
};
