import {GenerateLinksRequest} from '../../../models/requests/generateLinks.request';
import axios from '../../../utilities/axios';

export const generateLinksRequest = async (request: GenerateLinksRequest): Promise<string> => {
  try {
    const response = await axios.post<string>(
      `${process.env.REACT_APP_BASE_URL}/campaigns/link`,
      {...request},
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
