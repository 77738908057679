import React from "react";
import "./AuthLayout.scss";

interface IProps {
}

class AuthLayout extends React.Component<IProps> {
  render() {
    return (
      <div id="auth-layout">
        {this.props.children}
      </div>
    );
  }
}

export default AuthLayout;
