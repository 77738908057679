import {PayWithCardDto} from "../../../models/dtos/payWithCard.dto";
import {PayWithCardRequest} from '../../../models/requests/payWithCard.request';
import axios from '../../../utilities/axios';

export const payWithCardRequest = async (id: number, request: PayWithCardRequest): Promise<PayWithCardDto> => {
  try {
    const response = await axios.post<PayWithCardDto>(
      `${process.env.REACT_APP_BASE_URL}/orders/${id}/direct-charge?cardGuid=${request.cardGuid}`,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
