import React, {Component} from 'react';
import './RegistrationWaitingPage.scss';
import logo from "../../../assets/images/logo.svg";

interface IProps {}

class RegistrationWaitingPage extends Component<IProps> {
  render() {
    return (
      <div id="registration-waiting-page" className="page">
        <img className="logo" src={logo} alt="logo"/>
        <div className="page-content">
          <p className="title">BELGELERİNİZ İLETİLDİ</p>
          <div className="description">
            <span style={{fontWeight: 'bold'}}>
              İlettiğiniz belgeler ekibimiz tarafından inceleniyor.
            </span>
            <br />
            <br />
            İncelemeniz sonuçlanınca mail yoluyla bilgilendirileceksiniz.
            <br />
            <br />
            Sorularınız için&nbsp;
            <a
              href="mailto:info@kurumsal.kimgbister.com"
              style={{fontWeight: 'bold'}}
            >
              info@kurumsal.kimgbister.com
            </a>
            &nbsp;adresinden bizimle iletişime geçebilirsiniz.
          </div>
        </div>
      </div>
    );
  }
}

export default RegistrationWaitingPage;
