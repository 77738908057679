import axios from '../../../utilities/axios';
import {ApiKeyDto} from '../../../models/dtos/apiKey.dto';
import {CreateApiCampaignRequest} from '../../../models/requests/createApiCampaign.request';

export const createApiCampaignRequest = async (request: CreateApiCampaignRequest): Promise<ApiKeyDto> => {
  try {
    const response = await axios.post<ApiKeyDto>(
      `${process.env.REACT_APP_BASE_URL}/campaigns/api`,
      {...request},
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
