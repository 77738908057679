import {PaymentStatusDto} from '../../../models/dtos/paymentStatus.dto';
import axios from '../../../utilities/axios';

export const getSaveStatusRequest = async (syncId: string): Promise<PaymentStatusDto> => {
  try {
    const response = await axios.get<PaymentStatusDto>(
      `${process.env.REACT_APP_BASE_URL}/payment/save/${syncId}/status`,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
