import React from "react";
import downloadApple from "../../../../assets/images/landing/download-app-store.svg";
import downloadGoogle from "../../../../assets/images/landing/download-google-play.svg";
import kgbApp from "../../../../assets/images/landing/kgb-app-iPhone.png";
import arrow from "../../../../assets/images/landing/poly-kgb-app-arrow.svg";
import gb from "../../../../assets/images/landing/poly-kgb-app-gb.svg";
import gift from "../../../../assets/images/landing/poly-kgb-app-gift.svg";
import wallet from "../../../../assets/images/landing/poly-kgb-app-wallet.svg";
import FirebaseKey from "../../../../components/FirebaseKey/FirebaseKey";
import FirebaseService from "../../../../core/services/firebase.service";
import { Constants } from "../../../../core/utilities/constants";
import { UI } from "../../../../core/utilities/ui";
import "./SectionKgbApp.scss";

interface IProps {
  sectionRef: any;
}

const SectionKgbApp = (props: IProps) => {
  return (
    <div ref={props.sectionRef} id="section-kgb-app" className="section">
      <div className="section-content">
        <div className="row w-xl-100">
          <div
            className={`section-left ${UI.getLandingSectionResponsiveClass()}`}
          >
            <div className="content">
              <FirebaseKey
                className="header"
                firebaseKey={FirebaseService.landing_section6_header}
              />
              <FirebaseKey
                className="title"
                firebaseKey={FirebaseService.landing_section6_title}
              />
              <FirebaseKey
                className="description"
                firebaseKey={FirebaseService.landing_section6_desc}
              />
              <div className="topics">
                <div className="topic">
                  <img src={gift} alt="gift" />
                  <FirebaseKey
                    className="text"
                    firebaseKey={FirebaseService.landing_section6_feature1}
                  />
                </div>
                <div className="topic">
                  <img src={gb} alt="gb" />
                  <FirebaseKey
                    className="text"
                    firebaseKey={FirebaseService.landing_section6_feature2}
                  />
                </div>
                <div className="topic">
                  <img src={wallet} alt="wallet" />
                  <FirebaseKey
                    className="text"
                    firebaseKey={FirebaseService.landing_section6_feature3}
                  />
                </div>
                <div className="topic">
                  <img src={arrow} alt="arrow" />
                  <FirebaseKey
                    className="text"
                    firebaseKey={FirebaseService.landing_section6_feature4}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className={`section-right ${UI.getLandingSectionResponsiveClass()}`}
          >
            <div className="kgb-app">
              <img className="phone" src={kgbApp} alt="app" />
              <div className="store-wrapper">
                <span className="title">Ücretsiz indir;</span>
                <div className="links">
                  <a
                    href={Constants.linkAppStore}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <img src={downloadApple} alt="app store" />
                  </a>
                  <a
                    href={Constants.linkGooglePlay}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <img src={downloadGoogle} alt="google play" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionKgbApp;
