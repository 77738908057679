import axios from '../../../utilities/axios';
import {OrderDto} from '../../../models/dtos/order.dto';
import {OrderRequest} from '../../../models/requests/order.request';

export const createOrderRequest = async (request: OrderRequest): Promise<OrderDto> => {
  try {
    const response = await axios.post<OrderDto>(
      `${process.env.REACT_APP_BASE_URL}/orders`,
      {...request},
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
