import React from 'react';

export class UI {
  public static modalMaskStyle(): React.CSSProperties {
    return {backgroundColor: '#000000a8', backdropFilter: 'blur(8px)', WebkitBackdropFilter: 'blur(8px)'};
  }

  public static getLandingSectionResponsiveClass(): string {
    return "col-12 col-xl-6";
  }
}
