import {Dispatch} from 'redux';
import {ErrorDto, getErrorDtoFromApiError} from '../../../models/dtos/error.dto';
import {JwtDto} from '../../../models/dtos/jwt.dto';
import {SignUpRequest} from '../../../models/requests/signUp.request';
import {Helpers} from '../../../utilities/helpers';
import {history} from '../../../utilities/history';
import {LocalStorage} from '../../../utilities/localStorage';
import {getMeRequest} from '../../userService/getMe/repository';
import {SIGN_UP_FAIL, SIGN_UP_RESET, SIGN_UP_START, SIGN_UP_SUCCESS} from './constants';
import {signUpRequest} from './repository';
import {SignUpFailAction, SignUpResetAction, SignUpStartAction, SignUpSuccessAction} from './types';

const signUpStartAction = (): SignUpStartAction => {
  return {
    type: SIGN_UP_START,
  };
};
const signUpSuccessAction = (payload: JwtDto): SignUpSuccessAction => {
  return {
    type: SIGN_UP_SUCCESS,
    payload: payload,
  };
};
const signUpFailAction = (error: ErrorDto): SignUpFailAction => {
  return {
    type: SIGN_UP_FAIL,
    error: error,
  };
};
const signUpResetAction = (): SignUpResetAction => {
  return {
    type: SIGN_UP_RESET,
  };
};

export const signUp = (request: SignUpRequest) => async (dispatch: Dispatch) => {
  try {
    dispatch(signUpStartAction());
    const response = await signUpRequest(request);
    LocalStorage.set(LocalStorage.token, response.token);
    const me = await getMeRequest(dispatch);
    dispatch(signUpSuccessAction(response));
    history.push(Helpers.getRouteFromMe(me));
  } catch (error) {
    dispatch(signUpFailAction(getErrorDtoFromApiError(error)));
  }
};

export const signUpReset = () => (dispatch: Dispatch) => {
  dispatch(signUpResetAction());
};
