import {
  CREATE_ORDER_AND_PAY_FAIL,
  CREATE_ORDER_AND_PAY_RESET,
  CREATE_ORDER_AND_PAY_START,
  CREATE_ORDER_AND_PAY_SUCCESS,
} from './constants';
import {CreateOrderAndPayActions, CreateOrderAndPayState} from './types';

const initialState: CreateOrderAndPayState = {
  loading: false,
  data: undefined,
  error: undefined,
};

const reducer = (
  state = initialState,
  action: CreateOrderAndPayActions,
): CreateOrderAndPayState => {
  switch (action.type) {
    case CREATE_ORDER_AND_PAY_START:
      return {
        ...initialState,
        loading: true,
      };
    case CREATE_ORDER_AND_PAY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case CREATE_ORDER_AND_PAY_FAIL:
     return {
        ...state,
        loading: false,
        error: action.error,
      };
    case CREATE_ORDER_AND_PAY_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
