import Adjust from "@adjustcom/adjust-web-sdk";
import React from 'react';
import {connect} from 'react-redux';
import {Redirect, Route, Router, Switch} from 'react-router-dom';
import {bindActionCreators, Dispatch} from 'redux';
import './App.scss';
import AppLayout from './components/Layouts/AppLayout/AppLayout';
import AuthLayout from './components/Layouts/AuthLayout/AuthLayout';
import Loading from "./components/Loading/Loading";
import ApiErrorModal from './components/Modals/ApiErrorModal/ApiErrorModal';
import CampaignCreatePage from "./containers/App/CampaignCreatePage/CampaignCreatePage";
import CampaignDetailPage from "./containers/App/CampaignDetailPage/CampaignDetailPage";
import CampaignsPage from "./containers/App/CampaignsPage/CampaignsPage";
import ContactUsPage from "./containers/App/ContactUsPage/ContactUsPage";
import FaqPage from "./containers/App/FaqPage/FaqPage";
import HomePage from './containers/App/HomePage/HomePage';
import LandingPage from "./containers/App/LandingPage/LandingPage";
import OrderHistoryPage from "./containers/App/OrderHistoryPage/OrderHistoryPage";
import OrderPage from "./containers/App/OrderPage/OrderPage";
import PayByMeFailedPage from "./containers/App/PayByMeResultPage/PayByMeFailedPage";
import PayByMeSuccessPage from "./containers/App/PayByMeResultPage/PayByMeSuccessPage";
import ProfilePage from "./containers/App/ProfilePage/ProfilePage";
import RewardMsisdnPage from "./containers/App/RewardMsisdnPage/RewardMsisdnPage";
import ActivationPage from './containers/Auth/ActivationPage/ActivationPage';
import AuthPage from './containers/Auth/AuthPage/AuthPage';
import ContactUsPublicPage from "./containers/Auth/ContactUsPublicPage/ContactUsPublicPage";
import RegistrationPage from './containers/Auth/RegistrationPage/RegistrationPage';
import RegistrationWaitingPage from './containers/Auth/RegistrationWaitingPage/RegistrationWaitingPage';
import ResetPasswordPage from './containers/Auth/ResetPasswordPage/ResetPasswordPage';
import {ErrorDto, getErrorDtoFromApiError} from './core/models/dtos/error.dto';
import {MeDto} from './core/models/dtos/me.dto';
import {UserRole} from './core/models/enums/userRole';
import {checkToken} from './core/services/appService/checkToken/actions';
import {CheckTokenState} from './core/services/appService/checkToken/types';
import {setApiError} from './core/services/appService/setApiError/actions';
import {setAppLoading} from './core/services/appService/setAppLoading/actions';
import {SetAppLoadingState} from './core/services/appService/setAppLoading/types';
import {setMe} from './core/services/appService/setMe/actions';
import {setPathname} from './core/services/appService/setPathname/actions';
import {setRemoteConfig} from './core/services/appService/setRemoteConfig/actions';
import {SetRemoteConfigState} from './core/services/appService/setRemoteConfig/types';
import axios from './core/utilities/axios';
import {Errors} from './core/utilities/errors';
import {Helpers} from "./core/utilities/helpers";
import {history} from './core/utilities/history';
import {IStore} from './core/utilities/reducers';
import {router} from './core/utilities/router';

interface IProps {
  setAppLoadingState: SetAppLoadingState;
  setRemoteConfigState: SetRemoteConfigState;
  checkTokenState: CheckTokenState;
  pathname: string;
  me?: MeDto;
  setAppLoading: (isLoading: boolean) => void;
  setRemoteConfig: () => void;
  setPathname: (pathname: string) => void;
  setMe: (me?: MeDto) => void;
  setApiError: (error?: ErrorDto) => void;
  checkToken: () => void;
}

interface IState {
  callbackHistoryUnregister?: VoidFunction;
}

class App extends React.Component<IProps> {
  state: IState = {
    callbackHistoryUnregister: undefined,
  };

  constructor(props: IProps) {
    super(props);
    this.setAdjust();
    this.setAxiosInterceptor();
    this.props.setRemoteConfig();
  }

  componentDidMount() {
    this.setHistoryListener();
    this.props.checkToken();
    this.props.setAppLoading(false);
  }

  private setAdjust(): void {
    Adjust.initSdk({
      appToken: `${process.env.REACT_APP_ADJUST_APP_TOKEN}`,
      environment: Helpers.isEnvProd() ? 'production' : 'sandbox',
    });
  }

  private setAxiosInterceptor(): void {
    axios.interceptors.response.use(
      (response) => {
        this.props.setApiError()
        return response;
      },
      async (error) => {
        const errorDto = getErrorDtoFromApiError(error);
        if (
          Errors.isAuthError(errorDto) &&
          !Errors.isLoginError(errorDto)
        ) {
          this.props.setMe();
        } else {
          this.props.setApiError(errorDto);
        }
        return Promise.reject(error);
      }
    );
  }

  private setHistoryListener(): void {
    const callbackHistoryUnregister = history.listen((location: any) => {
      this.props.setPathname(location.pathname);
    });
    this.setState({callbackHistoryUnregister});
  }

  private isAppLoading(): boolean {
    return (
      this.props.setAppLoadingState.isLoading ||
      this.props.setRemoteConfigState.loading ||
      this.props.checkTokenState.loading
    );
  }

  private isAppVisible(): boolean {
    return (
      !!this.props.me &&
      this.props.me.roles.includes(UserRole.USER)
    );
  }

  render() {
    return (
      <Router history={history}>
        {this.isAppLoading()
          ?
          <Loading className="app-loading" fontSize={80}/>
          :
          this.isAppVisible() ?
            <AppLayout pathname={this.props.pathname}>
              <Switch>
                <Route exact path={router.LANDING} component={LandingPage}/>
                <Route exact path={router.HOME} component={HomePage}/>
                <Route exact path={router.REWARD_MSISDN} component={RewardMsisdnPage}/>
                <Route exact path={router.ORDER} component={OrderPage}/>
                <Route exact path={router.ORDER_HISTORY} component={OrderHistoryPage}/>
                <Route exact path={router.CAMPAIGNS} component={CampaignsPage}/>
                <Route exact path={`${router.CAMPAIGNS}/:campaignId`} component={CampaignDetailPage}/>
                <Route exact path={router.CAMPAIGN_CREATE} component={CampaignCreatePage}/>
                <Route exact path={router.PROFILE} component={ProfilePage}/>
                <Route exact path={router.FAQ} component={FaqPage}/>
                <Route exact path={router.CONTACT_US} component={ContactUsPage}/>
                <Route exact path={router.PAY_BY_ME_SUCCESS} component={PayByMeSuccessPage}/>
                <Route exact path={router.PAY_BY_ME_FAILED} component={PayByMeFailedPage}/>
                <Redirect to={router.LANDING}/>
              </Switch>
            </AppLayout>
            :
            <AuthLayout>
              <Switch>
                <Route exact path={router.LANDING} component={LandingPage}/>
                <Route exact path={router.AUTH} component={AuthPage}/>
                <Route exact path={router.RESET_PASSWORD} component={ResetPasswordPage}/>
                <Route exact path={router.ACTIVATION} component={ActivationPage}/>
                <Route exact path={router.REGISTRATION} component={RegistrationPage}/>
                <Route exact path={router.REGISTRATION_WAITING} component={RegistrationWaitingPage}/>
                <Route exact path={router.CONTACT_US} component={ContactUsPublicPage}/>
                <Redirect to={router.LANDING}/>
              </Switch>
            </AuthLayout>
        }
        <ApiErrorModal/>
      </Router>
    );
  }

  componentWillUnmount() {
    if (this.state.callbackHistoryUnregister) {
      this.state.callbackHistoryUnregister();
    }
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      setAppLoading,
      setRemoteConfig,
      setPathname,
      setMe,
      setApiError,
      checkToken,
    },
    dispatch
  );
};
const mapStateToProps = (store: IStore) => {
  return {
    setAppLoadingState: store.setAppLoading,
    setRemoteConfigState: store.setRemoteConfig,
    checkTokenState: store.checkToken,
    pathname: store.setPathname.pathname,
    me: store.setMe.me,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
