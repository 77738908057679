import {SET_VIDEO_URL} from "./constants";
import {SetVideoUrlActions, SetVideoUrlState} from "./types";

const initialState: SetVideoUrlState = {
  data: '',
};

const reducer = (
  state = initialState,
  action: SetVideoUrlActions,
): SetVideoUrlState => {
  switch (action.type) {
    case SET_VIDEO_URL:
      return {
        data: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
