import {notification} from "antd";
import {ReactNode} from "react";
import {Country} from "../models/custom/country";
import {InitialValuesProfile} from '../models/custom/initialValuesProfile';
import {InitialValuesRegister} from '../models/custom/initialValuesRegister';
import {MeDto} from '../models/dtos/me.dto';
import {UsageDto} from "../models/dtos/usage.dto";
import {OfferName} from "../models/enums/offerName";
import {UserRole} from '../models/enums/userRole';
import {Constants} from "./constants";
import {history} from './history';
import {router} from './router';

export class Helpers {
  static wait = (ms: number = 1000) => new Promise((resolve) => setTimeout(resolve, ms));

  static isEnvProd(): boolean {
    const env: string = `${process.env.REACT_APP_ENV}`;
    return env === 'production';
  }

  static getQueryParam(query: string): string | null {
    const searchParams: URLSearchParams = new URLSearchParams(history.location.search);
    return searchParams.get(query);
  }

  public static isEmailValid = (email: string): boolean => {
    if (email.trim().length === 0) return false;
    // eslint-disable-next-line no-useless-escape
    const emailRegexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !!(email && emailRegexp.test(email));
  };

  public static downloadStringAsTxt(data: string, fileNameWithoutExtension: string): void {
    const element = document.createElement("a");
    const file = new Blob([data], {type: 'text/plain'});
    element.href = URL.createObjectURL(file);
    element.download = `${fileNameWithoutExtension}.txt`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    element.remove();
  }

  public static getRouteFromMe(me: MeDto): string {
    if (me.roles.includes(UserRole.NEW)) {
      return router.ACTIVATION;
    } else if (me.roles.includes(UserRole.EMAIL_CONFIRMED)) {
      return router.REGISTRATION;
    } else if (me.roles.includes(UserRole.UNVERIFIED)) {
      return router.REGISTRATION_WAITING;
    } else if (me.roles.includes(UserRole.USER)) {
      return router.LANDING;
    }
    return router.AUTH;
  }

  public static hasRoleSideUser(me?: MeDto): boolean {
    return !!me && me.roles.includes(UserRole.SIDE_USER);
  }

  public static isCurrentPathPaymentRedirect(): boolean {
    return (
      window.location.pathname === router.PAY_BY_ME_SUCCESS ||
      window.location.pathname === router.PAY_BY_ME_FAILED
    );
  }

  public static showNotification(type: 'success' | 'error', message: ReactNode, description?: ReactNode): void {
    notification[type]({
      placement: 'bottomRight',
      message: message,
      description: description,
    });
  }

  public static getInitialValuesRegister(me: MeDto): InitialValuesRegister {
    return {
      initialCountryCode: Constants.countryISOCodeTR,
      initialBillingCountryCode: Constants.countryISOCodeTR,
      companyName: me.company.name,
      code: me.company.agency?.code,
      email: me.email,
      fullName: me.fullName,
      isSameBillingAddress: true,
      isContractsApproved: false,
      fileSignature: '',
      fileSignedContract: '',
      fileTaxPlate: '',
      isAgency: me.company.isAgency,
    };
  }

  public static getInitialValuesProfile(me: MeDto): InitialValuesProfile {
    const company = me.company;
    const isSameCountry: boolean = company.country === company.billingCountry;
    const isSameCity: boolean = company.city === company.billingCity;
    const isSameAddress: boolean = company.address === company.billingAddress;
    const isSameBillingAddress: boolean = isSameCountry && isSameCity && isSameAddress;
    return {
      address: me.company.address,
      billingAddress: me.company.billingAddress,
      billingCity: me.company.billingCity,
      billingCountry: me.company.billingCountry,
      city: me.company.city,
      companyName: me.company.name,
      companyPhoneNumber: me.company.phoneNumber,
      contractsApproved: me.roles.includes(UserRole.USER),
      country: me.company.country,
      isAgency: me.company.isAgency,
      code: me.company.agency?.code,
      email: me.email,
      fullName: me.fullName,
      msisdn: !!me.msisdn ? me.msisdn.slice(2) : '',
      smsName: me.company.smsName,
      taxNumber: me.company.taxNumber,
      tckn: me.tckn ?? '',
      // state
      initialCountryCode: me.company.country,
      initialBillingCountryCode: me.company.billingCountry,
      isSameBillingAddress: isSameBillingAddress,
      isContractsApproved: true,
      fileSignature: me.company.signature,
      fileSignedContract: me.company.signedContract,
      fileTaxPlate: me.company.taxPlate,
    };
  }

  public static getUsagesPoint1000(usages: UsageDto[]): UsageDto[] {
    return usages.filter(usage => usage.offer.name === OfferName.point1000);
  }

  public static getRemainingPoint1000(usages: UsageDto[]): number {
    let remaining = 0;
    this.getUsagesPoint1000(usages).forEach(usage => remaining += usage.remaining);
    return remaining;
  }

  public static getPointEquivalent(x: number, multiplier: number = 1000): string {
    return this.numberWithDotSeparator(x * multiplier);
  }

  private static numberWithDotSeparator(x: number): string {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  public static getCreditCardLastDigits(card?: string, digit: number = 4): string | undefined {
    if (!card) return undefined;
    return card.slice(card.length - digit);
  }

  public static isStringValidMsisdn(msisdn?: string): boolean {
    if (!msisdn) return false;
    return msisdn.startsWith('90') && msisdn.length === 12;
  }

  public static getCountryNameFromISOCode(isoCode: string): string {
    const country = Helpers.getAllCountries().filter(c => c.isoCode === isoCode);
    if (country) {
      return country[0].name;
    }
    return '';
  }

  public static getPriceWithCurrency(price: number): string {
    const isInteger = Number.isInteger(price);
    return `${isInteger ? price : price.toFixed(2)} ${Constants.currency}`;
  }

  public static getAllCountries(): Country[] {
    return [
      {isoCode: 'AF', name: 'Afghanistan'},
      {isoCode: 'AX', name: 'Aland Islands'},
      {isoCode: 'AL', name: 'Albania'},
      {isoCode: 'DZ', name: 'Algeria'},
      {isoCode: 'AS', name: 'American Samoa'},
      {isoCode: 'AD', name: 'Andorra'},
      {isoCode: 'AO', name: 'Angola'},
      {isoCode: 'AI', name: 'Anguilla'},
      {isoCode: 'AQ', name: 'Antarctica'},
      {isoCode: 'AG', name: 'Antigua And Barbuda'},
      {isoCode: 'AR', name: 'Argentina'},
      {isoCode: 'AM', name: 'Armenia'},
      {isoCode: 'AW', name: 'Aruba'},
      {isoCode: 'AU', name: 'Australia'},
      {isoCode: 'AT', name: 'Austria'},
      {isoCode: 'AZ', name: 'Azerbaijan'},
      {isoCode: 'BS', name: 'Bahamas The'},
      {isoCode: 'BH', name: 'Bahrain'},
      {isoCode: 'BD', name: 'Bangladesh'},
      {isoCode: 'BB', name: 'Barbados'},
      {isoCode: 'BY', name: 'Belarus'},
      {isoCode: 'BE', name: 'Belgium'},
      {isoCode: 'BZ', name: 'Belize'},
      {isoCode: 'BJ', name: 'Benin'},
      {isoCode: 'BM', name: 'Bermuda'},
      {isoCode: 'BT', name: 'Bhutan'},
      {isoCode: 'BO', name: 'Bolivia'},
      {isoCode: 'BA', name: 'Bosnia and Herzegovina'},
      {isoCode: 'BW', name: 'Botswana'},
      {isoCode: 'BV', name: 'Bouvet Island'},
      {isoCode: 'BR', name: 'Brazil'},
      {isoCode: 'IO', name: 'British Indian Ocean Territory'},
      {isoCode: 'BN', name: 'Brunei'},
      {isoCode: 'BG', name: 'Bulgaria'},
      {isoCode: 'BF', name: 'Burkina Faso'},
      {isoCode: 'BI', name: 'Burundi'},
      {isoCode: 'KH', name: 'Cambodia'},
      {isoCode: 'CM', name: 'Cameroon'},
      {isoCode: 'CA', name: 'Canada'},
      {isoCode: 'CV', name: 'Cape Verde'},
      {isoCode: 'KY', name: 'Cayman Islands'},
      {isoCode: 'CF', name: 'Central African Republic'},
      {isoCode: 'TD', name: 'Chad'},
      {isoCode: 'CL', name: 'Chile'},
      {isoCode: 'CN', name: 'China'},
      {isoCode: 'CX', name: 'Christmas Island'},
      {isoCode: 'CC', name: 'Cocos (Keeling) Islands'},
      {isoCode: 'CO', name: 'Colombia'},
      {isoCode: 'KM', name: 'Comoros'},
      {isoCode: 'CG', name: 'Congo'},
      {isoCode: 'CD', name: 'Congo The Democratic Republic Of The'},
      {isoCode: 'CK', name: 'Cook Islands'},
      {isoCode: 'CR', name: 'Costa Rica'},
      {isoCode: 'CI', name: "Cote D'Ivoire (Ivory Coast)"},
      {isoCode: 'HR', name: 'Croatia (Hrvatska)'},
      {isoCode: 'CU', name: 'Cuba'},
      {isoCode: 'CY', name: 'Cyprus'},
      {isoCode: 'CZ', name: 'Czech Republic'},
      {isoCode: 'DK', name: 'Denmark'},
      {isoCode: 'DJ', name: 'Djibouti'},
      {isoCode: 'DM', name: 'Dominica'},
      {isoCode: 'DO', name: 'Dominican Republic'},
      {isoCode: 'TL', name: 'East Timor'},
      {isoCode: 'EC', name: 'Ecuador'},
      {isoCode: 'EG', name: 'Egypt'},
      {isoCode: 'SV', name: 'El Salvador'},
      {isoCode: 'GQ', name: 'Equatorial Guinea'},
      {isoCode: 'ER', name: 'Eritrea'},
      {isoCode: 'EE', name: 'Estonia'},
      {isoCode: 'ET', name: 'Ethiopia'},
      {isoCode: 'FK', name: 'Falkland Islands'},
      {isoCode: 'FO', name: 'Faroe Islands'},
      {isoCode: 'FJ', name: 'Fiji Islands'},
      {isoCode: 'FI', name: 'Finland'},
      {isoCode: 'FR', name: 'France'},
      {isoCode: 'GF', name: 'French Guiana'},
      {isoCode: 'PF', name: 'French Polynesia'},
      {isoCode: 'TF', name: 'French Southern Territories'},
      {isoCode: 'GA', name: 'Gabon'},
      {isoCode: 'GM', name: 'Gambia The'},
      {isoCode: 'GE', name: 'Georgia'},
      {isoCode: 'DE', name: 'Germany'},
      {isoCode: 'GH', name: 'Ghana'},
      {isoCode: 'GI', name: 'Gibraltar'},
      {isoCode: 'GR', name: 'Greece'},
      {isoCode: 'GL', name: 'Greenland'},
      {isoCode: 'GD', name: 'Grenada'},
      {isoCode: 'GP', name: 'Guadeloupe'},
      {isoCode: 'GU', name: 'Guam'},
      {isoCode: 'GT', name: 'Guatemala'},
      {isoCode: 'GG', name: 'Guernsey and Alderney'},
      {isoCode: 'GN', name: 'Guinea'},
      {isoCode: 'GW', name: 'Guinea-Bissau'},
      {isoCode: 'GY', name: 'Guyana'},
      {isoCode: 'HT', name: 'Haiti'},
      {isoCode: 'HM', name: 'Heard Island and McDonald Islands'},
      {isoCode: 'HN', name: 'Honduras'},
      {isoCode: 'HK', name: 'Hong Kong S.A.R.'},
      {isoCode: 'HU', name: 'Hungary'},
      {isoCode: 'IS', name: 'Iceland'},
      {isoCode: 'IN', name: 'India'},
      {isoCode: 'ID', name: 'Indonesia'},
      {isoCode: 'IR', name: 'Iran'},
      {isoCode: 'IQ', name: 'Iraq'},
      {isoCode: 'IE', name: 'Ireland'},
      {isoCode: 'IL', name: 'Israel'},
      {isoCode: 'IT', name: 'Italy'},
      {isoCode: 'JM', name: 'Jamaica'},
      {isoCode: 'JP', name: 'Japan'},
      {isoCode: 'JE', name: 'Jersey'},
      {isoCode: 'JO', name: 'Jordan'},
      {isoCode: 'KZ', name: 'Kazakhstan'},
      {isoCode: 'KE', name: 'Kenya'},
      {isoCode: 'KI', name: 'Kiribati'},
      {isoCode: 'KP', name: 'Korea North'},
      {isoCode: 'KR', name: 'Korea South'},
      {isoCode: 'KW', name: 'Kuwait'},
      {isoCode: 'KG', name: 'Kyrgyzstan'},
      {isoCode: 'LA', name: 'Laos'},
      {isoCode: 'LV', name: 'Latvia'},
      {isoCode: 'LB', name: 'Lebanon'},
      {isoCode: 'LS', name: 'Lesotho'},
      {isoCode: 'LR', name: 'Liberia'},
      {isoCode: 'LY', name: 'Libya'},
      {isoCode: 'LI', name: 'Liechtenstein'},
      {isoCode: 'LT', name: 'Lithuania'},
      {isoCode: 'LU', name: 'Luxembourg'},
      {isoCode: 'MO', name: 'Macau S.A.R.'},
      {isoCode: 'MK', name: 'Macedonia'},
      {isoCode: 'MG', name: 'Madagascar'},
      {isoCode: 'MW', name: 'Malawi'},
      {isoCode: 'MY', name: 'Malaysia'},
      {isoCode: 'MV', name: 'Maldives'},
      {isoCode: 'ML', name: 'Mali'},
      {isoCode: 'MT', name: 'Malta'},
      {isoCode: 'IM', name: 'Man (Isle of)'},
      {isoCode: 'MH', name: 'Marshall Islands'},
      {isoCode: 'MQ', name: 'Martinique'},
      {isoCode: 'MR', name: 'Mauritania'},
      {isoCode: 'MU', name: 'Mauritius'},
      {isoCode: 'YT', name: 'Mayotte'},
      {isoCode: 'MX', name: 'Mexico'},
      {isoCode: 'FM', name: 'Micronesia'},
      {isoCode: 'MD', name: 'Moldova'},
      {isoCode: 'MC', name: 'Monaco'},
      {isoCode: 'MN', name: 'Mongolia'},
      {isoCode: 'ME', name: 'Montenegro'},
      {isoCode: 'MS', name: 'Montserrat'},
      {isoCode: 'MA', name: 'Morocco'},
      {isoCode: 'MZ', name: 'Mozambique'},
      {isoCode: 'MM', name: 'Myanmar'},
      {isoCode: 'NA', name: 'Namibia'},
      {isoCode: 'NR', name: 'Nauru'},
      {isoCode: 'NP', name: 'Nepal'},
      {isoCode: 'BQ', name: 'Bonaire, Sint Eustatius and Saba'},
      {isoCode: 'NL', name: 'Netherlands The'},
      {isoCode: 'NC', name: 'New Caledonia'},
      {isoCode: 'NZ', name: 'New Zealand'},
      {isoCode: 'NI', name: 'Nicaragua'},
      {isoCode: 'NE', name: 'Niger'},
      {isoCode: 'NG', name: 'Nigeria'},
      {isoCode: 'NU', name: 'Niue'},
      {isoCode: 'NF', name: 'Norfolk Island'},
      {isoCode: 'MP', name: 'Northern Mariana Islands'},
      {isoCode: 'NO', name: 'Norway'},
      {isoCode: 'OM', name: 'Oman'},
      {isoCode: 'PK', name: 'Pakistan'},
      {isoCode: 'PW', name: 'Palau'},
      {isoCode: 'PS', name: 'Palestinian Territory Occupied'},
      {isoCode: 'PA', name: 'Panama'},
      {isoCode: 'PG', name: 'Papua new Guinea'},
      {isoCode: 'PY', name: 'Paraguay'},
      {isoCode: 'PE', name: 'Peru'},
      {isoCode: 'PH', name: 'Philippines'},
      {isoCode: 'PN', name: 'Pitcairn Island'},
      {isoCode: 'PL', name: 'Poland'},
      {isoCode: 'PT', name: 'Portugal'},
      {isoCode: 'PR', name: 'Puerto Rico'},
      {isoCode: 'QA', name: 'Qatar'},
      {isoCode: 'RE', name: 'Reunion'},
      {isoCode: 'RO', name: 'Romania'},
      {isoCode: 'RU', name: 'Russia'},
      {isoCode: 'RW', name: 'Rwanda'},
      {isoCode: 'SH', name: 'Saint Helena'},
      {isoCode: 'KN', name: 'Saint Kitts And Nevis'},
      {isoCode: 'LC', name: 'Saint Lucia'},
      {isoCode: 'PM', name: 'Saint Pierre and Miquelon'},
      {isoCode: 'VC', name: 'Saint Vincent And The Grenadines'},
      {isoCode: 'BL', name: 'Saint-Barthelemy'},
      {isoCode: 'MF', name: 'Saint-Martin (French part)'},
      {isoCode: 'WS', name: 'Samoa'},
      {isoCode: 'SM', name: 'San Marino'},
      {isoCode: 'ST', name: 'Sao Tome and Principe'},
      {isoCode: 'SA', name: 'Saudi Arabia'},
      {isoCode: 'SN', name: 'Senegal'},
      {isoCode: 'RS', name: 'Serbia'},
      {isoCode: 'SC', name: 'Seychelles'},
      {isoCode: 'SL', name: 'Sierra Leone'},
      {isoCode: 'SG', name: 'Singapore'},
      {isoCode: 'SK', name: 'Slovakia'},
      {isoCode: 'SI', name: 'Slovenia'},
      {isoCode: 'SB', name: 'Solomon Islands'},
      {isoCode: 'SO', name: 'Somalia'},
      {isoCode: 'ZA', name: 'South Africa'},
      {isoCode: 'GS', name: 'South Georgia'},
      {isoCode: 'SS', name: 'South Sudan'},
      {isoCode: 'ES', name: 'Spain'},
      {isoCode: 'LK', name: 'Sri Lanka'},
      {isoCode: 'SD', name: 'Sudan'},
      {isoCode: 'SR', name: 'Suriname'},
      {isoCode: 'SJ', name: 'Svalbard And Jan Mayen Islands'},
      {isoCode: 'SZ', name: 'Swaziland'},
      {isoCode: 'SE', name: 'Sweden'},
      {isoCode: 'CH', name: 'Switzerland'},
      {isoCode: 'SY', name: 'Syria'},
      {isoCode: 'TW', name: 'Taiwan'},
      {isoCode: 'TJ', name: 'Tajikistan'},
      {isoCode: 'TZ', name: 'Tanzania'},
      {isoCode: 'TH', name: 'Thailand'},
      {isoCode: 'TG', name: 'Togo'},
      {isoCode: 'TK', name: 'Tokelau'},
      {isoCode: 'TO', name: 'Tonga'},
      {isoCode: 'TT', name: 'Trinidad And Tobago'},
      {isoCode: 'TN', name: 'Tunisia'},
      {isoCode: Constants.countryISOCodeTR, name: Constants.countryNameTR},
      {isoCode: 'TM', name: 'Turkmenistan'},
      {isoCode: 'TC', name: 'Turks And Caicos Islands'},
      {isoCode: 'TV', name: 'Tuvalu'},
      {isoCode: 'UG', name: 'Uganda'},
      {isoCode: 'UA', name: 'Ukraine'},
      {isoCode: 'AE', name: 'United Arab Emirates'},
      {isoCode: 'GB', name: 'United Kingdom'},
      {isoCode: 'US', name: 'United States'},
      {isoCode: 'UM', name: 'United States Minor Outlying Islands'},
      {isoCode: 'UY', name: 'Uruguay'},
      {isoCode: 'UZ', name: 'Uzbekistan'},
      {isoCode: 'VU', name: 'Vanuatu'},
      {isoCode: 'VA', name: 'Vatican City State (Holy See)'},
      {isoCode: 'VE', name: 'Venezuela'},
      {isoCode: 'VN', name: 'Vietnam'},
      {isoCode: 'VG', name: 'Virgin Islands (British)'},
      {isoCode: 'VI', name: 'Virgin Islands (US)'},
      {isoCode: 'WF', name: 'Wallis And Futuna Islands'},
      {isoCode: 'EH', name: 'Western Sahara'},
      {isoCode: 'YE', name: 'Yemen'},
      {isoCode: 'ZM', name: 'Zambia'},
      {isoCode: 'ZW', name: 'Zimbabwe'},
      {isoCode: 'XK', name: 'Kosovo'},
      {isoCode: 'CW', name: 'Curaçao'},
      {isoCode: 'SX', name: 'Sint Maarten (Dutch part)'},
    ];
  }

  public static getCitiesOfTurkey(): string [] {
    return [
      "İstanbul",
      "Ankara",
      "İzmir",
      "Adana",
      "Adıyaman",
      "Afyonkarahisar",
      "Ağrı",
      "Aksaray",
      "Amasya",
      "Antalya",
      "Ardahan",
      "Artvin",
      "Aydın",
      "Balıkesir",
      "Bartın",
      "Batman",
      "Bayburt",
      "Bilecik",
      "Bingöl",
      "Bitlis",
      "Bolu",
      "Burdur",
      "Bursa",
      "Çanakkale",
      "Çankırı",
      "Çorum",
      "Denizli",
      "Diyarbakır",
      "Düzce",
      "Edirne",
      "Elazığ",
      "Erzincan",
      "Erzurum",
      "Eskişehir",
      "Gaziantep",
      "Giresun",
      "Gümüşhane",
      "Hakkâri",
      "Hatay",
      "Iğdır",
      "Isparta",
      "Kahramanmaraş",
      "Karabük",
      "Karaman",
      "Kars",
      "Kastamonu",
      "Kayseri",
      "Kırıkkale",
      "Kırklareli",
      "Kırşehir",
      "Kilis",
      "Kocaeli",
      "Konya",
      "Kütahya",
      "Malatya",
      "Manisa",
      "Mardin",
      "Mersin",
      "Muğla",
      "Muş",
      "Nevşehir",
      "Niğde",
      "Ordu",
      "Osmaniye",
      "Rize",
      "Sakarya",
      "Samsun",
      "Siirt",
      "Sinop",
      "Sivas",
      "Şırnak",
      "Tekirdağ",
      "Tokat",
      "Trabzon",
      "Tunceli",
      "Şanlıurfa",
      "Uşak",
      "Van",
      "Yalova",
      "Yozgat",
      "Zonguldak",
    ];
  }
}
