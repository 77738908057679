import React from 'react';
import {NavLink} from 'react-router-dom';
import arrow from "../../assets/images/chevron-left.svg";
import logo from "../../assets/images/logo.svg";
import {router} from "../../core/utilities/router";
import AppButton, {AppButtonType} from "../Buttons/AppButton/AppButton";
import './AuthHeader.scss';

interface IProps {
}

const AuthHeader = (props: IProps) => {

  return (
    <div id="auth-header">
      <NavLink to={router.LANDING}>
        <AppButton
          type={AppButtonType.outline}
          content={
            <div className="d-flex align-items-center">
              <img src={arrow} alt="arrow"/>
              &nbsp;&nbsp;
              <span>Anasayfa</span>
            </div>
          }
          onClick={() => {
          }}
        />
      </NavLink>
      <img className="logo" src={logo} alt="logo"/>
    </div>
  );
};

export default AuthHeader;
