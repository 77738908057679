import {Form, Input} from 'antd';
import React from 'react';
import AppButton from '../../../../components/Buttons/AppButton/AppButton';
import {FormValuesSignUp} from '../../../../core/models/custom/formValuesSignUp';
import {Constants} from "../../../../core/utilities/constants";
import './SignUpForm.scss';

interface IProps {
  isSubmitting: boolean;
  callbackSignUp: (values: FormValuesSignUp) => void;
}

const SignUpForm = (props: IProps) => {
  function handleFinish(values: FormValuesSignUp) {
    delete values.rePassword;
    props.callbackSignUp(values);
  }

  return (
    <Form id="sign-up-form" className="form" onFinish={handleFinish}>
      <p className="title h1">YENİ ÜYELİK</p>

      <Form.Item
        name="fullName"
        rules={[{required: true, message: Constants.inputErrorRequiredField}]}
      >
        <Input className="form-input" placeholder="İsim Soyad"/>
      </Form.Item>

      <Form.Item
        name="companyName"
        rules={[{required: true, message: Constants.inputErrorRequiredField}]}
      >
        <Input className="form-input" placeholder="Firma Ünvanı"/>
      </Form.Item>

      <Form.Item
        name="email"
        rules={[
          {required: true, message: Constants.inputErrorRequiredField},
          {type: 'email', message: 'Hatalı mail'},
        ]}
      >
        <Input className="form-input" type="email" placeholder="Mail"/>
      </Form.Item>

      <Form.Item
        name="password"
        rules={[{required: true, message: Constants.inputErrorRequiredField}]}
      >
        <Input.Password className="form-input" placeholder="Şifre"/>
      </Form.Item>

      <Form.Item
        name="rePassword"
        dependencies={['password']}
        rules={[
          {required: true, message: Constants.inputErrorRequiredField},
          ({getFieldValue}) => ({
            validator(rule, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject('Şifreler uyuşmuyor!');
            },
          }),
        ]}
      >
        <Input.Password className="form-input" placeholder="Şifre Tekrar"/>
      </Form.Item>

      <AppButton
        content="KAYIT OLUŞTUR"
        htmlType="submit"
        disabled={props.isSubmitting}
        isLoading={props.isSubmitting}
        onClick={() => {
        }}
      />
    </Form>
  );
};

export default SignUpForm;
