import {Modal} from 'antd';
import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import icon from '../../../assets/images/modal-icon-error.svg';
import {ErrorDto} from '../../../core/models/dtos/error.dto';
import { CheckAndGetAgencyCodeState } from '../../../core/services/agencyService/checkAndGetAgencyCode/types';
import {setApiError} from '../../../core/services/appService/setApiError/actions';
import {SetApiErrorState} from '../../../core/services/appService/setApiError/types';
import {Errors} from '../../../core/utilities/errors';
import {IStore} from '../../../core/utilities/reducers';
import {UI} from '../../../core/utilities/ui';
import AppButton from '../../Buttons/AppButton/AppButton';
import './ApiErrorModal.scss';

interface IProps {
  apiErrorState: SetApiErrorState;
  checkAndGetAgencyCodeState: CheckAndGetAgencyCodeState;
  setApiError: (error?: ErrorDto) => void;
}

const ApiErrorModal = (props: IProps) => {
  const isVisible = (): boolean => {
    return !!props.apiErrorState.error  
    && !props.checkAndGetAgencyCodeState.error 
    && !props.checkAndGetAgencyCodeState.loading;
  };

  const getTitle = (error: ErrorDto): string => {
    switch (error.message) {
      default:
        return Errors.errorGenericTitle;
    }
  };

  const getText = (error: ErrorDto): string => {
    if (Errors.isLoginError(error)) {
      if (error.message === Errors.unauthorized) {
        return 'Hatalı email / şifre';
      }
    }
    switch (error.message) {
      case Errors.emailNotUnique:
        return 'Bu email adresi kullanılmaktadır.';
      case Errors.wrongConfirmationCode:
        return 'Hatalı kod girdiniz.';
      case Errors.wrongPasswordHash:
        return 'Hatalı hash kodu.';
      case Errors.invalidVkn:
        return 'Lütfen Vergi Numarasını kontrol ediniz!';
      case Errors.invalidTckn:
        return 'Lütfen TC Kimlik Numarasını kontrol ediniz!';
      case Errors.sideUserInvalidEmail:
        return 'Bu kullanıcı zaten kayıtlı!';
      case Errors.sideUserLimitReached:
        return 'Ortak yönetici limitiniz dolu!';
      default:
        return Errors.errorGenericText;
    }
  };

  return (
    <Modal
      className="api-error-modal"
      maskStyle={UI.modalMaskStyle()}
      visible={isVisible()}
      closable={false}
      footer={false}
      centered
      destroyOnClose
      onCancel={() => props.setApiError()}
    >
      {props.apiErrorState.error && (
        <div className="content">
          <img className="icon" src={icon} alt="icon"/>
          <label className="title">
            {getTitle(props.apiErrorState.error)}
          </label>
          <label className="text">
            {getText(props.apiErrorState.error)}
          </label>
          <AppButton content="Geri Dön" onClick={() => props.setApiError()}/>
        </div>
      )}
    </Modal>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      setApiError,
    },
    dispatch
  );
};
const mapStateToProps = (store: IStore) => {
  return {
    apiErrorState: store.setApiError,
    checkAndGetAgencyCodeState: store.checkAndGetAgencyCode,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApiErrorModal);
