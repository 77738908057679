import {Button, Modal} from "antd";
import React from 'react';
import FirebaseService from "../../../../core/services/firebase.service";
import {UI} from "../../../../core/utilities/ui";
import FirebaseKey from "../../../FirebaseKey/FirebaseKey";
import CopyBox from "../CopyBox/CopyBox";
import "./TryCodeModal.scss";

interface IProps {
  callbackClose: () => void;
}

const TryCodeModal = (props: IProps) => {

  return (
    <Modal
      wrapClassName="try-code-modal-wrapper"
      className="try-code-modal"
      maskStyle={UI.modalMaskStyle()}
      closable={false}
      footer={false}
      visible
      centered
      destroyOnClose
      onCancel={props.callbackClose}
    >
      <div className="content">
        <FirebaseKey className="title" firebaseKey={FirebaseService.landing_try_code_title}/>
        <div className="line-wrapper d-none d-md-flex">
          <span className="line"/>
        </div>
        <FirebaseKey className="text" firebaseKey={FirebaseService.landing_try_code_desc}/>
        <CopyBox text="KURUMSALDENEME"/>
        <FirebaseKey className="note" firebaseKey={FirebaseService.landing_try_code_note}/>
        <div className="button-wrapper">
          <Button
            className="landing-button outline"
            onClick={props.callbackClose}
          >
            <FirebaseKey firebaseKey={FirebaseService.landing_try_code_button}/>
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default TryCodeModal;
