import {Dispatch} from 'redux';
import {ErrorDto, getErrorDtoFromApiError} from '../../../models/dtos/error.dto';
import {UpdateMeRequest} from '../../../models/requests/updateMe.request';
import {Helpers} from "../../../utilities/helpers";
import {getMeRequest} from "../getMe/repository";
import {UPDATE_ME_FAIL, UPDATE_ME_RESET, UPDATE_ME_START, UPDATE_ME_SUCCESS,} from './constants';
import {updateMeRequest} from './repository';
import {UpdateMeFailAction, UpdateMeResetAction, UpdateMeStartAction, UpdateMeSuccessAction,} from './types';

const updateMeStartAction = (): UpdateMeStartAction => {
  return {
    type: UPDATE_ME_START,
  };
};
const updateMeSuccessAction = (payload: boolean): UpdateMeSuccessAction => {
  return {
    type: UPDATE_ME_SUCCESS,
    payload: payload,
  };
};
const updateMeFailAction = (error: ErrorDto): UpdateMeFailAction => {
  return {
    type: UPDATE_ME_FAIL,
    error: error,
  };
};
const updateMeResetAction = (): UpdateMeResetAction => {
  return {
    type: UPDATE_ME_RESET,
  };
};

export const updateMe = (request: UpdateMeRequest) => async (dispatch: Dispatch) => {
  try {
    dispatch(updateMeStartAction());
    await updateMeRequest(request);
    await getMeRequest(dispatch);
    dispatch(updateMeSuccessAction(true));
    Helpers.showNotification('success', 'Başarılı', 'Profiliniz başarıyla güncellenmiştir.');
  } catch (error) {
    dispatch(updateMeFailAction(getErrorDtoFromApiError(error)));
  }
};

export const updateMeReset = () => (dispatch: Dispatch) => {
  dispatch(updateMeResetAction());
};
