import {Dispatch} from 'redux';
import {ErrorDto, getErrorDtoFromApiError} from '../../../models/dtos/error.dto';
import {GenerateCodesRequest} from '../../../models/requests/generateCodes.request';
import {getUsageSuccessAction} from "../../offerService/getUsage/actions";
import {getUsageRequest} from "../../offerService/getUsage/repository";
import {GENERATE_CODES_FAIL, GENERATE_CODES_RESET, GENERATE_CODES_START, GENERATE_CODES_SUCCESS,} from './constants';
import {generateCodesRequest} from './repository';
import {
  GenerateCodesFailAction,
  GenerateCodesResetAction,
  GenerateCodesStartAction,
  GenerateCodesSuccessAction,
} from './types';

const generateCodesStartAction = (): GenerateCodesStartAction => {
  return {
    type: GENERATE_CODES_START,
  };
};
const generateCodesSuccessAction = (payload: string): GenerateCodesSuccessAction => {
  return {
    type: GENERATE_CODES_SUCCESS,
    payload: payload,
  };
};
const generateCodesFailAction = (error: ErrorDto): GenerateCodesFailAction => {
  return {
    type: GENERATE_CODES_FAIL,
    error: error,
  };
};
const generateCodesResetAction = (): GenerateCodesResetAction => {
  return {
    type: GENERATE_CODES_RESET,
  };
};

export const generateCodes = (request: GenerateCodesRequest) => async (dispatch: Dispatch) => {
  try {
    dispatch(generateCodesStartAction());
    const response = await generateCodesRequest(request);
    const usage = await getUsageRequest();
    dispatch(getUsageSuccessAction(usage));
    dispatch(generateCodesSuccessAction(response));
  } catch (error) {
    dispatch(generateCodesFailAction(getErrorDtoFromApiError(error)));
  }
};

export const generateCodesReset = () => (dispatch: Dispatch) => {
  dispatch(generateCodesResetAction());
};
