import {Dispatch} from 'redux';
import {ErrorDto, getErrorDtoFromApiError} from '../../../models/dtos/error.dto';
import {ApiKeyDto} from '../../../models/dtos/apiKey.dto';
import {CreateApiCampaignRequest} from '../../../models/requests/createApiCampaign.request';
import {
  CREATE_API_CAMPAIGN_FAIL,
  CREATE_API_CAMPAIGN_RESET,
  CREATE_API_CAMPAIGN_START,
  CREATE_API_CAMPAIGN_SUCCESS,
} from './constants';
import {createApiCampaignRequest} from './repository';
import {
  CreateApiCampaignFailAction,
  CreateApiCampaignResetAction,
  CreateApiCampaignStartAction,
  CreateApiCampaignSuccessAction,
} from './types';

const createApiCampaignStartAction = (): CreateApiCampaignStartAction => {
  return {
    type: CREATE_API_CAMPAIGN_START,
  };
};
const createApiCampaignSuccessAction = (payload: ApiKeyDto): CreateApiCampaignSuccessAction => {
  return {
    type: CREATE_API_CAMPAIGN_SUCCESS,
    payload: payload,
  };
};
const createApiCampaignFailAction = (error: ErrorDto): CreateApiCampaignFailAction => {
  return {
    type: CREATE_API_CAMPAIGN_FAIL,
    error: error,
  };
};
const createApiCampaignResetAction = (): CreateApiCampaignResetAction => {
  return {
    type: CREATE_API_CAMPAIGN_RESET,
  };
};

export const createApiCampaign = (request: CreateApiCampaignRequest) => async (dispatch: Dispatch) => {
  try {
    dispatch(createApiCampaignStartAction());
    const response = await createApiCampaignRequest(request);
    dispatch(createApiCampaignSuccessAction(response));
  } catch (error) {
    dispatch(createApiCampaignFailAction(getErrorDtoFromApiError(error)));
  }
};

export const createApiCampaignReset = () => (dispatch: Dispatch) => {
  dispatch(createApiCampaignResetAction());
};
