import {LoadingOutlined} from "@ant-design/icons";
import { Form, Input, Modal } from "antd";
import { useEffect, useState } from "react";
import features from "../../../assets/images/features.svg";
import { Constants } from "../../../core/utilities/constants";
import { CreditCard } from "../../../core/utilities/creditCard";
import { FormValuesWithdraw } from "../../../core/models/custom/formValuesWithdraw";
import { UI } from "../../../core/utilities/ui";
import AppButton, { AppButtonType } from "../../Buttons/AppButton/AppButton";
import "./WithdrawModal.scss";

interface IProps {
  isSubmittedWithdraw: boolean;
  withdraw: number;
  visible: boolean;
  callbackOk: (values: FormValuesWithdraw) => void
  callbackCancel: () => void
}

const WithdrawModal = (props: IProps) => {
  const [isSubmitDisabled, setSubmitDisabled] = useState(true);
  const [form] = Form.useForm();

  useEffect(() => {
    checkSubmitStatus();
  });

  function handleFinish(values: FormValuesWithdraw):void {
    form.resetFields();
    props.callbackOk(values)
  }

  function checkSubmitStatus(): void {
    const claimedValue = form.getFieldValue('claimedValue')
    const ibanOwner = form.getFieldValue('ibanOwner')
    const iban = form.getFieldValue('iban');
     const isDisabled =(
      !ibanOwner ||
      (!iban || iban.length < 25) ||
      (!claimedValue ||  claimedValue > props.withdraw)
    );
    setSubmitDisabled(isDisabled);
  }

  function handleChangeNumber(value: string): void {
    const iban = CreditCard.formatIBAN(value);
    form.setFieldsValue({ iban });
  }

  function handleChangePrice(value: any): void {
    const min = 0;
    const claimedValue =  Math.max(min, Math.min(props.withdraw, Number(value)));;
    form.setFieldsValue({ claimedValue });
  }

  return (
    <Modal
      className="credit-card-save-modal"
      maskStyle={UI.modalMaskStyle()}
      closable={false}
      footer={false}
      visible={props.visible}
      centered
      onCancel={props.callbackCancel}
    >
      <div className="content">
        <img className="icon" src={features} alt="landmark" />
        <label className="title">Kazancı Al</label>
        <label className="text">
          Bakiyenizde biriken kazancınızı dilediğinizde banka hesabınıza
          aktarabilirsiniz.
        </label>
        <label className="title bold">BAKİYE</label>
        <label className="title large">₺{(props.withdraw).toLocaleString("tr-TR", { maximumFractionDigits:2})}</label>
        <label className="sub-title">Almak istediğiniz miktar</label>
        <Form 
        form={form} 
        className="form"
        onChange={checkSubmitStatus}
        onFinish={handleFinish}
        >
          <Form.Item
            name="claimedValue"
            rules={[
              { required: true, message: Constants.inputErrorRequiredField },
            ]}
          >
            <Input
              className="form-input"
              prefix="₺ -"
              onChange={(e) => handleChangePrice(e.target.value)}
            />
          </Form.Item>

          <label className="text">
            Tamamını ya da bir kısmını alabilirsiniz.
          </label>
          <label className="sub-title">IBAN</label>

          <Form.Item
            name="ibanOwner"
            rules={[
              { required: true, message: Constants.inputErrorRequiredField },
            ]}
          >
            <Input
              className="form-input"
              placeholder="Ad soyad"
            />
          </Form.Item>

          <div className="card-data d-flex flex-column">
            <Form.Item
              name="iban"
              rules={[
                { required: true, message: Constants.inputErrorRequiredField },
              ]}
            >
              <Input
                className="form-input"
                prefix="TR"
                placeholder="90 0000 0000 0000 0000 0000 00"
                onChange={(e) => handleChangeNumber(e.target.value)}
              />
            </Form.Item>
          </div>
          <label className="text">
            Kazancınızın aktarılmasını istediğiniz hesap bilgilerini
            girmelisiniz.
          </label>
          {
          props.isSubmittedWithdraw
           ?
           <div className="loading-wrapper">
            <LoadingOutlined className="loading"/>
           </div>
           :
                  <div className="button-wrapper">
                  <AppButton
                    type={AppButtonType.outline}
                    content="Vazgeç"
                    onClick={props.callbackCancel}
                  />
                  <AppButton
                    content="Devam Et"
                    htmlType="submit"
                    onClick={()=>{}}
                    disabled={isSubmitDisabled}
                  />
                </div>
        }
        </Form>
      </div>
    </Modal>
  );
};

export default WithdrawModal;
