import React from "react";
import Loading from "../../../components/Loading/Loading";
import {Constants} from "../../../core/utilities/constants";
import {LocalStorage} from "../../../core/utilities/localStorage";

class PayByMeSuccessPage extends React.Component {

  componentDidMount() {
    LocalStorage.set(LocalStorage.vpos, Constants.vposResultSuccess);
  }

  render() {
    return (
      <Loading className="app-loading" fontSize={80}/>
    );
  }
}

export default PayByMeSuccessPage;
