import {GENERATE_CODES_FAIL, GENERATE_CODES_RESET, GENERATE_CODES_START, GENERATE_CODES_SUCCESS,} from './constants';
import {GenerateCodesActions, GenerateCodesState} from './types';

const initialState: GenerateCodesState = {
  loading: false,
  data: undefined,
  error: undefined,
};

const reducer = (
  state = initialState,
  action: GenerateCodesActions,
): GenerateCodesState => {
  switch (action.type) {
    case GENERATE_CODES_START:
      return {
        ...initialState,
        loading: true,
      };
    case GENERATE_CODES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case GENERATE_CODES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case GENERATE_CODES_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
