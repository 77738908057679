import React, {ReactNode} from 'react';
import wallet from "../../assets/images/account-balance-wallet.svg";
import add from "../../assets/images/add-primary.svg";
import gift from "../../assets/images/create-campaign.svg";
import {Helpers} from "../../core/utilities/helpers";
import "./CreateCampaignCard.scss";

interface IProps {
  balance?: number;
  callback: () => void;
}

const CreateCampaignCard = (props: IProps) => {

  function getDescription(): ReactNode {
    if (props.balance) {
      return (
        <React.Fragment>
          <h1 className="description-title">
            Yeni bir kampanya oluştur
          </h1>
          <label className="description-balance">
            <img className="me-1" src={wallet} alt="wallet"/>
            mevcut bakiye:&nbsp;
            <label className="balance">
              {Helpers.getPointEquivalent(props.balance)}
            </label>
            &nbsp;MetaByte
          </label>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <h1 className="description-title">
          Yeni kampanya oluşturun
        </h1>
        <label className="description-text">
         Hedeflerinize kolayca ulaşmak için MetaByte ile sadakat kampanyaları oluşturabilirsiniz.
        </label>
      </React.Fragment>
    );
  }

  return (
    <div
      id="create-campaign-card"
      onClick={props.callback}
    >
      <div className="indicator"/>
      <div className="create-campaign-card-content">
        {
          !props.balance &&
          <img src={gift} alt="gift"/>
        }
        <div className="description">
          {getDescription()}
        </div>
        <img src={add} alt="add"/>
      </div>
    </div>
  );
}

export default CreateCampaignCard;
