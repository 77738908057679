import axios from '../../../utilities/axios';
import {JwtDto} from '../../../models/dtos/jwt.dto';
import {SignUpRequest} from '../../../models/requests/signUp.request';

export const signUpRequest = async (request: SignUpRequest): Promise<JwtDto> => {
  try {
    const response = await axios.post<JwtDto>(
      `${process.env.REACT_APP_BASE_URL}/auth/sign-up`,
      {...request},
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
