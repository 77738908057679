import {LoadingOutlined} from "@ant-design/icons";
import {Form, Input, Modal, Switch} from "antd";
import React, {useEffect, useState} from 'react';
import creditCard from "../../../assets/images/credit-card.svg";
import icon from "../../../assets/images/modal-icon-credit-card-save.svg";
import {FormValuesCreditCard} from "../../../core/models/custom/formValuesCreditCard";
import {SaveCardRequest} from "../../../core/models/requests/saveCard.request";
import {Constants} from "../../../core/utilities/constants";
import {CreditCard} from "../../../core/utilities/creditCard";
import {UI} from "../../../core/utilities/ui";
import AppButton, {AppButtonType} from "../../Buttons/AppButton/AppButton";
import "./CreditCardSaveModal.scss";

interface IProps {
  isSubmitting: boolean;
  callbackOk: (request: SaveCardRequest) => void;
  callbackCancel: () => void;
}

const CreditCardSaveModal = (props: IProps) => {
  const [isApproved, setApproved] = useState(true);
  const [isSubmitDisabled, setSubmitDisabled] = useState(true);
  const [form] = Form.useForm();

  useEffect(() => {
    checkSubmitStatus();
  },);

  function checkSubmitStatus(): void {
    const name = form.getFieldValue('name');
    const number = form.getFieldValue('number');
    const expiry = form.getFieldValue('expiry');
    const cvc = form.getFieldValue('cvc');
    const isDisabled = (
      !name ||
      (!number || number.length < 19) ||
      (!expiry || expiry.length < 5) ||
      (!cvc || cvc.length < 3) ||
      !isApproved
    );
    setSubmitDisabled(isDisabled);
  }

  function handleFinish(values: FormValuesCreditCard): void {
    const ccv = values.cvc;
    const cardNo = values.number.replaceAll(' ', '');
    let expiryMonth = values.expiry.split('/')[0];
    if (expiryMonth.length === 1) {
      expiryMonth = `0${expiryMonth}`;
    }
    const expiryYear = `20${values.expiry.split('/')[1]}`;
    const request: SaveCardRequest = {
      ccv,
      cardNo,
      expiryMonth,
      expiryYear,
    }
    props.callbackOk(request);
  }

  function handleChangeNumber(value: string): void {
    const number = CreditCard.formatCreditCardNumber(value);
    form.setFieldsValue({number});
  }

  function handleChangeExpiry(value: string): void {
    const expiry = CreditCard.formatExpirationDate(value);
    form.setFieldsValue({expiry});
  }

  function handleChangeCvc(value: string): void {
    const cvc = CreditCard.formatCVC(value);
    form.setFieldsValue({cvc});
  }

  return (
    <Modal
      className="credit-card-save-modal"
      maskStyle={UI.modalMaskStyle()}
      closable={false}
      footer={false}
      visible
      centered
      destroyOnClose
      onCancel={props.callbackCancel}
    >
      <div className="content">
        <img className="icon" src={icon} alt="icon"/>
        <label className="title">
          Daha hızlı işlem yapabilmek için kartınızı kaydedin.
        </label>
        <label className="text">
          Kartınızı kaydederek kampanyalarınızı hızlıca yenileyebilirsiniz.
        </label>
        <label className="sub-title">
          Kart Bilgileri
        </label>
        <Form
          form={form}
          className="form"
          onChange={checkSubmitStatus}
          onFinish={handleFinish}
        >
          <Form.Item
            name="name"
            rules={[{required: true, message: Constants.inputErrorRequiredField}]}
          >
            <Input
              className="form-input"
              placeholder="Ad Soyad"
            />
          </Form.Item>

          <div className="card-data">

            <Form.Item
              name="number"
              rules={[{required: true, message: Constants.inputErrorRequiredField}]}
            >
              <Input
                className="form-input number"
                type="tel"
                prefix={<img src={creditCard} alt="credit card"/>}
                placeholder="Kart Numarası"
                onChange={e => handleChangeNumber(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              name="expiry"
              rules={[{required: true, message: ''}]}
            >
              <Input
                className="form-input expiry"
                type="tel"
                placeholder="AA/YY"
                onChange={e => handleChangeExpiry(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              name="cvc"
              rules={[{required: true, message: ''}]}
            >
              <Input
                className="form-input cvc"
                type="tel"
                placeholder="CVC"
                onChange={e => handleChangeCvc(e.target.value)}
              />
            </Form.Item>
          </div>
        </Form>
        <div
          className="switch"
          onClick={() => setApproved(!isApproved)}
        >
          <label className="text">
            Kartımın kaydedilmesine izin veriyorum.
          </label>
          <Switch
            defaultChecked
            checked={isApproved}
            onChange={e => setApproved(e)}
          />
        </div>

        {
          props.isSubmitting
            ?
            <div className="loading-wrapper">
              <LoadingOutlined className="loading"/>
            </div>
            :
            <div className="button-wrapper">
              <AppButton
                type={AppButtonType.outline}
                content="Vazgeç"
                onClick={props.callbackCancel}
              />
              <AppButton
                content="Kaydet"
                disabled={isSubmitDisabled}
                onClick={() => form.submit()}
              />
            </div>
        }
      </div>
    </Modal>
  );
}

export default CreditCardSaveModal;
