import {Dispatch} from 'redux';
import {ErrorDto, getErrorDtoFromApiError} from '../../../models/dtos/error.dto';
import {DELETE_SIDE_USER_START, DELETE_SIDE_USER_SUCCESS, DELETE_SIDE_USER_FAIL, DELETE_SIDE_USER_RESET} from './constants';
import {DeleteSideUserStartAction, DeleteSideUserSuccessAction, DeleteSideUserFailAction, DeleteSideUserResetAction} from './types';
import {deleteSideUserRequest} from './repository';

const deleteSideUserStartAction = (): DeleteSideUserStartAction => {
  return {
    type: DELETE_SIDE_USER_START,
  };
};
const deleteSideUserSuccessAction = (payload: boolean): DeleteSideUserSuccessAction => {
  return {
    type: DELETE_SIDE_USER_SUCCESS,
    payload: payload,
  };
};
const deleteSideUserFailAction = (error: ErrorDto): DeleteSideUserFailAction => {
  return {
    type: DELETE_SIDE_USER_FAIL,
    error: error,
  };
};
const deleteSideUserResetAction = (): DeleteSideUserResetAction => {
  return {
    type: DELETE_SIDE_USER_RESET,
  };
};

export const deleteSideUser = (id: number) => async (dispatch: Dispatch) => {
  try {
    dispatch(deleteSideUserStartAction());
    await deleteSideUserRequest(id);
    dispatch(deleteSideUserSuccessAction(true));
  } catch (error) {
    dispatch(deleteSideUserFailAction(getErrorDtoFromApiError(error)));
  }
};

export const deleteSideUserReset = () => (dispatch: Dispatch) => {
  dispatch(deleteSideUserResetAction());
};
