import {RewardMsisdnRequest} from '../../../models/requests/rewardMsisdn.request';
import axios from '../../../utilities/axios';

export const rewardMsisdnRequest = async (offerId: number, request: RewardMsisdnRequest): Promise<void> => {
  try {
    const response = await axios.post<void>(
      `${process.env.REACT_APP_BASE_URL}/offers/${offerId}/reward`,
      {...request},
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
