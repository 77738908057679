import {CombinedState, combineReducers, Reducer} from 'redux';
import checkAndGetAgencyCodeReducer from '../services/agencyService/checkAndGetAgencyCode/reducer';
import {CheckAndGetAgencyCodeState} from '../services/agencyService/checkAndGetAgencyCode/types';
import claimRevenueReducer from '../services/agencyService/claimRevenue/reducer';
import {ClaimRevenueState} from '../services/agencyService/claimRevenue/types';
import getUsedAgencyCodesReducer from '../services/agencyService/getUsedAgencyCodes/reducer';
import {GetUsedAgencyCodesState} from '../services/agencyService/getUsedAgencyCodes/types';
import getWalletDetailsReducer from '../services/agencyService/getWalletDetails/reducer';
import {GetWalletDetailsState} from '../services/agencyService/getWalletDetails/types';
import checkTokenReducer from '../services/appService/checkToken/reducer';
import {CheckTokenState} from '../services/appService/checkToken/types';
import setApiErrorReducer from '../services/appService/setApiError/reducer';
import {SetApiErrorState} from '../services/appService/setApiError/types';
import setAppLoadingReducer from '../services/appService/setAppLoading/reducer';
import {SetAppLoadingState} from '../services/appService/setAppLoading/types';
import setMeReducer from '../services/appService/setMe/reducer';
import {SetMeState} from '../services/appService/setMe/types';
import setPathnameReducer from '../services/appService/setPathname/reducer';
import {SetPathnameState} from '../services/appService/setPathname/types';
import setRemoteConfigReducer from '../services/appService/setRemoteConfig/reducer';
import {SetRemoteConfigState} from '../services/appService/setRemoteConfig/types';
import setVideoUrlReducer from '../services/appService/setVideoUrl/reducer';
import {SetVideoUrlState} from '../services/appService/setVideoUrl/types';
import loginReducer from '../services/authService/login/reducer';
import {LoginState} from '../services/authService/login/types';
import newPasswordReducer from '../services/authService/newPassword/reducer';
import {NewPasswordState} from '../services/authService/newPassword/types';
import refreshReducer from '../services/authService/refresh/reducer';
import {RefreshState} from '../services/authService/refresh/types';
import resetPasswordReducer from '../services/authService/resetPassword/reducer';
import {ResetPasswordState} from '../services/authService/resetPassword/types';
import signUpReducer from '../services/authService/signUp/reducer';
import {SignUpState} from '../services/authService/signUp/types';
import bulkRewardMsisdnReducer from '../services/campaignService/bulkRewardMsisdn/reducer';
import {BulkRewardMsisdnState} from '../services/campaignService/bulkRewardMsisdn/types';
import createApiCampaignReducer from '../services/campaignService/createApiCampaign/reducer';
import {CreateApiCampaignState} from '../services/campaignService/createApiCampaign/types';
import generateCodesReducer from '../services/campaignService/generateCodes/reducer';
import {GenerateCodesState} from '../services/campaignService/generateCodes/types';
import generateLinksReducer from '../services/campaignService/generateLinks/reducer';
import {GenerateLinksState} from '../services/campaignService/generateLinks/types';
import getApiKeyReducer from '../services/campaignService/getApiKey/reducer';
import {GetApiKeyState} from '../services/campaignService/getApiKey/types';
import getCampaignHistoryReducer from '../services/campaignService/getCampaignHistory/reducer';
import {GetCampaignHistoryState} from '../services/campaignService/getCampaignHistory/types';
import getDetailsReducer from '../services/campaignService/getDetails/reducer';
import {GetDetailsState} from '../services/campaignService/getDetails/types';
import getFileReducer from '../services/campaignService/getFile/reducer';
import {GetFileState} from '../services/campaignService/getFile/types';
import deleteCardReducer from '../services/cardService/deleteCard/reducer';
import {DeleteCardState} from '../services/cardService/deleteCard/types';
import saveCardReducer from '../services/cardService/saveCard/reducer';
import {SaveCardState} from '../services/cardService/saveCard/types';
import contactUsReducer from '../services/miscService/contactUs/reducer';
import {ContactUsState} from '../services/miscService/contactUs/types';
import tryBulkReducer from '../services/miscService/tryBulk/reducer';
import {TryBulkState} from '../services/miscService/tryBulk/types';
import getAllOffersReducer from '../services/offerService/getAllOffers/reducer';
import {GetAllOffersState} from '../services/offerService/getAllOffers/types';
import getUsageReducer from '../services/offerService/getUsage/reducer';
import {GetUsageState} from '../services/offerService/getUsage/types';
import rewardMsisdnReducer from '../services/offerService/rewardMsisdn/reducer';
import {RewardMsisdnState} from '../services/offerService/rewardMsisdn/types';
import createMoneyTransferOrderReducer from '../services/orderService/createMoneyTransferOrder/reducer';
import {CreateMoneyTransferOrderState} from '../services/orderService/createMoneyTransferOrder/types';
import createOrderReducer from '../services/orderService/createOrder/reducer';
import {CreateOrderState} from '../services/orderService/createOrder/types';
import createOrderAndPayReducer from '../services/orderService/createOrderAndPay/reducer';
import {CreateOrderAndPayState} from '../services/orderService/createOrderAndPay/types';
import createOrderAndPayWithCardReducer from '../services/orderService/createOrderAndPayWithCard/reducer';
import {CreateOrderAndPayWithCardState} from '../services/orderService/createOrderAndPayWithCard/types';
import getOrderHistoryReducer from '../services/orderService/getOrderHistory/reducer';
import {GetOrderHistoryState} from '../services/orderService/getOrderHistory/types';
import payReducer from '../services/orderService/pay/reducer';
import {PayState} from '../services/orderService/pay/types';
import payWithCardReducer from '../services/orderService/payWithCard/reducer';
import {PayWithCardState} from '../services/orderService/payWithCard/types';
import getChargeStatusReducer from '../services/paymentService/getChargeStatus/reducer';
import {GetChargeStatusState} from '../services/paymentService/getChargeStatus/types';
import getSaveStatusReducer from '../services/paymentService/getSaveStatus/reducer';
import {GetSaveStatusState} from '../services/paymentService/getSaveStatus/types';
import addSideUserReducer from '../services/userService/addSideUser/reducer';
import {AddSideUserState} from '../services/userService/addSideUser/types';
import confirmEmailReducer from '../services/userService/confirmEmail/reducer';
import {ConfirmEmailState} from '../services/userService/confirmEmail/types';
import deleteSideUserReducer from '../services/userService/deleteSideUser/reducer';
import {DeleteSideUserState} from '../services/userService/deleteSideUser/types';
import getMeReducer from '../services/userService/getMe/reducer';
import {GetMeState} from '../services/userService/getMe/types';
import getSideUsersReducer from '../services/userService/getSideUsers/reducer';
import {GetSideUsersState} from '../services/userService/getSideUsers/types';
import registerReducer from '../services/userService/register/reducer';
import {RegisterState} from '../services/userService/register/types';
import resendConfirmEmailReducer from '../services/userService/resendConfirmEmail/reducer';
import {ResendConfirmEmailState} from '../services/userService/resendConfirmEmail/types';
import updateMeReducer from '../services/userService/updateMe/reducer';
import {UpdateMeState} from '../services/userService/updateMe/types';

export interface IStore {
  // AGENCY
  checkAndGetAgencyCode: CheckAndGetAgencyCodeState;
  claimRevenue: ClaimRevenueState;
  getUsedAgencyCodes: GetUsedAgencyCodesState;
  getWalletDetails: GetWalletDetailsState;
  // APP
  checkToken: CheckTokenState;
  setApiError: SetApiErrorState;
  setAppLoading: SetAppLoadingState;
  setMe: SetMeState;
  setPathname: SetPathnameState;
  setRemoteConfig: SetRemoteConfigState;
  setVideoUrl: SetVideoUrlState;
  // AUTH
  login: LoginState;
  newPassword: NewPasswordState;
  refresh: RefreshState;
  resetPassword: ResetPasswordState;
  signUp: SignUpState;
  // CAMPAIGN
  bulkRewardMsisdn: BulkRewardMsisdnState;
  createApiCampaign: CreateApiCampaignState;
  generateCodes: GenerateCodesState;
  generateLinks: GenerateLinksState;
  getApiKey: GetApiKeyState;
  getCampaignHistory: GetCampaignHistoryState;
  getDetails: GetDetailsState;
  getFile: GetFileState;
  // CARD
  deleteCard: DeleteCardState;
  saveCard: SaveCardState;
  // MISC
  contactUs: ContactUsState;
  tryBulk: TryBulkState;
  // OFFER
  getAllOffers: GetAllOffersState;
  getUsage: GetUsageState;
  rewardMsisdn: RewardMsisdnState;
  // ORDER
  createMoneyTransferOrder: CreateMoneyTransferOrderState;
  createOrder: CreateOrderState;
  createOrderAndPay: CreateOrderAndPayState;
  createOrderAndPayWithCard: CreateOrderAndPayWithCardState;
  getOrderHistory: GetOrderHistoryState;
  pay: PayState;
  payWithCard: PayWithCardState;
  // PAYMENT
  getChargeStatus: GetChargeStatusState;
  getSaveStatus: GetSaveStatusState;
  // USER
  addSideUser: AddSideUserState;
  confirmEmail: ConfirmEmailState;
  deleteSideUser: DeleteSideUserState;
  getMe: GetMeState;
  getSideUsers: GetSideUsersState;
  register: RegisterState;
  resendConfirmEmail: ResendConfirmEmailState;
  updateMe: UpdateMeState;
}

export const rootReducer: Reducer<CombinedState<IStore>> = combineReducers({
  // AGENCY
  checkAndGetAgencyCode: checkAndGetAgencyCodeReducer,
  claimRevenue: claimRevenueReducer,
  getUsedAgencyCodes: getUsedAgencyCodesReducer,
  getWalletDetails: getWalletDetailsReducer,
  // APP
  checkToken: checkTokenReducer,
  setApiError: setApiErrorReducer,
  setAppLoading: setAppLoadingReducer,
  setMe: setMeReducer,
  setPathname: setPathnameReducer,
  setRemoteConfig: setRemoteConfigReducer,
  setVideoUrl: setVideoUrlReducer,
  // AUTH
  login: loginReducer,
  newPassword: newPasswordReducer,
  refresh: refreshReducer,
  resetPassword: resetPasswordReducer,
  signUp: signUpReducer,
  // CAMPAIGN
  bulkRewardMsisdn: bulkRewardMsisdnReducer,
  createApiCampaign: createApiCampaignReducer,
  generateCodes: generateCodesReducer,
  generateLinks: generateLinksReducer,
  getApiKey: getApiKeyReducer,
  getCampaignHistory: getCampaignHistoryReducer,
  getDetails: getDetailsReducer,
  getFile: getFileReducer,
  // CARD
  deleteCard: deleteCardReducer,
  saveCard: saveCardReducer,
  // MISC
  contactUs: contactUsReducer,
  tryBulk: tryBulkReducer,
  // OFFER
  getAllOffers: getAllOffersReducer,
  getUsage: getUsageReducer,
  rewardMsisdn: rewardMsisdnReducer,
  // ORDER
  createMoneyTransferOrder: createMoneyTransferOrderReducer,
  createOrder: createOrderReducer,
  createOrderAndPay: createOrderAndPayReducer,
  createOrderAndPayWithCard: createOrderAndPayWithCardReducer,
  getOrderHistory: getOrderHistoryReducer,
  pay: payReducer,
  payWithCard: payWithCardReducer,
  // PAYMENT
  getChargeStatus: getChargeStatusReducer,
  getSaveStatus: getSaveStatusReducer,
  // USER
  addSideUser: addSideUserReducer,
  confirmEmail: confirmEmailReducer,
  deleteSideUser: deleteSideUserReducer,
  getMe: getMeReducer,
  getSideUsers: getSideUsersReducer,
  register: registerReducer,
  resendConfirmEmail: resendConfirmEmailReducer,
  updateMe: updateMeReducer,
})
