import React from "react";
import filter from "../../../../assets/images/landing/feature-filter.svg";
import pieChart from "../../../../assets/images/landing/feature-pie-chart.svg";
import FirebaseKey from "../../../../components/FirebaseKey/FirebaseKey";
import FirebaseService from "../../../../core/services/firebase.service";
import {UI} from "../../../../core/utilities/ui";
import FeatureCard from "../FeatureCard/FeatureCard";
import "./SectionPromotion.scss";

interface IProps {
}

const SectionPromotion = (props: IProps) => {

  return (
    <div id="section-promotion" className="section">
      <div className="section-content">
        <div className="row w-xl-100">
          <div className={`section-left ${UI.getLandingSectionResponsiveClass()}`}>
            <div className="content">
              <FirebaseKey className="header" firebaseKey={FirebaseService.landing_section2_header}/>
              <FirebaseKey className="title" firebaseKey={FirebaseService.landing_section2_title}/>
              <FirebaseKey className="description" firebaseKey={FirebaseService.landing_section2_desc}/>
            </div>
          </div>
          <div className={`section-right ${UI.getLandingSectionResponsiveClass()}`}>
            <div className="row w-100">
              <FeatureCard
                className="col-12 col-md-6"
                icon={<img src={pieChart} alt="pieChart"/>}
                titleKey={FirebaseService.landing_section2_info1_title}
                descriptionKey={FirebaseService.landing_section2_info1_desc}
              />
              <FeatureCard
                className="col-12 col-md-6 mt-5 mt-md-0"
                icon={<img src={filter} alt="filter"/>}
                titleKey={FirebaseService.landing_section2_info2_title}
                descriptionKey={FirebaseService.landing_section2_info2_desc}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionPromotion;
