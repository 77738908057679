import {Dispatch} from 'redux';
import {ErrorDto, getErrorDtoFromApiError} from '../../../models/dtos/error.dto';
import {WalletDetailsDto} from '../../../models/dtos/walletDetails.dto';
import {
  GET_WALLET_DETAILS_FAIL,
  GET_WALLET_DETAILS_RESET,
  GET_WALLET_DETAILS_START,
  GET_WALLET_DETAILS_SUCCESS,
} from './constants';
import {getWalletDetailsRequest} from './repository';
import {
  GetWalletDetailsFailAction,
  GetWalletDetailsResetAction,
  GetWalletDetailsStartAction,
  GetWalletDetailsSuccessAction,
} from './types';

const getWalletDetailsStartAction = (): GetWalletDetailsStartAction => {
  return {
    type: GET_WALLET_DETAILS_START,
  };
};
const getWalletDetailsSuccessAction = (payload:WalletDetailsDto): GetWalletDetailsSuccessAction => {
  return {
    type: GET_WALLET_DETAILS_SUCCESS,
    payload: payload,
  };
};
const getWalletDetailsFailAction = (error: ErrorDto): GetWalletDetailsFailAction => {
  return {
    type: GET_WALLET_DETAILS_FAIL,
    error: error,
  };
};
const getWalletDetailsResetAction = (): GetWalletDetailsResetAction => {
  return {
    type: GET_WALLET_DETAILS_RESET,
  };
};

export const getWalletDetails = () => async (dispatch: Dispatch) => {
  try {
    dispatch(getWalletDetailsStartAction());
    const response = await getWalletDetailsRequest();
    dispatch(getWalletDetailsSuccessAction(response));
  } catch (error) {
    dispatch(getWalletDetailsFailAction(getErrorDtoFromApiError(error)));
  }
};

export const getWalletDetailsReset = () => (dispatch: Dispatch) => {
  dispatch(getWalletDetailsResetAction());
};
