import {GENERATE_LINKS_FAIL, GENERATE_LINKS_RESET, GENERATE_LINKS_START, GENERATE_LINKS_SUCCESS,} from './constants';
import {GenerateLinksActions, GenerateLinksState} from './types';

const initialState: GenerateLinksState = {
  loading: false,
  data: undefined,
  error: undefined,
};

const reducer = (
  state = initialState,
  action: GenerateLinksActions,
): GenerateLinksState => {
  switch (action.type) {
    case GENERATE_LINKS_START:
      return {
        ...initialState,
        loading: true,
      };
    case GENERATE_LINKS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case GENERATE_LINKS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case GENERATE_LINKS_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
