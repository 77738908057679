import axios from '../../../utilities/axios';
import {JwtDto} from '../../../models/dtos/jwt.dto';
import {ResetPasswordRequest} from '../../../models/requests/resetPassword.request';

export const newPasswordRequest = async (request: ResetPasswordRequest): Promise<JwtDto> => {
  try {
    const response = await axios.post<JwtDto>(
      `${process.env.REACT_APP_BASE_URL}/auth/reset-password`,
      {...request},
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
