import {Dispatch} from 'redux';
import {ErrorDto, getErrorDtoFromApiError} from '../../../models/dtos/error.dto';
import {SideUserDto} from '../../../models/dtos/sideUser.dto';
import {GET_SIDE_USERS_FAIL, GET_SIDE_USERS_RESET, GET_SIDE_USERS_START, GET_SIDE_USERS_SUCCESS} from './constants';
import {getSideUsersRequest} from "./repository";
import {
  GetSideUsersFailAction,
  GetSideUsersResetAction,
  GetSideUsersStartAction,
  GetSideUsersSuccessAction
} from './types';

const getSideUsersStartAction = (): GetSideUsersStartAction => {
  return {
    type: GET_SIDE_USERS_START,
  };
};
const getSideUsersSuccessAction = (payload: SideUserDto[]): GetSideUsersSuccessAction => {
  return {
    type: GET_SIDE_USERS_SUCCESS,
    payload: payload,
  };
};
const getSideUsersFailAction = (error: ErrorDto): GetSideUsersFailAction => {
  return {
    type: GET_SIDE_USERS_FAIL,
    error: error,
  };
};
const getSideUsersResetAction = (): GetSideUsersResetAction => {
  return {
    type: GET_SIDE_USERS_RESET,
  };
};

export const getSideUsers = () => async (dispatch: Dispatch) => {
  try {
    dispatch(getSideUsersStartAction());
    const response = await getSideUsersRequest();
    dispatch(getSideUsersSuccessAction(response));
  } catch (error) {
    dispatch(getSideUsersFailAction(getErrorDtoFromApiError(error)));
  }
};

export const getSideUsersReset = () => (dispatch: Dispatch) => {
  dispatch(getSideUsersResetAction());
};
