import React from 'react';
import {Helpers} from "../../../core/utilities/helpers";
import {router} from "../../../core/utilities/router";
import AppHeader from '../../AppHeader/AppHeader';
import FaqButton from "../../Buttons/FaqButton/FaqButton";
import './AppLayout.scss';

interface IProps {
  pathname: string;
}

class AppLayout extends React.Component<IProps> {

  public isHeaderAndFaqAvailable(): boolean {
    return (
      !Helpers.isCurrentPathPaymentRedirect() &&
      this.props.pathname !== router.LANDING
    );
  }

  render() {
    return (
      <div id="app-layout">
        {
          this.isHeaderAndFaqAvailable() &&
          <AppHeader/>
        }
        {this.props.children}
        {
          this.isHeaderAndFaqAvailable() &&
          <FaqButton/>
        }
      </div>
    );
  }
}

export default AppLayout;
