import {Modal} from "antd";
import React, {Component} from 'react';
import Iframe from "react-iframe";
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import {ErrorDto, getCustomPaymentError} from "../../../core/models/dtos/error.dto";
import {PaymentState} from "../../../core/models/enums/paymentState";
import {setApiError} from "../../../core/services/appService/setApiError/actions";
import {getSaveStatusRequest} from "../../../core/services/paymentService/getSaveStatus/repository";
import {Constants} from "../../../core/utilities/constants";
import {Helpers} from "../../../core/utilities/helpers";
import {LocalStorage} from "../../../core/utilities/localStorage";
import {IStore} from "../../../core/utilities/reducers";
import {UI} from "../../../core/utilities/ui";
import "./PayByMeModal.scss";
import {getMeRequest} from "../../../core/services/userService/getMe/repository";
import {setMe} from "../../../core/services/appService/setMe/actions";
import {MeDto} from "../../../core/models/dtos/me.dto";

interface IProps {
  url: string;
  syncId?: string;
  callbackPaymentResult: (isSuccess: boolean) => void;
  setMe: (me?: MeDto) => void;
  setApiError: (error?: ErrorDto) => void;
}

interface IState {
  intervalIdPaymentListener?: NodeJS.Timeout;
}

class PayByMeModal extends Component<IProps> {
  state: IState = {
    intervalIdPaymentListener: undefined,
  }

  private readonly paymentListenerIntervalMS = 1000;

  componentDidMount() {
    this.setPaymentListener();
  }

  private setPaymentListener(): void {
    LocalStorage.remove(LocalStorage.vpos);
    const intervalIdPaymentListener = setInterval(() => {
      const vposResult = LocalStorage.get(LocalStorage.vpos);
      if (vposResult) {
        this.clearPaymentListener();
        if (this.props.syncId) {
          this.syncVposResult(this.props.syncId);
        } else {
          this.handleVposResult(vposResult === Constants.vposResultSuccess);
        }
      }
    }, this.paymentListenerIntervalMS);
    this.setState({intervalIdPaymentListener});
  }

  private clearPaymentListener(): void {
    if (this.state.intervalIdPaymentListener) {
      clearInterval(this.state.intervalIdPaymentListener);
    }
  }

  private handleVposResult(isSuccess: boolean): void {
    this.props.callbackPaymentResult(isSuccess);
  }

  private async syncVposResult(syncId: string): Promise<void> {
    while (true) {
      const status = await getSaveStatusRequest(syncId);
      if (status.state === PaymentState.IN_PROGRESS) {
        await Helpers.wait(Constants.paymentStatusCheckIntervalMS);
      } else {
        switch (status.state) {
          case PaymentState.FAILED:
            const error = getCustomPaymentError(status.description);
            this.props.setApiError(error);
            this.handleVposResult(false);
            break;
          default:
            const me = await getMeRequest();
            this.props.setMe(me);
            if (!me.cardGuid) {
              alert('Undefined me.cardGuid after save card!');
            }
            this.handleVposResult(!!me.cardGuid);
        }
        break;
      }
    }
  }

  render() {
    return (
      <Modal
        className="pay-by-me-modal"
        maskStyle={UI.modalMaskStyle()}
        visible={true}
        closable={false}
        footer={false}
        centered
        destroyOnClose
      >
        <div className="content">
          <Iframe
            id="pay-by-me-iframe"
            url={this.props.url}
            width="350px"
            height="455px"
            position="relative"
          />
        </div>
      </Modal>
    );
  }

  componentWillUnmount() {
    this.clearPaymentListener();
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      setMe,
      setApiError,
    },
    dispatch
  );
};
const mapStateToProps = (store: IStore) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PayByMeModal);
