import React, {ReactNode} from "react";
import FirebaseKey from "../../../../components/FirebaseKey/FirebaseKey";
import "./FeatureCard.scss";

interface IProps {
  className?: string;
  icon: ReactNode;
  titleKey: string;
  descriptionKey: string;
}

const FeatureCard = (props: IProps) => {

  return (
    <div
      key={props.titleKey}
      className={`feature-card ${props.className ?? ''}`}
    >
      <div className="content">
        <div className="icon">{props.icon}</div>
        <FirebaseKey className="title" firebaseKey={props.titleKey}/>
        <FirebaseKey className="description" firebaseKey={props.descriptionKey}/>
      </div>
    </div>
  );
}

export default FeatureCard;
