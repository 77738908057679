import axios from '../../../utilities/axios';
import {SideUserDto} from '../../../models/dtos/sideUser.dto';

export const getSideUsersRequest = async (): Promise<SideUserDto[]> => {
  try {
    const response = await axios.get<SideUserDto[]>(
      `${process.env.REACT_APP_BASE_URL}/users/side-user`,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
