import {WalletDetailsDto} from '../../../models/dtos/walletDetails.dto'
import axios from '../../../utilities/axios';

export const getWalletDetailsRequest = async (): Promise<WalletDetailsDto> => {
  try {
    const response = await axios.get<WalletDetailsDto>(
      `${process.env.REACT_APP_BASE_URL}/agency/wallet`,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
