import {Form, Input} from 'antd';
import React from 'react';
import AppButton from '../../../../components/Buttons/AppButton/AppButton';
import {FormValuesResetPassword} from '../../../../core/models/custom/formValuesResetPassword';
import {Constants} from "../../../../core/utilities/constants";
import './ResetPasswordForm.scss';

interface IProps {
  isSubmitting: boolean;
  callbackSubmit: (values: FormValuesResetPassword) => void;
}

const ResetPasswordForm = (props: IProps) => {
  function handleFinish(values: FormValuesResetPassword) {
    props.callbackSubmit(values);
  }

  return (
    <Form id="reset-password-form" className="form" onFinish={handleFinish}>
      <Form.Item
        name="newPassword"
        rules={[{required: true, message: Constants.inputErrorRequiredField}]}
      >
        <Input.Password
          autoFocus
          className="form-input"
          placeholder="Şifre"
        />
      </Form.Item>

      <Form.Item
        name="rePassword"
        dependencies={['newPassword']}
        rules={[
          {required: true, message: Constants.inputErrorRequiredField},
          ({getFieldValue}) => ({
            validator(rule, value) {
              if (!value || getFieldValue('newPassword') === value) {
                return Promise.resolve();
              }
              return Promise.reject('Şifreler uyuşmuyor!');
            },
          }),
        ]}
      >
        <Input.Password
          className="form-input"
          placeholder="Şifre Tekrar"
        />
      </Form.Item>

      <AppButton
        content="Şifreyi Onayla"
        htmlType="submit"
        disabled={props.isSubmitting}
        isLoading={props.isSubmitting}
        onClick={() => {
        }}
      />
    </Form>
  );
};

export default ResetPasswordForm;
