import {PAY_WITH_CARD_FAIL, PAY_WITH_CARD_RESET, PAY_WITH_CARD_START, PAY_WITH_CARD_SUCCESS,} from './constants';
import {PayWithCardActions, PayWithCardState} from './types';

const initialState: PayWithCardState = {
  loading: false,
  data: undefined,
  error: undefined,
};

const reducer = (
  state = initialState,
  action: PayWithCardActions,
): PayWithCardState => {
  switch (action.type) {
    case PAY_WITH_CARD_START:
      return {
        ...initialState,
        loading: true,
      };
    case PAY_WITH_CARD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case PAY_WITH_CARD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case PAY_WITH_CARD_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
