import {Modal} from "antd";
import React from 'react';
import icon from "../../../assets/images/modal-icon-success.svg";
import {UI} from "../../../core/utilities/ui";
import AppButton from "../../Buttons/AppButton/AppButton";
import "./ContactUsSuccessModal.scss";

interface IProps {
  callback: () => void;
}

const ContactUsSuccessModal = (props: IProps) => {

  return (
    <Modal
      className="contact-us-success-modal"
      maskStyle={UI.modalMaskStyle()}
      closable={false}
      footer={false}
      visible
      centered
      destroyOnClose
    >
      <div className="content">
        <img className="icon" src={icon} alt="icon"/>
        <label className="title">
          Mesajınız alındı!
        </label>
        <label className="text">
        Aradığınız cevabı sıkça sorulan sorular arasında bulamadığınız için üzgünüz. En kısa sürede dönüş sağlayacağız. 
        </label>
        <AppButton
          content="Ana Sayfa"
          onClick={props.callback}
        />
      </div>
    </Modal>
  );
}

export default ContactUsSuccessModal;
