import {Dispatch} from 'redux';
import {ErrorDto, getErrorDtoFromApiError} from '../../../models/dtos/error.dto';
import {CONFIRM_EMAIL_FAIL, CONFIRM_EMAIL_RESET, CONFIRM_EMAIL_START, CONFIRM_EMAIL_SUCCESS} from './constants';
import {confirmEmailRequest} from './repository';
import {
  ConfirmEmailFailAction,
  ConfirmEmailResetAction,
  ConfirmEmailStartAction,
  ConfirmEmailSuccessAction,
} from './types';
import {getMeRequest} from '../getMe/repository';
import {history} from '../../../utilities/history';
import {Helpers} from '../../../utilities/helpers';

const confirmEmailStartAction = (): ConfirmEmailStartAction => {
  return {
    type: CONFIRM_EMAIL_START,
  };
};
const confirmEmailSuccessAction = (payload: boolean): ConfirmEmailSuccessAction => {
  return {
    type: CONFIRM_EMAIL_SUCCESS,
    payload: payload,
  };
};
const confirmEmailFailAction = (error: ErrorDto): ConfirmEmailFailAction => {
  return {
    type: CONFIRM_EMAIL_FAIL,
    error: error,
  };
};
const confirmEmailResetAction = (): ConfirmEmailResetAction => {
  return {
    type: CONFIRM_EMAIL_RESET,
  };
};

export const confirmEmail = (code: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(confirmEmailStartAction());
    await confirmEmailRequest(code);
    const me = await getMeRequest(dispatch);
    dispatch(confirmEmailSuccessAction(true));
    history.push(Helpers.getRouteFromMe(me));
  } catch (error) {
    dispatch(confirmEmailFailAction(getErrorDtoFromApiError(error)));
  }
};

export const confirmEmailReset = () => (dispatch: Dispatch) => {
  dispatch(confirmEmailResetAction());
};
