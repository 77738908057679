import {BulkRewardMsisdnDto} from '../../../models/dtos/bulkRewardMsisdn.dto';
import {BulkRewardMsisdnRequest} from '../../../models/requests/bulkRewardMsisdn.request';
import axios from '../../../utilities/axios';

export const bulkRewardMsisdnRequest = async (request: BulkRewardMsisdnRequest): Promise<BulkRewardMsisdnDto> => {
  try {
    const formData = new FormData();
    formData.append('file', request.file);
    formData.append('initialAllocation', request.initialAllocation.toString());
    formData.append('offerId', request.offerId.toString());
    formData.append('goal', request.goal.toString())
    if (request.name) {
      formData.append('name', request.name);
    }

    const response = await axios.post<BulkRewardMsisdnDto>(
      `${process.env.REACT_APP_BASE_URL}/campaigns/bulk`,
      formData,
      {headers: {'Content-Type': 'multipart/form-data'}},
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
