import {Button, Spin} from 'antd';
import React, {ReactNode} from 'react';
import './AppButton.scss';

interface IProps {
  className?: string;
  type?: AppButtonType;
  htmlType?: 'submit' | 'button';
  disabled?: boolean;
  isLoading?: boolean;
  content: string | ReactNode;
  onClick: Function;
}

export enum AppButtonType {
  primary = 'primary',
  secondary = 'default',
  outline = 'ghost',
  link = 'link',
}

const AppButton = (props: IProps) => {
  const getClassName = (): string => {
    let className = getType().toString();
    if (props.className) className += ` ${props.className}`;
    return className;
  };
  const getType = (): AppButtonType => {
    return props.type ?? AppButtonType.primary;
  };

  return (
    <Button
      className={`app-button ${getClassName()}`}
      type={getType()}
      htmlType={props.htmlType ?? 'button'}
      disabled={props.disabled}
      onClick={() => (!props.isLoading ? props.onClick() : {})}
    >
      {props.isLoading ? <Spin /> : props.content}
    </Button>
  );
};

export default AppButton;
