import {Button} from "antd";
import React from "react";
import Loading from "../../../../Loading/Loading";
import "./TryBulkButtons.scss";

interface IProps {
  textOk?: string;
  textCancel?: string;
  isLoading: boolean;
  isOkDisabled?: boolean;
  callbackCancel: () => void;
}

const TryBulkButtons = (props: IProps) => {

  return (
    <div id="try-bulk-buttons">
      {
        props.isLoading
          ?
          <div className="loading-wrapper">
            <Loading fontSize={48}/>
          </div>
          :
          <React.Fragment>
            <Button
              key="ok"
              htmlType="submit"
              disabled={props.isOkDisabled}
              className="landing-button"
            >
              {props.textOk ?? 'Devam Et'}
            </Button>
            <Button
              key="cancel"
              className="landing-button outline"
              onClick={props.callbackCancel}
            >
              {props.textCancel ?? 'Vazgeç'}
            </Button>
          </React.Fragment>
      }
    </div>
  );
}

export default TryBulkButtons;
