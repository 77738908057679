import {
  CHECK_AND_GET_AGENCY_CODE_FAIL,
  CHECK_AND_GET_AGENCY_CODE_RESET,
  CHECK_AND_GET_AGENCY_CODE_START,
  CHECK_AND_GET_AGENCY_CODE_SUCCESS,
} from './constants';
import {CheckAndGetAgencyCodeActions, CheckAndGetAgencyCodeState} from './types';

const initialState: CheckAndGetAgencyCodeState = {
  loading: false,
  data: undefined,
  error: undefined,
};

const reducer = (
  state = initialState,
  action: CheckAndGetAgencyCodeActions,
): CheckAndGetAgencyCodeState => {
  switch (action.type) {
    case CHECK_AND_GET_AGENCY_CODE_START:
      return {
        ...initialState,
        loading: true,
      };
    case CHECK_AND_GET_AGENCY_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case CHECK_AND_GET_AGENCY_CODE_FAIL:
     return {
        ...state,
        loading: false,
        error: action.error,
      };
    case CHECK_AND_GET_AGENCY_CODE_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
