import {Table} from "antd";
import moment from "moment";
import 'moment/locale/tr';
import React from 'react';
import {CampaignDetailsDto} from "../../../../core/models/dtos/campaignDetails.dto";
import {CampaignHistoryState} from "../../../../core/models/enums/campaignHistoryState";
import {Constants} from "../../../../core/utilities/constants";
import "./CampaignDetailTable.scss";

interface IProps {
  data: CampaignDetailsDto;
}

const CampaignDetailTable = (props: IProps) => {
  const columns = [
    {
      title: 'Tarih',
      dataIndex: 'date',
      key: 'date',
      render: (date: string) => {
        moment.locale('tr');
        return (
          <span>{moment(date).format(Constants.dateFormatUI)}</span>
        );
      },
    },
    {
      title: 'Kampanya adı',
      dataIndex: 'constName',
      key: 'constName',
      render: (_: any) => <span>{props.data.name}</span>
    },
    {
      title: 'Birim',
      dataIndex: 'offerName',
      key: 'offerName',
    },
    {
      title: 'Adet',
      dataIndex: 'constAmount',
      key: 'constAmount',
      render: (_: any) => <span>1</span>
    },
    {
      title: 'Telefon numarası',
      dataIndex: 'msisdn',
      key: 'msisdn',
    },
    {
      title: 'Durum',
      dataIndex: 'state',
      key: 'state',
      render: (state: CampaignHistoryState) => {
        return (
          <div className={`state ${state.toLowerCase()}`}>
            {
              state === CampaignHistoryState.SUCCESSFUL
                ? 'Başarılı'
                : 'Başarısız'
            }
          </div>
        );
      }
    },
  ];

  return (
    <Table
      id="campaign-detail-table"
      className="table"
      rowKey="[history.date]"
      columns={columns}
      dataSource={props.data.history}
    />
  );
}

export default CampaignDetailTable;
