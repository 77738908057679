import {
  CLAIM_REVENUE_FAIL,
  CLAIM_REVENUE_RESET,
  CLAIM_REVENUE_START,
  CLAIM_REVENUE_SUCCESS,
} from './constants';
import {ClaimRevenueActions, ClaimRevenueState} from './types';

const initialState: ClaimRevenueState = {
  loading: false,
  data: false,
  error: undefined,
};

const reducer = (
  state = initialState,
  action: ClaimRevenueActions,
): ClaimRevenueState => {
  switch (action.type) {
    case CLAIM_REVENUE_START:
      return {
        ...initialState,
        loading: true,
      };
    case CLAIM_REVENUE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case CLAIM_REVENUE_FAIL:
     return {
        ...state,
        loading: false,
        error: action.error,
      };
    case CLAIM_REVENUE_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
