import {Dispatch} from 'redux';
import {ErrorDto, getErrorDtoFromApiError} from '../../../models/dtos/error.dto';
import {
  RESEND_CONFIRM_EMAIL_FAIL,
  RESEND_CONFIRM_EMAIL_RESET,
  RESEND_CONFIRM_EMAIL_START,
  RESEND_CONFIRM_EMAIL_SUCCESS,
} from './constants';
import {resendConfirmEmailRequest} from './repository';
import {
  ResendConfirmEmailFailAction,
  ResendConfirmEmailResetAction,
  ResendConfirmEmailStartAction,
  ResendConfirmEmailSuccessAction,
} from './types';

const resendConfirmEmailStartAction = (): ResendConfirmEmailStartAction => {
  return {
    type: RESEND_CONFIRM_EMAIL_START,
  };
};
const resendConfirmEmailSuccessAction = (payload: boolean): ResendConfirmEmailSuccessAction => {
  return {
    type: RESEND_CONFIRM_EMAIL_SUCCESS,
    payload: payload,
  };
};
const resendConfirmEmailFailAction = (error: ErrorDto): ResendConfirmEmailFailAction => {
  return {
    type: RESEND_CONFIRM_EMAIL_FAIL,
    error: error,
  };
};
const resendConfirmEmailResetAction = (): ResendConfirmEmailResetAction => {
  return {
    type: RESEND_CONFIRM_EMAIL_RESET,
  };
};

export const resendConfirmEmail = () => async (dispatch: Dispatch) => {
  try {
    dispatch(resendConfirmEmailStartAction());
    await resendConfirmEmailRequest();
    dispatch(resendConfirmEmailSuccessAction(true));
  } catch (error) {
    dispatch(resendConfirmEmailFailAction(getErrorDtoFromApiError(error)));
  }
};

export const resendConfirmEmailReset = () => (dispatch: Dispatch) => {
  dispatch(resendConfirmEmailResetAction());
};
