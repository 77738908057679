import {Form, Input, Select} from "antd";
import React from 'react';
import lightning from "../../../../assets/images/lightning.svg";
import phone from "../../../../assets/images/phone.svg";
import AppButton from "../../../../components/Buttons/AppButton/AppButton";
import {FormValuesRewardMsisdn} from "../../../../core/models/custom/formValuesRewardMsisdn";
import {UsageDto} from "../../../../core/models/dtos/usage.dto";
import "./RewardMsisdnForm.scss";

const {Option} = Select;

interface IProps {
  usages: UsageDto[];
  callbackSubmit: (values: FormValuesRewardMsisdn) => void;
}

const RewardMsisdnForm = (props: IProps) => {
  const [form] = Form.useForm();

  function getInitialValues(): Object {
    return {offerId: props.usages.length === 1 ? props.usages[0].offer.id : ''}
  }

  function handleMsisdnChange(msisdn: string): void {
    if (msisdn) {
      if (msisdn.substring(0, 1) !== '5') {
        form.setFieldsValue({msisdn: ''});
      }
      if (msisdn.length > 10) {
        form.setFieldsValue({msisdn: msisdn.substring(0, 10)});
      }
    }
  }

  function handleMsisdnKeyPress(e: React.KeyboardEvent): void {
    if (isNaN(parseInt(e.key))) {
      e.preventDefault();
    }
  }

  function handleFinish(values: FormValuesRewardMsisdn) {
    props.callbackSubmit(values);
  }

  return (
    <Form
      form={form}
      id="reward-msisdn-form"
      className="form"
      initialValues={getInitialValues()}
      onFinish={handleFinish}
    >
      <p className="label">Yüklemek istediğiniz MetaByte adedini seçin</p>
      <div className="offer-wrapper">
        <Form.Item
          name="offerId"
          className="form-item w-50 me-2"
          rules={[{required: true, message: 'Paket seçin'}]}
        >
          <Select
            className="form-input select"
            disabled={props.usages.length === 1}
          >
            {props.usages.map(i => {
              return (
                <Option key={i.offer.id} value={i.offer.id}>
                  {i.offer.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          name="amount"
          className="form-item w-50"
          rules={[
            {required: true, message: 'Adet girin'},
            {min: 1, message: 'En az 1 adet'},
          ]}
        >
          <Input
            className="form-input"
            placeholder="Adet"
            type="number"
            autoFocus
            min={1}
          />
        </Form.Item>
      </div>

      <p className="label">1000 MetaByte yüklemek istediğiniz telefon numarasını yazınız.</p>
      <Form.Item
        name="msisdn"
        className="form-item msisdn-wrapper mb-2"
        rules={[
          {required: true, message: 'Telefon numarası girin'},
          {min: 10, message: ''},
        ]}
      >
        <Input
          className="form-input pe-2"
          type="tel"
          placeholder="5** *** ** **"
          prefix={<img className="me-2" src={phone} alt="phone"/>}
          suffix={
            <AppButton
              htmlType="submit"
              content={
                <div>
                  <img className="me-2" src={lightning} alt="lightning"/>
                  <span className="button-text-l">Hızlı yükle</span>
                </div>
              }
              onClick={() => {
              }}/>
          }
          onChange={(e) => handleMsisdnChange(e.target.value)}
          onKeyPress={handleMsisdnKeyPress}
        />
      </Form.Item>
      <label className="note">
      Lütfen telefon numarasını başında 0 olmadan 10 haneli olacak şekilde yazınız.       </label>
    </Form>
  );
}

export default RewardMsisdnForm;
