import React, {useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {IStore} from '../../../core/utilities/reducers';
import './ForgotPasswordModal.scss';
import {ResetPasswordState} from '../../../core/services/authService/resetPassword/types';
import {
  resetPassword,
  resetPasswordReset,
} from '../../../core/services/authService/resetPassword/actions';
import {Input, Modal} from 'antd';
import {UI} from '../../../core/utilities/ui';
import AppButton from '../../Buttons/AppButton/AppButton';
import {Helpers} from '../../../core/utilities/helpers';

interface IProps {
  resetPasswordState: ResetPasswordState;
  resetPassword: (email: string) => void;
  resetPasswordReset: () => void;
  callbackClose: () => void;
}

const ForgotPasswordModal = (props: IProps) => {
  const [email, setEmail] = useState('');

  function handleClose(): void {
    props.callbackClose();
    props.resetPasswordReset();
  }

  return (
    <Modal
      className="forgot-password-modal"
      maskStyle={UI.modalMaskStyle()}
      visible
      closable
      footer={false}
      centered
      destroyOnClose
      onCancel={() => handleClose()}
    >
      <div className="content">
        <label className="title">Şifremi Unuttum</label>
        {props.resetPasswordState.data ? (
          <p className="text">
            Şifreni yeniden belirlemek için gerekli bağlantı&nbsp;
            <span style={{fontWeight: 'bold'}}>{email}</span>&nbsp;adresine
            gönderilmiştir.
          </p>
        ) : (
          <React.Fragment>
            <p className="text">
              Şifrenizi sıfırlamak için lütfen kayıtlı olan e-mail adresinizi
              giriniz.
            </p>
            <Input
              className="input"
              type="email"
              placeholder="E-Mail"
              onChange={(e) => setEmail(e.target.value)}
            />
          </React.Fragment>
        )}
        {props.resetPasswordState.data ? (
          <AppButton content="Tamam" onClick={() => handleClose()} />
        ) : (
          <AppButton
            content="Gönder"
            isLoading={props.resetPasswordState.loading}
            disabled={
              props.resetPasswordState.loading || !Helpers.isEmailValid(email)
            }
            onClick={() => props.resetPassword(email)}
          />
        )}
      </div>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      resetPassword,
      resetPasswordReset,
    },
    dispatch
  );
};
const mapStateToProps = (store: IStore) => {
  return {
    resetPasswordState: store.resetPassword,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordModal);
