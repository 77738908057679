import React from 'react';
import faq from "../../../assets/images/button-faq.svg";
import {history} from "../../../core/utilities/history";
import {router} from "../../../core/utilities/router";
import AppButton from "../AppButton/AppButton";
import "./FaqButton.scss";

interface IProps {
}

const FaqButton = (props: IProps) => {

  return (
    <AppButton
      className="faq-button"
      content={<img src={faq} alt="faq"/>}
      onClick={() => history.push(router.FAQ)}
    />
  );
}

export default FaqButton;
