import {
  GET_FILE_FAIL,
  GET_FILE_RESET,
  GET_FILE_START,
  GET_FILE_SUCCESS,
} from './constants';
import {GetFileActions, GetFileState} from './types';

const initialState: GetFileState = {
  loading: false,
  data: undefined,
  error: undefined,
};

const reducer = (
  state = initialState,
  action: GetFileActions,
): GetFileState => {
  switch (action.type) {
    case GET_FILE_START:
      return {
        ...initialState,
        loading: true,
      };
    case GET_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case GET_FILE_FAIL:
     return {
        ...state,
        loading: false,
        error: action.error,
      };
    case GET_FILE_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
