import { Button } from "antd";
import React from "react";
import { NavLink } from "react-router-dom";
import arrow from "../../../../assets/images/landing/chevron-right-black.svg";
import arrowPrimary from "../../../../assets/images/landing/chevron-right-primary.svg";
import circles from "../../../../assets/images/landing/feature-circles.svg";
import logo from "../../../../assets/images/landing/logo-footer.svg";
import FirebaseKey from "../../../../components/FirebaseKey/FirebaseKey";
import FirebaseService from "../../../../core/services/firebase.service";
import { router } from "../../../../core/utilities/router";
import { UI } from "../../../../core/utilities/ui";
import FeatureCard from "../FeatureCard/FeatureCard";
import "./SectionRegister.scss";

interface IProps {}

const SectionRegister = (props: IProps) => {
  return (
    <div id="section-register" className="section">
      <div className="section-content">
        <div className="row w-xl-100">
          <div
            className={`section-left ${UI.getLandingSectionResponsiveClass()}`}
          >
            <div className="content">
              <FirebaseKey
                className="title"
                firebaseKey={FirebaseService.landing_section7_title}
              />
              <FirebaseKey
                className="description"
                firebaseKey={FirebaseService.landing_section7_desc}
              />
            </div>
            <div className="button-wrapper">
              <NavLink to={router.AUTH}>
                <Button
                  className="landing-button"
                  onClick={() =>
                    FirebaseService.logEvent(
                      FirebaseService.count_sign_up_clicked
                    )
                  }
                >
                  <FirebaseKey
                    firebaseKey={FirebaseService.landing_section7_signup}
                  />
                  <img className="arrow" src={arrow} alt="arrow" />
                </Button>
              </NavLink>
              <NavLink to={router.CONTACT_US}>
                <Button className="landing-button contact">
                  <FirebaseKey
                    firebaseKey={FirebaseService.landing_section7_contactus}
                  />
                  <img className="arrow" src={arrowPrimary} alt="arrow" />
                </Button>
              </NavLink>
            </div>
          </div>
          <div
            className={`section-right ${UI.getLandingSectionResponsiveClass()}`}
          >
            <div className="row w-100">
              <FeatureCard
                className="col-12 col-md-6"
                icon={<img src={circles} alt="circles" />}
                titleKey={FirebaseService.landing_section7_info1_title}
                descriptionKey={FirebaseService.landing_section7_info1_desc}
              />
              <FeatureCard
                className="col-12 col-md-6 mt-5 mt-md-0"
                icon={<img src={logo} alt="logo" />}
                titleKey={FirebaseService.landing_section7_info2_title}
                descriptionKey={FirebaseService.landing_section7_info2_desc}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionRegister;
