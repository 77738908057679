import {
  GET_WALLET_DETAILS_FAIL,
  GET_WALLET_DETAILS_RESET,
  GET_WALLET_DETAILS_START,
  GET_WALLET_DETAILS_SUCCESS,
} from './constants';
import {GetWalletDetailsActions, GetWalletDetailsState} from './types';

const initialState: GetWalletDetailsState = {
  loading: false,
  data: undefined,
  error: undefined,
};

const reducer = (
  state = initialState,
  action: GetWalletDetailsActions,
): GetWalletDetailsState => {
  switch (action.type) {
    case GET_WALLET_DETAILS_START:
      return {
        ...initialState,
        loading: true,
      };
    case GET_WALLET_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case GET_WALLET_DETAILS_FAIL:
     return {
        ...state,
        loading: false,
        error: action.error,
      };
    case GET_WALLET_DETAILS_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
