import axios from '../../../utilities/axios';

export const getFileRequest = async (id: number): Promise<string> => {
  try {
    const response = await axios.get<string>(
      `${process.env.REACT_APP_BASE_URL}/campaigns/${id}/file`,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
