import {Dispatch} from 'redux';
import {CampaignDetailsDto} from '../../../models/dtos/campaignDetails.dto';
import {ErrorDto, getErrorDtoFromApiError} from '../../../models/dtos/error.dto';
import {GET_DETAILS_FAIL, GET_DETAILS_RESET, GET_DETAILS_START, GET_DETAILS_SUCCESS,} from './constants';
import {getDetailsRequest} from './repository';
import {GetDetailsFailAction, GetDetailsResetAction, GetDetailsStartAction, GetDetailsSuccessAction,} from './types';

const getDetailsStartAction = (): GetDetailsStartAction => {
  return {
    type: GET_DETAILS_START,
  };
};
const getDetailsSuccessAction = (payload: CampaignDetailsDto): GetDetailsSuccessAction => {
  return {
    type: GET_DETAILS_SUCCESS,
    payload: payload,
  };
};
const getDetailsFailAction = (error: ErrorDto): GetDetailsFailAction => {
  return {
    type: GET_DETAILS_FAIL,
    error: error,
  };
};
const getDetailsResetAction = (): GetDetailsResetAction => {
  return {
    type: GET_DETAILS_RESET,
  };
};

export const getDetails = (id: number) => async (dispatch: Dispatch) => {
  try {
    dispatch(getDetailsStartAction());
    const response = await getDetailsRequest(id);
    dispatch(getDetailsSuccessAction(response));
  } catch (error) {
    dispatch(getDetailsFailAction(getErrorDtoFromApiError(error)));
  }
};

export const getDetailsReset = () => (dispatch: Dispatch) => {
  dispatch(getDetailsResetAction());
};
