import React, {Component, ReactNode} from 'react';
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import add from "../../../assets/images/add.svg";
import AccountBalance from "../../../components/AccountBalance/AccountBalance";
import EmptyStateCard from "../../../components/EmptyStateCard/EmptyStateCard";
import Loading from "../../../components/Loading/Loading";
import RewardMsisdnApprovalModal from "../../../components/Modals/RewardMsisdnApprovalModal/RewardMsisdnApprovalModal";
import RewardMsisdnSuccessModal from "../../../components/Modals/RewardMsisdnSuccessModal/RewardMsisdnSuccessModal";
import PageTitle from "../../../components/PageTitle/PageTitle";
import {FormValuesRewardMsisdn} from "../../../core/models/custom/formValuesRewardMsisdn";
import {RewardMsisdnApprovalData} from "../../../core/models/custom/rewardMsisdnApprovalData";
import {RewardMsisdnRequest} from "../../../core/models/requests/rewardMsisdn.request";
import {getUsage} from "../../../core/services/offerService/getUsage/actions";
import {GetUsageState} from "../../../core/services/offerService/getUsage/types";
import {rewardMsisdn} from "../../../core/services/offerService/rewardMsisdn/actions";
import {RewardMsisdnState} from "../../../core/services/offerService/rewardMsisdn/types";
import {Helpers} from "../../../core/utilities/helpers";
import {history} from "../../../core/utilities/history";
import {IStore} from "../../../core/utilities/reducers";
import {router} from "../../../core/utilities/router";
import RewardMsisdnForm from "./RewardMsisdnForm/RewardMsisdnForm";
import "./RewardMsisdnPage.scss";

interface IProps {
  getUsageState: GetUsageState;
  rewardMsisdnState: RewardMsisdnState;
  getUsage: () => void;
  rewardMsisdn: (offerId: number, request: RewardMsisdnRequest) => void;
}

interface IState {
  approvalData?: RewardMsisdnApprovalData;
  successAmount?: number;
}

class RewardMsisdnPage extends Component<IProps> {
  state: IState = {
    approvalData: undefined,
    successAmount: undefined,
  }

  componentDidMount() {
    this.props.getUsage();
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any) {
    if (prevProps.rewardMsisdnState.loading && !this.props.rewardMsisdnState.loading) {
      if (this.props.rewardMsisdnState.data) {
        const amount = this.state.approvalData?.amount;
        this.setState({approvalData: undefined, successAmount: amount});
      }
    }
  }

  private setApprovalData(formValues?: FormValuesRewardMsisdn): void {
    if (formValues) {
      const offer = this.props.getUsageState.data!.filter(i => i.offer.id === formValues.offerId)[0].offer;
      const approvalData: RewardMsisdnApprovalData = {
        ...formValues,
        offer,
      }
      this.setState({approvalData});
    } else {
      this.setState({approvalData: undefined});
    }
  }

  private handleSubmit(data: RewardMsisdnApprovalData): void {
    const request: RewardMsisdnRequest = {
      amount: parseInt(data.amount.toString()), // to send as number, otherwise it will be string e.g '1'
      msisdn: `90${data.msisdn}`,
    }
    this.props.rewardMsisdn(data.offer.id, request);
  }

  private handleSuccessClose(): void {
    this.setState({successAmount: undefined});
    this.props.getUsage();
  }

  private getEmptyStateCardButton(): ReactNode {
    return (
      <div className="d-flex align-items-center">
        <img src={add} alt="add"/>
        &nbsp;&nbsp;
        <span>MetaByte Al</span>
      </div>
    );
  }

  private renderContent(): ReactNode {
    if (this.props.getUsageState.loading) {
      return <Loading fontSize={48}/>
    } else if (this.props.getUsageState.data) {
      const balance = Helpers.getRemainingPoint1000(this.props.getUsageState.data);
      if (balance) {
        return (
          <RewardMsisdnForm
            usages={Helpers.getUsagesPoint1000(this.props.getUsageState.data)}
            callbackSubmit={values => this.setApprovalData(values)}
          />
        );
      }
      return (
        <EmptyStateCard
          title="Maalesef hiç MetaByte'ınız yok"
          text="Hızlı Yükleme yapabilmeniz için en az 1000 MetaByte'ınız olması gerekmektedir. İşleminize devam etmek için MetaByte alabilirsiniz."
          button={{
            child: this.getEmptyStateCardButton(),
            callback: () => history.push(router.ORDER)
          }}
        />
      );
    }
  }

  render() {
    return (
      <div id="reward-msisdn-page" className="page">
        <div className="page-content">
          <PageTitle text="Hızlı Yükle"/>
          <AccountBalance/>
          {this.renderContent()}
        </div>

        {
          this.state.approvalData &&
          <RewardMsisdnApprovalModal
            data={this.state.approvalData}
            isSubmitting={this.props.rewardMsisdnState.loading}
            callbackOk={data => this.handleSubmit(data)}
            callbackCancel={() => this.setApprovalData()}
          />
        }

        {
          this.state.successAmount &&
          <RewardMsisdnSuccessModal
            amount={this.state.successAmount}
            callback={() => this.handleSuccessClose()}
          />
        }
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getUsage,
      rewardMsisdn,
    },
    dispatch
  );
};
const mapStateToProps = (store: IStore) => {
  return {
    getUsageState: store.getUsage,
    rewardMsisdnState: store.rewardMsisdn,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(RewardMsisdnPage);
