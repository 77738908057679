import {Menu} from 'antd';
import React, {useState} from 'react';
import {connect} from "react-redux";
import {NavLink} from 'react-router-dom';
import {bindActionCreators, Dispatch} from "redux";
import downPrimary from '../../assets/images/chevron-down-primary.svg';
import down from '../../assets/images/chevron-down.svg';
import fastUploadActive from '../../assets/images/header-fast-upload-active.svg';
import fastUpload from '../../assets/images/header-fast-upload.svg';
import homeActive from '../../assets/images/header-home-active.svg';
import home from '../../assets/images/header-home.svg';
import logout from '../../assets/images/header-logout.svg';
import pointBuyActive from '../../assets/images/header-point-buy-active.svg';
import pointBuySubHistory from '../../assets/images/header-point-buy-sub-history.svg';
import pointBuySubNow from '../../assets/images/header-point-buy-sub-now.svg';
import pointBuy from '../../assets/images/header-point-buy.svg';
import pointGiveActive from '../../assets/images/header-point-give-active.svg';
import pointGiveSubCreate from '../../assets/images/header-point-give-sub-create.svg';
import pointGiveSubHistory from '../../assets/images/header-point-give-sub-history.svg';
import pointGive from '../../assets/images/header-point-give.svg';
import profileActive from '../../assets/images/header-profile-active.svg';
import profile from '../../assets/images/header-profile.svg';
import logo from '../../assets/images/logo.svg';
import {setMe} from "../../core/services/appService/setMe/actions";
import FirebaseService from "../../core/services/firebase.service";
import {history} from '../../core/utilities/history';
import {IStore} from "../../core/utilities/reducers";
import {router} from '../../core/utilities/router';
import LogoutModal from "../Modals/LogoutModal/LogoutModal";
import './AppHeader.scss';

const {SubMenu} = Menu;

interface IProps {
  pathname: string;
  setMe: () => void;
}

enum MenuItemKey {
  logo,
  home,
  pointBuy,
  pointGive,
  rewardMsisdn,
  profile,
  logout,
}

const AppHeader = (props: IProps) => {
  const [isLogoutModalVisible, setLogoutModalVisible] = useState(false);

  function handleLogoutModal(isApproved: boolean) {
    setLogoutModalVisible(false);
    if (isApproved) {
      props.setMe();
    }
  }

  function isMenuItemActive(key: MenuItemKey): boolean {
    switch (props.pathname) {
      case router.HOME:
        return key === MenuItemKey.home;
      case router.REWARD_MSISDN:
        return key === MenuItemKey.rewardMsisdn;
      case router.ORDER:
      case router.ORDER_HISTORY:
        return key === MenuItemKey.pointBuy;
      case router.CAMPAIGNS:
      case router.CAMPAIGN_CREATE:
        return key === MenuItemKey.pointGive;
      case router.PROFILE:
        return key === MenuItemKey.profile;
      default:
        return false;
    }
  }

  return (
    <Menu
      id="app-header"
      mode="horizontal"
      selectedKeys={[]}
    >
      <Menu.Item
        key={MenuItemKey.logo}
        className={`menu-item`}
        icon={
          <img
            className="logo"
            src={logo}
            alt="logo"
            onClick={() => history.push(router.LANDING)}
          />
        }
      />
      <Menu.Item
        key={MenuItemKey.home}
        className={`menu-item ${isMenuItemActive(MenuItemKey.home) && 'active'}`}
        icon={
          <img
            className="icon"
            src={isMenuItemActive(MenuItemKey.home) ? homeActive : home}
            alt="icon"
          />
        }
      >
        <NavLink to={router.HOME}>Ana Sayfa</NavLink>
      </Menu.Item>

      <SubMenu
        key={MenuItemKey.pointBuy}
        className={`submenu-item ${isMenuItemActive(MenuItemKey.pointBuy) && 'active'}`}
        icon={
          <img
            className="icon"
            src={isMenuItemActive(MenuItemKey.pointBuy) ? pointBuyActive : pointBuy}
            alt="icon"
          />
        }
        title={
          <label>
            Cüzdan
            <img className="ms-2" src={isMenuItemActive(MenuItemKey.pointBuy) ? downPrimary : down} alt="menu"/>
          </label>
        }
      >
        <Menu.Item key="order">
          <NavLink
            to={router.ORDER} className="sub"
            onClick={() => FirebaseService.logEvent(FirebaseService.nav_bar_quick_buy_points_clicked)}
          >
            <img className="icon" src={pointBuySubNow} alt="now"/>
            MetaByte Al
          </NavLink>
        </Menu.Item>
        <Menu.Divider/>
        <Menu.Item key="order-history">
          <NavLink to={router.ORDER_HISTORY} className="sub">
            <img className="icon" src={pointBuySubHistory} alt="history"/>
            MetaByte Geçmişi
          </NavLink>
        </Menu.Item>
      </SubMenu>

      <SubMenu
        key={MenuItemKey.pointGive}
        className={`submenu-item ${isMenuItemActive(MenuItemKey.pointGive) && 'active'}`}
        icon={
          <img
            className="icon"
            src={isMenuItemActive(MenuItemKey.pointGive) ? pointGiveActive : pointGive}
            alt="icon"
          />
        }
        title={
          <label>
            Kampanyalar
            <img className="ms-2" src={isMenuItemActive(MenuItemKey.pointGive) ? downPrimary : down} alt="menu"/>
          </label>
        }
      >
        <Menu.Item key="create-campaign">
          <NavLink
            to={router.CAMPAIGN_CREATE} className="sub"
            onClick={() => FirebaseService.logEvent(FirebaseService.nav_bar_quick_create_campaign_clicked)}
          >
            <img className="icon" src={pointGiveSubCreate} alt="create"/>
            Kampanya Oluştur
          </NavLink>
        </Menu.Item>
        <Menu.Divider/>
        <Menu.Item key="campaigns">
          <NavLink
            to={router.CAMPAIGNS} className="sub"
            onClick={() => FirebaseService.logEvent(FirebaseService.nav_bar_campaign_panel_clicked)}
          >
            <img className="icon" src={pointGiveSubHistory} alt="history"/>
            Kampanya Geçmişi
          </NavLink>
        </Menu.Item>
      </SubMenu>

      <Menu.Item
        key={MenuItemKey.rewardMsisdn}
        className={`menu-item ${isMenuItemActive(MenuItemKey.rewardMsisdn) && 'active'}`}
        icon={
          <img
            className="icon"
            src={isMenuItemActive(MenuItemKey.rewardMsisdn) ? fastUploadActive : fastUpload}
            alt="icon"
          />
        }
      >
        <NavLink
          to={router.REWARD_MSISDN}
          onClick={() => FirebaseService.logEvent(FirebaseService.nav_bar_quick_load_clicked)}
        >
          Hızlı Yükle
        </NavLink>
      </Menu.Item>

      <Menu.Item
        key={MenuItemKey.profile}
        className={`menu-item empty`}
        icon={
          <img
            className="icon"
            src={isMenuItemActive(MenuItemKey.profile) ? profileActive : profile}
            alt="icon"
          />
        }
        onClick={() => history.push(router.PROFILE)}
      />

      <Menu.Item
        key={MenuItemKey.logout}
        className={`menu-item empty`}
        icon={
          <img
            className="icon"
            src={logout}
            alt="icon"
          />
        }
        onClick={() => setLogoutModalVisible(true)}
      />

      {
        isLogoutModalVisible &&
        <LogoutModal
          callback={isApproved => handleLogoutModal(isApproved)}
        />
      }
    </Menu>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      setMe,
    },
    dispatch
  );
};
const mapStateToProps = (store: IStore) => {
  return {
    pathname: store.setPathname.pathname,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);
