import {Form, Input, Select} from "antd";
import React, {ReactNode, useState} from 'react';
import right from "../../../../assets/images/chevron-right.svg";
import AppButton from "../../../../components/Buttons/AppButton/AppButton";
import {FormValuesOrderInvoice} from "../../../../core/models/custom/formValuesOrderInvoice";
import {MeDto} from "../../../../core/models/dtos/me.dto";
import {Constants} from "../../../../core/utilities/constants";
import {Helpers} from "../../../../core/utilities/helpers";
import "./OrderInvoice.scss";

const {Option} = Select;

interface IProps {
  me?: MeDto;
  callbackChange: () => void;
  callbackContinue: (values: FormValuesOrderInvoice) => void;
}

const OrderInvoice = (props: IProps) => {
  const [countryISOCode, setCountryISOCode] = useState(Constants.countryISOCodeTR);
  const [form] = Form.useForm();

  function handleFormChange(): void {
    props.callbackChange();
  }

  function handleFinish(values: FormValuesOrderInvoice) {
    props.callbackContinue(values);
  }

  function getContinueButtonChild(): ReactNode {
    return (
      <div className="d-flex align-items-center">
        <span>Devam Et</span>
        &nbsp;&nbsp;
        <img src={right} alt="right"/>
      </div>
    );
  }

  function handleCountryChange(value: string) {
    setCountryISOCode(value);
    form.setFieldsValue({billingCity: ''});
  }

  return (
    <Form
      id="order-invoice"
      className="form"
      form={form}
      initialValues={{
        billingContactName: props.me?.fullName,
        billingCountry: props.me?.company.billingCountry ?? Constants.countryISOCodeTR,
        billingCity: props.me?.company.billingCity,
        billingAddress: props.me?.company.billingAddress,
      }}
      onChange={handleFormChange}
      onFinish={handleFinish}
    >
      <Form.Item
        name="billingContactName"
        className="form-item"
        rules={[{required: true, message: Constants.inputErrorRequiredField}]}
      >
        <Input
          className="form-input"
          placeholder="Ad Soyad"
        />
      </Form.Item>

      <Form.Item
        name="billingAddress"
        className="form-item"
        rules={[{required: true, message: Constants.inputErrorRequiredField}]}
      >
        <Input
          className="form-input"
          placeholder="Adres"
        />
      </Form.Item>

      <div className="input-row">
        <Form.Item
          name="billingCountry"
          className="form-item"
          rules={[{required: true, message: Constants.inputErrorRequiredField}]}
        >
          <Select
            className="form-input select"
            onChange={value => handleCountryChange(value as string)}
          >
            {Helpers.getAllCountries().map((country) => {
              return (
                <Option key={country.name} value={country.isoCode}>
                  {country.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          name="billingCity"
          className="form-item"
          rules={[{required: true, message: Constants.inputErrorRequiredField}]}
        >
          {
            countryISOCode === 'TR'
              ?
              <Select
                key={`city-for-${countryISOCode}`}
                className="form-input select"
              >
                {Helpers.getCitiesOfTurkey().map((city) => {
                  return (
                    <Option key={city} value={city}>
                      {city}
                    </Option>
                  );
                })}
              </Select>
              :
              <Input className="form-input"/>
          }
        </Form.Item>
      </div>

      <AppButton
        content={getContinueButtonChild()}
        htmlType="submit"
        onClick={() => {
        }}
      />
    </Form>
  );
}

export default OrderInvoice;
