import {Dispatch} from 'redux';
import {ErrorDto, getErrorDtoFromApiError} from '../../../models/dtos/error.dto';
import {OrderHistoryDto} from '../../../models/dtos/orderHistory.dto';
import {
  GET_ORDER_HISTORY_FAIL,
  GET_ORDER_HISTORY_RESET,
  GET_ORDER_HISTORY_START,
  GET_ORDER_HISTORY_SUCCESS,
} from './constants';
import {getOrderHistoryRequest} from './repository';
import {
  GetOrderHistoryFailAction,
  GetOrderHistoryResetAction,
  GetOrderHistoryStartAction,
  GetOrderHistorySuccessAction,
} from './types';

const getOrderHistoryStartAction = (): GetOrderHistoryStartAction => {
  return {
    type: GET_ORDER_HISTORY_START,
  };
};
const getOrderHistorySuccessAction = (payload: OrderHistoryDto[]): GetOrderHistorySuccessAction => {
  return {
    type: GET_ORDER_HISTORY_SUCCESS,
    payload: payload,
  };
};
const getOrderHistoryFailAction = (error: ErrorDto): GetOrderHistoryFailAction => {
  return {
    type: GET_ORDER_HISTORY_FAIL,
    error: error,
  };
};
const getOrderHistoryResetAction = (): GetOrderHistoryResetAction => {
  return {
    type: GET_ORDER_HISTORY_RESET,
  };
};

export const getOrderHistory = () => async (dispatch: Dispatch) => {
  try {
    dispatch(getOrderHistoryStartAction());
    const response = await getOrderHistoryRequest();
    dispatch(getOrderHistorySuccessAction(response));
  } catch (error) {
    dispatch(getOrderHistoryFailAction(getErrorDtoFromApiError(error)));
  }
};

export const getOrderHistoryReset = () => (dispatch: Dispatch) => {
  dispatch(getOrderHistoryResetAction());
};
