import {ApiKeyDto} from '../../../models/dtos/apiKey.dto';
import axios from '../../../utilities/axios';

export const getApiKeyRequest = async (id: number): Promise<ApiKeyDto> => {
  try {
    const response = await axios.get<ApiKeyDto>(
      `${process.env.REACT_APP_BASE_URL}/campaigns/${id}/api-key`,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
