import {LoadingOutlined} from "@ant-design/icons";
import {Modal} from "antd";
import React, {ReactNode} from 'react';
import checkmark from "../../../assets/images/checkmark.svg";
import icon from "../../../assets/images/modal-icon-campaign-approval.svg";
import {CampaignData} from "../../../core/models/custom/campaignData";
import {CampaignType} from "../../../core/models/enums/campaignType";
import FirebaseService from "../../../core/services/firebase.service";
import {UI} from "../../../core/utilities/ui";
import AppButton, {AppButtonType} from "../../Buttons/AppButton/AppButton";
import "./CampaignApprovalModal.scss";

interface IProps {
  data: CampaignData;
  isSubmitting: boolean;
  callbackOk: (data: CampaignData) => void;
  callbackCancel: () => void;
}

const CampaignApprovalModal = (props: IProps) => {

  function handleApprove(): void {
    switch (props.data.type) {
      case CampaignType.CODE:
        FirebaseService.logEvent(FirebaseService.approve_clicked_code);
        break;
      case CampaignType.LINK:
        FirebaseService.logEvent(FirebaseService.approve_clicked_link);
        break;
      case CampaignType.BULK:
        FirebaseService.logEvent(FirebaseService.approve_clicked_bulk);
        break;
      case CampaignType.API:
        FirebaseService.logEvent(FirebaseService.approve_clicked_api);
        break;
    }
    props.callbackOk(props.data);
  }

  function getType(): string {
    switch (props.data.type) {
      case CampaignType.CODE:
        return 'Kod ile yükle';
      case CampaignType.LINK:
        return 'Link ile yükle';
      case CampaignType.BULK:
        return 'Toplu yükleme';
      case CampaignType.API:
        return 'API ile yükle';
      default:
        alert(`Invalid campaign type: ${props.data.type}`);
        return '';
    }
  }

  function getAmount(): number {
    if (props.data.bulkFile) {
      return props.data.bulkFile.validLine;
    }
    return props.data.amount;
  }

  function getOkButtonContent(): ReactNode {
    return (
      <div className="d-flex align-items-center">
        <span>Onayla</span>
        &nbsp;&nbsp;
        <img src={checkmark} alt="checkmark"/>
      </div>
    );
  }

  return (
    <Modal
      className="campaign-approval-modal"
      maskStyle={UI.modalMaskStyle()}
      closable={false}
      footer={false}
      visible
      centered
      destroyOnClose
      onCancel={props.callbackCancel}
    >
      <div className="content">
        <img className="icon" src={icon} alt="icon"/>
        <label className="title">
          İşlemi onaylıyor musunuz?
        </label>
        <label className="text">
          {getAmount()}
          &nbsp;Adet&nbsp;
          {props.data.offer.name}
          &nbsp;ile,&nbsp;
          {getType()}
          &nbsp;yöntemini kullanarak kampanyanız oluşturulacak.
        </label>
        <label className="note">
          *Bu işlem geri alınamaz.
        </label>
        {
          props.isSubmitting
            ?
            <div className="loading-wrapper">
              <LoadingOutlined className="loading"/>
            </div>
            :
            <div className="button-wrapper">
              <AppButton
                type={AppButtonType.outline}
                content="Vazgeç"
                onClick={props.callbackCancel}
              />
              <AppButton
                content={getOkButtonContent()}
                onClick={handleApprove}
              />
            </div>
        }
      </div>
    </Modal>
  );
}

export default CampaignApprovalModal;
