import {
  REFRESH_FAIL,
  REFRESH_RESET,
  REFRESH_START,
  REFRESH_SUCCESS,
} from './constants';
import {RefreshActions, RefreshState} from './types';

const initialState: RefreshState = {
  loading: false,
  data: undefined,
  error: undefined,
};

const reducer = (
  state = initialState,
  action: RefreshActions,
): RefreshState => {
  switch (action.type) {
    case REFRESH_START:
      return {
        ...initialState,
        loading: true,
      };
    case REFRESH_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case REFRESH_FAIL:
     return {
        ...state,
        loading: false,
        error: action.error,
      };
    case REFRESH_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
