import {Button, Menu} from "antd";
import React, {ReactNode} from "react";
import {connect} from "react-redux";
import {NavLink} from "react-router-dom";
import {bindActionCreators, Dispatch} from "redux";
import contact from "../../../../assets/images/landing/header-contact-us.svg";
import drawer from "../../../../assets/images/landing/header-drawer.svg";
import user from "../../../../assets/images/landing/header-user.svg";
import logoMobile from "../../../../assets/images/landing/logo-mobile.svg";
import logo from "../../../../assets/images/landing/logo.svg";
import FirebaseKey from "../../../../components/FirebaseKey/FirebaseKey";
import {MeDto} from "../../../../core/models/dtos/me.dto";
import FirebaseService from "../../../../core/services/firebase.service";
import {history} from "../../../../core/utilities/history";
import {IStore} from "../../../../core/utilities/reducers";
import {router} from "../../../../core/utilities/router";
import "./LandingHeader.scss";

const {SubMenu} = Menu;

interface IProps {
  me?: MeDto;
  pathname: string;
  refGoal?: any;
  refUsage?: any;
  refIntegration?: any;
  refKgbApp?: any;
}

enum MenuItem {
  logo = 'logo',
  campaign = 'campaign',
  howToUse = 'howToUse',
  integration = 'integration',
  customer = 'customer',
}

const LandingHeader = (props: IProps) => {

  function isRefs(): boolean {
    return !!props.refGoal && !!props.refUsage && !!props.refIntegration && !!props.refKgbApp;
  }

  function handleClickMenuItem(item: MenuItem): void {
    switch (item) {
      case MenuItem.campaign:
        FirebaseService.logEvent(FirebaseService.user_count_campaign_goals_clicked);
        props.refGoal.current?.scrollIntoView({behavior: 'smooth', block: 'start'});
        break;
      case MenuItem.howToUse:
        FirebaseService.logEvent(FirebaseService.user_count_landing_how_to_use_clicked);
        props.refUsage.current?.scrollIntoView({behavior: 'smooth', block: 'start'});
        break;
      case MenuItem.integration:
        FirebaseService.logEvent(FirebaseService.user_count_landing_integration_clicked);
        props.refIntegration.current?.scrollIntoView({behavior: 'smooth', block: 'start'});
        break;
      case MenuItem.customer:
        FirebaseService.logEvent(FirebaseService.user_count_customer_experience_clicked);
        props.refKgbApp.current?.scrollIntoView({behavior: 'smooth', block: 'start'});
        break;
    }
  }

  function renderMenuItems(): ReactNode {
    return (
      <React.Fragment>
        <Menu.Item
          key={MenuItem.campaign} className="menu-item"
          onClick={() => handleClickMenuItem(MenuItem.campaign)}
        >
          <FirebaseKey firebaseKey={FirebaseService.landing_header_campaign_tab}/>
        </Menu.Item>
        <Menu.Item
          key={MenuItem.howToUse} className="menu-item"
          onClick={() => handleClickMenuItem(MenuItem.howToUse)}
        >
          <FirebaseKey firebaseKey={FirebaseService.landing_header_how_to_use_tab}/>
        </Menu.Item>
        <Menu.Item
          key={MenuItem.integration} className="menu-item"
          onClick={() => handleClickMenuItem(MenuItem.integration)}
        >
          <FirebaseKey firebaseKey={FirebaseService.landing_header_integration_tab}/>
        </Menu.Item>
        <Menu.Item
          key={MenuItem.customer} className="menu-item"
          onClick={() => handleClickMenuItem(MenuItem.customer)}
        >
          <FirebaseKey firebaseKey={FirebaseService.landing_header_customer_tab}/>
        </Menu.Item>
      </React.Fragment>
    );
  }

  function renderDrawer(): ReactNode {
    return (
      <SubMenu
        key="drawer"
        title={<img src={drawer} alt="drawer"/>}
        popupClassName="drawer-popup"
      >
        {isRefs() && renderMenuItems()}
        <Menu.Item key="contact">
          <NavLink to={router.CONTACT_US}>
            Bize Ulaş
          </NavLink>
        </Menu.Item>
        {
          !props.me &&
          <Menu.Item key="login" className="menu-item button ms-auto">
            <Button className="landing-button outline">
              <NavLink to={router.AUTH}>
                <FirebaseKey firebaseKey={FirebaseService.landing_header_login}/>
              </NavLink>
            </Button>
          </Menu.Item>
        }
      </SubMenu>
    );
  }

  function renderHeaderMobile(): ReactNode {
    return (
      <Menu
        id="landing-header"
        className="menu d-flex d-xl-none"
        mode="horizontal"
        selectedKeys={[]}
      >
        <Menu.Item
          key="logo"
          className="menu-item"
          icon={<img src={logoMobile} alt="logo"/>}
          onClick={() => history.push(router.LANDING)}
        />
        {
          props.me
            ?
            <Menu.Item key="campaigns" className="menu-item button">
              <Button
                className="landing-button outline user"
                onClick={() => FirebaseService.logEvent(FirebaseService.user_count_control_panel_clicked)}
              >
                <NavLink to={router.CAMPAIGNS}>
                  <img src={user} alt="user"/>
                </NavLink>
              </Button>
            </Menu.Item>
            :
            <Menu.Item key="signup" className="menu-item button">
              <Button
                className="landing-button"
                onClick={() => FirebaseService.logEvent(FirebaseService.count_sign_up_clicked)}
              >
                <NavLink to={router.AUTH}>
                  <FirebaseKey firebaseKey={FirebaseService.landing_header_signup}/>
                </NavLink>
              </Button>
            </Menu.Item>
        }
        {
          props.pathname !== router.CONTACT_US &&
          renderDrawer()
        }
      </Menu>
    );
  }

  function renderHeaderDesktop(): ReactNode {
    return (
      <Menu
        id="landing-header"
        className="menu d-none d-xl-flex"
        mode="horizontal"
        selectedKeys={[]}
      >
        <Menu.Item
          key="logo"
          className="menu-item"
          icon={<img src={logo} alt="logo"/>}
          onClick={() => history.push(router.LANDING)}
        />
        {isRefs() && renderMenuItems()}
        {
          props.me
            ?
            <Menu.Item key="campaigns" className="menu-item button ms-auto">
              <Button
                className="landing-button"
                onClick={() => FirebaseService.logEvent(FirebaseService.user_count_control_panel_clicked)}
              >
                <NavLink to={router.CAMPAIGNS}>
                  <FirebaseKey firebaseKey={FirebaseService.landing_header_campaigns_tab}/>
                </NavLink>
              </Button>
            </Menu.Item>
            :
            <React.Fragment>
              <Menu.Item key="login" className="menu-item button ms-auto">
                <Button
                  className="landing-button outline"
                  onClick={() => FirebaseService.logEvent(FirebaseService.count_login_clicked)}
                >
                  <NavLink to={router.AUTH}>
                    <FirebaseKey firebaseKey={FirebaseService.landing_header_login}/>
                  </NavLink>
                </Button>
              </Menu.Item>
              <Menu.Item key="signup" className="menu-item button">
                <Button
                  className="landing-button"
                  onClick={() => FirebaseService.logEvent(FirebaseService.count_sign_up_clicked)}
                >
                  <NavLink to={router.AUTH}>
                    <FirebaseKey firebaseKey={FirebaseService.landing_header_signup}/>
                  </NavLink>
                </Button>
              </Menu.Item>
            </React.Fragment>
        }

        {
          props.pathname !== router.CONTACT_US &&
          <Menu.Item key="contact" className="menu-item">
            <NavLink to={router.CONTACT_US}>
              <img className="contact" src={contact} alt="contact us"/>
            </NavLink>
          </Menu.Item>
        }
      </Menu>
    );
  }

  return (
    <React.Fragment>
      {renderHeaderMobile()}
      {renderHeaderDesktop()}
    </React.Fragment>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {},
    dispatch
  );
};
const mapStateToProps = (store: IStore) => {
  return {
    me: store.setMe.me,
    pathname: store.setPathname.pathname,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingHeader);
