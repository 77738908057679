import React, {Component, ReactNode} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import add from "../../../assets/images/add.svg";
import AccountBalance from "../../../components/AccountBalance/AccountBalance";
import CreateCampaignCard from "../../../components/CreateCampaignCard/CreateCampaignCard";
import EmptyStateCard from "../../../components/EmptyStateCard/EmptyStateCard";
import Loading from "../../../components/Loading/Loading";
import PageTitle from "../../../components/PageTitle/PageTitle";
import FirebaseService from "../../../core/services/firebase.service";
import {getUsage} from "../../../core/services/offerService/getUsage/actions";
import {GetUsageState} from "../../../core/services/offerService/getUsage/types";
import {Helpers} from "../../../core/utilities/helpers";
import {history} from "../../../core/utilities/history";
import {IStore} from '../../../core/utilities/reducers';
import {router} from "../../../core/utilities/router";
import './HomePage.scss';

interface IProps {
  getUsageState: GetUsageState;
  getUsage: () => void;
}

class HomePage extends Component<IProps> {

  componentDidMount() {
    this.props.getUsage();
    FirebaseService.logEvent(FirebaseService.landing_view_total);
  }

  // Hemen MetaByte alın ve 2 adımda m&uuml;şterilerinize mobil promosyon hediye edin

  private getEmptyUsageText(): string {
    return '<div class="empty-text">' +
      '<p>MetaByte Business ile m&uuml;şterileriniz i&ccedil;in sadakat kampanyası oluşturmanız &ccedil;ok kolay! Sadık m&uuml;şteriler, kazan&ccedil;lı markalar!</p>\n' +
      // '\n' +
      // '<p><strong>1) MetaByte satın alın</strong></p>\n' +
      // '\n' +
      // '<p>G&uuml;nl&uuml;k 1 GB değerinde olan 1000 MetaByte&#39;ları m&uuml;şterilerinize hediye edebilirsiniz. Tek yapmanız gereken ihtiyacınız olan miktarı belirleyip satın alma işlemini ger&ccedil;ekleştirmek! M&uuml;şterileriniz MetaByte&#39;larını diledikleri zaman G&uuml;nl&uuml;k 1 GB&#39;a &ccedil;evirebilir.</p>\n' +
      // '\n' +
      // '<p>İşte size 15 yıllık tecr&uuml;bemiz ile hazırladığımız bir ipucu; <strong>Kim GB İster</strong> ile &ccedil;alışan markaların online sadakat kampanyaları diğer kampanyalarına oranla %70 daha fazla ilgi g&ouml;r&uuml;yor!</p>\n' +
      // '\n' +
      // '<p><strong>2) MetaByte&#39;larınızı hediye etmek i&ccedil;in y&ouml;nteminizi se&ccedil;in</strong></p>\n' +
      // '\n' +
      // '<p>M&uuml;şterilerinize, kendi sisteminize en uygun y&ouml;ntemi se&ccedil;erek mobil promosyon hediye edebilirsiniz. Bu sayede minimum efor ile maksimum etkiye ulaşırsınız!</p>\n' +
      '</div>';
  }

  private renderContent(): ReactNode {
    if (this.props.getUsageState.loading) {
      return <Loading/>
    } else if (this.props.getUsageState.data) {
      if (this.props.getUsageState.data.length === 0) {
        return (
          <EmptyStateCard
            title="Hoş Geldin!"
            text={this.getEmptyUsageText()}
            button={{
              child: (
                <div className="d-flex align-items-center">
                  <img src={add} alt="add"/>
                  &nbsp;&nbsp;
                  <span>MetaByte Al</span>
                </div>
              ),
              callback: () => history.push(router.ORDER)
            }}
          />
        );
      }
      const balance = Helpers.getRemainingPoint1000(this.props.getUsageState.data);
      if (balance) {
        return (
          <CreateCampaignCard
            callback={() => history.push(router.CAMPAIGN_CREATE)}
          />
        );
      }
      return (
        <EmptyStateCard
          title="Hoş Geldin!"
          text="Hediye edebileceğin hiç MetaByte’ın yok. Hemen MetaByte al, hediye etmeye başla! "
          button={{
            child: (
              <div className="d-flex align-items-center">
                <img src={add} alt="add"/>
                &nbsp;&nbsp;
                <span>MetaByte Al</span>
              </div>
            ),
            callback: () => history.push(router.ORDER)
          }}
        />
      );
    }
  }

  render() {
    return (
      <div id="home-page" className="page">
        <div className="page-content">
          <PageTitle text="Ana Sayfa"/>
          <AccountBalance/>
          {this.renderContent()}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({
      getUsage,
    },
    dispatch
  );
};
const mapStateToProps = (store: IStore) => {
  return {
    getUsageState: store.getUsage,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
