import React, {Component, ReactNode} from 'react';
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import right from "../../../assets/images/chevron-right-black.svg";
import Loading from "../../../components/Loading/Loading";
import {getDetails} from "../../../core/services/campaignService/getDetails/actions";
import {GetDetailsState} from "../../../core/services/campaignService/getDetails/types";
import {history} from "../../../core/utilities/history";
import {IStore} from "../../../core/utilities/reducers";
import {router} from "../../../core/utilities/router";
import "./CampaignDetailPage.scss";
import CampaignDetailTable from "./CampaignDetailTable/CampaignDetailTable";

interface IProps {
  match: any
  getDetailsState: GetDetailsState;
  getDetails: (id: number) => void;
}

class CampaignDetailPage extends Component<IProps> {

  private readonly campaignId;

  constructor(props: IProps) {
    super(props);
    this.campaignId = +this.props.match.params.campaignId;
  }

  componentDidMount() {
    if (!isNaN(this.campaignId)) {
      this.props.getDetails(this.campaignId);
    } else {
      history.push(router.CAMPAIGNS);
    }
  }

  private renderContent(): ReactNode {
    if (this.props.getDetailsState.loading) {
      return <Loading/>
    } else if (this.props.getDetailsState.data) {
      const data = this.props.getDetailsState.data;
      return (
        <React.Fragment>
          <div className="title">
            <label
              className="title-main"
              onClick={() => history.push(router.CAMPAIGNS)}
            >
              Kampanya Geçmişi
            </label>
            <img src={right} alt="arrow"/>
            <label className="title-sub">{data.name}</label>
          </div>
          <CampaignDetailTable
            data={data}
          />
        </React.Fragment>
      );
    }
  }

  render() {
    return (
      <div id="campaign-detail-page" className="page">
        <div className="page-content">
          {this.renderContent()}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getDetails,
    },
    dispatch
  );
};
const mapStateToProps = (store: IStore) => {
  return {
    getDetailsState: store.getDetails,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignDetailPage);
