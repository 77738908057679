import {TRY_BULK_START, TRY_BULK_SUCCESS, TRY_BULK_FAIL, TRY_BULK_RESET} from './constants';
import {TryBulkActions, TryBulkState} from './types';

const initialState: TryBulkState = {
  loading: false,
  data: undefined,
  error: undefined,
};

const reducer = (
  state = initialState,
  action: TryBulkActions,
): TryBulkState => {
  switch (action.type) {
    case TRY_BULK_START:
      return {
        ...initialState,
        loading: true,
      };
    case TRY_BULK_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case TRY_BULK_FAIL:
     return {
        ...state,
        loading: false,
        error: action.error,
      };
    case TRY_BULK_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
