import axios from '../../../utilities/axios';

export const deleteSideUserRequest = async (id: number): Promise<void> => {
  try {
    const response = await axios.delete<void>(
      `${process.env.REACT_APP_BASE_URL}/users/side-user/${id}`,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
