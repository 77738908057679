import {Dispatch} from 'redux';
import {ErrorDto, getErrorDtoFromApiError} from '../../../models/dtos/error.dto';
import {getMeRequest} from "../../userService/getMe/repository";
import {DELETE_CARD_FAIL, DELETE_CARD_RESET, DELETE_CARD_START, DELETE_CARD_SUCCESS,} from './constants';
import {deleteCardRequest} from './repository';
import {DeleteCardFailAction, DeleteCardResetAction, DeleteCardStartAction, DeleteCardSuccessAction,} from './types';

const deleteCardStartAction = (): DeleteCardStartAction => {
  return {
    type: DELETE_CARD_START,
  };
};
const deleteCardSuccessAction = (payload: boolean): DeleteCardSuccessAction => {
  return {
    type: DELETE_CARD_SUCCESS,
    payload: payload,
  };
};
const deleteCardFailAction = (error: ErrorDto): DeleteCardFailAction => {
  return {
    type: DELETE_CARD_FAIL,
    error: error,
  };
};
const deleteCardResetAction = (): DeleteCardResetAction => {
  return {
    type: DELETE_CARD_RESET,
  };
};

export const deleteCard = () => async (dispatch: Dispatch) => {
  try {
    dispatch(deleteCardStartAction());
    await deleteCardRequest();
    await getMeRequest(dispatch);
    dispatch(deleteCardSuccessAction(true));
  } catch (error) {
    dispatch(deleteCardFailAction(getErrorDtoFromApiError(error)));
  }
};

export const deleteCardReset = () => (dispatch: Dispatch) => {
  dispatch(deleteCardResetAction());
};
