import { Modal } from "antd";
import React, { useState } from "react";
import { MoneyTransferOrderRequest } from "../../../core/models/requests/moneyTransferOrder.request";
import { UI } from "../../../core/utilities/ui";
import FirebaseKey from "../../FirebaseKey/FirebaseKey";
import CompanyForm from "./CompanyForm/CompanyForm";
import "./PayWithEftModal.scss";

interface IProps {
  amountValue?: number;
  visible: boolean;
  isSubmitting: boolean;
  callback: (request: MoneyTransferOrderRequest) => void;
  onCancel: () => void;
}


const PayWithEftModal = (props: IProps) => {

  const progressSteps = ["Bilgiler", "Havale", "Onay"];

  const [activeTab, setActiveTab] = useState("first");

  return (
    <Modal
      className="pay-with-saved-card-approval-modal"
      maskStyle={UI.modalMaskStyle()}
      closable={false}
      footer={false}
      visible={props.visible}
      centered
      destroyOnClose
      onCancel={() => props.onCancel()}
    >
      <div className="content">
        <ul className={`progress-bar progress-${activeTab}`}>
          {
            progressSteps.map((steps, index) => {

              return (
                <li key={`${index}-progress-steps`} className={`progress-${index}`}>{steps}</li>
              );
            })
          }
        </ul>
        {
          activeTab === "first" ?
            <React.Fragment>
              <FirebaseKey className="title" firebaseKey="havale_popup_title" />
              <FirebaseKey className="text" firebaseKey="havale_popup_desc" />
            </React.Fragment>
            :
            <React.Fragment>
              <FirebaseKey className="title" firebaseKey="havale_popup_title" />
              <FirebaseKey className="text" firebaseKey="havale_popup_step2_desc" />
            </React.Fragment>
        }
        <CompanyForm
          amountValue={props.amountValue}
          callbackSubmit={props.callback}
          isSubmitting={props.isSubmitting}
          onCancel={() => props.onCancel()}
          callbackProgress={(activePage: string) => setActiveTab(activePage)}
        />

      </div>
    </Modal>
  );
};

export default PayWithEftModal;
