import axios from '../../../utilities/axios';
import {FakeBulkSmsRequest} from '../../../models/requests/fakeBulkSms.request';

export const tryBulkRequest = async (request: FakeBulkSmsRequest): Promise<void> => {
  try {
    const response = await axios.post<void>(
      `${process.env.REACT_APP_BASE_URL}/misc/fake/bulk-sms`,
      {...request},
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
