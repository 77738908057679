import parse from "html-react-parser";
import React from 'react';
import {ButtonProperties} from "../../core/models/custom/buttonProperties";
import AppButton from "../Buttons/AppButton/AppButton";
import "./EmptyStateCard.scss";

interface IProps {
  title: string;
  text: string;
  button?: ButtonProperties;
}

const EmptyStateCard = (props: IProps) => {

  return (
    <div id="empty-state-card">
      <h1 className="title">{parse(props.title)}</h1>
      <label className="text">{parse(props.text)}</label>
      {
        props.button &&
        <AppButton
          content={props.button.child}
          onClick={props.button.callback}
        />
      }
    </div>
  );
}

export default EmptyStateCard;
