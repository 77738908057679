import { Dispatch } from 'redux';
import { BulkRewardMsisdnDto } from '../../../models/dtos/bulkRewardMsisdn.dto';
import { ErrorDto, getErrorDtoFromApiError } from '../../../models/dtos/error.dto';
import { BulkRewardMsisdnRequest } from '../../../models/requests/bulkRewardMsisdn.request';
import { getUsageSuccessAction } from "../../offerService/getUsage/actions";
import { getUsageRequest } from "../../offerService/getUsage/repository";
import {
  BULK_REWARD_MSISDN_FAIL,
  BULK_REWARD_MSISDN_RESET,
  BULK_REWARD_MSISDN_START,
  BULK_REWARD_MSISDN_SUCCESS
} from './constants';
import { bulkRewardMsisdnRequest } from './repository';
import {
  BulkRewardMsisdnFailAction,
  BulkRewardMsisdnResetAction,
  BulkRewardMsisdnStartAction,
  BulkRewardMsisdnSuccessAction
} from './types';

const bulkRewardMsisdnStartAction = (): BulkRewardMsisdnStartAction => {
  return {
    type: BULK_REWARD_MSISDN_START,
  };
};
const bulkRewardMsisdnSuccessAction = (payload: BulkRewardMsisdnDto): BulkRewardMsisdnSuccessAction => {
  return {
    type: BULK_REWARD_MSISDN_SUCCESS,
    payload: payload,
  };
};
const bulkRewardMsisdnFailAction = (error: ErrorDto): BulkRewardMsisdnFailAction => {
  return {
    type: BULK_REWARD_MSISDN_FAIL,
    error: error,
  };
};
const bulkRewardMsisdnResetAction = (): BulkRewardMsisdnResetAction => {
  return {
    type: BULK_REWARD_MSISDN_RESET,
  };
};

export const bulkRewardMsisdn = (request: BulkRewardMsisdnRequest) => async (dispatch: Dispatch) => {
  try {
    dispatch(bulkRewardMsisdnStartAction());
    const response = await bulkRewardMsisdnRequest(request);
    const usage = await getUsageRequest();
    dispatch(getUsageSuccessAction(usage));
    dispatch(bulkRewardMsisdnSuccessAction(response));
  } catch (error) {
    dispatch(bulkRewardMsisdnFailAction(getErrorDtoFromApiError(error)));
  }
};

export const bulkRewardMsisdnReset = () => (dispatch: Dispatch) => {
  dispatch(bulkRewardMsisdnResetAction());
};
