import React, {ReactNode, useState} from 'react';
import './FileUpload.scss';
import {LocalStorage} from '../../core/utilities/localStorage';
import axios from 'axios';
import {Upload} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';

interface IProps {
  key?: string;
  child: ReactNode;
  callbackUpload: (url: string) => void;
  callbackFail: (error: any) => void;
}

const FileUpload = (props: IProps) => {
  const [isUploading, setUploading] = useState(false);

  async function uploadFile(options: any): Promise<void> {
    setUploading(true);
    const {onSuccess, file} = options;
    const formData = new FormData();
    const config = {
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${LocalStorage.get(LocalStorage.token)}`,
      },
    };
    formData.append('file', file);
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/misc/upload`,
      undefined,
      config
    );
    onSuccess(res.data);
    const blob = new Blob([file], {type: file.type});
    await handleUploadS3(res.data.uploadUrl, res.data.fileUrl, blob);
  }

  async function handleUploadS3(
    uploadUrl: string,
    fileUrl: string,
    blob: Blob
  ): Promise<void> {
    try {
      // DO NOT use axios. It adds Authorization (Bearer token) that will cause error;
      // Only one auth mechanism allowed; only the X-Amz-Algorithm query parameter,
      // Signature query string parameter or the Authorization header should be specified

      // const response = await axios.put(uploadUrl, blob,{
      //   headers: {"Content-Type": blob.type}
      // });

      await fetch(uploadUrl, {
        method: 'PUT',
        body: blob,
        headers: {
          'Content-Type': blob.type,
        },
      });
      setUploading(false);
      props.callbackUpload(fileUrl);
    } catch (error) {
      props.callbackFail(error);
    }
  }

  return (
    <Upload
      key={props.key ?? 'file-upload'}
      className="file-upload"
      multiple={false}
      showUploadList={true}
      customRequest={uploadFile}
    >
      {isUploading ? (
        <LoadingOutlined style={{fontSize: '24px'}} />
      ) : (
        <div>{props.child}</div>
      )}
    </Upload>
  );
};

export default FileUpload;
