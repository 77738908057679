import React from 'react';
import "./PageTitle.scss";

interface IProps {
  className?: string;
  text: string;
}

const PageTitle = (props: IProps) => {
  return (
    <h1
      id="page-title"
      className={props.className}
    >
      {props.text}
    </h1>
  );
}

export default PageTitle;
