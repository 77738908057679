import {Dispatch} from 'redux';
import {ErrorDto, getCustomPaymentError, getErrorDtoFromApiError} from '../../../models/dtos/error.dto';
import {SyncIdDto} from "../../../models/dtos/syncId.dto";
import {PaymentState} from "../../../models/enums/paymentState";
import {CreateOrderAndPayWithCardRequest} from '../../../models/requests/createOrderAndPayWithCard.request';
import {Constants} from "../../../utilities/constants";
import {Helpers} from "../../../utilities/helpers";
import {getChargeStatusRequest} from "../../paymentService/getChargeStatus/repository";
import {createOrderRequest} from "../createOrder/repository";
import {payWithCardRequest} from "../payWithCard/repository";
import {
  CREATE_ORDER_AND_PAY_WITH_CARD_FAIL,
  CREATE_ORDER_AND_PAY_WITH_CARD_RESET,
  CREATE_ORDER_AND_PAY_WITH_CARD_START,
  CREATE_ORDER_AND_PAY_WITH_CARD_SUCCESS,
} from './constants';
import {
  CreateOrderAndPayWithCardFailAction,
  CreateOrderAndPayWithCardResetAction,
  CreateOrderAndPayWithCardStartAction,
  CreateOrderAndPayWithCardSuccessAction,
} from './types';

const createOrderAndPayWithCardStartAction = (): CreateOrderAndPayWithCardStartAction => {
  return {
    type: CREATE_ORDER_AND_PAY_WITH_CARD_START,
  };
};
const createOrderAndPayWithCardSuccessAction = (payload: SyncIdDto): CreateOrderAndPayWithCardSuccessAction => {
  return {
    type: CREATE_ORDER_AND_PAY_WITH_CARD_SUCCESS,
    payload: payload,
  };
};
const createOrderAndPayWithCardFailAction = (error: ErrorDto): CreateOrderAndPayWithCardFailAction => {
  return {
    type: CREATE_ORDER_AND_PAY_WITH_CARD_FAIL,
    error: error,
  };
};
const createOrderAndPayWithCardResetAction = (): CreateOrderAndPayWithCardResetAction => {
  return {
    type: CREATE_ORDER_AND_PAY_WITH_CARD_RESET,
  };
};

export const createOrderAndPayWithCard = (request: CreateOrderAndPayWithCardRequest) => async (dispatch: Dispatch) => {
  try {
    dispatch(createOrderAndPayWithCardStartAction());
    const order = await createOrderRequest(request.orderRequest);
    const payWithCard = await payWithCardRequest(order.id, request.payWithCardRequest);
    /** Same logic payWithCard */
    while (true) {
      const status = await getChargeStatusRequest(payWithCard.syncId);
      if (status.state === PaymentState.IN_PROGRESS) {
        await Helpers.wait(Constants.paymentStatusCheckIntervalMS);
      } else {
        switch (status.state) {
          case PaymentState.FAILED:
            const error = getCustomPaymentError(status.description);
            dispatch(createOrderAndPayWithCardFailAction(error));
            break;
          default:
            dispatch(createOrderAndPayWithCardSuccessAction({syncId: payWithCard.syncId}));
        }
        break;
      }
    }
  } catch (error) {
    dispatch(createOrderAndPayWithCardFailAction(getErrorDtoFromApiError(error)));
  }
};

export const createOrderAndPayWithCardReset = () => (dispatch: Dispatch) => {
  dispatch(createOrderAndPayWithCardResetAction());
};
