export interface ErrorDto {
  status: number;
  path: string;
  message: string;
}

export const ErrorGeneric: ErrorDto = {
  status: 500,
  path: '',
  message: 'Something went wrong',
};

export const getErrorDtoFromApiError = (error: any): ErrorDto => {
  if (!error.response || !error.response.data) {
    return ErrorGeneric;
  }
  return error.response.data;
};

export const customBulkRewardMsisdnErrorPath = 'customBulkRewardMsisdnError';
export const getCustomBulkRewardMsisdnError = (failedTotal: number): ErrorDto => {
  return {
    status: 400,
    path: customBulkRewardMsisdnErrorPath,
    message: failedTotal.toString(),
  }
}

export const customPaymentErrorPath = 'customPaymentError';
export const getCustomPaymentError = (message: string): ErrorDto => {
  return {
    status: 400,
    path: customPaymentErrorPath,
    message: message,
  }
}
