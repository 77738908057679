import {Dispatch} from 'redux';
import {SET_APP_LOADING} from './constants';
import {SetAppLoadingAction} from './types';

const setAppLoadingAction = (payload: boolean): SetAppLoadingAction => {
  return {
    type: SET_APP_LOADING,
    payload: payload,
  };
};

export const setAppLoading = (isLoading: boolean) => async (dispatch: Dispatch) => {
  dispatch(setAppLoadingAction(isLoading));
};
