export class Constants {
  public static readonly dateFormatUI: string = 'DD MMMM yyyy';
  public static readonly dateFormatDB: string = 'yyyy-MM-DD';
  public static readonly currency: string = 'TL';
  public static readonly countryISOCodeTR = 'TR';
  public static readonly countryNameTR = 'Türkiye';

  public static readonly inputErrorRequiredField = 'Bu alan boş olamaz';
  public static readonly inputErrorFileUpload = 'Dosya yüklenirken bir hata oluştu.';

  public static readonly vposResultSuccess: string = 'success';
  public static readonly vposResultFailed: string = 'failed';

  public static readonly paymentStatusCheckIntervalMS: number = 2000;

  public static readonly sideUserMaxLimit: number = 5;
  public static readonly msisdnLength: number = 10;
  public static readonly otpLength: number = 6;

  public static readonly awsBucketKurumsalKgb: string = 'https://static.4play.com.tr/kurumsal-kimgbister';
  public static readonly linkApiDocumentation: string = `${Constants.awsBucketKurumsalKgb}/Kurumsal_Kim_GB_Ister_Api.pdf`;
  public static readonly linkKullaniciTaahhutnamesi: string = 'https://static.4play.com.tr/kurumsal-kimgbister/Metabyte_Business_Sozlesmeler.pdf';
  public static readonly linkKullaniciSozlesmesi: string = 'https://static.4play.com.tr/kurumsal-kimgbister/Kim_GB_Ister_Kullanici_Sozlesmesi.pdf';
  public static readonly linkAydinlatmaMetni: string = 'https://static.4play.com.tr/kurumsal-kimgbister/MetaByte-Business-Aydinlatma-Metni-.pdf';
  public static readonly linkPrivacyAndDataPolicy: string = 'https://static.4play.com.tr/kurumsal-kimgbister/4P_Gizlilik_ve_Veri_Politikasi.pdf';
  public static readonly link4P: string = 'https://www.4play.com.tr';
  public static readonly link4PMail: string = 'info@kurumsal.kimgbister.com';
  public static readonly linkAppStore: string = 'https://apps.apple.com/tr/app/kim-gb-i-ster/id1552759438?l';
  public static readonly linkGooglePlay: string = 'https://play.google.com/store/apps/details?id=com.solidict.catalog4p';

  public static readonly videoUrlMain: string = `${Constants.awsBucketKurumsalKgb}/kgbLanding.mp4`;
  public static readonly videoUrlApi: string = `${Constants.awsBucketKurumsalKgb}/kgbApiYukle.mp4`;
  public static readonly videoUrlCode: string = `${Constants.awsBucketKurumsalKgb}/kgbKodYukle.mp4`;
  public static readonly videoUrlLink: string = `${Constants.awsBucketKurumsalKgb}/kgbLinkYukle.mp4`;
  public static readonly videoUrlBulk: string = `${Constants.awsBucketKurumsalKgb}/kgbTopluYukle.mp4`;
}
