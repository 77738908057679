import {LoadingOutlined} from "@ant-design/icons";
import {Modal} from "antd";
import React, {ReactNode} from 'react';
import checkmark from "../../../assets/images/checkmark.svg";
import icon from "../../../assets/images/modal-icon-reward-msisdn-approval.svg";
import {RewardMsisdnApprovalData} from "../../../core/models/custom/rewardMsisdnApprovalData";
import {UI} from "../../../core/utilities/ui";
import AppButton, {AppButtonType} from "../../Buttons/AppButton/AppButton";
import "./RewardMsisdnApprovalModal.scss";

interface IProps {
  data: RewardMsisdnApprovalData;
  isSubmitting: boolean;
  callbackOk: (data: RewardMsisdnApprovalData) => void;
  callbackCancel: () => void;
}

const RewardMsisdnApprovalModal = (props: IProps) => {

  function getOkButtonContent(): ReactNode {
    return (
      <div className="d-flex align-items-center">
        <span>Onayla</span>
        &nbsp;&nbsp;
        <img src={checkmark} alt="checkmark"/>
      </div>
    );
  }

  return (
    <Modal
      className="reward-msisdn-approval-modal"
      maskStyle={UI.modalMaskStyle()}
      closable={false}
      footer={false}
      visible
      centered
      destroyOnClose
      onCancel={props.callbackCancel}
    >
      <div className="content">
        <img className="icon" src={icon} alt="icon"/>
        <label className="title">
          İşlemi onaylıyor musunuz?
        </label>
        <label className="text">
          {props.data.msisdn} numarasına {props.data.offer.value} değerinde {props.data.offer.name} yüklenecek.
        </label>
        <label className="note">
          *Bu işlem geri alınamaz.
        </label>
        {
          props.isSubmitting
            ?
            <div className="loading-wrapper">
              <LoadingOutlined className="loading"/>
            </div>
            :
            <div className="button-wrapper">
              <AppButton
                type={AppButtonType.outline}
                content="Vazgeç"
                onClick={props.callbackCancel}
              />
              <AppButton
                content={getOkButtonContent()}
                onClick={() => props.callbackOk(props.data)}
              />
            </div>
        }
      </div>
    </Modal>
  );
}

export default RewardMsisdnApprovalModal;
