import {Modal} from "antd";
import React, {useState} from 'react';
import checkmark from "../../../assets/images/checkmark.svg";
import {CampaignType} from "../../../core/models/enums/campaignType";
import {UI} from "../../../core/utilities/ui";
import AppButton from "../../Buttons/AppButton/AppButton";
import "./CampaignTypeUsageModal.scss";
import Loading from "../../Loading/Loading";
import {Constants} from "../../../core/utilities/constants";

interface IProps {
  type: CampaignType;
  callback: () => void;
}

const CampaignTypeUsageModal = (props: IProps) => {
  const [isUsageLoaded, setUsageLoaded] = useState(false);

  function getUsageSrc(): string {
    switch (props.type) {
      case CampaignType.CODE:
        return `${Constants.awsBucketKurumsalKgb}/campaign-type-usage-code.svg`;
      case CampaignType.LINK:
        return `${Constants.awsBucketKurumsalKgb}/campaign-type-usage-link.svg`;
      case CampaignType.BULK:
        return `${Constants.awsBucketKurumsalKgb}/campaign-type-usage-bulk.svg`;
      case CampaignType.API:
        return `${Constants.awsBucketKurumsalKgb}/campaign-type-usage-api.svg`;
    }
  }

  return (
    <Modal
      className="campaign-type-usage-modal"
      maskStyle={UI.modalMaskStyle()}
      closable={false}
      footer={false}
      visible
      centered
      destroyOnClose
      onCancel={props.callback}
    >
      <div className="content">
        <img
          className="usage"
          src={getUsageSrc()}
          alt="usage"
          onLoad={() => setUsageLoaded(true)}
        />
        {
          !isUsageLoaded &&
          <Loading fontSize={48} className="my-5"/>
        }
        <AppButton
          content={
            <div className="d-flex align-items-center">
              <span>Anladım</span>
              &nbsp;&nbsp;
              <img src={checkmark} alt="checkmark"/>
            </div>
          }
          onClick={props.callback}
        />
      </div>
    </Modal>
  );
}

export default CampaignTypeUsageModal;
