import {GET_SIDE_USERS_START, GET_SIDE_USERS_SUCCESS, GET_SIDE_USERS_FAIL, GET_SIDE_USERS_RESET} from './constants';
import {GetSideUsersActions, GetSideUsersState} from './types';

const initialState: GetSideUsersState = {
  loading: false,
  data: undefined,
  error: undefined,
};

const reducer = (
  state = initialState,
  action: GetSideUsersActions,
): GetSideUsersState => {
  switch (action.type) {
    case GET_SIDE_USERS_START:
      return {
        ...initialState,
        loading: true,
      };
    case GET_SIDE_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case GET_SIDE_USERS_FAIL:
     return {
        ...state,
        loading: false,
        error: action.error,
      };
    case GET_SIDE_USERS_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
