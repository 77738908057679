import {Modal} from "antd";
import React from 'react';
import icon from "../../../assets/images/modal-icon-side-user-add.svg";
import {FormValuesSideUser} from "../../../core/models/custom/formValuesSideUser";
import {UI} from "../../../core/utilities/ui";
import "./SideUserAddModal.scss";
import SideUserForm from "./SideUserForm/SideUserForm";

interface IProps {
  isVisible: boolean;
  isSubmitting: boolean;
  callback: (values?: FormValuesSideUser) => void;
}

const SideUserAddModal = (props: IProps) => {

  return (
    <Modal
      className="side-user-add-modal"
      maskStyle={UI.modalMaskStyle()}
      closable={false}
      footer={false}
      visible={props.isVisible}
      centered
      destroyOnClose
      onCancel={() => props.callback()}
    >
      <div className="content">
        <img className="icon" src={icon} alt="icon"/>
        <label className="title">
          Yeni ortak yönetici ekle
        </label>
        <label className="text">
          Sizinle aynı yetkilere sahip olacak ortak bir yöneticinin bilgilerini kaydedin.
        </label>
        <SideUserForm
          isSubmitting={props.isSubmitting}
          callbackSubmit={props.callback}
        />
      </div>
    </Modal>
  );
}

export default SideUserAddModal;
