import {SET_APP_LOADING} from './constants';
import {SetAppLoadingActions, SetAppLoadingState} from './types';

const initialState: SetAppLoadingState = {
  isLoading: true,
};

const reducer = (
  state = initialState,
  action: SetAppLoadingActions,
): SetAppLoadingState => {
  switch (action.type) {
    case SET_APP_LOADING:
      return {
        isLoading: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
