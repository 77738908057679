import {Collapse} from "antd";
import React, {Component, ReactNode} from 'react';
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import add from "../../../assets/images/add.svg";
import info from "../../../assets/images/info.svg";
import EmptyStateCard from "../../../components/EmptyStateCard/EmptyStateCard";
import Loading from "../../../components/Loading/Loading";
import CampaignApprovalModal from "../../../components/Modals/CampaignApprovalModal/CampaignApprovalModal";
import CampaignBulkFileModal from "../../../components/Modals/CampaignBulkFileModal/CampaignBulkFileModal";
import CampaignCreateSuccessModal
  from "../../../components/Modals/CampaignCreateSuccessModal/CampaignCreateSuccessModal";
import CampaignTypeUsageModal from "../../../components/Modals/CampaignTypeUsageModal/CampaignTypeUsageModal";
import CreditCardSaveModal from "../../../components/Modals/CreditCardSaveModal/CreditCardSaveModal";
import PayByMeModal from "../../../components/Modals/PayByMeModal/PayByMeModal";
import PageTitle from "../../../components/PageTitle/PageTitle";
import {CampaignApprovalValues} from "../../../core/models/custom/campaignApprovalValues";
import {CampaignData} from "../../../core/models/custom/campaignData";
import {CampaignPackageValues} from "../../../core/models/custom/campaignPackageValues";
import {CampaignSuccessData} from "../../../core/models/custom/campaignSuccessData";
import {FormValuesCampaignPackage} from "../../../core/models/custom/formValuesCampaignPackage";
import {customBulkRewardMsisdnErrorPath, ErrorDto} from "../../../core/models/dtos/error.dto";
import {MeDto} from "../../../core/models/dtos/me.dto";
import {CampaignType} from "../../../core/models/enums/campaignType";
import {BulkRewardMsisdnRequest} from "../../../core/models/requests/bulkRewardMsisdn.request";
import {CreateApiCampaignRequest} from "../../../core/models/requests/createApiCampaign.request";
import {GenerateCodesRequest} from "../../../core/models/requests/generateCodes.request";
import {GenerateLinksRequest} from "../../../core/models/requests/generateLinks.request";
import {SaveCardRequest} from "../../../core/models/requests/saveCard.request";
import {setApiError} from "../../../core/services/appService/setApiError/actions";
import {setMe} from "../../../core/services/appService/setMe/actions";
import {bulkRewardMsisdn, bulkRewardMsisdnReset} from "../../../core/services/campaignService/bulkRewardMsisdn/actions";
import {BulkRewardMsisdnState} from "../../../core/services/campaignService/bulkRewardMsisdn/types";
import {createApiCampaign} from "../../../core/services/campaignService/createApiCampaign/actions";
import {CreateApiCampaignState} from "../../../core/services/campaignService/createApiCampaign/types";
import {generateCodes, generateCodesReset} from "../../../core/services/campaignService/generateCodes/actions";
import {GenerateCodesState} from "../../../core/services/campaignService/generateCodes/types";
import {generateLinks, generateLinksReset} from "../../../core/services/campaignService/generateLinks/actions";
import {GenerateLinksState} from "../../../core/services/campaignService/generateLinks/types";
import {saveCard, saveCardReset} from "../../../core/services/cardService/saveCard/actions";
import {SaveCardState} from "../../../core/services/cardService/saveCard/types";
import FirebaseService from "../../../core/services/firebase.service";
import {getUsage} from "../../../core/services/offerService/getUsage/actions";
import {GetUsageState} from "../../../core/services/offerService/getUsage/types";
import {Helpers} from "../../../core/utilities/helpers";
import {history} from "../../../core/utilities/history";
import {IStore} from "../../../core/utilities/reducers";
import {router} from "../../../core/utilities/router";
import CampaignApproval from "./CampaignApproval/CampaignApproval";
import "./CampaignCreatePage.scss";
import CampaignMethod from "./CampaignMethod/CampaignMethod";
import CampaignPackage from "./CampaignPackage/CampaignPackage";

const {Panel} = Collapse;

interface IProps {
  me?: MeDto;
  getUsageState: GetUsageState;
  generateCodesState: GenerateCodesState;
  generateLinksState: GenerateLinksState;
  bulkRewardMsisdnState: BulkRewardMsisdnState;
  createApiCampaignState: CreateApiCampaignState;
  saveCardState: SaveCardState;
  getUsage: () => void;
  generateCodes: (request: GenerateCodesRequest) => void;
  generateCodesReset: () => void;
  generateLinks: (request: GenerateLinksRequest) => void;
  generateLinksReset: () => void;
  bulkRewardMsisdn: (request: BulkRewardMsisdnRequest) => void;
  bulkRewardMsisdnReset: () => void;
  createApiCampaign: (request: CreateApiCampaignRequest) => void;
  saveCard: (request: SaveCardRequest) => void;
  saveCardReset: () => void;
  setMe: (me?: MeDto) => void;
  setApiError: (error?: ErrorDto) => void;
}

interface IState {
  step: CreateStep;
  dataPackage?: CampaignPackageValues;
  dataMethod?: CampaignType;
  dataApproval?: CampaignApprovalValues;
  campaignData?: CampaignData;
  campaignSuccessData?: CampaignSuccessData;
  campaignTypeUsageModalData?: CampaignType;
}

enum CreateStep {
  package = 'package',
  method = 'method',
  approval = 'approval',
}

class CampaignCreatePage extends Component<IProps> {
  state: IState = {
    step: CreateStep.package,
  }

  componentDidMount() {
    this.props.getUsage();
    FirebaseService.logEvent(FirebaseService.create_new_campaign_view);
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any) {
    if (!!this.state.campaignData && this.state.campaignData.type) {
      let isCampaignCreated = false;
      switch (this.state.campaignData.type) {
        case CampaignType.CODE:
          isCampaignCreated = (
            prevProps.generateCodesState.loading &&
            !this.props.generateCodesState.loading &&
            !!this.props.generateCodesState.data
          );
          break;
        case CampaignType.LINK:
          isCampaignCreated = (
            prevProps.generateLinksState.loading &&
            !this.props.generateLinksState.loading &&
            !!this.props.generateLinksState.data
          );
          break;
        case CampaignType.BULK:
          isCampaignCreated = (
            prevProps.bulkRewardMsisdnState.loading &&
            !this.props.bulkRewardMsisdnState.loading &&
            !!this.props.bulkRewardMsisdnState.data
          );
          break;
        case CampaignType.API:
          isCampaignCreated = (
            prevProps.createApiCampaignState.loading &&
            !this.props.createApiCampaignState.loading &&
            !!this.props.createApiCampaignState.data
          );
          break;
      }
      if (isCampaignCreated) {
        const name = this.state.campaignData.campaignName;
        const type = this.state.campaignData.type;
        let amount = this.state.campaignData.amount;
        let downloadData;
        if (type === CampaignType.CODE) {
          downloadData = this.props.generateCodesState.data;
        } else if (type === CampaignType.LINK) {
          downloadData = this.props.generateLinksState.data;
        } else if (type === CampaignType.BULK) {
          amount = this.props.bulkRewardMsisdnState.data?.successfulRewardCount ?? 0;
        }
        const campaignSuccessData: CampaignSuccessData = {
          name,
          type,
          amount,
          downloadData,
          balance: Helpers.getRemainingPoint1000(this.props.getUsageState.data ?? []),
          apiKey: this.props.createApiCampaignState.data?.apiKey,
        };
        this.setState(
          {campaignData: undefined, campaignSuccessData},
          () => this.resetCampaignEndpoints(),
        );
      } else {
        if (this.props.bulkRewardMsisdnState.error?.path === customBulkRewardMsisdnErrorPath) {
          this.props.setApiError(this.props.bulkRewardMsisdnState.error);
        }
      }
    }
  }

  private resetCampaignEndpoints(): void {
    this.props.generateCodesReset();
    this.props.generateLinksReset();
    this.props.bulkRewardMsisdnReset();
  }

  private setStep(step: CreateStep): void {
    if (!step) return;
    if (!this.state.dataPackage && step !== CreateStep.package) return;
    if (!this.state.dataMethod && step === CreateStep.approval) return;
    this.setState({step});
  }

  private setUsageModalData(type?: CampaignType): void {
    this.setState({campaignTypeUsageModalData: type});
  }

  private isModalApprovalVisible(): boolean {
    const campaignData = this.state.campaignData;
    if (campaignData) {
      return (
        (
          campaignData.type === CampaignType.CODE ||
          campaignData.type === CampaignType.LINK
        )
        ||
        (
          !this.isModalBulkFileVisible() &&
          !this.isModalCreditCardSaveVisible()
        )
      );
    }
    return false;
  }

  private isModalBulkFileVisible(): boolean {
    const campaignData = this.state.campaignData;
    if (campaignData) {
      return (
        campaignData.type === CampaignType.BULK &&
        !campaignData.bulkFile?.file
      );
    }
    return false;
  }

  private isModalCreditCardSaveVisible(): boolean {
    const campaignData = this.state.campaignData;
    if (campaignData) {
      return (
        campaignData.type === CampaignType.API &&
        (!!campaignData.isAutoRenew && !this.props.me?.cardGuid)
      );
    }
    return false;
  }

  private handleContinuePackage(values: FormValuesCampaignPackage): void {
    const selectedUsage = this.props.getUsageState.data?.find(o => o.offer.id === values.offerId);
    if (!selectedUsage) return;
    const dataPackage: CampaignPackageValues = {
      amount: values.amount,
      usage: selectedUsage,
    }
    this.setState({
      step: CreateStep.method,
      dataPackage,
    });
  }

  private handleContinueMethod(type: CampaignType): void {
    this.setState({
      step: CreateStep.approval,
      dataMethod: type,
    });
  }

  private handleContinueApproval(dataApproval: CampaignApprovalValues): void {
    if (
      !this.state.dataPackage ||
      !this.state.dataMethod
    ) {
      alert('Missing package and/or method');
      return;
    }
    const campaignData: CampaignData = {
      campaignName: dataApproval.name,
      isAutoRenew: dataApproval.isAutoRenew,
      goal: dataApproval.goal,
      offer: this.state.dataPackage.usage.offer,
      amount: this.state.dataPackage.amount,
      type: this.state.dataMethod,
    }
    this.setState({
      dataApproval,
      campaignData,
    });
  }

  private handleModalCampaignApproval(data: CampaignData): void {
    const initialAllocation = parseInt(data.amount.toString());
    const name = data.campaignName;
    const offerId = data.offer.id;
    const goal = data.goal;
    if (data.type === CampaignType.CODE) {
      const request: GenerateCodesRequest = {
        initialAllocation,
        name,
        offerId,
        goal,
      };
      this.props.generateCodes(request);
    } else if (data.type === CampaignType.LINK) {
      const request: GenerateLinksRequest = {
        initialAllocation,
        name,
        offerId,
        goal,
      };
      this.props.generateLinks(request);
    } else if (data.type === CampaignType.BULK) {
      if (!data.bulkFile) {
        alert("Bulk file missing!");
        return;
      }
      const request: BulkRewardMsisdnRequest = {
        goal,
        initialAllocation,
        name,
        offerId,
        file: data.bulkFile.file,
      };
      this.props.bulkRewardMsisdn(request);
    } else if (data.type === CampaignType.API) {
      const cardGuid = this.props.me?.cardGuid;
      if (data.isAutoRenew && !cardGuid) {
        alert('Undefined cardGuid!');
      }
      const request: CreateApiCampaignRequest = {
        initialAllocation,
        goal,
        name,
        offerId,
        cardGuid: data.isAutoRenew ? cardGuid : undefined,
      };
      this.props.createApiCampaign(request);
    } else {
      alert(`Invalid campaign type: ${data.type}`);
    }
  }

  private handleModalCampaignBulkFile(data: CampaignData): void {
    this.setState({campaignData: data});
  }

  private handleModalCreditCardSave(request?: SaveCardRequest): void {
    if (request) {
      this.props.saveCard(request);
    } else {
      this.setState({
        campaignData: undefined,
      });
    }
  }

  private async handleModalPayByMeSaveCard(): Promise<void> {
    this.props.saveCardReset();
  }

  private handleNewCampaign(): void {
    this.setState({
      step: CreateStep.package,
      dataPackage: undefined,
      dataMethod: undefined,
      dataApproval: undefined,
      campaignData: undefined,
      campaignSuccessData: undefined,
      campaignTypeUsageModalData: undefined,
    });
  }

  private getEmptyStateText(): string {
    return '<div class="empty-text">' +
      'Hedefinize kolayca ulaşmak için sadakat kampanyaları oluşturabilirsiniz. ' +
      '<strong>Kampanya oluşturabilmek için önce MetaByte almanız gerekmektedir.</strong>' +
      '</div>';
  }

  private renderHeaderInfo(text: ReactNode, type?: CampaignType): ReactNode {
    return (
      <div
        className="header-suffix"
        onClick={() => type ? this.setUsageModalData(type) : {}}
      >
        <label className="text">{text}</label>
      </div>
    );
  }

  private renderHeaderPackage(): ReactNode {
    const dataPackage = this.state.dataPackage;
    return (
      <div className="panel-header">
        <div className="d-flex flex-column">
          <label className="title">
           MetaByte Adedi
          </label>
          <label className="sub-title">
            Kampanyanız boyunca hediye etmek istediğiniz MetaByte adedini seçiniz.
            <br/> 
            {/* *1000 MetaByte, Günlük 1 GB değerindedir.  */}
          </label>
        </div>
        {
          dataPackage &&
          this.renderHeaderInfo(`${dataPackage.usage.offer.name} / ${dataPackage.amount} Adet`)
        }
      </div>
    );
  }

  private renderHeaderMethod(): ReactNode {
    let method;
    if (this.state.dataMethod) {
      switch (this.state.dataMethod) {
        case CampaignType.CODE:
          method = 'KOD İLE YÜKLE';
          break;
        case CampaignType.LINK:
          method = 'LİNK İLE YÜKLE';
          break;
        case CampaignType.BULK:
          method = 'TOPLU YÜKLE';
          break;
        case CampaignType.API:
          method = 'API İLE YÜKLE';
          break;
      }
    }
    return (
      <div className="panel-header">
        <div className="d-flex flex-column">
          <label className="title">
            Kampanya Yönetimi
          </label>
          <label className="sub-title">
          MetaByte'larınızı yüklemek için kampanya yöntemi seçiniz.
          </label>
        </div>
        {
          this.state.dataMethod &&
          this.renderHeaderInfo(
            <div className="d-flex align-items-center">
              <img className="me-1" src={info} alt="info"/>
              {method}
            </div>,
            this.state.dataMethod
          )
        }
      </div>
    );
  }

  private renderContent(): ReactNode {
    if (this.state.campaignSuccessData) {
      // to refresh inputs for new campaign
      return <React.Fragment/>
    } else if (this.props.getUsageState.loading) {
      return <Loading fontSize={48}/>
    } else if (this.props.getUsageState.data) {
      const balance = Helpers.getRemainingPoint1000(this.props.getUsageState.data);
      if (balance) {
        return (
          <Collapse
            accordion
            expandIconPosition="right"
            className="create-campaign-form"
            activeKey={this.state.step}
            onChange={key => this.setStep(key as CreateStep)}
          >
            <Panel
              key={CreateStep.package}
              header={this.renderHeaderPackage()}
              className="package"
            >
              <CampaignPackage
                usages={Helpers.getUsagesPoint1000(this.props.getUsageState.data)}
                callbackChange={() => this.setState({dataPackage: undefined})}
                callbackSubmit={values => this.handleContinuePackage(values)}
              />
            </Panel>
            <Panel
              key={CreateStep.method}
              header={this.renderHeaderMethod()}
              className="method"
            >
              <CampaignMethod
                callbackChange={() => this.setState({dataMethod: undefined})}
                callbackSubmit={type => this.handleContinueMethod(type)}
                callbackUsage={type => this.setUsageModalData(type)}
              />
            </Panel>
            <Panel
              key={CreateStep.approval}
              header="Onay"
              className="approval"
            >
              {
                this.state.dataMethod &&
                <CampaignApproval               
                  type={this.state.dataMethod}
                  callbackUsageLink={type => this.setUsageModalData(type)}
                  callbackSubmit={data => this.handleContinueApproval(data)}
                />
              }
            </Panel>
          </Collapse>
        );
      } else {
        return (
          <EmptyStateCard
            title="Maalesef hiç MetaByte'ınız yok"
            text={this.getEmptyStateText()}
            button={{
              child: (
                <div className="d-flex align-items-center">
                  <img src={add} alt="add"/>
                  &nbsp;&nbsp;
                  <span>MetaByte Al</span>
                </div>
              ),
              callback: () => {
                FirebaseService.logEvent(FirebaseService.create_new_campaign_no_points_clicked);
                history.push(router.ORDER);
              }
            }}
          />
        );
      }
    }
  }

  render() {
    return (
      <div id="campaign-create-page" className="page">
        <div className="page-content">
          <PageTitle text="Kampanya Oluştur"/>
          {this.renderContent()}
        </div>

        {
          this.isModalApprovalVisible() &&
          <CampaignApprovalModal
            data={this.state.campaignData!}
            isSubmitting={
              this.props.generateCodesState.loading ||
              this.props.generateLinksState.loading ||
              this.props.bulkRewardMsisdnState.loading ||
              this.props.createApiCampaignState.loading
            }
            callbackOk={data => this.handleModalCampaignApproval(data)}
            callbackCancel={() => this.setState({campaignData: undefined})}
          />
        }

        {
          this.isModalBulkFileVisible() &&
          <CampaignBulkFileModal
            data={this.state.campaignData!}
            callbackOk={data => this.handleModalCampaignBulkFile(data)}
            callbackCancel={() => this.setState({campaignData: undefined})}
          />
        }

        {
          this.isModalCreditCardSaveVisible() &&
          <CreditCardSaveModal
            isSubmitting={this.props.saveCardState.loading}
            callbackOk={request => this.handleModalCreditCardSave(request)}
            callbackCancel={() => this.handleModalCreditCardSave()}
          />
        }

        {
          this.props.saveCardState.data &&
          <PayByMeModal
            url={this.props.saveCardState.data.redirectUrl}
            syncId={this.props.saveCardState.data.syncId}
            callbackPaymentResult={_ => this.handleModalPayByMeSaveCard()}
          />
        }

        {
          this.state.campaignSuccessData &&
          <CampaignCreateSuccessModal
            data={this.state.campaignSuccessData}
            callbackUsageLink={type => this.setUsageModalData(type)}
            callbackNewCampaign={() => this.handleNewCampaign()}
            callbackHomepage={() => history.push(router.HOME)}
          />
        }

        {
          this.state.campaignTypeUsageModalData &&
          <CampaignTypeUsageModal
            type={this.state.campaignTypeUsageModalData}
            callback={() => this.setUsageModalData()}
          />
        }
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getUsage,
      generateCodes,
      generateCodesReset,
      generateLinks,
      generateLinksReset,
      bulkRewardMsisdn,
      bulkRewardMsisdnReset,
      createApiCampaign,
      saveCard,
      saveCardReset,
      setMe,
      setApiError,
    },
    dispatch
  );
};
const mapStateToProps = (store: IStore) => {
  return {
    me: store.setMe.me,
    getUsageState: store.getUsage,
    generateCodesState: store.generateCodes,
    generateLinksState: store.generateLinks,
    bulkRewardMsisdnState: store.bulkRewardMsisdn,
    createApiCampaignState: store.createApiCampaign,
    saveCardState: store.saveCard,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignCreatePage);
