import {Dispatch} from 'redux';
import { CheckAgencyCodeDto } from '../../../models/dtos/checkAgencyCode.dto';
import {ErrorDto, getErrorDtoFromApiError} from '../../../models/dtos/error.dto';
import {
  CHECK_AND_GET_AGENCY_CODE_FAIL,
  CHECK_AND_GET_AGENCY_CODE_RESET,
  CHECK_AND_GET_AGENCY_CODE_START,
  CHECK_AND_GET_AGENCY_CODE_SUCCESS,
} from './constants';
import {checkAndGetAgencyCodeRequest} from './repository';
import {
  CheckAndGetAgencyCodeFailAction,
  CheckAndGetAgencyCodeResetAction,
  CheckAndGetAgencyCodeStartAction,
  CheckAndGetAgencyCodeSuccessAction,
} from './types';

const checkAndGetAgencyCodeStartAction = (): CheckAndGetAgencyCodeStartAction => {
  return {
    type: CHECK_AND_GET_AGENCY_CODE_START,
  };
};
const checkAndGetAgencyCodeSuccessAction = (payload: CheckAgencyCodeDto): CheckAndGetAgencyCodeSuccessAction => {
  return {
    type: CHECK_AND_GET_AGENCY_CODE_SUCCESS,
    payload: payload,
  };
};
const checkAndGetAgencyCodeFailAction = (error: ErrorDto): CheckAndGetAgencyCodeFailAction => {
  return {
    type: CHECK_AND_GET_AGENCY_CODE_FAIL,
    error: error,
  };
};
const checkAndGetAgencyCodeResetAction = (): CheckAndGetAgencyCodeResetAction => {
  return {
    type: CHECK_AND_GET_AGENCY_CODE_RESET,
  };
};

export const checkAndGetAgencyCode = (code: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(checkAndGetAgencyCodeStartAction());
    const response = await checkAndGetAgencyCodeRequest(code);
    dispatch(checkAndGetAgencyCodeSuccessAction(response));
  } catch (error) {
    dispatch(checkAndGetAgencyCodeFailAction(getErrorDtoFromApiError(error)));
  }
};

export const checkAndGetAgencyCodeReset = () => (dispatch: Dispatch) => {
  dispatch(checkAndGetAgencyCodeResetAction());
};
