import { Modal } from "antd";
import { router } from "../../../core/utilities/router";
import { UI } from "../../../core/utilities/ui";
import AppButton from "../../Buttons/AppButton/AppButton";
import FirebaseKey from "../../FirebaseKey/FirebaseKey";
import "./OrderEftSuccessModal.scss";

interface IProps {
  callback: (route: string) => void;
}



const OrderEftSuccessModal = (props: IProps) => {

  const progressSteps = ["Bilgiler", "Havale", "Onay"];

  return (
    <Modal
      className="order-success-modal"
      maskStyle={UI.modalMaskStyle()}
      closable={false}
      footer={false}
      visible
      centered
      destroyOnClose
      onCancel={() => props.callback(router.HOME)}
    >
      <div className="content">
        <ul className={`progress-bar progress-third`}>
          {
            progressSteps.map((steps, index) => <li key={`${index}-progress-steps`} className={`progress-${index}`}>{steps}</li>)
          }
        </ul>
        <label className="title">
          <FirebaseKey firebaseKey="havale_succesfull_popup_title" />
        </label>
        <p className="description">
          <FirebaseKey firebaseKey="havale_succesfull_popup_desc" />
        </  p>

        <AppButton
          content={<FirebaseKey firebaseKey="havale_succesfull_popup_button" />}
          onClick={() => props.callback(router.HOME)}
        />
      </div>
    </Modal>
  );
}

export default OrderEftSuccessModal;
