import {Dispatch} from 'redux';
import {ErrorDto, getErrorDtoFromApiError} from '../../../models/dtos/error.dto';
import {GET_FILE_FAIL, GET_FILE_RESET, GET_FILE_START, GET_FILE_SUCCESS,} from './constants';
import {getFileRequest} from './repository';
import {GetFileFailAction, GetFileResetAction, GetFileStartAction, GetFileSuccessAction,} from './types';

const getFileStartAction = (): GetFileStartAction => {
  return {
    type: GET_FILE_START,
  };
};
const getFileSuccessAction = (payload: string): GetFileSuccessAction => {
  return {
    type: GET_FILE_SUCCESS,
    payload: payload,
  };
};
const getFileFailAction = (error: ErrorDto): GetFileFailAction => {
  return {
    type: GET_FILE_FAIL,
    error: error,
  };
};
const getFileResetAction = (): GetFileResetAction => {
  return {
    type: GET_FILE_RESET,
  };
};

export const getFile = (id: number) => async (dispatch: Dispatch) => {
  try {
    dispatch(getFileStartAction());
    const response = await getFileRequest(id);
    dispatch(getFileSuccessAction(response));
  } catch (error) {
    dispatch(getFileFailAction(getErrorDtoFromApiError(error)));
  }
};

export const getFileReset = () => (dispatch: Dispatch) => {
  dispatch(getFileResetAction());
};
