import {Dispatch} from 'redux';
import {ApiKeyDto} from '../../../models/dtos/apiKey.dto';
import {ErrorDto, getErrorDtoFromApiError} from '../../../models/dtos/error.dto';
import {GET_API_KEY_FAIL, GET_API_KEY_RESET, GET_API_KEY_START, GET_API_KEY_SUCCESS,} from './constants';
import {getApiKeyRequest} from "./repository";
import {GetApiKeyFailAction, GetApiKeyResetAction, GetApiKeyStartAction, GetApiKeySuccessAction,} from './types';

const getApiKeyStartAction = (): GetApiKeyStartAction => {
  return {
    type: GET_API_KEY_START,
  };
};
const getApiKeySuccessAction = (payload: ApiKeyDto): GetApiKeySuccessAction => {
  return {
    type: GET_API_KEY_SUCCESS,
    payload: payload,
  };
};
const getApiKeyFailAction = (error: ErrorDto): GetApiKeyFailAction => {
  return {
    type: GET_API_KEY_FAIL,
    error: error,
  };
};
const getApiKeyResetAction = (): GetApiKeyResetAction => {
  return {
    type: GET_API_KEY_RESET,
  };
};

export const getApiKey = (id: number) => async (dispatch: Dispatch) => {
  try {
    dispatch(getApiKeyStartAction());
    const response = await getApiKeyRequest(id);
    dispatch(getApiKeySuccessAction(response));
  } catch (error) {
    dispatch(getApiKeyFailAction(getErrorDtoFromApiError(error)));
  }
};

export const getApiKeyReset = () => (dispatch: Dispatch) => {
  dispatch(getApiKeyResetAction());
};
