import React, {Component} from "react";
import ContactUsPage from "../../App/ContactUsPage/ContactUsPage";
import LandingFooter from "../../App/LandingPage/LandingFooter/LandingFooter";
import LandingHeader from "../../App/LandingPage/LandingHeader/LandingHeader";
import "./ContactUsPublicPage.scss";

interface IProps {
}

class ContactUsPublicPage extends Component<IProps> {

  render() {
    return (
      <div id="contact-us-public-page" className="page">
        <div className="page-content">
          <LandingHeader/>
          <ContactUsPage/>
          <LandingFooter/>
        </div>
      </div>
    );
  }
}

export default ContactUsPublicPage;
