import {Form, Input, Select} from "antd";
import React from 'react';
import AppButton from "../../../../components/Buttons/AppButton/AppButton";
import {FormValuesContactUs} from "../../../../core/models/custom/formValuesContactUs";
import {Constants} from "../../../../core/utilities/constants";
import "./ContactUsForm.scss";

const {Option} = Select;

interface IProps {
  subjects: string[];
  isSubmitting: boolean;
  callbackSubmit: (values: FormValuesContactUs) => void;
}

const ContactUsForm = (props: IProps) => {
  const [form] = Form.useForm();

  function handleMsisdnKeyPress(e: React.KeyboardEvent): void {
    if (isNaN(parseInt(e.key))) {
      e.preventDefault();
    }
  }

  function handleFinish(values: FormValuesContactUs) {
    props.callbackSubmit(values);
  }

  return (
    <Form
      form={form}
      id="contact-us-form"
      className="form"
      onFinish={handleFinish}
    >
      <label className="label">Ad - Soyad</label>
      <Form.Item
        name="fullName"
        rules={[{required: true, message: Constants.inputErrorRequiredField}]}
      >
        <Input
          autoFocus
          className="form-input"
          placeholder="Ad - Soyad"
        />
      </Form.Item>

      <label className="label">Email</label>
      <Form.Item
        name="email"
        rules={[{required: true, type: 'email' , message: Constants.inputErrorRequiredField}]}
      >
        <Input
          autoFocus
          className="form-input"
          placeholder="Email"
        />
      </Form.Item>

      <label className="label">Telefon numarası</label>
      <Form.Item
        name="msisdn"
        className="item"
        rules={[
          {required: true, message: Constants.inputErrorRequiredField},
        ]}
      >
        <Input
          className="form-input"
          type="tel"
          placeholder="Telefon numarası"
          onKeyPress={handleMsisdnKeyPress}
        />
      </Form.Item>

      <label className="label">Konu</label>
      <Form.Item
        name="subject"
        rules={[{required: true, message: Constants.inputErrorRequiredField}]}
      >
        <Select
          className="form-input select"
          placeholder="Konu"
        >
          {props.subjects.map((subject) => {
            return (
              <Option key={subject} value={subject}>
                {subject}
              </Option>
            );
          })}
        </Select>
      </Form.Item>

      <label className="label">Mesaj</label>
      <Form.Item
        name="message"
        rules={[{required: true, message: Constants.inputErrorRequiredField}]}
      >
        <Input.TextArea
          className="form-input text-area"
          placeholder="Mesaj"
          rows={7}
        />
      </Form.Item>

      <AppButton
        content="Mesaj gönder"
        htmlType="submit"
        disabled={props.isSubmitting}
        isLoading={props.isSubmitting}
        onClick={() => {
        }}
      />
    </Form>
  );
}

export default ContactUsForm;
