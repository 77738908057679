import {Modal} from "antd";
import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import {FormValuesTryBulkStepMsisdnList} from "../../../../core/models/custom/formValuesTryBulkStepMsisdnList";
import {FakeBulkSmsRequest} from "../../../../core/models/requests/fakeBulkSms.request";
import FirebaseService from "../../../../core/services/firebase.service";
import {tryBulk, tryBulkReset} from "../../../../core/services/miscService/tryBulk/actions";
import {TryBulkState} from "../../../../core/services/miscService/tryBulk/types";
import {IStore} from "../../../../core/utilities/reducers";
import {UI} from "../../../../core/utilities/ui";
import "./TryBulkModal.scss";
import TryBulkStepMsisdnList from "./TryBulkStepMsisdnList/TryBulkStepMsisdnList";

interface IProps {
  callbackClose: () => void;
  tryBulkState: TryBulkState;
  tryBulk: (request: FakeBulkSmsRequest) => void;
  tryBulkReset: () => void;
}

class TryBulkModal extends Component<IProps> {

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any) {
    if (prevProps.tryBulkState.loading && !this.props.tryBulkState.loading) {
      if (this.props.tryBulkState.data) {
        this.props.callbackClose();
      }
    }
  }

  private handleSubmitMsisdnList(values: FormValuesTryBulkStepMsisdnList): void {
    FirebaseService.logEvent(FirebaseService.user_count_landing_bulk_popup_upload_clicked);
    const msisdns: string[] = [];
    msisdns.push(values.msisdn1);
    if (values.msisdn2) {
      msisdns.push(values.msisdn2);
    }
    if (values.msisdn3) {
      msisdns.push(values.msisdn3);
    }
    this.props.tryBulk({msisdns})
  }

  render() {
    return (
      <Modal
        wrapClassName="try-bulk-modal-wrapper"
        className="try-bulk-modal"
        maskStyle={UI.modalMaskStyle()}
        closable={false}
        footer={false}
        visible
        centered
        destroyOnClose
        onCancel={this.props.callbackClose}
      >
        <div className="content">
          <span className="title">Toplu yükle</span>
          <div className="line-wrapper d-none d-md-flex">
            <span className="line"/>
          </div>
          <TryBulkStepMsisdnList
            isLoading={this.props.tryBulkState.loading}
            callbackSubmit={values => this.handleSubmitMsisdnList(values)}
            callbackCancel={() => this.props.callbackClose()}
          />
        </div>
      </Modal>
    );
  }

  componentWillUnmount() {
    this.props.tryBulkReset();
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      tryBulk,
      tryBulkReset,
    },
    dispatch
  );
};
const mapStateToProps = (store: IStore) => {
  return {
    tryBulkState: store.tryBulk,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(TryBulkModal);
