import { Button } from "antd";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Dispatch, bindActionCreators } from "redux";
import fener from "../../../../assets/images/landing/brand-fener.png";
import getir from "../../../../assets/images/landing/brand-getir.png";
import hopi from "../../../../assets/images/landing/brand-hopi.png";
import yaani from "../../../../assets/images/landing/brand-yaani.png";
import reklam from "../../../../assets/images/landing/brand-reklam.png";
import game from "../../../../assets/images/landing/brand-game.png";
import biletcom from "../../../../assets/images/landing/brand-biletcom.png";
import biogrenci from "../../../../assets/images/landing/brand-biogrenci.png";
import kingbilet from "../../../../assets/images/landing/brand-kingbilet.png";
import arrow from "../../../../assets/images/landing/chevron-right-black.svg";
import check from "../../../../assets/images/landing/title-check.svg";
import FirebaseKey from "../../../../components/FirebaseKey/FirebaseKey";
import { MeDto } from "../../../../core/models/dtos/me.dto";
import { setVideoUrl } from "../../../../core/services/appService/setVideoUrl/actions";
import FirebaseService from "../../../../core/services/firebase.service";
import { IStore } from "../../../../core/utilities/reducers";
import { router } from "../../../../core/utilities/router";
import LandingHeader from "../LandingHeader/LandingHeader";
import "./SectionBanner.scss";

interface IProps {
  me?: MeDto;
  refGoal: any;
  refUsage: any;
  refIntegration: any;
  refKgbApp: any;
  videoUrl: string;
  setVideoUrl: (url: string) => void;
}

const SectionBanner = (props: IProps) => {
  // const [isVideoMainPlaying, setVideoMainPlaying] = useState(true);

  // useEffect(() => {
  //   if (props.videoUrl.length > 0) {
  //     setVideoMainPlaying(false);
  //   }
  // }, [props.videoUrl]);

  // function handleVideoClick(event: React.MouseEvent, url: string): void {
  //   if (!isMobile) {
  //     if (!isSafari) {
  //       event.preventDefault();
  //       props.setVideoUrl(url);
  //     }
  //   }
  // }

  return (
    <section id="section-banner" className="section">
      <div className="section-content">
        <LandingHeader
          refGoal={props.refGoal}
          refUsage={props.refUsage}
          refIntegration={props.refIntegration}
          refKgbApp={props.refKgbApp}
        />
        <div className="row">
          <div
            // className={`section-left ${UI.getLandingSectionResponsiveClass()}`}
            className={`section-left col-12`}
          >
            <div className="content">
              <FirebaseKey
                className="main-title"
                firebaseKey={FirebaseService.landing_section1_main_title}
              />
              <FirebaseKey
                className="title"
                firebaseKey={FirebaseService.landing_section1_title}
                suffix={<img className="check" src={check} alt="check" />}
              />
              <FirebaseKey
                className="description"
                firebaseKey={FirebaseService.landing_section1_desc}
              />
              {!props.me && (
                <NavLink to={router.AUTH}>
                  <Button
                    className="landing-button"
                    onClick={() =>
                      FirebaseService.logEvent(
                        FirebaseService.count_sign_up_clicked
                      )
                    }
                  >
                    <FirebaseKey
                      firebaseKey={FirebaseService.landing_section1_button}
                    />
                    <img className="arrow" src={arrow} alt="arrow" />
                  </Button>
                </NavLink>
              )}
            </div>
          </div>
          {/* <div className={`section-right ${UI.getLandingSectionResponsiveClass()}`}>
            <VisibilitySensor
              partialVisibility
              offset={{top: 180}}
              onChange={isVisible => {
                if (isVisible && !isVideoMainPlaying) {
                  setVideoMainPlaying(true);
                } else if (!isVisible && isVideoMainPlaying) {
                  setVideoMainPlaying(false);
                }
              }}
            >
              <div
                className="player-wrapper"
                onClick={event => handleVideoClick(event, Constants.videoUrlMain)}
              >
                <Placeholder/>
                <ReactPlayer
                  key="banner-video"
                  controls
                  muted
                  loop
                  className="react-player"
                  width="100%"
                  height="100%"
                  url={Constants.videoUrlMain}
                  playing={isVideoMainPlaying}
                  onPlay={() => setVideoMainPlaying(true)}
                  onPause={() => setVideoMainPlaying(false)}
                />
              </div>
            </VisibilitySensor>
          </div> */}
        </div>
        <div className="brands-note">
          <FirebaseKey
            className="note"
            firebaseKey={FirebaseService.landing_section1_note}
          />
          <div className="brands">
            <img src={fener} alt="fener" />
            <img src={hopi} alt="hopi" />
            <img src={getir} alt="getir" />
            <img src={yaani} alt="yaani" />
            <img src={reklam} alt="reklam" />
            <img src={game} alt="game" />
            <img src={biletcom} alt="biletcom" />
            <img src={biogrenci} alt="biogrenci" />
            <img src={kingbilet} alt="kingbilet" />
          </div>
        </div>
      </div>
    </section>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      setVideoUrl,
    },
    dispatch
  );
};
const mapStateToProps = (store: IStore) => {
  return {
    me: store.setMe.me,
    videoUrl: store.setVideoUrl.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionBanner);
