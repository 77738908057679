import React from 'react';
import sideUser from "../../../assets/images/side-user-add.svg";
import "./SideUserCard.scss";

interface IProps {
  callback: () => void;
}

const SideUserCard = (props: IProps) => {

  return (
    <div
      id="side-user-card"
      onClick={props.callback}
    >
      <div className="indicator"/>
      <div className="side-user-card-content">
        <img src={sideUser} alt="side-user"/>
        <div className="description">
          <h1 className="description-title">
            Ortak yönetici ekleyin
          </h1>
          <label className="description-text">
            Sizinle aynı yetkilere sahip olacak ortak bir yönetici ekleyin.
          </label>
        </div>
      </div>
    </div>
  );
}

export default SideUserCard;
