import {DELETE_SIDE_USER_START, DELETE_SIDE_USER_SUCCESS, DELETE_SIDE_USER_FAIL, DELETE_SIDE_USER_RESET} from './constants';
import {DeleteSideUserActions, DeleteSideUserState} from './types';

const initialState: DeleteSideUserState = {
  loading: false,
  data: undefined,
  error: undefined,
};

const reducer = (
  state = initialState,
  action: DeleteSideUserActions,
): DeleteSideUserState => {
  switch (action.type) {
    case DELETE_SIDE_USER_START:
      return {
        ...initialState,
        loading: true,
      };
    case DELETE_SIDE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case DELETE_SIDE_USER_FAIL:
     return {
        ...state,
        loading: false,
        error: action.error,
      };
    case DELETE_SIDE_USER_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
