import {Modal} from "antd";
import React from 'react';
import icon from "../../../assets/images/modal-icon-logout.svg";
import {UI} from "../../../core/utilities/ui";
import AppButton, {AppButtonType} from "../../Buttons/AppButton/AppButton";
import "./LogoutModal.scss";

interface IProps {
  callback: (isApproved: boolean) => void;
}

const LogoutModal = (props: IProps) => {

  return (
    <Modal
      className="logout-modal"
      maskStyle={UI.modalMaskStyle()}
      closable={false}
      footer={false}
      visible
      centered
      destroyOnClose
      onCancel={() => props.callback(false)}
    >
      <div className="content">
        <img className="icon" src={icon} alt="icon"/>
        <label className="title">
          Çıkış yapmak istiyor musunuz?
        </label>
        <div className="button-wrapper">
          <AppButton
            type={AppButtonType.outline}
            content="Vazgeç"
            onClick={() => props.callback(false)}
          />
          <AppButton
            content="Çıkış yap"
            onClick={() => props.callback(true)}
          />
        </div>
      </div>
    </Modal>
  );
}

export default LogoutModal;
