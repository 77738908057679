import React, {Component, ReactNode} from 'react';
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import add from "../../../assets/images/add.svg";
import EmptyStateCard from "../../../components/EmptyStateCard/EmptyStateCard";
import Loading from "../../../components/Loading/Loading";
import PageTitle from "../../../components/PageTitle/PageTitle";
import {getOrderHistory} from "../../../core/services/orderService/getOrderHistory/actions";
import {GetOrderHistoryState} from "../../../core/services/orderService/getOrderHistory/types";
import {history} from "../../../core/utilities/history";
import {IStore} from "../../../core/utilities/reducers";
import {router} from "../../../core/utilities/router";
import "./OrderHistoryPage.scss";
import OrderHistoryTable from "./OrderHistoryTable/OrderHistoryTable";

interface IProps {
  getOrderHistoryState: GetOrderHistoryState;
  getOrderHistory: () => void;
}

class OrderHistoryPage extends Component<IProps> {

  componentDidMount() {
    this.props.getOrderHistory();
  }

  private renderContent(): ReactNode {
    if (this.props.getOrderHistoryState.loading) {
      return <Loading fontSize={48}/>
    } else if (this.props.getOrderHistoryState.data) {
      const data = this.props.getOrderHistoryState.data;
      if (data.length === 0) {
        return (
          <EmptyStateCard
            title="Henüz MetaByte satın almadınız"
            text="MetaByte satın aldıkça işlem geçmişinizi bu sayfadan görüntüleyebilirsiniz. "
            button={{
              child: (
                <div className="d-flex align-items-center">
                  <img src={add} alt="add"/>
                  &nbsp;&nbsp;
                  <span>MetaByte Al</span>
                </div>
              ),
              callback: () => history.push(router.ORDER)
            }}
          />
        );
      }
      return <OrderHistoryTable data={data}/>;
    }
  }

  render() {
    return (
      <div id="order-history-page" className="page">
        <div className="page-content">
          <PageTitle text="MetaByte Geçmişi"/>
          {this.renderContent()}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getOrderHistory,
    },
    dispatch
  );
};
const mapStateToProps = (store: IStore) => {
  return {
    getOrderHistoryState: store.getOrderHistory,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderHistoryPage);
