import {Form, Input} from "antd";
import React, {ReactNode, useState} from 'react';
import add from "../../../../../assets/images/landing/try-bulk-add-msisdn.svg";
import {FormError} from "../../../../../core/models/custom/formError";
import {FormValuesTryBulkStepMsisdnList} from "../../../../../core/models/custom/formValuesTryBulkStepMsisdnList";
import {Constants} from "../../../../../core/utilities/constants";
import TryBulkButtons from "../TryBulkButtons/TryBulkButtons";
import "./TryBulkStepMsisdnList.scss";

interface IProps {
  isLoading: boolean;
  callbackSubmit: (values: FormValuesTryBulkStepMsisdnList) => void;
  callbackCancel: () => void;
}

enum MsisdnId {
  msisdn1 = 'msisdn1',
  msisdn2 = 'msisdn2',
  msisdn3 = 'msisdn3',
}

const TryBulkStepMsisdnList = (props: IProps) => {
  const [msisdnNo, setMsisdnNo] = useState(1);
  const [isSubmitted, setSubmitted] = useState(false);
  const [isMsisdnError1, setMsisdnError1] = useState(false);
  const [isMsisdnError2, setMsisdnError2] = useState(false);
  const [isMsisdnError3, setMsisdnError3] = useState(false);
  const [form] = Form.useForm();

  function handleAddMsisdn(): void {
    if (msisdnNo === 3) {
      return;
    }
    setMsisdnNo(prev => prev + 1);
  }

  function handleMsisdnChange(msisdn: string, id: MsisdnId): void {
    if (msisdn) {
      if (msisdn.substring(0, 1) !== '5') {
        switch (id) {
          case MsisdnId.msisdn1:
            form.setFieldsValue({msisdn1: ''});
            break;
          case MsisdnId.msisdn2:
            form.setFieldsValue({msisdn2: ''});
            break;
          case MsisdnId.msisdn3:
            form.setFieldsValue({msisdn3: ''});
            break;
        }
      } else if (msisdn.length > Constants.msisdnLength) {
        switch (id) {
          case MsisdnId.msisdn1:
            form.setFieldsValue({msisdn1: msisdn.substring(0, Constants.msisdnLength)});
            setMsisdnError1(false);
            break;
          case MsisdnId.msisdn2:
            form.setFieldsValue({msisdn2: msisdn.substring(0, Constants.msisdnLength)});
            setMsisdnError2(false);
            break;
          case MsisdnId.msisdn3:
            form.setFieldsValue({msisdn3: msisdn.substring(0, Constants.msisdnLength)});
            setMsisdnError3(false);
            break;
        }
      } else if (isSubmitted) {
        switch (id) {
          case MsisdnId.msisdn1:
            setMsisdnError1(msisdn.length !== Constants.msisdnLength);
            break;
          case MsisdnId.msisdn2:
            setMsisdnError2(msisdn.length !== Constants.msisdnLength);
            break;
          case MsisdnId.msisdn3:
            setMsisdnError3(msisdn.length !== Constants.msisdnLength);
            break;
        }
      }
    }
  }

  function handleMsisdnKeyPress(e: React.KeyboardEvent): void {
    if (isNaN(parseInt(e.key))) {
      e.preventDefault();
    }
  }

  function handleFail(e: any): void {
    setSubmitted(true);
    setMsisdnError1((e as FormError).errorFields.filter(err => err.name.includes('msisdn1')).length > 0);
    setMsisdnError2((e as FormError).errorFields.filter(err => err.name.includes('msisdn2')).length > 0);
    setMsisdnError3((e as FormError).errorFields.filter(err => err.name.includes('msisdn3')).length > 0);
  }

  function handleFinish(values: FormValuesTryBulkStepMsisdnList): void {
    props.callbackSubmit({
      msisdn1: `90${values.msisdn1}`,
      msisdn2: values.msisdn2 ? `90${values.msisdn2}` : undefined,
      msisdn3: values.msisdn3 ? `90${values.msisdn3}` : undefined,
    });
  }

  function renderMsisdn(id: MsisdnId): ReactNode {
    let className = "app-input";
    switch (id) {
      case MsisdnId.msisdn1:
        className += isMsisdnError1 ? ' error' : '';
        break;
      case MsisdnId.msisdn2:
        className += isMsisdnError2 ? ' error' : '';
        break;
      case MsisdnId.msisdn3:
        className += isMsisdnError3 ? ' error' : '';
        break;
    }
    return (
      <div
        key={id}
        className={className}
      >
        <span className="prefix">+90</span>
        <div className="input-wrapper">
          <span className="label">Telefon numarası</span>
          <Form.Item
            name={id}
            className="input"
            rules={[
              {required: true, message: ''},
              {len: Constants.msisdnLength, message: ''},
            ]}
          >
            <Input
              type="tel"
              onChange={e => handleMsisdnChange(e.target.value, id)}
              onKeyPress={handleMsisdnKeyPress}
            />
          </Form.Item>
        </div>
      </div>
    );
  }

  return (
    <Form
      form={form}
      id="try-bulk-step-msisdn-list"
      onFinishFailed={handleFail}
      onFinish={handleFinish}
    >
      <span className="text">
        Hızlı test etmek için yükleme yapmak istediğiniz telefon numarasını yazın.
      </span>

      {renderMsisdn(MsisdnId.msisdn1)}
      {msisdnNo >= 2 && renderMsisdn(MsisdnId.msisdn2)}
      {msisdnNo >= 3 && renderMsisdn(MsisdnId.msisdn3)}

      {
        msisdnNo !== 3 &&
        <img
          className="button-add" src={add} alt="add"
          onClick={handleAddMsisdn}
        />
      }

      <span className="text">
      Girdiğiniz numaralar SMS ile bilgilendirilecek. Adımları takip ederek testi tamamlayabilirsiniz.
      </span>

      <TryBulkButtons
        textOk="Toplu yükle"
        isLoading={props.isLoading}
        callbackCancel={props.callbackCancel}
      />
    </Form>
  );
}

export default TryBulkStepMsisdnList;
