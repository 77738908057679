import {PayDto} from '../../../models/dtos/pay.dto';
import axios from '../../../utilities/axios';

export const payRequest = async (orderId: number): Promise<PayDto> => {
  try {
    const response = await axios.post<PayDto>(
      `${process.env.REACT_APP_BASE_URL}/orders/${orderId}/start-payment`,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
