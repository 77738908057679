import { OrderDto } from '../../../models/dtos/order.dto';
import { MoneyTransferOrderRequest } from '../../../models/requests/moneyTransferOrder.request';
import axios from '../../../utilities/axios';

export const createMoneyTransferOrderRequest = async (request: MoneyTransferOrderRequest): Promise<OrderDto> => {
  try {
    const response = await axios.post<OrderDto>(
      `${process.env.REACT_APP_BASE_URL}/orders/money-transfer-order`,
      {...request}
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
