import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import button from "../../assets/images/account-balance-button.svg";
import wallet from "../../assets/images/account-balance-wallet.svg";
import {Helpers} from "../../core/utilities/helpers";
import {history} from "../../core/utilities/history";
import {IStore} from "../../core/utilities/reducers";
import {router} from "../../core/utilities/router";
import "./AccountBalance.scss";

interface IProps {
  balance?: number;
}

const AccountBalance = (props: IProps) => {

  function getBalance(): string | undefined {
    const balance = props.balance;
    if (balance) {
      return Helpers.getPointEquivalent(balance);
    } else if (balance === 0) {
      return '0';
    }
    return undefined;
  }

  return (
    <div id="account-balance">
      <div className="balance-wrapper">
        <label className={`balance ${!props.balance && 'empty'}`}>
          {getBalance()}
          <span className="suffix">MetaByte</span>
        </label>
        <img
          className="button"
          src={button}
          alt="button"
          onClick={() => history.push(router.ORDER)}
        />
      </div>
      <div className="label-wrapper">
        <img src={wallet} alt="wallet"/>
        <label className="label">
          mevcut bakiye
          &nbsp;&nbsp;
          {/* *1000 MetaByte, Günlük 1 GB değerindedir. */}
        </label>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({}, dispatch);
};
const mapStateToProps = (store: IStore) => {
  const data = store.getUsage.data;
  let balance;
  if (data) {
    balance = Helpers.getRemainingPoint1000(data);
  }
  return {
    balance: balance,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountBalance);
