import { LoadingOutlined } from "@ant-design/icons";
import { Form, Input, InputNumber } from "antd";
import React, { ReactNode, useEffect, useState } from "react";
import { connect } from "react-redux";
import copyButton from "../../../../assets/images/copy-button.svg";
import fileUploaded from "../../../../assets/images/file-uploaded.svg";
import fileUpload from "../../../../assets/images/receipt-upload-icon.svg";
import { FormValuesCompany } from "../../../../core/models/custom/formValuesCompany";
import { MeDto } from "../../../../core/models/dtos/me.dto";
import { MoneyTransferOrderRequest } from "../../../../core/models/requests/moneyTransferOrder.request";
import { SetApiErrorState } from "../../../../core/services/appService/setApiError/types";
import { GetAllOffersState } from "../../../../core/services/offerService/getAllOffers/types";
import { Constants } from "../../../../core/utilities/constants";
import { Errors } from "../../../../core/utilities/errors";
import { Helpers } from "../../../../core/utilities/helpers";
import { IStore } from "../../../../core/utilities/reducers";
import AppButton, { AppButtonType } from "../../../Buttons/AppButton/AppButton";
import FileUpload from "../../../FileUpload/FileUpload";
import FirebaseKey from "../../../FirebaseKey/FirebaseKey";
import CopyBox from "../../TryModals/CopyBox/CopyBox";
import "./CompanyForm.scss";

interface IProps {
  apiErrorState: SetApiErrorState;
  amountValue?: number;
  isSubmitting: boolean;
  callbackSubmit: (request: MoneyTransferOrderRequest) => void;
  getAllOffersState: GetAllOffersState;
  me?: MeDto;
  onCancel: () => void;
  callbackProgress: (activePage: string) => void;
}



const CompanyForm = (props: IProps) => {
  // const [form] = Form.useForm();
  const [activePage, setActivePage] = useState("first");
  const [chosenAmount, setChosenAmount] = useState(props.amountValue);
  const [isFileUploaded, setFileUploadStatus] = useState(false);
  const [formValues, setFormValues] = useState<MoneyTransferOrderRequest>();
  const [fileUploadBoxClass, setFileUploadBoxClass] = useState("file-upload");

  useEffect(() => {
    props.callbackProgress(activePage)
    // eslint-disable-next-line
  }, [activePage])
  
  const offer =
    props.getAllOffersState.data && props.getAllOffersState.data.length > 0
      ? props.getAllOffersState.data[0]
      : undefined;
  console.log(props.getAllOffersState.data?.length);

  function renderFirstTabButton(): ReactNode {
    return (
      <AppButton
        htmlType="submit"
        content={<FirebaseKey firebaseKey="havale_popup_continue_button" />}
        onClick={() => { }}
      />
    );
  }

  function renderSecondTabButton(): ReactNode {
    return (
      <AppButton
        content={<FirebaseKey firebaseKey="havale_popup_continue_button" />}
        onClick={() => { handleSecondTabButtonClick() }}
      />
    );
  }

  function renderThirdTabButton(): ReactNode {
    return (
      <AppButton
        content={<FirebaseKey firebaseKey="havale_succesfull_popup_button" />}
        onClick={() => { }}
      />
    );
  }

  function handleFinish(values: FormValuesCompany): void {
    const request: MoneyTransferOrderRequest = {
      businessName: values.businessName,
      taxAddress: values.taxAddress,
      taxNumber: values.taxNumber,
      taxOffice: values.taxOffice,
      offers: [
        {
          offerId: offer?.id,
          amount: values.amount,
        },
      ],
      receiptUrl: "",
    };
    console.log(request);
    setFormValues(
      {
        ...request
      }
    )
    setActivePage("second");
    setTimeout(() => {
      console.log(formValues);
    }, 1000); console.log(formValues);
  }

  async function handleSecondTabButtonClick() {
    if (isFileUploaded) {
      console.log(formValues);
      props.callbackSubmit(formValues!)
    } else {
      setFileUploadBoxClass("warning-file-upload")
    }
  }

  async function handleCallbackFileUpload(url: string) {
    await setFormValues({
      ...formValues!,
      receiptUrl: url
    })
    setFileUploadStatus(true);
    setFileUploadBoxClass("file-upload")
  }

  function handleFileUploadError(error: any) {
    console.log(error);
    alert(Constants.inputErrorFileUpload);
  }

  return (
    <Form
      // form={form}
      id="company-form"
      onFinish={handleFinish}
      initialValues={{
        businessName: props.me?.company.name,
        taxOffice: "",
        taxNumber: props.me?.company.taxNumber,
        taxAddress: props.me?.company.address,
        amount: props.amountValue || 0,
      }}
    >

      <div className={`company-form-${activePage}`}>
        <div className="form-item-first">

          <label>Bilgiler</label>
          <Form.Item
            name="businessName"
            rules={[
              { required: true, message: "Bu alanın doldurulması gerekmektedir." },
            ]}
          >
            <Input className="form-input" placeholder="Ticaret Ünvanı" />
          </Form.Item>
          <Form.Item
            name="taxOffice"
            rules={[
              { required: true, message: "Bu alanın doldurulması gerekmektedir." },
            ]}
          >
            <Input className="form-input" placeholder="Vergi Dairesi" />
          </Form.Item>
          <Form.Item
            name="taxNumber"
            rules={[
              { required: true, message: Errors.invalidVkn},
            ]}
          >
            <Input className="form-input" placeholder="Vergi No" />
          </Form.Item>
          <Form.Item
            name="taxAddress"
            rules={[
              { required: true, message: "Bu alanın doldurulması gerekmektedir." },
            ]}
          >
            <Input className="form-input" placeholder="Address" />
          </Form.Item>
          <label className="offer-label">MetaByte adedi</label>
          <div className="offer-wrapper">
            <div className="info">
              <div className="description">
                <span className="point">{offer?.name}</span>
                <span className="name">{offer?.value} değerindedir.</span>
              </div>
              <div className="price-box">
                <span className="price">
                  {Helpers.getPriceWithCurrency(offer?.price ?? 0)}
                </span>
                <br />
                <span>/adet</span>
              </div>
            </div>
          </div>
          <Form.Item
            noStyle
            shouldUpdate={(prevValue, nextValue) =>
              prevValue.amount !== nextValue.amount
            }
          >
            {({ getFieldValue, setFieldsValue }) => {
              const handleAmountUpdate = (amount: number) => {
                setFieldsValue({ amount: getFieldValue("amount") + amount });
                setChosenAmount(chosenAmount! + amount)
                console.log(chosenAmount);
              };

              return (
                <div className="price-amount-container">
                  <div className="input-box">
                    <div
                      className="adjust-button decrement"
                      onClick={() =>
                        getFieldValue("amount") > 0 ? handleAmountUpdate(-1) : false
                      }
                    >
                      -
                    </div>
                    <Form.Item
                      name="amount"
                      rules={[{ required: true, message: "Adet girin" }]}
                    >
                      <InputNumber className="input" placeholder="Adet" min={0} />
                    </Form.Item>
                    <div
                      className="adjust-button increment"
                      onClick={() => handleAmountUpdate(+1)}
                    >
                      +
                    </div>
                  </div>
                  <div className="total-amount">
                    {Helpers.getPriceWithCurrency(
                      getFieldValue("amount") * (offer?.price ?? 0)
                    )}
                  </div>
                </div>
              );
            }}
          </Form.Item>

        </div>
      </div>


      <div className={`second-tab-form-${activePage}`}>
        <div className="second-tab">
          <div className="chosen-amount">
            <div className="left">{offer?.name} / {chosenAmount} AD.</div>
            <div className="right">
              {Helpers.getPriceWithCurrency(
                offer!.price * chosenAmount!
              )}
            </div>
          </div>

          <div className={`company-iban ${activePage}`}>
            <label className="iban-label">IBAN</label>
            <div className="copy-section mb-2">
              <CopyBox
                text="TR24 0006 2000 4930 0006 2950 64"
                copyButton={copyButton}
              />
            </div>
            <FirebaseKey firebaseKey="havale_popup_bank_info" />
            <br />
            <FirebaseKey firebaseKey="havale_popup_note" />
          </div>

          <div className="border">

          </div>

          <div className={`${fileUploadBoxClass}`}>
            <FileUpload
              child={
                <div
                  className="file-upload-dragger"
                >
                  {
                    !isFileUploaded ?
                      <>
                        <img className="file-upload-image" src={fileUpload} alt="file upload" />
                        <p className="upload-text">
                          <span className="bold">Dekontu yüklemek için tıkla</span>
                          &nbsp;ya da sürükle bırak
                          <br />
                        </p>
                      </>
                      :
                      <img className="file-upload-image" src={fileUploaded} alt="file upload" />
                  }
                </div>
              }
              callbackUpload={(url) => handleCallbackFileUpload(url)}
              callbackFail={handleFileUploadError}
            />
          </div>
          <div className={`${fileUploadBoxClass}-text`}>
            Dekontun yüklenmesi gerekmektedir.
          </div>
        </div>
      </div>

      {props.isSubmitting ? (
        <div className="loading-wrapper">
          <LoadingOutlined className="loading" />
        </div>
      ) : (
        <div className="button-wrapper">
          <AppButton
            type={AppButtonType.outline}
            content={<FirebaseKey firebaseKey="havale_popup_cancel_button" />}
            onClick={() => props.onCancel()}
          />
          {
            activePage === "first" ?

              <React.Fragment>
                {renderFirstTabButton()}
              </React.Fragment>
              :
              activePage === "second" ?
                <React.Fragment>
                  {renderSecondTabButton()}
                </React.Fragment>

                :
                activePage === "third" &&

                <React.Fragment>
                  {renderThirdTabButton()}
                </React.Fragment>

          }

        </div>
      )}
    </Form>
  );
};

const mapStateToProps = (store: IStore) => {
  return {
    me: store.setMe.me,
    getAllOffersState: store.getAllOffers,
    apiErrorState: store.setApiError,
  };
};

export default connect(mapStateToProps)(CompanyForm);
