import { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import ProfileForm from '../../../components/ProfileForm/ProfileForm';
import { ProfileValues } from '../../../core/models/custom/profileValues';
import { MeDto } from '../../../core/models/dtos/me.dto';
import { WalletDetailsDto } from '../../../core/models/dtos/walletDetails.dto';
import { AgencyRevenueRequest } from '../../../core/models/requests/agencyRevenue.request';
import { RegisterRequest } from '../../../core/models/requests/register.request';
import { claimRevenue,claimRevenueReset } from '../../../core/services/agencyService/claimRevenue/actions';
import { ClaimRevenueState } from '../../../core/services/agencyService/claimRevenue/types';
import { refresh } from '../../../core/services/authService/refresh/actions';
import { getWalletDetails } from '../../../core/services/agencyService/getWalletDetails/actions';
import { GetWalletDetailsState } from '../../../core/services/agencyService/getWalletDetails/types';
import { register } from '../../../core/services/userService/register/actions';
import { RegisterState } from '../../../core/services/userService/register/types';
import { Helpers } from '../../../core/utilities/helpers';
import { IStore } from '../../../core/utilities/reducers';
import './RegistrationPage.scss';
import logo from '../../../assets/images/logo.svg'

interface IProps {
  me?: MeDto;
  registerState: RegisterState;
  register: (request: RegisterRequest) => void;
  getWalletDetailsState: GetWalletDetailsState;
  getWalletDetails: () => void;
  refresh: () => void;
  claimRevenueState: ClaimRevenueState;
  claimRevenue: (request:AgencyRevenueRequest) => void;
  claimRevenueReset: () => void;
}

interface IState {
  walletDetail?: WalletDetailsDto;
}

class RegistrationPage extends Component<IProps> {
  state: IState = {
    walletDetail: undefined,
  }

  componentDidMount() {
    if (this.props.me?.company.isAgency) {
      this.props.getWalletDetails()
    }
    this.props.refresh()
  }

  private handleRegister(values: ProfileValues): void {
    const request: RegisterRequest = {
      address: values.profile.address,
      billingAddress: values.isSameBillingAddress ? values.profile.address : values.profile.billingAddress ?? values.profile.address,
      billingCity: values.isSameBillingAddress ? values.profile.city : values.profile.billingCity ?? values.profile.city,
      billingCountry: values.isSameBillingAddress ? values.profile.country : values.profile.billingCountry ?? values.profile.country,
      city: values.profile.city,
      companyPhoneNumber: values.profile.companyPhoneNumber,
      country: values.profile.country,
      msisdn: values.profile.msisdn,
      isAgency: values.profile.isAgency,
      referenceCode: values.profile.referenceCode,
      signature: values.signature,
      signedContract: values.signedContract,
      smsName: values.profile.smsName,
      taxNumber: values.profile.taxNumber,
      taxPlate: values.taxPlate,
      tckn: values.profile.tckn,
    };
    this.props.register(request);
  }

  private handleModalWithdrawSubmit(request: AgencyRevenueRequest ): void {
    this.props.claimRevenue(request)
  }

  private renderContent(): ReactNode {
    
      return (
        this.props.me &&
        <ProfileForm
        getWalletDetailsState={this.props.getWalletDetailsState}
        callback={() => this.props.claimRevenueReset()}
        isVisible={!!this.props.claimRevenueState.data}
        isSubmittedWithdraw={this.props.claimRevenueState.loading}
        callbackOk={request => this.handleModalWithdrawSubmit(request)}
        initialValues={Helpers.getInitialValuesRegister(this.props.me)}
        isRegistration={true}
        isSubmitting={this.props.registerState.loading}
        callbackSubmit={(values) => this.handleRegister(values)}
      />
      );
    }

  render() {
    return (
      <div id="registration-page" className="page">
        <div className="page-content">
          <div className="content">
            <div className="description">
                <img className='logo' src={logo} alt="logo" />
            </div>
            {this.renderContent()}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      refresh,
      register,
      getWalletDetails,
      claimRevenue,
      claimRevenueReset,
    },
    dispatch
  );
};
const mapStateToProps = (store: IStore) => {
  return {
    me: store.setMe.me,
    registerState: store.register,
    getWalletDetailsState: store.getWalletDetails,
    claimRevenueState: store.claimRevenue,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationPage);
