/**
 * https://github.com/Snowradar/react-credit-cards
 * https://codesandbox.io/s/ovvwzkzry9
 */

export enum CardType {
  visa = 'visa',
  master = 'master',
}

export class CreditCard {

  private static clearNumber(value = ""): string {
    return value.replace(/\D+/g, "");
  }

  public static formatCreditCardNumber(value: string): string {
    if (!value) {
      return value;
    }
    const clearValue = this.clearNumber(value);
    const nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 8)} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 16)}`;
    return nextValue.trim();
  }

  public static formatCVC(value: string): string {
    const clearValue = this.clearNumber(value);
    const maxLength = 3;
    return clearValue.slice(0, maxLength);
  }

  public static formatExpirationDate(value: string): string {
    const clearValue = this.clearNumber(value);
    if (clearValue.length >= 3) {
      return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
    }
    return clearValue;
  }

  public static getCardType(card: string): CardType {
    return card.charAt(0) === '4' ? CardType.visa : CardType.master;
  }

  public static formatIBAN(value:string):string {
    if (!value) {
      return value;
    }
    const clearValue = this.clearNumber(value);
    const nextValue = `${clearValue.slice(0, 2)} ${clearValue.slice(2, 6)} ${clearValue.slice(6, 10)} ${clearValue.slice(10, 14)} ${clearValue.slice(14, 18)} ${clearValue.slice(18,22)} ${clearValue.slice(22, 24)}`;
    return nextValue.trim();
  }
}
