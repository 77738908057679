import {Form, Input, Select} from "antd";
import React, {useState} from 'react';
import right from "../../../../assets/images/chevron-right.svg";
import AppButton from "../../../../components/Buttons/AppButton/AppButton";
import {FormValuesCampaignPackage} from "../../../../core/models/custom/formValuesCampaignPackage";
import {UsageDto} from "../../../../core/models/dtos/usage.dto";
import {Helpers} from "../../../../core/utilities/helpers";
import "./CampaignPackage.scss";

const {Option} = Select;

interface IProps {
  usages: UsageDto[];
  callbackChange: () => void;
  callbackSubmit: (values: FormValuesCampaignPackage) => void;
}

const CampaignPackage = (props: IProps) => {
  const [isAmountBiggerThanBalanceWarningVisible, setAmountBiggerThanBalanceWarningVisible] = useState(false);
  const [form] = Form.useForm();
  const balance = Helpers.getRemainingPoint1000(props.usages);

  function getInitialValues(): Object {
    return {offerId: props.usages.length === 1 ? props.usages[0].offer.id : ''}
  }

  function handleAmountKeyPress(e: React.KeyboardEvent): void {
    if (isNaN(parseInt(e.key))) {
      e.preventDefault();
    }
  }

  function handleAmountChange(amount: string): void {
    if (parseInt(amount) > balance) {
      setAmountBiggerThanBalanceWarningVisible(true);
      form.setFieldsValue({amount: balance});
    } else {
      setAmountBiggerThanBalanceWarningVisible(false);
    }
  }

  function handleChange() {
    props.callbackChange()
  }

  function handleFinish(values: FormValuesCampaignPackage) {
    setAmountBiggerThanBalanceWarningVisible(false);
    props.callbackSubmit(values);
  }

  return (
    <Form
      form={form}
      id="campaign-package"
      className="form"
      initialValues={getInitialValues()}
      onChange={handleChange}
      onFinish={handleFinish}
    >
      <div className="d-flex inputs">
        <Form.Item
          name="offerId"
          className="form-item w-50 mb-0 me-2"
          rules={[{required: true, message: 'Paket seçin'}]}
        >
          <Select
            className="form-input select"
            disabled={props.usages.length === 1}
          >
            {props.usages.map(i => {
              return (
                <Option key={i.offer.id} value={i.offer.id}>
                  {i.offer.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          name="amount"
          className="form-item w-50 mb-0"
          rules={[
            {required: true, message: 'Adet girin'},
          ]}
        >
          <Input
            className="form-input"
            placeholder="Adet"
            type="number"
            autoFocus
            min={1}
            max={balance}
            onKeyPress={handleAmountKeyPress}
            onChange={e => handleAmountChange(e.target.value)}
          />
        </Form.Item>
      </div>

      <div className="d-flex inputs">
        <Form.Item className="form-item w-50 me-2 mb-0"/>
        <Form.Item className="form-item w-50 mb-0">
          <label className="balance">
            mevcut bakiye: 
            <span className="tag"> {Helpers.getPointEquivalent(balance)}</span>
            &nbsp;MetaByte
          </label>
        </Form.Item>
      </div>

      {
        isAmountBiggerThanBalanceWarningVisible &&
        <Form.Item className="amount-warning form-item">
          Girdiğiniz adet mevcut bakiyenizi aşıyor. MetaByte adedi bakiyeniz ile değiştirildi.
        </Form.Item>
      }

      <AppButton
        htmlType="submit"
        content={
          <div className="d-flex align-items-center">
            <span>Devam Et</span>
            &nbsp;&nbsp;
            <img src={right} alt="right"/>
          </div>
        }
        onClick={() => {
        }}/>
    </Form>
  );
}

export default CampaignPackage;
