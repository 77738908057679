import {
  SAVE_CARD_FAIL,
  SAVE_CARD_RESET,
  SAVE_CARD_START,
  SAVE_CARD_SUCCESS,
} from './constants';
import {SaveCardActions, SaveCardState} from './types';

const initialState: SaveCardState = {
  loading: false,
  data: undefined,
  error: undefined,
};

const reducer = (
  state = initialState,
  action: SaveCardActions,
): SaveCardState => {
  switch (action.type) {
    case SAVE_CARD_START:
      return {
        ...initialState,
        loading: true,
      };
    case SAVE_CARD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case SAVE_CARD_FAIL:
     return {
        ...state,
        loading: false,
        error: action.error,
      };
    case SAVE_CARD_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
