import {
  UPDATE_ME_FAIL,
  UPDATE_ME_RESET,
  UPDATE_ME_START,
  UPDATE_ME_SUCCESS,
} from './constants';
import {UpdateMeActions, UpdateMeState} from './types';

const initialState: UpdateMeState = {
  loading: false,
  data: false,
  error: undefined,
};

const reducer = (
  state = initialState,
  action: UpdateMeActions,
): UpdateMeState => {
  switch (action.type) {
    case UPDATE_ME_START:
      return {
        ...initialState,
        loading: true,
      };
    case UPDATE_ME_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case UPDATE_ME_FAIL:
     return {
        ...state,
        loading: false,
        error: action.error,
      };
    case UPDATE_ME_RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default reducer;
