import {Dispatch} from 'redux';
import {ErrorDto, getErrorDtoFromApiError} from '../../../models/dtos/error.dto';
import {GenerateLinksRequest} from '../../../models/requests/generateLinks.request';
import {getUsageSuccessAction} from "../../offerService/getUsage/actions";
import {getUsageRequest} from "../../offerService/getUsage/repository";
import {GENERATE_LINKS_FAIL, GENERATE_LINKS_RESET, GENERATE_LINKS_START, GENERATE_LINKS_SUCCESS,} from './constants';
import {generateLinksRequest} from './repository';
import {
  GenerateLinksFailAction,
  GenerateLinksResetAction,
  GenerateLinksStartAction,
  GenerateLinksSuccessAction,
} from './types';

const generateLinksStartAction = (): GenerateLinksStartAction => {
  return {
    type: GENERATE_LINKS_START,
  };
};
const generateLinksSuccessAction = (payload: string): GenerateLinksSuccessAction => {
  return {
    type: GENERATE_LINKS_SUCCESS,
    payload: payload,
  };
};
const generateLinksFailAction = (error: ErrorDto): GenerateLinksFailAction => {
  return {
    type: GENERATE_LINKS_FAIL,
    error: error,
  };
};
const generateLinksResetAction = (): GenerateLinksResetAction => {
  return {
    type: GENERATE_LINKS_RESET,
  };
};

export const generateLinks = (request: GenerateLinksRequest) => async (dispatch: Dispatch) => {
  try {
    dispatch(generateLinksStartAction());
    const response = await generateLinksRequest(request);
    const usage = await getUsageRequest();
    dispatch(getUsageSuccessAction(usage));
    dispatch(generateLinksSuccessAction(response));
  } catch (error) {
    dispatch(generateLinksFailAction(getErrorDtoFromApiError(error)));
  }
};

export const generateLinksReset = () => (dispatch: Dispatch) => {
  dispatch(generateLinksResetAction());
};
