import axios from '../../../utilities/axios';
import {AddSideUserRequest} from '../../../models/requests/addSideUser.request';

export const addSideUserRequest = async (request: AddSideUserRequest): Promise<void> => {
  try {
    const response = await axios.post<void>(
      `${process.env.REACT_APP_BASE_URL}/users/side-user`,
      {...request},
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
