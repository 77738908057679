import React, { ReactNode, useState } from 'react';
import featureAutoRenew from "../../../../assets/images/campaign-feature-auto-renew.svg";
import featureNoNumber from "../../../../assets/images/campaign-feature-no-number-required.svg";
import featureNonRefundable from "../../../../assets/images/campaign-feature-non-refundable.svg";
import featureNumber from "../../../../assets/images/campaign-feature-number-required.svg";
import featureOnlyDesktop from "../../../../assets/images/campaign-feature-only-desktop.svg";
import featureOnlyMobile from "../../../../assets/images/campaign-feature-only-mobile.svg";
import typeApi from "../../../../assets/images/campaign-type-api.svg";
import typeBulk from "../../../../assets/images/campaign-type-bulk.svg";
import typeCode from "../../../../assets/images/campaign-type-code.svg";
import typeLink from "../../../../assets/images/campaign-type-link.svg";
import typeSelectionActive from "../../../../assets/images/campaign-type-selection-active.png";
import typeSelectionPassive from "../../../../assets/images/campaign-type-selection-passive.png";
import rightPrimary from "../../../../assets/images/chevron-right-primary.svg";
import right from "../../../../assets/images/chevron-right.svg";
import AppButton from "../../../../components/Buttons/AppButton/AppButton";
import { CampaignType } from "../../../../core/models/enums/campaignType";
import "./CampaignMethod.scss";

interface IProps {
  callbackChange: () => void;
  callbackSubmit: (type: CampaignType) => void;
  callbackUsage: (type: CampaignType) => void;
}

enum Feature {
  number_required = 'number_required',
  no_number_required = 'no_number_required',
  non_refundable = 'non_refundable',
  auto_renew = 'auto_renew',
  only_mobile = 'only_mobile',
  only_desktop = 'only_desktop',
}

const CampaignMethod = (props: IProps) => {
  const [selectedType, setSelectedType] = useState<CampaignType>();

  function handleSelect(type: CampaignType) {
    setSelectedType(type);
    props.callbackChange();
  }

  function getContentText(type: CampaignType): string {
    switch (type) {
      case CampaignType.CODE:
        return "MetaByte'larınızı tek kullanımlık kodlara çevirerek müşterilerinize hediye edebilirsiniz.";
      case CampaignType.LINK:
        return "MetaByte'larınızı tek kullanımlık linklere çevirerek müşterilerinize hediye edebilirsiniz.";
      case CampaignType.BULK:
        return "MetaByte'larınızı telefon numarası listesi yükleyerek müşterilerinize hediye edebilirsiniz.";
      case CampaignType.API:
        return "MetaByte'larınızı sisteme entegre olarak müşterilerinize hediye edebilirsiniz.";
      default:
        return '';
    }
  }

  function renderFeature(feature: Feature): ReactNode {
    let icon;
    let text;
    switch (feature) {
      case Feature.no_number_required:
        icon = featureNoNumber;
        text = 'TELEFON NUMARASI PAYLAŞMAK GEREKMEZ.';
        break;
      case Feature.number_required:
        icon = featureNumber;
        text = 'TELEFON NUMARASI PAYLAŞMAK GEREKLİDİR.';
        break;
      case Feature.non_refundable:
        icon = featureNonRefundable;
        text = 'İADE EDİLEMEZ';
        break;
      case Feature.auto_renew:
        icon = featureAutoRenew;
        text = 'BAKİYE OTOMATİK YENİLENEBİLİR.';
        break;
      case Feature.only_mobile:
        icon = featureOnlyMobile;
        text = 'SADECE MOBİL KULLANIM İÇİN UYGUNDUR.';
        break;
      case Feature.only_desktop:
        icon = featureOnlyDesktop;
        text = 'SADECE DESKTOP VE OFFLINE İÇİN ÖNERILIR';
        break;
    }
    return (
      <div className={`feature ${feature.toLowerCase()}`}>
        <img src={icon} alt="feature" />
        <label className="feature-text">{text}</label>
      </div>
    );
  }

  function renderHeader(type: CampaignType): ReactNode {
    let icon;
    let title;
    const selection = selectedType === type ? typeSelectionActive : typeSelectionPassive;
    switch (type) {
      case CampaignType.CODE:
        icon = typeCode;
        title = 'Kod ile yükle';
        break;
      case CampaignType.LINK:
        icon = typeLink;
        title = 'Link ile yükle';
        break;
      case CampaignType.BULK:
        icon = typeBulk;
        title = 'Toplu yükle';
        break;
      case CampaignType.API:
        icon = typeApi;
        title = 'API ile yükle';
        break;
    }
    return (
      <div className="header">
        <img className="icon" src={icon} alt="code" />
        <label className="title">{title}</label>
        <img className="selection" src={selection} alt="active" />
      </div>
    );
  }

  function renderFeatures(type: CampaignType): ReactNode {
    switch (type) {
      case CampaignType.CODE:
        return (
          <React.Fragment>
            {renderFeature(Feature.no_number_required)}
            {renderFeature(Feature.non_refundable)}
          </React.Fragment>
        );
      case CampaignType.LINK:
        return (
          <React.Fragment>
            {renderFeature(Feature.no_number_required)}
            {renderFeature(Feature.only_mobile)}
            {renderFeature(Feature.non_refundable)}
          </React.Fragment>
        );
      case CampaignType.BULK:
        return (
          <React.Fragment>
            {renderFeature(Feature.number_required)}
          </React.Fragment>
        );
      case CampaignType.API:
        return (
          <React.Fragment>
            {renderFeature(Feature.number_required)}
            {renderFeature(Feature.auto_renew)}
          </React.Fragment>
        );
      default:
        return <React.Fragment />;
    }
  }

  function renderCampaignType(type: CampaignType): ReactNode {
    return (
      <div
        key={type}
        className={`campaign-type ${type.toLowerCase()} ${selectedType === type && 'active'}`}
        onClick={() => handleSelect(type)}
      >
        {renderHeader(type)}
        <div className="content">
          <label className="text">
            {getContentText(type)}
          </label>
          <div className="features">
            {renderFeatures(type)}
          </div>
          <div
            className="how-to-link"
            onClick={() => props.callbackUsage(type)}
          >
            nasıl kullanılır?
            <img className="icon" src={rightPrimary} alt="how to" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div id="campaign-method">
      <div className="methods">
        {renderCampaignType(CampaignType.CODE)}
        {renderCampaignType(CampaignType.LINK)}
        {renderCampaignType(CampaignType.BULK)}
        {renderCampaignType(CampaignType.API)}
      </div>

      <AppButton
        content={
          <div className="d-flex align-items-center">
            <span>Devam Et</span>
            &nbsp;&nbsp;
            <img src={right} alt="right" />
          </div>
        }
        disabled={!selectedType}
        onClick={() => props.callbackSubmit(selectedType!)}
      />
    </div>
  );
}

export default CampaignMethod;
