import {Modal} from "antd";
import React from 'react';
import add from "../../../assets/images/add-primary.svg";
import icon from "../../../assets/images/modal-icon-success.svg";
import {PackageOrder} from "../../../containers/App/OrderPage/OrderPage";
import {Helpers} from "../../../core/utilities/helpers";
import {router} from "../../../core/utilities/router";
import {UI} from "../../../core/utilities/ui";
import AppButton, {AppButtonType} from "../../Buttons/AppButton/AppButton";
import "./OrderSuccessModal.scss";

interface IProps {
  package: PackageOrder;
  callback: (route: string) => void;
}

const OrderSuccessModal = (props: IProps) => {

  return (
    <Modal
      className="order-success-modal"
      maskStyle={UI.modalMaskStyle()}
      closable={false}
      footer={false}
      visible
      centered
      destroyOnClose
      onCancel={() => props.callback(router.HOME)}
    >
      <div className="content">
        <img className="icon" src={icon} alt="icon"/>
        <label className="title">
        MetaByte'lar hesabınıza yüklendi!
        </label>
        <div className="package-info">
          <img src={add} alt="add"/>
          <label className="package">
            {`${props.package.offer.name} / ${props.package.amount}`}&nbsp;AD.
          </label>
          <label className="total">
            {Helpers.getPriceWithCurrency(props.package.offer.price * props.package.amount)}
          </label>
        </div>
        <AppButton
          content="Kampanya Oluştur"
          onClick={() => props.callback(router.CAMPAIGN_CREATE)}
        />
        <AppButton
          className="home-page"
          type={AppButtonType.outline}
          content="Ana Sayfa"
          onClick={() => props.callback(router.HOME)}
        />
      </div>
    </Modal>
  );
}

export default OrderSuccessModal;
