import axios from '../../../utilities/axios';
import {JwtDto} from '../../../models/dtos/jwt.dto';

export const refreshRequest = async (): Promise<JwtDto> => {
  try {
    const response = await axios.get<JwtDto>(
      `${process.env.REACT_APP_BASE_URL}/auth/refresh`,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
