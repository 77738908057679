import axios from '../../../utilities/axios';
import {ContactUsRequest} from '../../../models/requests/contactUs.request';

export const contactUsRequest = async (request: ContactUsRequest): Promise<void> => {
  try {
    const response = await axios.post<void>(
      `${process.env.REACT_APP_BASE_URL}/misc/contact-us`,
      {...request},
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
