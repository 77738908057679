import {OfferDto} from '../../../models/dtos/offer.dto';
import axios from '../../../utilities/axios';

export const getAllOffersRequest = async (): Promise<OfferDto[]> => {
  try {
    const response = await axios.get<OfferDto[]>(
      `${process.env.REACT_APP_BASE_URL}/offers`,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
