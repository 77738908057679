import { AutoComplete, Collapse, Input, Tooltip } from "antd";
import React, { Component, ReactNode } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import down from "../../../assets/images/chevron-down-primary.svg";
import flag from "../../../assets/images/flag-big.svg";
import flagWarning from "../../../assets/images/flag-warning.svg";
import historyIcon from "../../../assets/images/history.svg";
import info from "../../../assets/images/info.svg";
import Loading from "../../../components/Loading/Loading";
import CreditCardSaveModal from "../../../components/Modals/CreditCardSaveModal/CreditCardSaveModal";
import OrderEftSuccessModal from "../../../components/Modals/OrderEftSuccessModal/OrderEftSuccessModal";
import OrderFailModal from "../../../components/Modals/OrderFailModal/OrderFailModal";
import OrderSuccessModal from "../../../components/Modals/OrderSuccessModal/OrderSuccessModal";
import PayByMeModal from "../../../components/Modals/PayByMeModal/PayByMeModal";
import PayWithSavedCardApprovalModal from "../../../components/Modals/PayWithSavedCardApprovalModal/PayWithSavedCardApprovalModal";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { FormValuesOrderInvoice } from "../../../core/models/custom/formValuesOrderInvoice";
import { MeDto } from "../../../core/models/dtos/me.dto";
import { OfferDto } from "../../../core/models/dtos/offer.dto";
import { CreateOrderAndPayRequest } from "../../../core/models/requests/createOrderAndPay.request";
import { CreateOrderAndPayWithCardRequest } from "../../../core/models/requests/createOrderAndPayWithCard.request";
import { MoneyTransferOrderRequest } from "../../../core/models/requests/moneyTransferOrder.request";
import { OrderRequest } from "../../../core/models/requests/order.request";
import { SaveCardRequest } from "../../../core/models/requests/saveCard.request";
import { checkAndGetAgencyCode } from "../../../core/services/agencyService/checkAndGetAgencyCode/actions";
import { CheckAndGetAgencyCodeState } from "../../../core/services/agencyService/checkAndGetAgencyCode/types";
import { getUsedAgencyCodes } from "../../../core/services/agencyService/getUsedAgencyCodes/actions";
import { GetUsedAgencyCodesState } from "../../../core/services/agencyService/getUsedAgencyCodes/types";
import { setMe } from "../../../core/services/appService/setMe/actions";
import { deleteCard } from "../../../core/services/cardService/deleteCard/actions";
import { DeleteCardState } from "../../../core/services/cardService/deleteCard/types";
import {
  saveCard,
  saveCardReset,
} from "../../../core/services/cardService/saveCard/actions";
import { SaveCardState } from "../../../core/services/cardService/saveCard/types";
import { getAllOffers } from "../../../core/services/offerService/getAllOffers/actions";
import { GetAllOffersState } from "../../../core/services/offerService/getAllOffers/types";
import { createMoneyTransferOrder } from "../../../core/services/orderService/createMoneyTransferOrder/actions";
import { CreateMoneyTransferOrderState } from "../../../core/services/orderService/createMoneyTransferOrder/types";
import {
  createOrderAndPay,
  createOrderAndPayReset,
} from "../../../core/services/orderService/createOrderAndPay/actions";
import { CreateOrderAndPayState } from "../../../core/services/orderService/createOrderAndPay/types";
import { createOrderAndPayWithCard } from "../../../core/services/orderService/createOrderAndPayWithCard/actions";
import { CreateOrderAndPayWithCardState } from "../../../core/services/orderService/createOrderAndPayWithCard/types";
import { getMeRequest } from "../../../core/services/userService/getMe/repository";
import { Helpers } from "../../../core/utilities/helpers";
import { history } from "../../../core/utilities/history";
import { IStore } from "../../../core/utilities/reducers";
import { router } from "../../../core/utilities/router";
import OrderInvoice from "./OrderInvoice/OrderInvoice";
import OrderPackage from "./OrderPackage/OrderPackage";
import "./OrderPage.scss";
import OrderPayment from "./OrderPayment/OrderPayment";

const { Panel } = Collapse;
interface IProps {
  me?: MeDto;
  getAllOffersState: GetAllOffersState;
  getAllOffers: () => void;
  createOrderAndPayState: CreateOrderAndPayState;
  saveCardState: SaveCardState;
  deleteCardState: DeleteCardState;
  createOrderAndPayWithCardState: CreateOrderAndPayWithCardState;
  createMoneyTransferOrderState: CreateMoneyTransferOrderState;
  getUsedAgencyCodesState: GetUsedAgencyCodesState;
  checkAndGetAgencyCodeState: CheckAndGetAgencyCodeState;
  checkAndGetAgencyCode: (code: string) => void;
  getUsedAgencyCodes: () => void;
  createMoneyTransferOrder: (request: MoneyTransferOrderRequest) => void;
  createOrderAndPay: (request: CreateOrderAndPayRequest) => void;
  createOrderAndPayReset: () => void;
  saveCard: (request: SaveCardRequest) => void;
  saveCardReset: () => void;
  deleteCard: () => void;
  createOrderAndPayWithCard: (
    request: CreateOrderAndPayWithCardRequest
  ) => void;
  setMe: (me?: MeDto) => void;
}

interface IState {
  refCode: string;
  step: OrderStep;
  dataPackage?: PackageOrder;
  dataInvoice?: FormValuesOrderInvoice;
  paymentResult?: boolean;
  eftCompanyResult?: boolean;
  isCardSaveEnabled: boolean;
  isModalPayWithSavedCardVisible: boolean;
  isValidRef: boolean;
}

export interface PackageOrder {
  amount: number;
  offer: OfferDto;
}

export enum OrderStep {
  package = "package",
  invoice = "invoice",
  payment = "payment",
}

class OrderPage extends Component<IProps> {
  state: IState = {
    refCode: "",
    step: OrderStep.package,
    dataPackage: undefined,
    dataInvoice: undefined,
    paymentResult: undefined,
    isCardSaveEnabled: false,
    isModalPayWithSavedCardVisible: false,
    isValidRef: false,
  };

  componentDidMount() {
    this.props.getAllOffers();
    this.props.getUsedAgencyCodes();
    if (this.state.refCode.length === 0) {
      this.setState({
        isValidRef: true,
      });
    }
  }

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<{}>,
    snapshot?: any
  ) {
    if (
      prevProps.createOrderAndPayWithCardState.loading &&
      !this.props.createOrderAndPayWithCardState.loading
    ) {
      if (this.props.createOrderAndPayWithCardState.data) {
        this.setState({
          isModalPayWithSavedCardVisible: false,
          isCardSaveEnabled: false,
          paymentResult: true,
        });
      }
    }
    if (
      prevProps.checkAndGetAgencyCodeState.loading &&
      !this.props.checkAndGetAgencyCodeState.loading
    ) {
      if (this.props.checkAndGetAgencyCodeState.data) {
        this.setState({
          step: OrderStep.invoice,
          isValidRef: true,
        });
      } else {
        this.setState({ isValidRef: false, });
      }
    }

    if (
      prevProps.createMoneyTransferOrderState.loading &&
      !this.props.createMoneyTransferOrderState.loading
    ) {
      if (this.props.createMoneyTransferOrderState.data) {
        this.setState({ eftCompanyResult: true, });
      } else {
        this.setState({ eftCompanyResult: false, });
      }
    }
  }

  private setStep(step: OrderStep): void {
    if (!step) return;
    if (!this.state.dataPackage && step !== OrderStep.package) return;
    if (!this.state.dataInvoice && step === OrderStep.payment) return;
    this.setState({ step });
  }

  private getOrderRequest(): OrderRequest | null {
    if (!this.state.dataPackage || !this.state.dataInvoice) {
      alert("Package or Invoice not defined!");
      return null;
    }
    return {
      ...this.state.dataInvoice,
      offers: [
        {
          amount: this.state.dataPackage.amount,
          offerId: this.state.dataPackage.offer.id,
        },
      ],
    };
  }

  private async createOrderAndPayWithCard(): Promise<void> {
    const orderRequest = this.getOrderRequest();
    if (orderRequest) {
      const me = await getMeRequest();
      this.props.setMe(me);
      if (!me.cardGuid) {
        alert("Undefined cardGuid in me response!");
        return;
      }
      this.props.createOrderAndPayWithCard({
        orderRequest,
        payWithCardRequest: { cardGuid: me.cardGuid },
      });
    }
  }

  private handleContinuePackage(dataPackage: PackageOrder): void {
    if (this.state.refCode === "") {
      this.setState({ step: OrderStep.invoice, dataPackage });
    } else if (this.state.refCode.length > 0) {
      this.props.checkAndGetAgencyCode(this.state.refCode);
      this.setState({
        dataPackage,
      });
    }
  }

  private handleContinueInvoice(dataInvoice: FormValuesOrderInvoice): void {
    this.setState({
      step: OrderStep.payment,
      dataInvoice,
    });
  }

  private handleContinuePaymentSavedCard(): void {
    this.setState({ isModalPayWithSavedCardVisible: true });
  }

  private handleContinuePaymentNoCard(isCardSaveEnabled: boolean): void {
    if (isCardSaveEnabled) {
      this.setState({ isCardSaveEnabled: true });
    } else {
      const orderRequest = this.getOrderRequest();
      if (orderRequest) {
        this.props.createOrderAndPay({ orderRequest });
      }
    }
  }

  private async handleEftCompany(values: MoneyTransferOrderRequest): Promise<void> {
    if (this.state.refCode === "") {
      await this.setState({ refCode: null });
    }
    const request: MoneyTransferOrderRequest = await {
      ...values,
      agencyCode: this.state.refCode,
    };
    this.props.createMoneyTransferOrder(request);
  }

  private handleModalPayWithSavedCardApproval(isApproved: boolean): void {
    if (isApproved) {
      this.createOrderAndPayWithCard();
    } else {
      this.setState({ isModalPayWithSavedCardVisible: false });
    }
  }

  private handleModalCreditCardSave(request?: SaveCardRequest): void {
    if (request) {
      this.props.saveCard(request);
    } else {
      this.setState({ isCardSaveEnabled: false });
    }
  }

  private handleModalPayByMeSaveCard(isSuccess: boolean): void {
    this.props.saveCardReset();
    if (isSuccess) {
      this.createOrderAndPayWithCard();
    }
  }

  private handleModalPayByMeCreateOrderAndPay(isSuccess: boolean): void {
    this.setState({ paymentResult: isSuccess });
    this.props.createOrderAndPayReset();
  }

  private handleModalOrderSuccess(route: string): void {
    history.push(route);
  }

  private handleModalOrderFail(): void {
    this.setState({ paymentResult: undefined });
  }

  private renderPageTitle(): ReactNode {
    return (
      <div className="page-title-wrapper">
        <PageTitle text="Cüzdan" />
        <NavLink to={router.ORDER_HISTORY} className="history-link">
          <img className="icon" src={historyIcon} alt="history" />
          <label>MetaByte Geçmişi</label>
        </NavLink>
      </div>
    );
  }

  private renderHeaderInfo(text: string): ReactNode {
    return (
      <div className="header-suffix">
        <label className="text">{text}</label>
      </div>
    );
  }

  private renderHeaderPackage(): ReactNode {
    const dataPackage = this.state.dataPackage;
    return (
      <div className="panel-header">
        MetaByte Adedi
        {dataPackage &&
          this.renderHeaderInfo(
            `${dataPackage.offer.name} / ${dataPackage.amount
            } Adet / ${Helpers.getPriceWithCurrency(
              dataPackage.amount * dataPackage.offer.price
            )}`
          )}
      </div>
    );
  }

  private renderHeaderInvoice(): ReactNode {
    const dataInvoice = this.state.dataInvoice;
    return (
      <div className="panel-header">
        Faturanızın Gönderileceği Adres
        {dataInvoice &&
          this.renderHeaderInfo(
            `${dataInvoice.billingContactName} / ${dataInvoice.billingCity
            } / ${Helpers.getCountryNameFromISOCode(
              dataInvoice.billingCountry
            )}`
          )}
      </div>
    );
  }

  private renderRefCodes() {
    if (this.props.getUsedAgencyCodesState.data) {
      const options = [
        {
          label: <span>Son kullanılan referans kodları</span>,
          options: this.props.getUsedAgencyCodesState.data.map((code) => ({
            value: code,
            label: <span>{code}</span>,
          })),
        },
      ];

      return options;
    }
    return [];
  }

  private autoOnSelect(value: string) {
    this.setState({ refCode: value });
  }

  private renderContent(): ReactNode {
    if (this.props.getAllOffersState.loading) {
      return <Loading fontSize={48} />;
    } else if (this.props.getAllOffersState.data) {
      return (
        <Collapse
          accordion
          expandIconPosition="right"
          className="order-form"
          activeKey={this.state.step}
          onChange={(key) => this.setStep(key as OrderStep)}
        >
          <Panel
            key={OrderStep.package}
            header={this.renderHeaderPackage()}
            className="package"
          >
            <div className="d-flex justify-content-between">
              <OrderPackage
                offers={this.props.getAllOffersState.data}
                callbackChange={() => this.setState({ dataPackage: undefined })}
                callbackContinue={(amount) =>
                  this.handleContinuePackage(amount)
                }
              />
              <div className="d-flex flex-column align-items-start">
                <div className="d-flex align-items-center data">
                  <AutoComplete
                    className="form-input auto"
                    dropdownClassName="certain-category-search-dropdown"
                    bordered={false}
                    options={this.renderRefCodes()}
                    onSelect={(value) => this.autoOnSelect(value)}
                  >
                    <Input
                      className={
                        this.state.isValidRef === true ? "" : "warning"
                      }
                      onChange={(e) => this.autoOnSelect(e.target.value)}
                      value={this.state.refCode}
                      prefix={
                        this.state.isValidRef === true ? (
                          <img src={flag} alt="flag" />
                        ) : (
                          <img src={flagWarning} alt="flag" />
                        )
                      }
                      placeholder="Referans kodu"
                      suffix={<img src={down} alt="down" />}
                    />
                  </AutoComplete>
                  <Tooltip
                    overlayClassName="tooltip-sms-name"
                    placement="topLeft"
                    title={
                      <React.Fragment>
                        <span className="text">
                          Satın alımınıza aracı olan bir kurum varsa referans
                          kodunu girebilirsiniz.
                        </span>
                      </React.Fragment>
                    }
                  >
                    <img className="ml-3" src={info} alt="info" />
                  </Tooltip>
                </div>
                {this.state.isValidRef === false ? (
                  <div className="ant-form-item-explain ant-form-item-explain-error">
                    Geçersiz referans kodu. Lütfen kodu <br /> kontrol edin.
                  </div>
                ) : (
                  <React.Fragment />
                )}
              </div>
            </div>
          </Panel>
          <Panel
            key={OrderStep.invoice}
            header={this.renderHeaderInvoice()}
            className="invoice"
          >
            <OrderInvoice
              me={this.props.me}
              callbackChange={() => this.setState({ dataInvoice: undefined })}
              callbackContinue={(values) => this.handleContinueInvoice(values)}
            />
          </Panel>
          <Panel key={OrderStep.payment} header="Ödeme" className="payment">
            <OrderPayment
              amountValue={this.state.dataPackage?.amount}
              isSubmitting={
                this.props.createOrderAndPayState.loading ||
                this.props.createOrderAndPayWithCardState.loading
              }
              isDeleteCardLoading={this.props.deleteCardState.loading}
              creditCard={this.props.me?.maskedPan}
              callbackSavedCard={() => this.handleContinuePaymentSavedCard()}
              callbackNoCard={(isCardSaveEnabled) =>
                this.handleContinuePaymentNoCard(isCardSaveEnabled)
              }
              callbackDeleteCard={() => this.props.deleteCard()}
              callbackEftCompany={(values) => this.handleEftCompany(values)}
            />
          </Panel>
        </Collapse>
      );
    }
  }

  render() {
    return (
      <div id="order-page" className="page">
        <div className="page-content">
          {this.renderPageTitle()}
          {this.renderContent()}
        </div>

        {this.state.isModalPayWithSavedCardVisible && (
          <PayWithSavedCardApprovalModal
            amount={this.state.dataPackage?.amount!}
            offer={this.state.dataPackage?.offer!}
            cardLast4Digit={
              Helpers.getCreditCardLastDigits(this.props.me?.maskedPan) ?? "-"
            }
            isSubmitting={this.props.createOrderAndPayWithCardState.loading}
            callback={(isApproved) =>
              this.handleModalPayWithSavedCardApproval(isApproved)
            }
          />
        )}

        {this.state.isCardSaveEnabled && (
          <CreditCardSaveModal
            isSubmitting={
              this.props.saveCardState.loading ||
              this.props.createOrderAndPayWithCardState.loading
            }
            callbackOk={(request) => this.handleModalCreditCardSave(request)}
            callbackCancel={() => this.handleModalCreditCardSave()}
          />
        )}

        {this.props.saveCardState.data && (
          <PayByMeModal
            url={this.props.saveCardState.data.redirectUrl}
            syncId={this.props.saveCardState.data.syncId}
            callbackPaymentResult={(isSuccess) =>
              this.handleModalPayByMeSaveCard(isSuccess)
            }
          />
        )}

        {this.props.createOrderAndPayState.data && (
          <PayByMeModal
            url={this.props.createOrderAndPayState.data.redirectUrl}
            callbackPaymentResult={(isSuccess) =>
              this.handleModalPayByMeCreateOrderAndPay(isSuccess)
            }
          />
        )}

        {this.state.paymentResult && (
          <OrderSuccessModal
            package={this.state.dataPackage!}
            callback={(route) => this.handleModalOrderSuccess(route)}
          />
        )}

        {this.state.eftCompanyResult && (
          <OrderEftSuccessModal
            callback={(route) => this.handleModalOrderSuccess(route)}
          />
        )}

        {this.state.paymentResult === false && (
          <OrderFailModal callback={() => this.handleModalOrderFail()} />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      checkAndGetAgencyCode,
      getUsedAgencyCodes,
      getAllOffers,
      createMoneyTransferOrder,
      createOrderAndPay,
      createOrderAndPayReset,
      saveCard,
      saveCardReset,
      deleteCard,
      createOrderAndPayWithCard,
      setMe,
    },
    dispatch
  );
};
const mapStateToProps = (store: IStore) => {
  return {
    checkAndGetAgencyCodeState: store.checkAndGetAgencyCode,
    getUsedAgencyCodesState: store.getUsedAgencyCodes,
    createMoneyTransferOrderState: store.createMoneyTransferOrder,
    me: store.setMe.me,
    getAllOffersState: store.getAllOffers,
    createOrderAndPayState: store.createOrderAndPay,
    saveCardState: store.saveCard,
    deleteCardState: store.deleteCard,
    createOrderAndPayWithCardState: store.createOrderAndPayWithCard,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderPage);
