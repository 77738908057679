import {UsageDto} from '../../../models/dtos/usage.dto';
import axios from '../../../utilities/axios';

export const getUsageRequest = async (): Promise<UsageDto[]> => {
  try {
    const response = await axios.get<UsageDto[]>(
      `${process.env.REACT_APP_BASE_URL}/offers/usage`,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
