import {LoadingOutlined} from "@ant-design/icons";
import {Modal} from "antd";
import React from 'react';
import icon from "../../../assets/images/modal-icon-side-user-delete.svg";
import {SideUserDto} from "../../../core/models/dtos/sideUser.dto";
import {UI} from "../../../core/utilities/ui";
import AppButton, {AppButtonType} from "../../Buttons/AppButton/AppButton";
import "./SideUserDeleteApprovalModal.scss";

interface IProps {
  sideUser?: SideUserDto;
  isSubmitting: boolean;
  callback: (sideUser?: SideUserDto) => void;
}

const SideUserDeleteApprovalModal = (props: IProps) => {

  return (
    <Modal
      className="side-user-delete-approval-modal"
      maskStyle={UI.modalMaskStyle()}
      closable={false}
      footer={false}
      visible={!!props.sideUser}
      centered
      destroyOnClose
      onCancel={() => props.callback()}
    >
      <div className="content">
        <img className="icon" src={icon} alt="icon"/>
        <label className="title">
          {props.sideUser?.fullName}
        </label>
        <label className="text">
          Ortak yöneticinizi silmek istediğinize emin misiniz?
        </label>
        {
          props.isSubmitting
            ?
            <div className="loading-wrapper">
              <LoadingOutlined className="loading"/>
            </div>
            :
            <div className="button-wrapper">
              <AppButton
                type={AppButtonType.outline}
                content="Vazgeç"
                onClick={() => props.callback()}
              />
              <AppButton
                content="Yöneticiyi sil"
                onClick={() => props.callback(props.sideUser)}
              />
            </div>
        }
      </div>
    </Modal>
  );
}

export default SideUserDeleteApprovalModal;
