import { Form, Input, Select } from "antd";
import React, { useState } from "react";
import down from "../../../../assets/images/chevron-down-gray.svg";
import right from "../../../../assets/images/chevron-right-primary.svg";
import AppButton from "../../../../components/Buttons/AppButton/AppButton";
import { CampaignApprovalValues } from "../../../../core/models/custom/campaignApprovalValues";
import { FormValuesCampaignApproval } from "../../../../core/models/custom/formValuesCampaignApproval";
import { CampaignType } from "../../../../core/models/enums/campaignType";
import { Constants } from "../../../../core/utilities/constants";
import "./CampaignApproval.scss";

interface IProps {
  type: CampaignType;
  callbackUsageLink: (type: CampaignType) => void;
  callbackSubmit: (values: CampaignApprovalValues) => void;
}

const CampaignApproval = (props: IProps) => {
  const [isRequired, setIsRequired] = useState(true);
  const [isAutoRenew, setAutoRenew] = useState(false);
  const [otherText, setOtherText] = useState(" ");
  const [option, setOption] = useState("");
  const [form] = Form.useForm();

  function handleFinish(values: FormValuesCampaignApproval) {
    const data: CampaignApprovalValues = {
      name: values.campaignName,
      isAutoRenew: props.type === CampaignType.API ? isAutoRenew : undefined,
      goal: values.goal,
    };
    props.callbackSubmit(data);
  }

  function getLinkText(): string {
    switch (props.type) {
      case CampaignType.CODE:
        return "Kod ile";
      case CampaignType.LINK:
        return "Link ile";
      case CampaignType.BULK:
        return "Toplu";
      case CampaignType.API:
        return "API ile";
    }
  }

  const { Option } = Select;

  function handleSelectChange(option: string) {
    setOption(option);

    if (option === otherText) {
      setIsRequired(false);
    }
  }

  return (
    <Form
      form={form}
      id="campaign-approval"
      className="form"
      onFinish={handleFinish}
    >
      <div className="d-flex">
        <div className="d-flex flex-column">
          <Form.Item
            name="campaignName"
            className="form-item"
            rules={[
              { required: true, message: Constants.inputErrorRequiredField },
            ]}
          >
            <Input
              autoFocus
              className="form-input"
              placeholder="Kampanya Adı"
            />
          </Form.Item>
          <Form.Item
            name="goal"
            className="form-item d-flex flex-column"
            rules={[
              {
                required: isRequired,
                message: Constants.inputErrorRequiredField,
              },
            ]}
          >
            <Select
              placeholder="Kampanya İçeriği"
              suffixIcon={<img src={down} alt="down" />}
              className={`form-input ${option.length === 0 ||
                  option === "yeni-müşteri-kazanma" ||
                  option === "var-olan-müşteri-aktifliğini-artırma" ||
                  option === "satış" ||
                  option === "müşteri-memnuniyeti" ||
                  option === "çalışan-mutluluğu" ||
                  option === "ürüne-yenilik-getirme"
                  ? "select"
                  : "oth"
                }`}
              onChange={(option) => handleSelectChange(option as string)}
            >
              <Option value="satış">Satış</Option>
              <Option value="yeni-müşteri-kazanma">Yeni müşteri kazanma</Option>
              <Option value="var-olan-müşteri-aktifliğini-artırma">
                Var olan müşteri aktifliğini artırma
              </Option>
              <Option value="müşteri-memnuniyeti">Müşteri memnuniyeti</Option>
              <Option value="çalışan-mutluluğu">Çalışan mutluluğu</Option>
              <Option value="yeni-özellik-getirme">Yeni özellik getirme</Option>
              <Option value={otherText}>Diğer</Option>
            </Select>
          </Form.Item>
          {option.length === 0 ||
            option === "yeni-müşteri-kazanma" ||
            option === "var-olan-müşteri-aktifliğini-artırma" ||
            option === "satış" ||
            option === "müşteri-memnuniyeti" ||
            option === "çalışan-mutluluğu" ||
            option === "ürüne-yenilik-getirme" ? (
            <React.Fragment />
          ) : (
            <Input
              className="other"
              onChange={(e) => setOtherText(e.target.value)}
            />
          )}
        </div>

        <Form.Item className="form-item text">
          <div className="d-flex flex-column">
            <label className="note">
              Bu kampanyayı oluşturmadan önce kullanım yönteminizin sisteminizle
              uyumlu olup olmadığını kontrol ediniz.
            </label>
            <label
              className="link"
              onClick={() => props.callbackUsageLink(props.type)}
            >
              {getLinkText()}&nbsp;yükleme yönteminin detayları için tıklayınız.
              <img className="ms-3" src={right} alt="link" />
            </label>
          </div>
        </Form.Item>
      </div>

      {props.type === CampaignType.API && (
        <div className="switch" onClick={() => setAutoRenew(isAutoRenew)}>
          {/* <div className="d-flex flex-column">
            <label className="title">Otomatik bakiye yenilememe</label>
            <label className="sub-title">
              MetaByte bakiyeniz %80 oranında azaldığında bakiyenizin otomatik
              yenilenmesi için talimat verebilirsiniz.
            </label>
          </div>
          <Switch
            defaultChecked
            checked={isAutoRenew}
            onChange={(e) => setAutoRenew(e)}
          /> */}
        </div>
      )}

      <AppButton
        htmlType="submit"
        content="Kampanyayı Oluştur"
        onClick={() => { }}
      />
    </Form>
  );
};

export default CampaignApproval;
