import {LoadingOutlined} from "@ant-design/icons";
import {Modal} from "antd";
import React, {ReactNode} from 'react';
import checkmark from "../../../assets/images/checkmark.svg";
import icon from "../../../assets/images/modal-icon-pay-with-saved-card.svg";
import {OfferDto} from "../../../core/models/dtos/offer.dto";
import {Helpers} from "../../../core/utilities/helpers";
import {UI} from "../../../core/utilities/ui";
import AppButton, {AppButtonType} from "../../Buttons/AppButton/AppButton";
import "./PayWithSavedCardApprovalModal.scss";

interface IProps {
  amount: number;
  offer: OfferDto;
  cardLast4Digit: string;
  isSubmitting: boolean;
  callback: (isApproved: boolean) => void;
}

const PayWithSavedCardApprovalModal = (props: IProps) => {

  function getOkButtonContent(): ReactNode {
    return (
      <div className="d-flex align-items-center">
        <span>Onayla</span>
        &nbsp;&nbsp;
        <img src={checkmark} alt="checkmark"/>
      </div>
    );
  }

  return (
    <Modal
      className="pay-with-saved-card-approval-modal"
      maskStyle={UI.modalMaskStyle()}
      closable={false}
      footer={false}
      visible
      centered
      destroyOnClose
      onCancel={() => props.callback(false)}
    >
      <div className="content">
        <img className="icon" src={icon} alt="icon"/>
        <label className="title">
          İşlemi onaylıyor musunuz?
        </label>
        <label className="text">
          {props.amount}
          &nbsp;adet&nbsp;
          {props.offer.name}
          'ı&nbsp;
          {Helpers.getPriceWithCurrency(props.offer.price * props.amount)}
          &nbsp;karşılığında Son 4 hanesi&nbsp;
          {props.cardLast4Digit}
          &nbsp;
          olan kredi kartınız ile satın alıyorsunuz.
          <br/>
          *{props.offer.name} {props.offer.value} değerindedir.
        </label>
        <label className="note">
          *Bu işlem geri alınamaz.
        </label>
        {
          props.isSubmitting
            ?
            <div className="loading-wrapper">
              <LoadingOutlined className="loading"/>
            </div>
            :
            <div className="button-wrapper">
              <AppButton
                type={AppButtonType.outline}
                content="Vazgeç"
                onClick={() => props.callback(false)}
              />
              <AppButton
                content={getOkButtonContent()}
                onClick={() => props.callback(true)}
              />
            </div>
        }
      </div>
    </Modal>
  );
}

export default PayWithSavedCardApprovalModal;
