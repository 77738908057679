import React from "react";
import usage1 from "../../../../assets/images/landing/usage-1.svg";
import usage2 from "../../../../assets/images/landing/usage-2.svg";
import usage3 from "../../../../assets/images/landing/usage-3.svg";
import FirebaseKey from "../../../../components/FirebaseKey/FirebaseKey";
import FirebaseService from "../../../../core/services/firebase.service";
import {UI} from "../../../../core/utilities/ui";
import "./SectionUsage.scss";

interface IProps {
  sectionRef: any;
}

const SectionUsage = (props: IProps) => {

  return (
    <div
      ref={props.sectionRef}
      id="section-usage" className="section"
    >
      <div className="section-content">
        <div className="row w-xl-100">
          <div className={`section-left ${UI.getLandingSectionResponsiveClass()}`}>
            <div className="content">
              <FirebaseKey className="header" firebaseKey={FirebaseService.landing_section4_header}/>
              <FirebaseKey className="title" firebaseKey={FirebaseService.landing_section4_title}/>
              <FirebaseKey className="description" firebaseKey={FirebaseService.landing_section4_desc}/>
            </div>
          </div>
          <div className={`section-right ${UI.getLandingSectionResponsiveClass()}`}>
            <div className="row w-xl-100">
              <div className="usage col-12 col-md-4">
                <img src={usage1} alt="usage"/>
                <FirebaseKey firebaseKey={FirebaseService.landing_section4_step1}/>
              </div>
              <div className="usage col-12 col-md-4">
                <img src={usage2} alt="usage"/>
                <FirebaseKey firebaseKey={FirebaseService.landing_section4_step2}/>
              </div>
              <div className="usage col-12 col-md-4">
                <img src={usage3} alt="usage"/>
                <FirebaseKey firebaseKey={FirebaseService.landing_section4_step3}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionUsage;
