import {Table} from "antd";
import moment from "moment";
import 'moment/locale/tr';
import React from 'react';
import typeApi from "../../../../assets/images/campaign-type-api-grey.svg";
import typeBulk from "../../../../assets/images/campaign-type-bulk-grey.svg";
import typeCode from "../../../../assets/images/campaign-type-code-grey.svg";
import typeLink from "../../../../assets/images/campaign-type-link-grey.svg";
import download from "../../../../assets/images/download-grey.svg";
import {CampaignDto} from "../../../../core/models/dtos/campaign.dto";
import {CampaignType} from "../../../../core/models/enums/campaignType";
import {Constants} from "../../../../core/utilities/constants";
import {history} from "../../../../core/utilities/history";
import {router} from "../../../../core/utilities/router";
import "./CampaignHistoryTable.scss";

interface IProps {
  data: CampaignDto[];
  requestedApiKeyCampaignId?: number;
  copiedApiKeyCampaignId?: number;
  callbackGetFile: (item: CampaignDto) => void;
  callbackGetApiKey: (id: number) => void;
}

const CampaignHistoryTable = (props: IProps) => {
  const columns = [
    {
      title: 'Tarih',
      dataIndex: 'created',
      key: 'created',
      render: (created: string) => {
        moment.locale('tr');
        return (
          <span>{moment(created).format(Constants.dateFormatUI)}</span>
        );
      },
    },
    {
      title: 'Kampanya adı',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Birim',
      dataIndex: 'offerName',
      key: 'offerName',
    },
    {
      title: 'Adet',
      dataIndex: 'used',
      key: 'used',
      render: (_: number, item: CampaignDto) => {
        const type = item.type;
        switch (type) {
          case CampaignType.CODE:
          case CampaignType.LINK:
            return <span>{item.initialAllocation}</span>;
          case CampaignType.BULK:
            return <span>{`${item.used ?? 0} / ${item.initialAllocation}`}</span>;
          case CampaignType.API:
            return <span>{`${item.used ?? 0} / ${item.allocated}`}</span>;
        }
      }
    },
    {
      title: 'Yükleme yöntemi',
      dataIndex: 'type',
      key: 'type',
      render: (type: CampaignType) => {
        let image;
        switch (type) {
          case CampaignType.CODE:
            image = typeCode;
            break;
          case CampaignType.LINK:
            image = typeLink;
            break;
          case CampaignType.BULK:
            image = typeBulk;
            break;
          case CampaignType.API:
            image = typeApi;
            break;
        }
        return <img src={image} alt="type"/>
      }
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (_: any, item: CampaignDto) => {
        const type = item.type;
        switch (type) {
          case CampaignType.CODE:
            return (
              <img
                className="action action-code"
                src={download}
                alt="download"
                onClick={() => props.callbackGetFile(item)}
              />
            );
          case CampaignType.LINK:
            return (
              <img
                className="action action-link"
                src={download}
                alt="download"
                onClick={() => props.callbackGetFile(item)}
              />
            );
          case CampaignType.BULK:
            return (
              <span
                className="action action-bulk"
                onClick={() => history.push(`${router.CAMPAIGNS}/${item.id}`)}
              >
                MSISDN List
              </span>
            );
          case CampaignType.API:
            return (
              <span
                className="action action-api"
                onClick={() => props.callbackGetApiKey(item.id)}
              >
                API Key
                {
                  props.copiedApiKeyCampaignId === item.id &&
                  <React.Fragment>
                    <br/>
                    Kopyalandı
                  </React.Fragment>
                }
              </span>
            );
        }
      }
    }
  ];

  return (
    <React.Fragment>
      <div className="table-title">
        Kampanya Geçmişi
      </div>
      <Table
        id="campaign-history-table"
        className="table"
        rowKey="id"
        columns={columns}
        dataSource={props.data}
      />
    </React.Fragment>
  );
}

export default CampaignHistoryTable;
