import {notification} from "antd";
import React, {Component, ReactNode} from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import add from "../../../assets/images/add.svg";
import copy from "../../../assets/images/copy.svg";
import AppButton from "../../../components/Buttons/AppButton/AppButton";
import CreateCampaignCard from "../../../components/CreateCampaignCard/CreateCampaignCard";
import EmptyStateCard from "../../../components/EmptyStateCard/EmptyStateCard";
import Loading from "../../../components/Loading/Loading";
import PageTitle from "../../../components/PageTitle/PageTitle";
import {CampaignDto} from "../../../core/models/dtos/campaign.dto";
import {getApiKey} from "../../../core/services/campaignService/getApiKey/actions";
import {GetApiKeyState} from "../../../core/services/campaignService/getApiKey/types";
import {getCampaignHistory} from "../../../core/services/campaignService/getCampaignHistory/actions";
import {GetCampaignHistoryState} from "../../../core/services/campaignService/getCampaignHistory/types";
import {getFile} from "../../../core/services/campaignService/getFile/actions";
import {GetFileState} from "../../../core/services/campaignService/getFile/types";
import {getUsage} from "../../../core/services/offerService/getUsage/actions";
import {GetUsageState} from "../../../core/services/offerService/getUsage/types";
import {Helpers} from "../../../core/utilities/helpers";
import {history} from "../../../core/utilities/history";
import {IStore} from "../../../core/utilities/reducers";
import {router} from "../../../core/utilities/router";
import CampaignHistoryTable from "./CampaignHistoryTable/CampaignHistoryTable";
import "./CampaignsPage.scss";

interface IProps {
  getCampaignHistoryState: GetCampaignHistoryState;
  getUsageState: GetUsageState;
  getFileState: GetFileState;
  getApiKeyState: GetApiKeyState;
  getCampaignHistory: () => void;
  getUsage: () => void;
  getFile: (id: number) => void;
  getApiKey: (id: number) => void;
}

interface IState {
  requestedFileCampaign?: CampaignDto;
  requestedApiKeyCampaignId?: number;
  copiedApiKeyCampaignId?: number;
}

class CampaignsPage extends Component<IProps> {
  state: IState = {};

  componentDidMount() {
    this.props.getCampaignHistory();
    this.props.getUsage();
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any) {
    if (prevProps.getFileState.loading && !this.props.getFileState.loading) {
      const data = this.props.getFileState.data
      if (data?.length === 0) {
        // Status 200 but response empty string
        Helpers.showNotification('error', 'Boş dosya');
      } else if (data) {
        const fileName = this.state.requestedFileCampaign?.name ?? 'Details';
        Helpers.downloadStringAsTxt(data, fileName);
      }
    }

    if (prevProps.getApiKeyState.loading && !this.props.getApiKeyState.loading) {
      if (this.props.getApiKeyState.data) {
        this.handleCopyApiKey(this.props.getApiKeyState.data.apiKey);
      }
    }
  }

  private handleGetFile(campaign: CampaignDto): void {
    if (this.props.getFileState.loading) return;
    this.setState(
      {requestedFileCampaign: campaign},
      () => this.props.getFile(campaign.id),
    );
  }

  private handleGetApiKey(id: number): void {
    if (this.props.getApiKeyState.loading) return;
    this.setState({
      requestedApiKeyCampaignId: id,
      copiedApiKeyCampaignId: undefined,
    });
    this.props.getApiKey(id);
  }

  private handleCopyApiKey(apiKey: string): void {
    navigator.clipboard.writeText(apiKey)
      .then(() => {
        this.setState({
          requestedApiKeyCampaignId: undefined,
          copiedApiKeyCampaignId: this.state.requestedApiKeyCampaignId,
        }, () => {
          setTimeout(() => {
            this.setState({copiedApiKeyCampaignId: undefined});
          }, 5000);
        });
      })
      .catch(() => {
        this.setState({requestedApiKeyCampaignId: undefined});
        const message = (
          <CopyToClipboard text={apiKey}>
            <div className="d-flex align-items-center">
              API KEY {apiKey}
              <AppButton
                className="ms-2 py-0 px-3"
                content={<img src={copy} alt="copy"/>}
                onClick={() => {
                }}
              />
            </div>
          </CopyToClipboard>
        );
        notification.open({
          message: message,
          placement: "bottomRight",
          style: {width: "450px"},
        });
      })
  }

  private getEmptyStateText(): string {
    return '<div class="empty-text">' +
      'Hedeflerinize kolayca ulaşmak için MetaByte ile sadakat kampanyaları oluşturabilirsiniz.' +
      '<strong>Kampanya düzenleyebilmek için puan almanız gerekmektedir.</strong>' +
      '</div>';
  }

  private renderPoint(isAnyPoint: boolean): ReactNode {
    return isAnyPoint
      ?
      <CreateCampaignCard
        callback={() => history.push(router.CAMPAIGN_CREATE)}
      />
      :
      <EmptyStateCard
        title="Maalesef hiç MetaByte'ınız yok"
        text={this.getEmptyStateText()}
        button={{
          child: (
            <div className="d-flex align-items-center">
              <img src={add} alt="add"/>
              &nbsp;&nbsp;
              <span>MetaByte Al</span>
            </div>
          ),
          callback: () => history.push(router.ORDER)
        }}
      />
  }

  private renderCampaignHistory(campaigns: CampaignDto[], isAnyPoint: boolean): ReactNode {
    if (campaigns.length > 0) {
      return (
        <CampaignHistoryTable
          data={campaigns}
          requestedApiKeyCampaignId={this.state.requestedApiKeyCampaignId}
          copiedApiKeyCampaignId={this.state.copiedApiKeyCampaignId}
          callbackGetFile={campaign => this.handleGetFile(campaign)}
          callbackGetApiKey={id => this.handleGetApiKey(id)}
        />
      );
    } else {
      if (isAnyPoint) {
        return (
          <EmptyStateCard
            title="Henüz hiç kampanya oluşturmadınız"
            text="Kampanya oluşturdukça işlem geçmişinizi bu sayfadan görüntüleyebilirsiniz."
          />
        );
      }
    }
  }

  private renderContent(): ReactNode {
    if (
      this.props.getCampaignHistoryState.loading ||
      this.props.getUsageState.loading
    ) {
      return <Loading fontSize={48}/>;
    } else if (
      this.props.getCampaignHistoryState.data &&
      this.props.getUsageState.data
    ) {
      const isAnyPoint = Helpers.getRemainingPoint1000(this.props.getUsageState.data) > 0;
      return (
        <React.Fragment>
          {this.renderPoint(isAnyPoint)}
          {this.renderCampaignHistory(this.props.getCampaignHistoryState.data, isAnyPoint)}
        </React.Fragment>
      );
    }
  }

  render() {
    return (
      <div id="campaigns-page" className="page">
        <div className="page-content">
          <PageTitle
            className={`${this.props.getCampaignHistoryState.data && this.props.getCampaignHistoryState.data.length > 0 && 'with-campaigns'}`}
            text="Kampanya Paneli"
          />
          {this.renderContent()}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getCampaignHistory,
      getUsage,
      getFile,
      getApiKey,
    },
    dispatch
  );
};
const mapStateToProps = (store: IStore) => {
  return {
    getCampaignHistoryState: store.getCampaignHistory,
    getUsageState: store.getUsage,
    getFileState: store.getFile,
    getApiKeyState: store.getApiKey,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignsPage);
