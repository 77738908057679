import {Dispatch} from 'redux';
import {ErrorDto, getErrorDtoFromApiError} from '../../../models/dtos/error.dto';
import {JwtDto} from '../../../models/dtos/jwt.dto';
import {LoginRequest} from '../../../models/requests/login.request';
import {Helpers} from '../../../utilities/helpers';
import {history} from '../../../utilities/history';
import {LocalStorage} from '../../../utilities/localStorage';
import {getMeRequest} from '../../userService/getMe/repository';
import {LOGIN_FAIL, LOGIN_RESET, LOGIN_START, LOGIN_SUCCESS} from './constants';
import {loginRequest} from './repository';
import {LoginFailAction, LoginResetAction, LoginStartAction, LoginSuccessAction} from './types';

const loginStartAction = (): LoginStartAction => {
  return {
    type: LOGIN_START,
  };
};
const loginSuccessAction = (payload: JwtDto): LoginSuccessAction => {
  return {
    type: LOGIN_SUCCESS,
    payload: payload,
  };
};
const loginFailAction = (error: ErrorDto): LoginFailAction => {
  return {
    type: LOGIN_FAIL,
    error: error,
  };
};
const loginResetAction = (): LoginResetAction => {
  return {
    type: LOGIN_RESET,
  };
};

export const login = (request: LoginRequest) => async (dispatch: Dispatch) => {
  try {
    dispatch(loginStartAction());
    const response = await loginRequest(request);
    LocalStorage.set(LocalStorage.token, response.token);
    const me = await getMeRequest(dispatch);
    dispatch(loginSuccessAction(response));
    history.push(Helpers.getRouteFromMe(me));
  } catch (error) {
    dispatch(loginFailAction(getErrorDtoFromApiError(error)));
  }
};

export const loginReset = () => (dispatch: Dispatch) => {
  dispatch(loginResetAction());
};
