import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {FormValuesResetPassword} from '../../../core/models/custom/formValuesResetPassword';
import {ResetPasswordRequest} from '../../../core/models/requests/resetPassword.request';
import {newPassword} from '../../../core/services/authService/newPassword/actions';
import {NewPasswordState} from '../../../core/services/authService/newPassword/types';
import {Helpers} from '../../../core/utilities/helpers';
import {history} from '../../../core/utilities/history';
import {IStore} from '../../../core/utilities/reducers';
import {router} from '../../../core/utilities/router';
import ResetPasswordForm from './ResetPasswordForm/ResetPasswordForm';
import './ResetPasswordPage.scss';

interface IProps {
  newPasswordState: NewPasswordState;
  newPassword: (request: ResetPasswordRequest) => void;
}

class ResetPasswordPage extends Component<IProps> {
  private readonly hash;

  constructor(props: IProps) {
    super(props);
    this.hash = Helpers.getQueryParam('hash');
    if (!this.hash) {
      alert('Geçersiz bağlantı!');
      history.push(router.HOME);
    }
  }

  private handleResetPassword(values: FormValuesResetPassword): void {
    if (!this.hash) {
      alert('Undefined hash');
      return;
    }
    const request: ResetPasswordRequest = {
      hash: this.hash,
      newPassword: values.newPassword,
    };
    this.props.newPassword(request);
  }

  render() {
    return (
      <div id="reset-password-page" className="page">
        <div className="page-content">
          <div className="content">
            <div className="description">
              <label className="title">Yeni Şifre Oluştur</label>
              <label className="info">Oluşturmak istediğin şifreyi girebilirsin</label>
            </div>
            <ResetPasswordForm
              isSubmitting={this.props.newPasswordState.loading}
              callbackSubmit={(values) => this.handleResetPassword(values)}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      newPassword,
    },
    dispatch
  );
};
const mapStateToProps = (store: IStore) => {
  return {
    newPasswordState: store.newPassword,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);
