import {GenerateCodesRequest} from '../../../models/requests/generateCodes.request';
import axios from '../../../utilities/axios';

export const generateCodesRequest = async (request: GenerateCodesRequest): Promise<string> => {
  try {
    const response = await axios.post<string>(
      `${process.env.REACT_APP_BASE_URL}/campaigns/code`,
      {...request},
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
