import React, {Component, ReactNode} from 'react';
import {connect} from "react-redux";
import {bindActionCreators, Dispatch} from "redux";
import SideUserAddModal from "../../../components/Modals/SideUserAddModal/SideUserAddModal";
import SideUserAddSuccessModal from "../../../components/Modals/SideUserAddSuccessModal/SideUserAddSuccessModal";
import SideUserDeleteApprovalModal
  from "../../../components/Modals/SideUserDeleteApprovalModal/SideUserDeleteApprovalModal";
import SideUserDeleteSuccessModal
  from "../../../components/Modals/SideUserDeleteSuccessModal/SideUserDeleteSuccessModal";
import PageTitle from "../../../components/PageTitle/PageTitle";
import ProfileForm from "../../../components/ProfileForm/ProfileForm";
import {FormValuesSideUser} from "../../../core/models/custom/formValuesSideUser";
import {ProfileValues} from "../../../core/models/custom/profileValues";
import {MeDto} from "../../../core/models/dtos/me.dto";
import {SideUserDto} from "../../../core/models/dtos/sideUser.dto";
import {AddSideUserRequest} from "../../../core/models/requests/addSideUser.request";
import {UpdateMeRequest} from "../../../core/models/requests/updateMe.request";
import {addSideUser, addSideUserReset} from "../../../core/services/userService/addSideUser/actions";
import {AddSideUserState} from "../../../core/services/userService/addSideUser/types";
import {deleteSideUser, deleteSideUserReset} from "../../../core/services/userService/deleteSideUser/actions";
import {DeleteSideUserState} from "../../../core/services/userService/deleteSideUser/types";
import {claimRevenue, claimRevenueReset} from '../../../core/services/agencyService/claimRevenue/actions';
import {ClaimRevenueState} from '../../../core/services/agencyService/claimRevenue/types';
import {getSideUsers} from "../../../core/services/userService/getSideUsers/actions";
import {GetSideUsersState} from "../../../core/services/userService/getSideUsers/types";
import {updateMe} from "../../../core/services/userService/updateMe/actions";
import {UpdateMeState} from "../../../core/services/userService/updateMe/types";
import {getWalletDetails} from '../../../core/services/agencyService/getWalletDetails/actions';
import {GetWalletDetailsState} from '../../../core/services/agencyService/getWalletDetails/types';
import {Helpers} from "../../../core/utilities/helpers";
import {IStore} from "../../../core/utilities/reducers";
import "./ProfilePage.scss";
import { WalletDetailsDto } from '../../../core/models/dtos/walletDetails.dto';
import { AgencyRevenueRequest } from '../../../core/models/requests/agencyRevenue.request';
import { FormValuesWithdraw } from '../../../core/models/custom/formValuesWithdraw';

interface IProps {
  me?: MeDto;
  updateMeState: UpdateMeState;
  updateMe: (request: UpdateMeRequest) => void;
  getSideUsersState: GetSideUsersState;
  getSideUsers: () => void;
  addSideUserState: AddSideUserState;
  addSideUser: (request: AddSideUserRequest) => void;
  addSideUserReset: () => void;
  claimRevenueState: ClaimRevenueState;
  claimRevenue: (request:AgencyRevenueRequest) => void;
  claimRevenueReset: () => void;
  deleteSideUserState: DeleteSideUserState;
  deleteSideUser: (id: number) => void;
  deleteSideUserReset: () => void;
  getWalletDetailsState: GetWalletDetailsState;
  getWalletDetails: () => void;
}

interface IState {
  isModalSideUserAddVisible: boolean;
  sideUserToDelete?: SideUserDto;
  walletDetail?: WalletDetailsDto;
}

class ProfilePage extends Component<IProps> {
  state: IState = {
    isModalSideUserAddVisible: false,
    sideUserToDelete: undefined,
    walletDetail: undefined,
  }

  componentDidMount() {
    if (!Helpers.hasRoleSideUser(this.props.me)) {
      this.props.getSideUsers();
    }
    if (this.props.me?.company.isAgency) {
      this.props.getWalletDetails()
    }
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{}>, snapshot?: any) {
    if (prevProps.addSideUserState.loading && !this.props.addSideUserState.loading) {
      if (this.props.addSideUserState.data) {
        this.props.getSideUsers();
        this.setModalSideUserAddVisibility(false);
      }
    }
    if (prevProps.deleteSideUserState.loading && !this.props.deleteSideUserState.loading) {
      if (this.props.deleteSideUserState.data) {
        this.props.getSideUsers();
        this.setSideUserToDelete();
      }
    }
    if (prevProps.claimRevenueState.loading && ! this.props.claimRevenueState.loading) {
      if (this.props.claimRevenueState.data) {
        this.props.getWalletDetails()
      }
    }
  }

  private setModalSideUserAddVisibility(isVisible: boolean): void {
    this.setState({isModalSideUserAddVisible: isVisible});
  }

  private setSideUserToDelete(sideUser?: SideUserDto): void {
    this.setState({sideUserToDelete: sideUser});
  }

  private handleSubmit(values: ProfileValues): void {
    const request: UpdateMeRequest = {
      billingAddress: values.isSameBillingAddress ? values.profile.address : values.profile.billingAddress,
      billingCity: values.isSameBillingAddress ? values.profile.city : values.profile.billingCity,
      billingCountry: values.isSameBillingAddress ? values.profile.country : values.profile.billingCountry,
      fullName: values.profile.fullName,
      msisdn: values.profile.msisdn,
      smsName: values.profile.smsName,
    } 
    this.props.updateMe(request);
  }

  private handleModalSideUserAdd(values?: FormValuesSideUser): void {
    values
      ? this.props.addSideUser({...values})
      : this.setModalSideUserAddVisibility(false)
  }

  private handleModalSideUserDeleteApproval(sideUser?: SideUserDto): void {
    sideUser
      ? this.props.deleteSideUser(sideUser.id)
      : this.setSideUserToDelete()
  }

  private handleModalWithdrawSubmit(values: FormValuesWithdraw ): void {
    const request: AgencyRevenueRequest = {
      ...values,
    }
    this.props.claimRevenue(request)
  }

  private renderContent(): ReactNode {
    
      return (
        this.props.me &&
        <ProfileForm
        callback={() => this.props.claimRevenueReset()}
        isVisible={!!this.props.claimRevenueState.data}
        isSubmittedWithdraw={this.props.claimRevenueState.loading}
        callbackOk={values => this.handleModalWithdrawSubmit(values)}
        getWalletDetailsState={this.props.getWalletDetailsState}
        getSideUsersState={Helpers.hasRoleSideUser(this.props.me) ? undefined : this.props.getSideUsersState}
        initialValues={Helpers.getInitialValuesProfile(this.props.me)}
        isRegistration={false}
        isSubmitting={this.props.updateMeState.loading}
        callbackSubmit={values => this.handleSubmit(values)}
        callbackSideUserAdd={() => this.setModalSideUserAddVisibility(true)}
        callbackSideUserDelete={sideUser => this.setSideUserToDelete(sideUser)}
      />
      );
  }

  render() {
    return (
      <div id="profile-page" className="page">
        <div className="page-content">
          <PageTitle text="Profil"/>
          {this.renderContent()}
        </div>

        <SideUserAddModal
          isVisible={this.state.isModalSideUserAddVisible}
          isSubmitting={this.props.addSideUserState.loading}
          callback={values => this.handleModalSideUserAdd(values)}
        />

        <SideUserAddSuccessModal
          isVisible={!!this.props.addSideUserState.data}
          callback={() => this.props.addSideUserReset()}
        />

        <SideUserDeleteApprovalModal
          sideUser={this.state.sideUserToDelete}
          isSubmitting={this.props.deleteSideUserState.loading}
          callback={sideUser => this.handleModalSideUserDeleteApproval(sideUser)}
        />

        <SideUserDeleteSuccessModal
          isVisible={!!this.props.deleteSideUserState.data}
          callback={() => this.props.deleteSideUserReset()}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      claimRevenue,
      claimRevenueReset,
      updateMe,
      getSideUsers,
      addSideUser,
      addSideUserReset,
      deleteSideUser,
      deleteSideUserReset,
      getWalletDetails,
    },
    dispatch
  );
};
const mapStateToProps = (store: IStore) => {
  return {
    me: store.setMe.me,
    updateMeState: store.updateMe,
    getSideUsersState: store.getSideUsers,
    addSideUserState: store.addSideUser,
    deleteSideUserState: store.deleteSideUser,
    getWalletDetailsState: store.getWalletDetails,
    claimRevenueState: store.claimRevenue,
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
