import {Dispatch} from 'redux';
import {ErrorDto, getErrorDtoFromApiError} from '../../../models/dtos/error.dto';
import {UsageDto} from '../../../models/dtos/usage.dto';
import {GET_USAGE_FAIL, GET_USAGE_RESET, GET_USAGE_START, GET_USAGE_SUCCESS,} from './constants';
import {getUsageRequest} from './repository';
import {GetUsageFailAction, GetUsageResetAction, GetUsageStartAction, GetUsageSuccessAction,} from './types';

const getUsageStartAction = (): GetUsageStartAction => {
  return {
    type: GET_USAGE_START,
  };
};
export const getUsageSuccessAction = (payload: UsageDto[]): GetUsageSuccessAction => {
  return {
    type: GET_USAGE_SUCCESS,
    payload: payload,
  };
};
const getUsageFailAction = (error: ErrorDto): GetUsageFailAction => {
  return {
    type: GET_USAGE_FAIL,
    error: error,
  };
};
const getUsageResetAction = (): GetUsageResetAction => {
  return {
    type: GET_USAGE_RESET,
  };
};

export const getUsage = () => async (dispatch: Dispatch) => {
  try {
    dispatch(getUsageStartAction());
    const response = await getUsageRequest();
    dispatch(getUsageSuccessAction(response));
  } catch (error) {
    dispatch(getUsageFailAction(getErrorDtoFromApiError(error)));
  }
};

export const getUsageReset = () => (dispatch: Dispatch) => {
  dispatch(getUsageResetAction());
};
