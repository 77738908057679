import {Input, Spin} from 'antd';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import AppButton from '../../../components/Buttons/AppButton/AppButton';
import {confirmEmail} from '../../../core/services/userService/confirmEmail/actions';
import {ConfirmEmailState} from '../../../core/services/userService/confirmEmail/types';
import {resendConfirmEmail} from '../../../core/services/userService/resendConfirmEmail/actions';
import {ResendConfirmEmailState} from '../../../core/services/userService/resendConfirmEmail/types';
import {IStore} from '../../../core/utilities/reducers';
import './ActivationPage.scss';

interface IProps {
  confirmEmailState: ConfirmEmailState;
  resendConfirmEmailState: ResendConfirmEmailState;
  confirmEmail: (code: string) => void;
  resendConfirmEmail: () => void;
}

interface IState {
  code: string;
}

class ActivationPage extends Component<IProps> {
  state: IState = {
    code: '',
  };

  private handleSubmit(): void {
    if (!this.state.code || this.props.confirmEmailState.loading) return;
    this.props.confirmEmail(this.state.code);
  }

  private handleResend(): void {
    if (this.props.resendConfirmEmailState.loading) return;
    this.setState({code: ''});
    this.props.resendConfirmEmail();
  }

  render() {
    return (
      <div id="activation-page" className="page">
        <div className="page-content">
          <p className="title">Üyeliğinizi aktive edin.</p>
          <div className="content">
            <div className="code-box">
              <label className="description">
                Lütfen verdiğiniz mail adresine gönderilen aktivasyon kodunu
                giriniz.
              </label>
              <Input
                className="code-input"
                autoFocus={true}
                value={this.state.code}
                onChange={(e) => this.setState({code: e.target.value})}
                onPressEnter={() => this.handleSubmit()}
              />
            </div>
            <AppButton
              content="KODU ONAYLA"
              disabled={!this.state.code}
              isLoading={this.props.confirmEmailState.loading}
              onClick={() => this.handleSubmit()}
            />
          </div>
          <label className="resend-button" onClick={() => this.handleResend()}>
            {this.props.resendConfirmEmailState.loading ? (
              <Spin/>
            ) : (
              'Kodu tekrar gönder'
            )}
          </label>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      confirmEmail,
      resendConfirmEmail,
    },
    dispatch
  );
};
const mapStateToProps = (store: IStore) => {
  return {
    confirmEmailState: store.confirmEmail,
    resendConfirmEmailState: store.resendConfirmEmail,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivationPage);
