import axios from '../../../utilities/axios';
import {UpdateMeRequest} from '../../../models/requests/updateMe.request';

export const updateMeRequest = async (request: UpdateMeRequest): Promise<void> => {
  try {
    const response = await axios.patch<void>(
      `${process.env.REACT_APP_BASE_URL}/users/details`,
      {...request},
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
