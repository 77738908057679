import axios from '../../../utilities/axios';
import {SaveCardDto} from '../../../models/dtos/saveCard.dto';
import {SaveCardRequest} from '../../../models/requests/saveCard.request';

export const saveCardRequest = async (request: SaveCardRequest): Promise<SaveCardDto> => {
  try {
    const response = await axios.post<SaveCardDto>(
      `${process.env.REACT_APP_BASE_URL}/card`,
      {...request},
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
